import { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { GeoJsonProperties } from "geojson";
import { Icons } from "kepler.gl/components";

import { TooltipBox } from ".";
import { useAppSelector } from "../../redux/hooks";
import { selectLayersSlice } from "../../redux/selectors/layers";

interface LayerTooltipProps {
  geoLayerId: EntityId;
  featureProps: GeoJsonProperties;
}

export const LayerTooltip: FC<LayerTooltipProps> = ({
  geoLayerId,
  featureProps,
}) => {
  const layers = useAppSelector(selectLayersSlice);
  const layer = layers.entities[geoLayerId];

  return layer ? (
    <TooltipBox title={layer.name} icon={<Icons.Layers />}>
      <table>
        <tbody>
          <tr>
            <td>Properties</td>
          </tr>
          {Object.entries(featureProps || {}).map(([k, v]) => (
            <tr key={k + "-props-row"}>
              <th className="userdata" scope="row" key={k + "-props-key"}>
                {k}
              </th>
              <td key={k + "-props-val"}>{String(v)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </TooltipBox>
  ) : null;
};
