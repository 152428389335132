type QueueState =
  | { state: "NONE" }
  | { state: "QUEUED_CHECK" }
  | { state: "QUEUED_CB"; cb: () => void };

const None = { state: "NONE" as const };
const QueuedCheck = { state: "QUEUED_CHECK" as const };
const QueuedCb = (cb: () => void) => ({ state: "QUEUED_CB" as const, cb });

export function adaptiveThrottle<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Fn extends (...args: any[]) => void,
>(slowFunction: Fn): Fn {
  // drawing stuff - repainting the map can be slow, so we want to reduce the amount of
  // dispatches we send, as they synchronously redraw the map.
  let state: QueueState = None;
  function _draw(sinceLastMs: DOMHighResTimeStamp | IdleDeadline) {
    switch (state.state) {
      case "NONE":
        return;
      case "QUEUED_CHECK":
        // the check is this _draw call, nothing has been added since the check was scheduled
        // so we're done
        state = None;
        return;
      case "QUEUED_CB":
        // in this case something HAS been added since the check, so
        state.cb(); // draw it
        state = QueuedCheck;
        _scheduleDraw(sinceLastMs);
        return;
    }
  }
  function _scheduleDraw(sinceLastMs: IdleDeadline | DOMHighResTimeStamp) {
    /* requestAnimationFrame is clearly the smoothest, but it does
      effectively the framerate of your app back to the execution time of slowFunction,
      which is what we are trying to avoid. So, we check our performance, and
      if it's good we use smooth requestAnimationFrame, and if it's bad we
      use janky requestIdleCallback (because that keeps the rest of the app smooth) */
    const PERIOD_30FPS = 1000 / 30;
    const PERIOD_15FPS = 1000 / 15;

    if (typeof sinceLastMs !== "number" || sinceLastMs > PERIOD_30FPS) {
      // if we've dropped below 30fps, fall back to requestIdleCallback.
      requestIdleCallback(_draw, { timeout: PERIOD_15FPS });
    } else {
      // if we're fast, stay fast.
      requestAnimationFrame(_draw);
    }
  }
  function throttledSlowFunction(..._args: Parameters<Fn>) {
    // eslint-disable-next-line prefer-rest-params
    const args = arguments as unknown as Parameters<Fn>;
    // put callbacks in queue
    // each frame, just execute the final one in the queue, drop the rest.
    // eslint-disable-next-line prefer-spread
    const cb = () => slowFunction.apply(null, args);
    const queued = state.state !== "NONE";
    state = QueuedCb(cb);
    // invariant: if pendingDraw is not null, then there is an idleCallback/animationFrame scheduled to
    // call it.
    if (!queued) {
      // so if pendingDraw *WAS* null then we need to kick off the draws.
      _scheduleDraw(0);
    }
  }
  return throttledSlowFunction as Fn;
}
