export type Timezone = {
  ianaId: string;
  displayName: string;
  group: string;
};

const ianaToTimezone = (iana: string): Timezone => {
  const [group, ..._zone] = iana.split("/");
  const friendly = Intl.DateTimeFormat(undefined, {
    day: "2-digit",
    timeZone: iana,
    timeZoneName: "longGeneric",
  })
    .format(undefined)
    .slice(4);
  return {
    ianaId: iana,
    displayName: friendly,
    group,
  };
};

const browserIANA = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const defaultTimezone = {
  ianaId: browserIANA,
  displayName: `Browser Default (${browserIANA})`,
  group: "Shortcuts",
};

const additionalTimezones: Array<Timezone> = [
  defaultTimezone,
  {
    ianaId: "UTC",
    displayName: "Coordinated Universal Time (UTC)",
    group: "Shortcuts",
  },
];

const timezones = additionalTimezones.concat(
  Intl.supportedValuesOf("timeZone").sort().map(ianaToTimezone),
);

export default timezones;
