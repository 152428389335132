import { useCallback } from "react";

import {
  ANNOTATOR_MODAL_TYPE,
  AnnotatorModalType,
  EVENT_GENERATOR_MODAL_TYPE,
  EVENT_LOG_MODAL_TYPE,
  EventGeneratorModalType,
  NONE_MODAL,
  PROJECT_MODAL_TYPE,
  ProjectModalType,
  showModal,
} from "../../redux/reducers/ui/modal";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Modal } from "./Modal";
import { EventGeneratorBuilder } from "./event-generator-builder/EventGeneratorBuilder";
import { EventLog } from "./event-log/EventLog";
import { AnnotatorBuilder } from "./annotator-builder/AnnotatorBuilder";
import { ProjectForm } from "./project/ProjectForm";

export const Modals = () => {
  const dispatch = useAppDispatch();
  const current = useAppSelector((state) => state.app.ui.modal.current);

  const onClose = useCallback(() => dispatch(showModal(NONE_MODAL)), []);

  return (
    <>
      <Modal
        title={"eventGenerator.builder.title"}
        open={current.type === EVENT_GENERATOR_MODAL_TYPE}
        onClose={onClose}
        maxWidth="sm"
      >
        <EventGeneratorBuilder
          onClose={onClose}
          eventGenerator={(current as EventGeneratorModalType).payload}
        />
      </Modal>
      <Modal
        title={"events.log.title"}
        open={current.type === EVENT_LOG_MODAL_TYPE}
        onClose={onClose}
        fullScreen
      >
        <EventLog onClose={onClose} />
      </Modal>
      <Modal
        title={"annotator.modal.title"}
        open={current.type === ANNOTATOR_MODAL_TYPE}
        onClose={onClose}
        maxWidth="lg"
      >
        <AnnotatorBuilder
          onClose={onClose}
          annotator={(current as AnnotatorModalType).payload}
        />
      </Modal>
      <Modal
        title={"project.modal.title"}
        open={current.type === PROJECT_MODAL_TYPE}
        onClose={onClose}
        maxWidth="lg"
      >
        <ProjectForm
          onClose={onClose}
          project={(current as ProjectModalType).payload}
        />
      </Modal>
    </>
  );
};
