/* eslint-disable @typescript-eslint/no-unused-vars */

// this is injected as an esbuild `define`. use it for stuff like
// ASSERTS && assert(some_value !== undefined)
declare interface Window {
  ASSERTS: true;
}

declare const ESBUILD_ASSERTS: true;
declare const ASSERTS: true;

window.ASSERTS = ESBUILD_ASSERTS;

declare const STATIC_PATH: string;
