import { TimeRange } from "../../../redux/reducers/time-control";
import {
  fromUnixTime,
  getUnixTime,
  Interval,
  intervalToDuration,
  secondsToHours,
  eachMinuteOfInterval,
  eachHourOfInterval,
} from "date-fns";
import {
  computeChunkBounds,
  computeChunksInRange,
  DeviceState,
  DeviceStatesRoot,
} from "../../../redux/reducers/device-state";
import { EntityId } from "@reduxjs/toolkit";
import { EventsRoot, Event } from "../../../redux/reducers/events";
import { ChunkId } from "../../../redux/reducers/chunks";

type HistogramData = { x0: number; x1: number; y: number }[];

export function _getHistogramBinEdges(slider: TimeRange): Date[] | undefined {
  const sliderInterval: Interval = {
    start: fromUnixTime(slider.end - slider.length),
    end: fromUnixTime(slider.end),
  };
  const sliderDuration = intervalToDuration(sliderInterval);
  const sdSecs = slider.length;
  const sdHours = secondsToHours(sdSecs);
  const sdDays = sliderDuration.days || 0;

  let bins: Date[];
  if (sdHours <= 1) {
    bins = eachMinuteOfInterval(sliderInterval);
  } else if (sdHours <= 2) {
    bins = eachMinuteOfInterval(sliderInterval, { step: 2 });
  } else if (sdHours <= 4) {
    bins = eachMinuteOfInterval(sliderInterval, { step: 5 });
  } else if (sdHours <= 24) {
    bins = eachMinuteOfInterval(sliderInterval, { step: 15 });
  } else if (sdDays < 2) {
    bins = eachMinuteOfInterval(sliderInterval, { step: 30 });
  } else if (sdDays < 5) {
    bins = eachHourOfInterval(sliderInterval);
  } else {
    bins = eachHourOfInterval(sliderInterval, { step: 6 });
  }

  if (120 < bins.length) {
    console.warn("histogram would be too detailed, refusing to build it");
    return undefined;
  }

  return bins;
}

// a HistogramCounter is expected to mutate histogramData with new counts.
// When we finallly get around to memoizing this, the results of this are
// the natural place to put them.
type HistogramCounter = (
  edges: Date[],
  histogramData: HistogramData,
  chunkId: ChunkId,
) => void;

export const _countDeviceStates = (deviceStates: DeviceStatesRoot) =>
  function deviceStatesCounter(
    edges: Date[],
    histogramData: HistogramData,
    chunkId: ChunkId,
  ) {
    const devices = deviceStates.chunks[chunkId]?.states;
    if (!devices) {
      return;
    }
    const iDSes: { [deviceId: EntityId]: number } = {};

    let _bin: HistogramData[0];
    iterBins: for (let i = 0; i < edges.length - 1; i++) {
      _bin = histogramData[i];

      // skip this bin for this chunk if they don't intersect
      const { startIncl, endExcl } = computeChunkBounds(chunkId);
      if (_bin.x1 < startIncl || endExcl <= _bin.x0) {
        continue iterBins;
      }

      iterDevices: for (const deviceId of devices.ids) {
        const device = devices.entities[deviceId];
        if (!device) {
          continue iterDevices;
        }

        let iDS = iDSes[deviceId] || 0;
        let ds: DeviceState;

        // skip deviceStates before our bin starts
        for (iDS; iDS < device.states.length; iDS++) {
          ds = device.states[iDS];
          if (_bin.x0 <= ds.time) {
            break;
          }
          // continue;
        }

        // count deviceStates before our bin ends
        for (iDS; iDS < device.states.length; iDS++) {
          ds = device.states[iDS];
          if (ds.time < _bin.x1) {
            _bin.y += 1;
          } else {
            break;
          }
        }
        // save the device state we were up to, the next bin will start from here
        iDSes[deviceId] = iDS;
      }
    }
  };

export const _countEvents = (eventsRoot: EventsRoot) =>
  function eventsCounter(
    edges: Date[],
    histogramData: HistogramData,
    chunkId: ChunkId,
  ) {
    const events = eventsRoot.chunks[chunkId]?.events;
    if (!events) {
      return;
    }

    let iEvent = 0;
    let _bin: HistogramData[0];

    iterBins: for (let i = 0; i < edges.length - 1; i++) {
      _bin = histogramData[i];

      // skip this bin for this chunk if they don't intersect
      const { startIncl, endExcl } = computeChunkBounds(chunkId);
      if (_bin.x1 < startIncl || endExcl <= _bin.x0) {
        continue iterBins;
      }

      let e: Event;

      // assumption: events must be ordered.

      // skip events before our bin starts
      for (iEvent; iEvent < events.length; iEvent++) {
        e = events[iEvent];
        if (_bin.x0 <= e.time) {
          break;
        }
        // continue;
      }

      // count event before our bin ends
      for (iEvent; iEvent < events.length; iEvent++) {
        e = events[iEvent];
        if (e.time < _bin.x1) {
          _bin.y += 1;
        } else {
          break;
        }
      }
      // iEvent is the event we were up to, the next bin will start from here.
    }
  };

export function _getHistogramBinCounts(
  edges: Date[],
  counter: HistogramCounter,
) {
  // according to dateFns docs, the each*OfInterval functions interpret the interval with inclusive bounds at both ends.
  const _histogramData: HistogramData = Array(edges.length - 1);

  for (let i = 0; i < edges.length - 1; i++) {
    // initialize the bins
    _histogramData[i] = {
      x0: getUnixTime(edges[i]),
      x1: getUnixTime(edges[i + 1]),
      y: 0,
    };
  }

  const wantedChunks = computeChunksInRange(
    getUnixTime(edges[0]),
    getUnixTime(edges.at(-1)!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
  );

  for (const chunkId of wantedChunks) {
    counter(edges, _histogramData, chunkId);
  }

  // postprocess the bins to make kepler happy, yes we could do this above but
  // it's ugly enough as it is
  let iCountedAnything = false;
  for (let i = 0; i < edges.length - 1; i++) {
    if (_histogramData[i].y > 0) {
      iCountedAnything = true;
    }
    _histogramData[i].x0 *= 1000;
    _histogramData[i].x1 *= 1000;
  }

  if (!iCountedAnything) {
    // a histogram of all 0s renders really poorly.
    return undefined;
  }
  return _histogramData;
}

export function getHistogramData(
  slider: TimeRange,
  deviceStates: DeviceStatesRoot,
): HistogramData | undefined {
  const bins = _getHistogramBinEdges(slider);
  if (!bins) {
    return undefined;
  }
  const counter = _countDeviceStates(deviceStates);
  return _getHistogramBinCounts(bins, counter);
}

export function getEventHistogramData(
  slider: TimeRange,
  events: EventsRoot,
): HistogramData | undefined {
  const bins = _getHistogramBinEdges(slider);
  if (!bins) {
    return undefined;
  }
  const counter = _countEvents(events);
  return _getHistogramBinCounts(bins, counter);
}
