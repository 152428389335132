import { FC, FormEvent } from "react";
import { Icons, InlineInput, PanelHeaderAction } from "kepler.gl/components";

import {
  Alertor,
  updateAlertor,
  deleteAlertorRequested,
} from "../../../redux/reducers/alertor";
import {
  HeaderActionSection,
  HeaderLabelSection,
  StyledPanelHeaderWrapper,
} from "../../common";
import { useAppDispatch } from "../../../redux/hooks";
import { startConfirm } from "../../../redux/reducers/ui/confirm";

const AlertorNameEditor: FC<AlertorPanelHeaderProps> = ({ model }) => {
  const dispatch = useAppDispatch();

  const onEdit = (e: FormEvent<HTMLInputElement>) => {
    dispatch(
      updateAlertor({
        id: model.id,
        changes: { name: e.currentTarget.value },
      }),
    );
  };

  return (
    <InlineInput
      type="text"
      value={model.name}
      onClick={(e: FormEvent<HTMLInputElement>) => {
        e.stopPropagation();
      }}
      onChange={onEdit}
      id={`${model.name}:input-alert-config-label`}
    />
  );
};

type AlertorPanelHeaderProps = {
  model: Alertor;
};

export const AlertorPanelHeader: FC<AlertorPanelHeaderProps> = ({ model }) => {
  const dispatch = useAppDispatch();

  const onToggleConfig = () => {
    dispatch(
      updateAlertor({
        id: model.id,
        changes: { showConfig: !model.showConfig },
      }),
    );
  };

  const onDelete = () => {
    dispatch(
      startConfirm({
        description: { id: "alertor.manager.confirmDelete" },
        onAccept: deleteAlertorRequested(model),
        variant: "delete",
      }),
    );
  };

  return (
    <StyledPanelHeaderWrapper
      active={model.showConfig}
      labelRCGColorValues={model.color}
    >
      <HeaderLabelSection>
        <Icons.VertDots height="20px" />
        <AlertorNameEditor model={model} />
      </HeaderLabelSection>
      <HeaderActionSection>
        <PanelHeaderAction
          id={model.id}
          tooltip="tooltip.deleteAlertor"
          onClick={onDelete}
          IconComponent={Icons.Trash}
        />
        <PanelHeaderAction
          onClick={onToggleConfig}
          id={model.id}
          tooltip={"tooltip.alertorSettings"}
          IconComponent={model.showConfig ? Icons.ArrowUp : Icons.ArrowDown}
        />
      </HeaderActionSection>
    </StyledPanelHeaderWrapper>
  );
};
