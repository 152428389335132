import { createAction, createReducer } from "@reduxjs/toolkit";
import { Annotator } from "../annotator";
import { EventGenerator } from "../event-generators";
import { Project } from "../projects";

export const NONE_MODAL_TYPE = "NONE";
export const EVENT_GENERATOR_MODAL_TYPE = "EVENT_GENERATOR_MODAL";
export const EVENT_LOG_MODAL_TYPE = "EVENT_LOG_MODAL";
export const ANNOTATOR_MODAL_TYPE = "ANNOTATOR_MODAL";
export const PROJECT_MODAL_TYPE = "PROJECT_MODAL";

export type NoneModalType = {
  type: typeof NONE_MODAL_TYPE;
};

export const NONE_MODAL: NoneModalType = { type: NONE_MODAL_TYPE };

export type EventGeneratorModalType = {
  type: typeof EVENT_GENERATOR_MODAL_TYPE;
  payload?: EventGenerator;
};

export const newEventGeneratorModal = (
  payload?: EventGenerator,
): EventGeneratorModalType => ({
  type: EVENT_GENERATOR_MODAL_TYPE,
  payload,
});

export type EventLogModalType = {
  type: typeof EVENT_LOG_MODAL_TYPE;
};

export const EVENT_LOG_MODAL: EventLogModalType = {
  type: EVENT_LOG_MODAL_TYPE,
};

export type AnnotatorModalType = {
  type: typeof ANNOTATOR_MODAL_TYPE;
  payload?: Annotator;
};

export const newAnnotatorModal = (payload?: Annotator): AnnotatorModalType => ({
  type: ANNOTATOR_MODAL_TYPE,
  payload,
});

export type ProjectModalType = {
  type: typeof PROJECT_MODAL_TYPE;
  payload?: Project;
};

export const newProjectModal = (payload?: Project): ProjectModalType => ({
  type: PROJECT_MODAL_TYPE,
  payload,
});

export type ModalType =
  | NoneModalType
  | EventGeneratorModalType
  | EventLogModalType
  | AnnotatorModalType
  | ProjectModalType;

export const showModal = createAction<ModalType>("modals/showModal");

export type ModalState = {
  current: ModalType;
};

const initialState = { current: NONE_MODAL } as ModalState;

export const modal = createReducer(initialState, (builder) => {
  builder.addCase(showModal, (state, action) => {
    state.current = action.payload;
  });
});
