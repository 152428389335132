import { FC } from "react";
import { useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  changeProjectAndNavigateToDashboard,
  deleteProject,
  Project,
} from "../../redux/reducers/projects";
import { useAppDispatch } from "../../redux/hooks";
import { newProjectModal, showModal } from "../../redux/reducers/ui/modal";
import { startConfirm } from "../../redux/reducers/ui/confirm";

type ProjectCardProps = {
  project?: Project;
};

const CARD_HEIGHT = 150;
const CARD_WIDTH = 350;
const CARD_CONTENT_HEIGHT = 115;

export const ProjectCard: FC<ProjectCardProps> = ({ project }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showSkeleton = !project;
  return (
    <Card sx={{ height: CARD_HEIGHT, width: CARD_WIDTH }}>
      <CardActionArea
        onClick={
          showSkeleton
            ? undefined
            : () =>
                dispatch(
                  changeProjectAndNavigateToDashboard({
                    to: project.id,
                    navigate,
                  }),
                )
        }
        // default component is button, but there are buttons nested within
        // and nesting buttons is not cool:
        // https://www.w3docs.com/snippets/css/is-it-possible-to-nest-an-html-button-element-inside-an-a-element-in-html5.html
        component="div"
        disabled={showSkeleton}
        sx={{ height: CARD_HEIGHT }}
      >
        <CardContent sx={{ pb: 1, height: CARD_CONTENT_HEIGHT }}>
          <>
            <Tooltip title={project?.name || ""}>
              <Typography variant="h5" component="h5" noWrap>
                {showSkeleton ? (
                  <Skeleton animation="wave" width="30%" />
                ) : (
                  project.name
                )}
              </Typography>
            </Tooltip>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
            >
              {showSkeleton ? (
                <>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </>
              ) : (
                project.description
              )}
            </Typography>
          </>
        </CardContent>
        <CardActions sx={{ pt: 0 }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            width="100%"
          >
            {showSkeleton ? null : (
              <>
                <Button
                  size="small"
                  color="primary"
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(showModal(newProjectModal(project)));
                  }}
                >
                  <FormattedMessage id="project.card.action.edit" />
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      startConfirm({
                        description: {
                          id: "project.card.action.confirmDelete",
                        },
                        onAccept: deleteProject(project.id),
                        variant: "delete",
                      }),
                    );
                  }}
                >
                  <FormattedMessage id="project.card.action.delete" />
                </Button>
              </>
            )}
          </Box>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};
