import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ClientReadableStream,
  Request,
  StreamInterceptor,
  UnaryInterceptor,
  UnaryResponse,
} from "grpc-web";
import { Message } from "google-protobuf";
import { identifyToFullStory } from "../integrations/fullStory";

import { AppServicePromiseClient } from "../generated/sora/app/v1beta/service_grpc_web_pb";

import {
  SERVER_URL,
  AUTH0_ACCOUNT_ID_CLAIM,
  AUTH0_SUBDOMAIN_CLAIM,
} from "../constants";

const authenticateRequest = <REQ, RESP>(
  request: Request<REQ, RESP>,
  token: string,
) => {
  const metadata = request.getMetadata();
  metadata.Authorization = `Bearer ${token}`;
};

class UnaryAuthInterceptor<REQ, RESP> implements UnaryInterceptor<REQ, RESP> {
  token: string;

  constructor(token: string) {
    this.token = token;
  }

  intercept(
    request: Request<REQ, RESP>,
    invoker: (_: Request<REQ, RESP>) => Promise<UnaryResponse<REQ, RESP>>,
  ): Promise<UnaryResponse<REQ, RESP>> {
    authenticateRequest(request, this.token);
    return invoker(request);
  }
}

class StreamAuthInterceptor<REQ, RESP> implements StreamInterceptor<REQ, RESP> {
  token: string;

  constructor(token: string) {
    this.token = token;
  }

  intercept(
    request: Request<REQ, RESP>,
    invoker: (_: Request<REQ, RESP>) => ClientReadableStream<RESP>,
  ): ClientReadableStream<RESP> {
    authenticateRequest(request, this.token);
    return invoker(request);
  }
}

export const AppServiceContext = createContext({
  appService: undefined,
  hasToken: false,
  subdomain: undefined,
} as {
  appService?: AppServicePromiseClient;
  hasToken: boolean;
  subdomain?: string;
});

type AppServiceProviderProps = {
  children: ReactNode;
};

export const AppServiceProvider: FC<AppServiceProviderProps> = ({
  children,
}) => {
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    error,
    user,
    isLoading,
    isAuthenticated,
  } = useAuth0();
  const [authOpts, setAuthOpts] = useState({
    unaryInterceptors: [] as UnaryInterceptor<Message, Message>[],
    streamInterceptors: [] as StreamInterceptor<Message, Message>[],
  });
  const [hasToken, setHasToken] = useState(false);
  const [subdomain, setSubDomain] = useState<string | undefined>(undefined);

  const devOpts = useMemo(() => {
    if (process.env.NODE_ENV === "production") {
      return {
        unaryInterceptors: [],
        streamInterceptors: [],
      };
    }

    // This is a fork of the original grpc-web-devtools on the official
    // Firefox/Chrome extension sites.
    // The original would not work with other interceptors.
    //
    // See https://github.com/jrapoport/grpc-web-devtools
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const devInterceptors = window.__GRPCWEB_DEVTOOLS__;

    if (!devInterceptors) {
      return {
        unaryInterceptors: [],
        streamInterceptors: [],
      };
    }

    const { devToolsUnaryInterceptor, devToolsStreamInterceptor } =
      devInterceptors();

    if (!devToolsUnaryInterceptor || !devToolsStreamInterceptor) {
      return {
        unaryInterceptors: [],
        streamInterceptors: [],
      };
    }

    return {
      unaryInterceptors: [devToolsUnaryInterceptor],
      streamInterceptors: [devToolsStreamInterceptor],
    };
  }, []);

  useEffect(() => {
    async function getAccessToken(): Promise<string | undefined> {
      // already authed? this should return from cache.
      if (isAuthenticated) {
        return await getAccessTokenSilently();
        // auth0 stuff loading? Don't bother, this useEffect will reexec when isLoading is done.
      } else if (isLoading) {
        return undefined;
      }

      // first try and actively get an access token
      try {
        return await getAccessTokenSilently();
      } catch (e) {
        // that might fail, if so pop up a real login.
        console.warn("initial getAccessToken failed, trying a login", e);
        await loginWithRedirect();
        return undefined;
      }
    }

    async function initialize() {
      const accessToken = await getAccessToken();
      if (accessToken === undefined) {
        return;
      }

      if (user) {
        const claimsStr = Object.entries(user)
          .map(([k, v]) => `${k}=${v}`)
          .join(",\n");
        const subdomain = user[AUTH0_SUBDOMAIN_CLAIM];
        if (!subdomain || typeof subdomain !== "string") {
          throw new Error(
            `Subdomain claim ${AUTH0_SUBDOMAIN_CLAIM} is missing from the Auth0 user/app_metadata. Found claims: \n${claimsStr}`,
          );
        }
        const accountId = user[AUTH0_ACCOUNT_ID_CLAIM];
        if (!accountId || typeof accountId !== "string") {
          throw new Error(
            `Account ID claim ${AUTH0_ACCOUNT_ID_CLAIM} is missing from the Auth0 user/app_metadata. Found claims: \n${claimsStr}`,
          );
        }
        setSubDomain(subdomain);
        identifyToFullStory(accountId, user);
      }

      if (!hasToken) {
        // it is crucial that authOpts are set BEFORE hasToken
        setAuthOpts({
          unaryInterceptors: [
            new UnaryAuthInterceptor<Message, Message>(accessToken),
          ],
          streamInterceptors: [
            new StreamAuthInterceptor<Message, Message>(accessToken),
          ],
        });

        setHasToken(true);
      }
    }

    initialize().catch((_e) => {
      console.error("auth initialize error", _e);
      // an exception is expected to be thrown on first load
    });
  }, [getAccessTokenSilently, isLoading, isAuthenticated, hasToken]);

  useEffect(() => {
    if (error) {
      console.error("Auth0 error:", error);
    }
  });

  const appService = useMemo(() => {
    const opts = {
      unaryInterceptors: [
        ...devOpts.unaryInterceptors,
        ...authOpts.unaryInterceptors,
      ],
      streamInterceptors: [
        ...devOpts.streamInterceptors,
        ...authOpts.streamInterceptors,
      ],
      grpcArgKeepalivePermitWithoutCalls: 1,
      grpcArgHttp2MaxPingsWithoutData: 0,
    };

    return hasToken
      ? new AppServicePromiseClient(SERVER_URL, null, opts)
      : undefined;
  }, [devOpts, hasToken]);

  return (
    <AppServiceContext.Provider value={{ appService, hasToken, subdomain }}>
      {children}
    </AppServiceContext.Provider>
  );
};
