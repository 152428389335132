import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton, Typography } from "@mui/material";

export default () => {
  const { logout } = useAuth0();

  return (
    <IconButton
      onClick={() => logout({ returnTo: window.location.origin })}
      sx={{
        color: "#6a7485",
        "&:hover": {
          color: "white",
        },
      }}
    >
      <LogoutIcon fontSize="small" />
      <Typography
        sx={{
          marginLeft: "8px",
          color: "#6a7485",
        }}
      >
        Logout
      </Typography>
    </IconButton>
  );
};
