import styled from "styled-components";
import { StyledPanelHeader } from "kepler.gl/components";

export const IconButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-width: 0px;

  color: ${({ theme }) => theme.labelColor};

  transition: all 0.2s ease;

  &:not(:disabled) {
    cursor: pointer;

    &.active {
      color: ${({ theme }) => theme.labelHoverColor};
    }

    &:hover {
      color: ${({ theme }) => theme.labelHoverColor};
    }
  }

  svg {
    outline: none;
  }
`;

export const SidePanelIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  margin-top: -44px;
`;

export const HeaderLabelSection = styled.div`
  display: flex;
  color: ${({ theme }) => theme.textColor};
`;

export const HeaderActionSection = styled.div`
  display: flex;
  svg {
    outline: none;
  }
`;

export const FieldName = styled.div`
  display: flex;
  color: ${({ theme }) => theme.textColor};
`;

export const FieldValue = styled.div`
  display: flex;
  color: ${({ theme }) => theme.subtextColor};
`;

export const StyledPanelHeaderWrapper = styled(StyledPanelHeader)`
  :hover {
    background-color: ${({ theme }) => theme.panelBackgroundHover};
  }
`;

export const EvenlySpaced = styled.div`
  display: flex;
  justify-content: space-between;
`;
