import { ScenegraphLayer } from "@deck.gl/mesh-layers";
import { createSelector } from "@reduxjs/toolkit";
import { RGBAColor } from "@deck.gl/core";

import { RootState } from "../redux/store";
import { DeviceState } from "../redux/reducers/device-state";

import { PitchYawRoll, Position3D } from "@deck.gl/core/utils/positions";
import { setOpacity } from "../utils/color";
import {
  selectColors,
  selectCurrDeviceState,
  selectDeviceNames,
  selectIds,
  selectOrientations,
  selectPositions,
} from "../redux/selectors/devices";

import {
  IconURLMapGLB,
  ProjectIcon,
  projectSelectors,
} from "../redux/reducers/projects";
import { TooltipProps } from "../components/tooltips";
import { useAppSelector } from "../redux/hooks";
import { selectProjectIdFromRoute } from "../redux/selectors/router";
import { selectVisible } from "../redux/selectors/settings";
import { EntityType } from "../redux/reducers/settings";
import { rotateForDeckGL } from "../utils/orientation";

export type DeviceStateData = {
  pos: Position3D;
  orientation: PitchYawRoll;
  color: RGBAColor;
  name: string;
  state: DeviceState;
  visible: boolean;
};

const selectData = createSelector(
  selectPositions,
  selectOrientations,
  selectColors,
  selectIds,
  selectVisible(EntityType.Device),
  selectDeviceNames,
  selectCurrDeviceState,
  (ps, os, cs, ids, vs, ns, ss) =>
    ps
      .map((p, ix) => ix)
      .filter((ix) => ps[ix] !== undefined)
      .map(
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        // filtering out pos undefined should also guarantee
        // that pos, orientation are defined. I haven't included an assertion
        // here for performance reasons.
        (ix) => ({
          pos: ps[ix]!,
          orientation: rotateForDeckGL(os[ix]),
          color: cs[ix],
          name: ns[ix],
          state: ss[ix]!,
          visible: vs[ids[ix]] ?? true,
        }),
      ),
  /* eslint-enable */
);

export default function (
  state: RootState,
  setTooltip: (_: TooltipProps) => void,
) {
  const data = selectData(state);
  const projectId = useAppSelector(selectProjectIdFromRoute);
  const icon: ProjectIcon = useAppSelector(
    (state) =>
      projectSelectors.selectById(state, projectId || "")?.icon ||
      ProjectIcon.bus,
  );

  return [
    new ScenegraphLayer<DeviceStateData>({
      id: "devices-layer",
      data,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scenegraph: IconURLMapGLB[icon] as any,
      pickable: true,
      autoHighlight: true,
      highlightColor: [255, 0, 0, 255],
      getPosition: (d) => d.pos,
      getOrientation: (d) => d.orientation,
      sizeMinPixels: 3,
      getColor: (d) => setOpacity(d.color, d.visible ? 155 : 0),
      onHover: (info) => setTooltip({ tooltipType: "device", hoverInfo: info }),
    }),
  ];
}
