import { all, put, select, takeLatest } from "redux-saga/effects";
import { updateMap } from "kepler.gl/actions";

import { centerDevice } from "../reducers/devices";
import { centerSpaceTime } from "../reducers/events";
import { DeviceState } from "../reducers/device-state";
import { Device } from "../reducers/devices";
import { RootState } from "../store";
import { selectCurrDeviceState, selectIds } from "../selectors/devices";

// Chosen so that event will be visible when you zoom into them and there is a device
// on the same position on the map. Make sure you visually test that this still works
// when you change this.
const ZOOM_LEVEL = 18;

const deviceToCurrState = (
  state: RootState,
  device: Device,
): DeviceState | undefined => {
  const currentStates = selectCurrDeviceState(state);
  const ids = selectIds(state);
  const ix = ids.findIndex((id) => id == device.id);
  if (ix == -1) {
    console.warn(
      "deviceToCurrState tried to state of a device that has no state in the current slider.",
    );
    return undefined;
  }
  return currentStates[ix];
};

function* centerDeviceSaga(action: ReturnType<typeof centerDevice>) {
  const deviceState: DeviceState | undefined = yield select(
    deviceToCurrState,
    action.payload,
  );
  if (deviceState) {
    yield put(
      updateMap({
        latitude: deviceState.pos.lat,
        longitude: deviceState.pos.lon,
        zoom: ZOOM_LEVEL,
      }),
    );
  }
}

function* centerSpaceTimeSaga(action: ReturnType<typeof centerSpaceTime>) {
  yield put(
    updateMap({
      latitude: action.payload.lat,
      longitude: action.payload.lon,
      zoom: ZOOM_LEVEL,
    }),
  );
}

export default function* updateMapSaga() {
  yield all([
    takeLatest(centerDevice, centerDeviceSaga),
    takeLatest(centerSpaceTime, centerSpaceTimeSaga),
  ]);
}
