/**
 * @fileoverview gRPC-Web generated client stub for sora.app.v1beta
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: sora/app/v1beta/service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var sora_app_v1beta_types_pb = require('../../../sora/app/v1beta/types_pb.js')

var sora_v1beta_common_pb = require('../../../sora/v1beta/common_pb.js')
const proto = {};
proto.sora = {};
proto.sora.app = {};
proto.sora.app.v1beta = require('./service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sora.app.v1beta.AppServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sora.app.v1beta.AppServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.StreamStateRequest,
 *   !proto.sora.app.v1beta.StreamStateResponse>}
 */
const methodDescriptor_AppService_StreamState = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/StreamState',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sora.app.v1beta.StreamStateRequest,
  proto.sora.app.v1beta.StreamStateResponse,
  /**
   * @param {!proto.sora.app.v1beta.StreamStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.StreamStateResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.StreamStateRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.StreamStateResponse>}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.streamState =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sora.app.v1beta.AppService/StreamState',
      request,
      metadata || {},
      methodDescriptor_AppService_StreamState);
};


/**
 * @param {!proto.sora.app.v1beta.StreamStateRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.StreamStateResponse>}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.streamState =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sora.app.v1beta.AppService/StreamState',
      request,
      metadata || {},
      methodDescriptor_AppService_StreamState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.StreamEventsRequest,
 *   !proto.sora.app.v1beta.StreamEventsResponse>}
 */
const methodDescriptor_AppService_StreamEvents = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/StreamEvents',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sora.app.v1beta.StreamEventsRequest,
  proto.sora.app.v1beta.StreamEventsResponse,
  /**
   * @param {!proto.sora.app.v1beta.StreamEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.StreamEventsResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.StreamEventsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.StreamEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.streamEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sora.app.v1beta.AppService/StreamEvents',
      request,
      metadata || {},
      methodDescriptor_AppService_StreamEvents);
};


/**
 * @param {!proto.sora.app.v1beta.StreamEventsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.StreamEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.streamEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sora.app.v1beta.AppService/StreamEvents',
      request,
      metadata || {},
      methodDescriptor_AppService_StreamEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.QueryStateRequest,
 *   !proto.sora.app.v1beta.QueryStateResponse>}
 */
const methodDescriptor_AppService_QueryState = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/QueryState',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.QueryStateRequest,
  proto.sora.app.v1beta.QueryStateResponse,
  /**
   * @param {!proto.sora.app.v1beta.QueryStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.QueryStateResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.QueryStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.QueryStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.QueryStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.queryState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/QueryState',
      request,
      metadata || {},
      methodDescriptor_AppService_QueryState,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.QueryStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.QueryStateResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.queryState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/QueryState',
      request,
      metadata || {},
      methodDescriptor_AppService_QueryState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.QueryEventsRequest,
 *   !proto.sora.app.v1beta.QueryEventsResponse>}
 */
const methodDescriptor_AppService_QueryEvents = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/QueryEvents',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.QueryEventsRequest,
  proto.sora.app.v1beta.QueryEventsResponse,
  /**
   * @param {!proto.sora.app.v1beta.QueryEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.QueryEventsResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.QueryEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.QueryEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.QueryEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.queryEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/QueryEvents',
      request,
      metadata || {},
      methodDescriptor_AppService_QueryEvents,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.QueryEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.QueryEventsResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.queryEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/QueryEvents',
      request,
      metadata || {},
      methodDescriptor_AppService_QueryEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.QueryStateAggregateRequest,
 *   !proto.sora.app.v1beta.QueryStateAggregateResponse>}
 */
const methodDescriptor_AppService_QueryStateAggregate = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/QueryStateAggregate',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.QueryStateAggregateRequest,
  proto.sora.app.v1beta.QueryStateAggregateResponse,
  /**
   * @param {!proto.sora.app.v1beta.QueryStateAggregateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.QueryStateAggregateResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.QueryStateAggregateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.QueryStateAggregateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.QueryStateAggregateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.queryStateAggregate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/QueryStateAggregate',
      request,
      metadata || {},
      methodDescriptor_AppService_QueryStateAggregate,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.QueryStateAggregateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.QueryStateAggregateResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.queryStateAggregate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/QueryStateAggregate',
      request,
      metadata || {},
      methodDescriptor_AppService_QueryStateAggregate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.ReadLayersRequest,
 *   !proto.sora.app.v1beta.ReadLayersResponse>}
 */
const methodDescriptor_AppService_ReadLayers = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/ReadLayers',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.ReadLayersRequest,
  proto.sora.app.v1beta.ReadLayersResponse,
  /**
   * @param {!proto.sora.app.v1beta.ReadLayersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.ReadLayersResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.ReadLayersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.ReadLayersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.ReadLayersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.readLayers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadLayers',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadLayers,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.ReadLayersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.ReadLayersResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.readLayers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadLayers',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadLayers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.CreateLayerRequest,
 *   !proto.sora.app.v1beta.CreateLayerResponse>}
 */
const methodDescriptor_AppService_CreateLayer = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/CreateLayer',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.CreateLayerRequest,
  proto.sora.app.v1beta.CreateLayerResponse,
  /**
   * @param {!proto.sora.app.v1beta.CreateLayerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.CreateLayerResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.CreateLayerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.CreateLayerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.CreateLayerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.createLayer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateLayer',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateLayer,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.CreateLayerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.CreateLayerResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.createLayer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateLayer',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateLayer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.UpdateLayerRequest,
 *   !proto.sora.app.v1beta.UpdateLayerResponse>}
 */
const methodDescriptor_AppService_UpdateLayer = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/UpdateLayer',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.UpdateLayerRequest,
  proto.sora.app.v1beta.UpdateLayerResponse,
  /**
   * @param {!proto.sora.app.v1beta.UpdateLayerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.UpdateLayerResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.UpdateLayerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.UpdateLayerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.UpdateLayerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.updateLayer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateLayer',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateLayer,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.UpdateLayerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.UpdateLayerResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.updateLayer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateLayer',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateLayer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.DeleteLayerRequest,
 *   !proto.sora.app.v1beta.DeleteLayerResponse>}
 */
const methodDescriptor_AppService_DeleteLayer = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/DeleteLayer',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.DeleteLayerRequest,
  proto.sora.app.v1beta.DeleteLayerResponse,
  /**
   * @param {!proto.sora.app.v1beta.DeleteLayerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.DeleteLayerResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.DeleteLayerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.DeleteLayerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.DeleteLayerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.deleteLayer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteLayer',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteLayer,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.DeleteLayerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.DeleteLayerResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.deleteLayer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteLayer',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteLayer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.ReadEventGeneratorsRequest,
 *   !proto.sora.app.v1beta.ReadEventGeneratorsResponse>}
 */
const methodDescriptor_AppService_ReadEventGenerators = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/ReadEventGenerators',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.ReadEventGeneratorsRequest,
  proto.sora.app.v1beta.ReadEventGeneratorsResponse,
  /**
   * @param {!proto.sora.app.v1beta.ReadEventGeneratorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.ReadEventGeneratorsResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.ReadEventGeneratorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.ReadEventGeneratorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.ReadEventGeneratorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.readEventGenerators =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadEventGenerators',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadEventGenerators,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.ReadEventGeneratorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.ReadEventGeneratorsResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.readEventGenerators =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadEventGenerators',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadEventGenerators);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.CreateEventGeneratorRequest,
 *   !proto.sora.app.v1beta.CreateEventGeneratorResponse>}
 */
const methodDescriptor_AppService_CreateEventGenerator = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/CreateEventGenerator',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.CreateEventGeneratorRequest,
  proto.sora.app.v1beta.CreateEventGeneratorResponse,
  /**
   * @param {!proto.sora.app.v1beta.CreateEventGeneratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.CreateEventGeneratorResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.CreateEventGeneratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.CreateEventGeneratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.CreateEventGeneratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.createEventGenerator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateEventGenerator',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateEventGenerator,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.CreateEventGeneratorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.CreateEventGeneratorResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.createEventGenerator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateEventGenerator',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateEventGenerator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.UpdateEventGeneratorRequest,
 *   !proto.sora.app.v1beta.UpdateEventGeneratorResponse>}
 */
const methodDescriptor_AppService_UpdateEventGenerator = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/UpdateEventGenerator',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.UpdateEventGeneratorRequest,
  proto.sora.app.v1beta.UpdateEventGeneratorResponse,
  /**
   * @param {!proto.sora.app.v1beta.UpdateEventGeneratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.UpdateEventGeneratorResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.UpdateEventGeneratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.UpdateEventGeneratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.UpdateEventGeneratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.updateEventGenerator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateEventGenerator',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateEventGenerator,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.UpdateEventGeneratorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.UpdateEventGeneratorResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.updateEventGenerator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateEventGenerator',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateEventGenerator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.DeleteEventGeneratorRequest,
 *   !proto.sora.app.v1beta.DeleteEventGeneratorResponse>}
 */
const methodDescriptor_AppService_DeleteEventGenerator = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/DeleteEventGenerator',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.DeleteEventGeneratorRequest,
  proto.sora.app.v1beta.DeleteEventGeneratorResponse,
  /**
   * @param {!proto.sora.app.v1beta.DeleteEventGeneratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.DeleteEventGeneratorResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.DeleteEventGeneratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.DeleteEventGeneratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.DeleteEventGeneratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.deleteEventGenerator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteEventGenerator',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteEventGenerator,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.DeleteEventGeneratorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.DeleteEventGeneratorResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.deleteEventGenerator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteEventGenerator',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteEventGenerator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.ReadAlertorsRequest,
 *   !proto.sora.app.v1beta.ReadAlertorsResponse>}
 */
const methodDescriptor_AppService_ReadAlertors = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/ReadAlertors',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.ReadAlertorsRequest,
  proto.sora.app.v1beta.ReadAlertorsResponse,
  /**
   * @param {!proto.sora.app.v1beta.ReadAlertorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.ReadAlertorsResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.ReadAlertorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.ReadAlertorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.ReadAlertorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.readAlertors =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadAlertors',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadAlertors,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.ReadAlertorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.ReadAlertorsResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.readAlertors =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadAlertors',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadAlertors);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.CreateAlertorRequest,
 *   !proto.sora.app.v1beta.CreateAlertorResponse>}
 */
const methodDescriptor_AppService_CreateAlertor = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/CreateAlertor',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.CreateAlertorRequest,
  proto.sora.app.v1beta.CreateAlertorResponse,
  /**
   * @param {!proto.sora.app.v1beta.CreateAlertorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.CreateAlertorResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.CreateAlertorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.CreateAlertorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.CreateAlertorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.createAlertor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateAlertor',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateAlertor,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.CreateAlertorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.CreateAlertorResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.createAlertor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateAlertor',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateAlertor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.UpdateAlertorRequest,
 *   !proto.sora.app.v1beta.UpdateAlertorResponse>}
 */
const methodDescriptor_AppService_UpdateAlertor = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/UpdateAlertor',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.UpdateAlertorRequest,
  proto.sora.app.v1beta.UpdateAlertorResponse,
  /**
   * @param {!proto.sora.app.v1beta.UpdateAlertorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.UpdateAlertorResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.UpdateAlertorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.UpdateAlertorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.UpdateAlertorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.updateAlertor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateAlertor',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateAlertor,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.UpdateAlertorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.UpdateAlertorResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.updateAlertor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateAlertor',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateAlertor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.DeleteAlertorRequest,
 *   !proto.sora.app.v1beta.DeleteAlertorResponse>}
 */
const methodDescriptor_AppService_DeleteAlertor = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/DeleteAlertor',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.DeleteAlertorRequest,
  proto.sora.app.v1beta.DeleteAlertorResponse,
  /**
   * @param {!proto.sora.app.v1beta.DeleteAlertorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.DeleteAlertorResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.DeleteAlertorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.DeleteAlertorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.DeleteAlertorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.deleteAlertor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteAlertor',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteAlertor,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.DeleteAlertorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.DeleteAlertorResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.deleteAlertor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteAlertor',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteAlertor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.ReadAnnotatorsRequest,
 *   !proto.sora.app.v1beta.ReadAnnotatorsResponse>}
 */
const methodDescriptor_AppService_ReadAnnotators = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/ReadAnnotators',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.ReadAnnotatorsRequest,
  proto.sora.app.v1beta.ReadAnnotatorsResponse,
  /**
   * @param {!proto.sora.app.v1beta.ReadAnnotatorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.ReadAnnotatorsResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.ReadAnnotatorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.ReadAnnotatorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.ReadAnnotatorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.readAnnotators =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadAnnotators',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadAnnotators,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.ReadAnnotatorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.ReadAnnotatorsResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.readAnnotators =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadAnnotators',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadAnnotators);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.CreateAnnotatorRequest,
 *   !proto.sora.app.v1beta.CreateAnnotatorResponse>}
 */
const methodDescriptor_AppService_CreateAnnotator = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/CreateAnnotator',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.CreateAnnotatorRequest,
  proto.sora.app.v1beta.CreateAnnotatorResponse,
  /**
   * @param {!proto.sora.app.v1beta.CreateAnnotatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.CreateAnnotatorResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.CreateAnnotatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.CreateAnnotatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.CreateAnnotatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.createAnnotator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateAnnotator',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateAnnotator,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.CreateAnnotatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.CreateAnnotatorResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.createAnnotator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateAnnotator',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateAnnotator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.UpdateAnnotatorRequest,
 *   !proto.sora.app.v1beta.UpdateAnnotatorResponse>}
 */
const methodDescriptor_AppService_UpdateAnnotator = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/UpdateAnnotator',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.UpdateAnnotatorRequest,
  proto.sora.app.v1beta.UpdateAnnotatorResponse,
  /**
   * @param {!proto.sora.app.v1beta.UpdateAnnotatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.UpdateAnnotatorResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.UpdateAnnotatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.UpdateAnnotatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.UpdateAnnotatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.updateAnnotator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateAnnotator',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateAnnotator,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.UpdateAnnotatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.UpdateAnnotatorResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.updateAnnotator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateAnnotator',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateAnnotator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.DeleteAnnotatorRequest,
 *   !proto.sora.app.v1beta.DeleteAnnotatorResponse>}
 */
const methodDescriptor_AppService_DeleteAnnotator = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/DeleteAnnotator',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.DeleteAnnotatorRequest,
  proto.sora.app.v1beta.DeleteAnnotatorResponse,
  /**
   * @param {!proto.sora.app.v1beta.DeleteAnnotatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.DeleteAnnotatorResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.DeleteAnnotatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.DeleteAnnotatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.DeleteAnnotatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.deleteAnnotator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteAnnotator',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteAnnotator,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.DeleteAnnotatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.DeleteAnnotatorResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.deleteAnnotator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteAnnotator',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteAnnotator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.ReadProjectsRequest,
 *   !proto.sora.app.v1beta.ReadProjectsResponse>}
 */
const methodDescriptor_AppService_ReadProjects = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/ReadProjects',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.ReadProjectsRequest,
  proto.sora.app.v1beta.ReadProjectsResponse,
  /**
   * @param {!proto.sora.app.v1beta.ReadProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.ReadProjectsResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.ReadProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.ReadProjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.ReadProjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.readProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadProjects',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadProjects,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.ReadProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.ReadProjectsResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.readProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadProjects',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.CreateProjectRequest,
 *   !proto.sora.app.v1beta.CreateProjectResponse>}
 */
const methodDescriptor_AppService_CreateProject = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/CreateProject',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.CreateProjectRequest,
  proto.sora.app.v1beta.CreateProjectResponse,
  /**
   * @param {!proto.sora.app.v1beta.CreateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.CreateProjectResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.CreateProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.CreateProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.createProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateProject',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateProject,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.CreateProjectResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.createProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateProject',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.UpdateProjectRequest,
 *   !proto.sora.app.v1beta.UpdateProjectResponse>}
 */
const methodDescriptor_AppService_UpdateProject = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/UpdateProject',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.UpdateProjectRequest,
  proto.sora.app.v1beta.UpdateProjectResponse,
  /**
   * @param {!proto.sora.app.v1beta.UpdateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.UpdateProjectResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.UpdateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.UpdateProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.UpdateProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.updateProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateProject',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateProject,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.UpdateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.UpdateProjectResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.updateProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateProject',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.DeleteProjectRequest,
 *   !proto.sora.app.v1beta.DeleteProjectResponse>}
 */
const methodDescriptor_AppService_DeleteProject = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/DeleteProject',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.DeleteProjectRequest,
  proto.sora.app.v1beta.DeleteProjectResponse,
  /**
   * @param {!proto.sora.app.v1beta.DeleteProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.DeleteProjectResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.DeleteProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.DeleteProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.DeleteProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.deleteProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteProject',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteProject,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.DeleteProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.DeleteProjectResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.deleteProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteProject',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.ReadDevicesRequest,
 *   !proto.sora.app.v1beta.ReadDevicesResponse>}
 */
const methodDescriptor_AppService_ReadDevices = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/ReadDevices',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.ReadDevicesRequest,
  proto.sora.app.v1beta.ReadDevicesResponse,
  /**
   * @param {!proto.sora.app.v1beta.ReadDevicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.ReadDevicesResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.ReadDevicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.ReadDevicesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.ReadDevicesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.readDevices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadDevices',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadDevices,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.ReadDevicesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.ReadDevicesResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.readDevices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/ReadDevices',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadDevices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.CreateDeviceRequest,
 *   !proto.sora.app.v1beta.CreateDeviceResponse>}
 */
const methodDescriptor_AppService_CreateDevice = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/CreateDevice',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.CreateDeviceRequest,
  proto.sora.app.v1beta.CreateDeviceResponse,
  /**
   * @param {!proto.sora.app.v1beta.CreateDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.CreateDeviceResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.CreateDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.CreateDeviceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.CreateDeviceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.createDevice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateDevice',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateDevice,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.CreateDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.CreateDeviceResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.createDevice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/CreateDevice',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateDevice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.UpdateDeviceRequest,
 *   !proto.sora.app.v1beta.UpdateDeviceResponse>}
 */
const methodDescriptor_AppService_UpdateDevice = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/UpdateDevice',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.UpdateDeviceRequest,
  proto.sora.app.v1beta.UpdateDeviceResponse,
  /**
   * @param {!proto.sora.app.v1beta.UpdateDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.UpdateDeviceResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.UpdateDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.UpdateDeviceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.UpdateDeviceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.updateDevice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateDevice',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateDevice,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.UpdateDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.UpdateDeviceResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.updateDevice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/UpdateDevice',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateDevice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sora.app.v1beta.DeleteDeviceRequest,
 *   !proto.sora.app.v1beta.DeleteDeviceResponse>}
 */
const methodDescriptor_AppService_DeleteDevice = new grpc.web.MethodDescriptor(
  '/sora.app.v1beta.AppService/DeleteDevice',
  grpc.web.MethodType.UNARY,
  proto.sora.app.v1beta.DeleteDeviceRequest,
  proto.sora.app.v1beta.DeleteDeviceResponse,
  /**
   * @param {!proto.sora.app.v1beta.DeleteDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sora.app.v1beta.DeleteDeviceResponse.deserializeBinary
);


/**
 * @param {!proto.sora.app.v1beta.DeleteDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sora.app.v1beta.DeleteDeviceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sora.app.v1beta.DeleteDeviceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sora.app.v1beta.AppServiceClient.prototype.deleteDevice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteDevice',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteDevice,
      callback);
};


/**
 * @param {!proto.sora.app.v1beta.DeleteDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sora.app.v1beta.DeleteDeviceResponse>}
 *     Promise that resolves to the response
 */
proto.sora.app.v1beta.AppServicePromiseClient.prototype.deleteDevice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sora.app.v1beta.AppService/DeleteDevice',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteDevice);
};


module.exports = proto.sora.app.v1beta;

