import {
  createAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RGBAColor } from "@deck.gl/core";
import {
  JavaScriptValue,
  Struct,
} from "google-protobuf/google/protobuf/struct_pb";

import { Alertor as AlertorPB } from "../../generated/sora/app/v1beta/types_pb";

import {
  colorArrayFromInt,
  intFromColorArray,
  randColor,
  stripAlpha,
} from "../../utils/color";
import { randHex } from "../../utils/rand";
import { selectAlertorsSlice } from "../selectors/alertor";
import { ProjectId } from "./projects";

export type Alertor = {
  id: string;
  name: string;
  type: string;
  projectId: ProjectId;
  enabled: boolean;
  color: RGBAColor;
  config: { [key: string]: JavaScriptValue };
  showConfig: boolean;
};

export const newAlertor = (projectId: ProjectId): Alertor => ({
  id: "",
  name: `Alert ${randHex()}`,
  type: "sora.alertor.slack.v1",
  color: randColor(),
  projectId,
  enabled: true,
  config: {},
  showConfig: false,
});

export const fromPB = (a: AlertorPB): Alertor => ({
  id: a.getId(),
  name: a.getName(),
  type: a.getType(),
  color: stripAlpha(colorArrayFromInt(a.getColor())),
  projectId: a.getProjectId(),
  enabled: a.getEnabled(),
  config: a.getConfig()?.toJavaScript() || {},
  showConfig: false,
});

export const toPB = (a: Alertor) =>
  new AlertorPB()
    .setId(a.id)
    .setName(a.name)
    .setType(a.type)
    .setColor(intFromColorArray(a.color))
    .setEnabled(a.enabled)
    .setConfig(Struct.fromJavaScript(a.config))
    .setProjectId(a.projectId);

const alertorAdapter = createEntityAdapter<Alertor>();
const alertorSlice = createSlice({
  name: "alertors",
  initialState: alertorAdapter.getInitialState(),
  reducers: {
    addAlertors: alertorAdapter.upsertMany,
    updateAlertor: alertorAdapter.updateOne,
    deleteAlertor: alertorAdapter.removeOne,
    clearAlertors: alertorAdapter.removeAll,
  },
});

export const readAlertorsRequested = createAction<ProjectId>(
  "alertors/readRequested",
);
export const createAlertorRequested = createAction<Alertor>(
  "alertors/createRequested",
);
export const deleteAlertorRequested = createAction<Alertor>(
  "alertors/deleteRequested",
);

export const alertorSelectors =
  alertorAdapter.getSelectors(selectAlertorsSlice);
export const { addAlertors, updateAlertor, deleteAlertor, clearAlertors } =
  alertorSlice.actions;
export default alertorSlice.reducer;
