export type RequestState = Pending | Succeeded | Errored;

export type Errored = {
  type: "errored";
  error: string;
};

export type Pending = {
  type: "pending";
};

export type Succeeded = {
  type: "succeeded";
};

export const erroredRequestState = (error: string): Errored => ({
  type: "errored",
  error,
});

export const pendingRequestState = (): RequestState => ({ type: "pending" });

export const succeededRequestState = (): RequestState => ({
  type: "succeeded",
});
