// source: sora/app/v1beta/service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var sora_app_v1beta_types_pb = require('../../../sora/app/v1beta/types_pb.js');
goog.object.extend(proto, sora_app_v1beta_types_pb);
var sora_v1beta_common_pb = require('../../../sora/v1beta/common_pb.js');
goog.object.extend(proto, sora_v1beta_common_pb);
goog.exportSymbol('proto.sora.app.v1beta.CreateAlertorRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateAlertorResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateAnnotatorRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateAnnotatorResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateDeviceRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateDeviceResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateEventGeneratorRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateEventGeneratorResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateLayerRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateLayerResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateProjectRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.CreateProjectResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteAlertorRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteAlertorResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteAnnotatorRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteAnnotatorResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteDeviceRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteDeviceResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteEventGeneratorRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteEventGeneratorResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteLayerRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteLayerResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteProjectRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeleteProjectResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.DeviceSelection', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryEventsRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryEventsResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryEventsResponse.ContinuationCase', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryStateAggregateRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryStateAggregateRequest.RangeCase', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryStateAggregateResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryStateAggregateResponse.Date', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryStateRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryStateResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.QueryStateResponse.ContinuationCase', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadAlertorsRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadAlertorsResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadAnnotatorsRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadAnnotatorsResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadDevicesRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadDevicesResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadEventGeneratorsRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadEventGeneratorsResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadLayersRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadLayersResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadProjectsRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.ReadProjectsResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.StreamEventsRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.StreamEventsResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.StreamStateRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.StreamStateResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.TimeSeriesRangeSelection', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateAlertorRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateAlertorResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateAnnotatorRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateAnnotatorResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateDeviceRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateDeviceResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateEventGeneratorRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateEventGeneratorResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateLayerRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateLayerResponse', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateProjectRequest', null, global);
goog.exportSymbol('proto.sora.app.v1beta.UpdateProjectResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.ReadDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadDevicesRequest.displayName = 'proto.sora.app.v1beta.ReadDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.ReadDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.sora.app.v1beta.ReadDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadDevicesResponse.displayName = 'proto.sora.app.v1beta.ReadDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateDeviceRequest.displayName = 'proto.sora.app.v1beta.CreateDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateDeviceResponse.displayName = 'proto.sora.app.v1beta.CreateDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateDeviceRequest.displayName = 'proto.sora.app.v1beta.UpdateDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateDeviceResponse.displayName = 'proto.sora.app.v1beta.UpdateDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteDeviceRequest.displayName = 'proto.sora.app.v1beta.DeleteDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteDeviceResponse.displayName = 'proto.sora.app.v1beta.DeleteDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadProjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.ReadProjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadProjectsRequest.displayName = 'proto.sora.app.v1beta.ReadProjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadProjectsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.ReadProjectsResponse.repeatedFields_, null);
};
goog.inherits(proto.sora.app.v1beta.ReadProjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadProjectsResponse.displayName = 'proto.sora.app.v1beta.ReadProjectsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateProjectRequest.displayName = 'proto.sora.app.v1beta.CreateProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateProjectResponse.displayName = 'proto.sora.app.v1beta.CreateProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateProjectRequest.displayName = 'proto.sora.app.v1beta.UpdateProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateProjectResponse.displayName = 'proto.sora.app.v1beta.UpdateProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteProjectRequest.displayName = 'proto.sora.app.v1beta.DeleteProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteProjectResponse.displayName = 'proto.sora.app.v1beta.DeleteProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadAnnotatorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.ReadAnnotatorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadAnnotatorsRequest.displayName = 'proto.sora.app.v1beta.ReadAnnotatorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.ReadAnnotatorsResponse.repeatedFields_, null);
};
goog.inherits(proto.sora.app.v1beta.ReadAnnotatorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadAnnotatorsResponse.displayName = 'proto.sora.app.v1beta.ReadAnnotatorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateAnnotatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateAnnotatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateAnnotatorRequest.displayName = 'proto.sora.app.v1beta.CreateAnnotatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateAnnotatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateAnnotatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateAnnotatorResponse.displayName = 'proto.sora.app.v1beta.CreateAnnotatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateAnnotatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateAnnotatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateAnnotatorRequest.displayName = 'proto.sora.app.v1beta.UpdateAnnotatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateAnnotatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateAnnotatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateAnnotatorResponse.displayName = 'proto.sora.app.v1beta.UpdateAnnotatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteAnnotatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteAnnotatorRequest.displayName = 'proto.sora.app.v1beta.DeleteAnnotatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteAnnotatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteAnnotatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteAnnotatorResponse.displayName = 'proto.sora.app.v1beta.DeleteAnnotatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadAlertorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.ReadAlertorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadAlertorsRequest.displayName = 'proto.sora.app.v1beta.ReadAlertorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadAlertorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.ReadAlertorsResponse.repeatedFields_, null);
};
goog.inherits(proto.sora.app.v1beta.ReadAlertorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadAlertorsResponse.displayName = 'proto.sora.app.v1beta.ReadAlertorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateAlertorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateAlertorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateAlertorRequest.displayName = 'proto.sora.app.v1beta.CreateAlertorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateAlertorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateAlertorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateAlertorResponse.displayName = 'proto.sora.app.v1beta.CreateAlertorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateAlertorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateAlertorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateAlertorRequest.displayName = 'proto.sora.app.v1beta.UpdateAlertorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateAlertorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateAlertorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateAlertorResponse.displayName = 'proto.sora.app.v1beta.UpdateAlertorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteAlertorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteAlertorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteAlertorRequest.displayName = 'proto.sora.app.v1beta.DeleteAlertorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteAlertorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteAlertorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteAlertorResponse.displayName = 'proto.sora.app.v1beta.DeleteAlertorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadEventGeneratorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.ReadEventGeneratorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadEventGeneratorsRequest.displayName = 'proto.sora.app.v1beta.ReadEventGeneratorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.ReadEventGeneratorsResponse.repeatedFields_, null);
};
goog.inherits(proto.sora.app.v1beta.ReadEventGeneratorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadEventGeneratorsResponse.displayName = 'proto.sora.app.v1beta.ReadEventGeneratorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateEventGeneratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateEventGeneratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateEventGeneratorRequest.displayName = 'proto.sora.app.v1beta.CreateEventGeneratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateEventGeneratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateEventGeneratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateEventGeneratorResponse.displayName = 'proto.sora.app.v1beta.CreateEventGeneratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateEventGeneratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateEventGeneratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateEventGeneratorRequest.displayName = 'proto.sora.app.v1beta.UpdateEventGeneratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateEventGeneratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateEventGeneratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateEventGeneratorResponse.displayName = 'proto.sora.app.v1beta.UpdateEventGeneratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteEventGeneratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteEventGeneratorRequest.displayName = 'proto.sora.app.v1beta.DeleteEventGeneratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteEventGeneratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteEventGeneratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteEventGeneratorResponse.displayName = 'proto.sora.app.v1beta.DeleteEventGeneratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadLayersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.ReadLayersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadLayersRequest.displayName = 'proto.sora.app.v1beta.ReadLayersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.ReadLayersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.ReadLayersResponse.repeatedFields_, null);
};
goog.inherits(proto.sora.app.v1beta.ReadLayersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.ReadLayersResponse.displayName = 'proto.sora.app.v1beta.ReadLayersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateLayerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateLayerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateLayerRequest.displayName = 'proto.sora.app.v1beta.CreateLayerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.CreateLayerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.CreateLayerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.CreateLayerResponse.displayName = 'proto.sora.app.v1beta.CreateLayerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateLayerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateLayerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateLayerRequest.displayName = 'proto.sora.app.v1beta.UpdateLayerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.UpdateLayerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.UpdateLayerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.UpdateLayerResponse.displayName = 'proto.sora.app.v1beta.UpdateLayerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteLayerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteLayerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteLayerRequest.displayName = 'proto.sora.app.v1beta.DeleteLayerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeleteLayerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.DeleteLayerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeleteLayerResponse.displayName = 'proto.sora.app.v1beta.DeleteLayerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.DeviceSelection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.DeviceSelection.repeatedFields_, null);
};
goog.inherits(proto.sora.app.v1beta.DeviceSelection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.DeviceSelection.displayName = 'proto.sora.app.v1beta.DeviceSelection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.TimeSeriesRangeSelection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.TimeSeriesRangeSelection.displayName = 'proto.sora.app.v1beta.TimeSeriesRangeSelection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.StreamStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.StreamStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.StreamStateRequest.displayName = 'proto.sora.app.v1beta.StreamStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.StreamStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.StreamStateResponse.repeatedFields_, null);
};
goog.inherits(proto.sora.app.v1beta.StreamStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.StreamStateResponse.displayName = 'proto.sora.app.v1beta.StreamStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.StreamEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.StreamEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.StreamEventsRequest.displayName = 'proto.sora.app.v1beta.StreamEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.StreamEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.StreamEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.sora.app.v1beta.StreamEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.StreamEventsResponse.displayName = 'proto.sora.app.v1beta.StreamEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.QueryStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.QueryStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.QueryStateRequest.displayName = 'proto.sora.app.v1beta.QueryStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.QueryStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sora.app.v1beta.QueryStateResponse.oneofGroups_);
};
goog.inherits(proto.sora.app.v1beta.QueryStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.QueryStateResponse.displayName = 'proto.sora.app.v1beta.QueryStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.QueryEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.QueryEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.QueryEventsRequest.displayName = 'proto.sora.app.v1beta.QueryEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.QueryEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.QueryEventsResponse.repeatedFields_, proto.sora.app.v1beta.QueryEventsResponse.oneofGroups_);
};
goog.inherits(proto.sora.app.v1beta.QueryEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.QueryEventsResponse.displayName = 'proto.sora.app.v1beta.QueryEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.QueryStateAggregateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sora.app.v1beta.QueryStateAggregateRequest.oneofGroups_);
};
goog.inherits(proto.sora.app.v1beta.QueryStateAggregateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.QueryStateAggregateRequest.displayName = 'proto.sora.app.v1beta.QueryStateAggregateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.displayName = 'proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.QueryStateAggregateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sora.app.v1beta.QueryStateAggregateResponse.repeatedFields_, null);
};
goog.inherits(proto.sora.app.v1beta.QueryStateAggregateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.QueryStateAggregateResponse.displayName = 'proto.sora.app.v1beta.QueryStateAggregateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.QueryStateAggregateResponse.Date, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.QueryStateAggregateResponse.Date.displayName = 'proto.sora.app.v1beta.QueryStateAggregateResponse.Date';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.displayName = 'proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadDevicesRequest}
 */
proto.sora.app.v1beta.ReadDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadDevicesRequest;
  return proto.sora.app.v1beta.ReadDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadDevicesRequest}
 */
proto.sora.app.v1beta.ReadDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.ReadDevicesRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.ReadDevicesRequest} returns this
 */
proto.sora.app.v1beta.ReadDevicesRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.ReadDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    sora_app_v1beta_types_pb.Device.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadDevicesResponse}
 */
proto.sora.app.v1beta.ReadDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadDevicesResponse;
  return proto.sora.app.v1beta.ReadDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadDevicesResponse}
 */
proto.sora.app.v1beta.ReadDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Device;
      reader.readMessage(value,sora_app_v1beta_types_pb.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Device devices = 1;
 * @return {!Array<!proto.sora.app.v1beta.Device>}
 */
proto.sora.app.v1beta.ReadDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.sora.app.v1beta.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, sora_app_v1beta_types_pb.Device, 1));
};


/**
 * @param {!Array<!proto.sora.app.v1beta.Device>} value
 * @return {!proto.sora.app.v1beta.ReadDevicesResponse} returns this
*/
proto.sora.app.v1beta.ReadDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sora.app.v1beta.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sora.app.v1beta.Device}
 */
proto.sora.app.v1beta.ReadDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sora.app.v1beta.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.ReadDevicesResponse} returns this
 */
proto.sora.app.v1beta.ReadDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && sora_app_v1beta_types_pb.Device.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateDeviceRequest}
 */
proto.sora.app.v1beta.CreateDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateDeviceRequest;
  return proto.sora.app.v1beta.CreateDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateDeviceRequest}
 */
proto.sora.app.v1beta.CreateDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Device;
      reader.readMessage(value,sora_app_v1beta_types_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Device device = 1;
 * @return {?proto.sora.app.v1beta.Device}
 */
proto.sora.app.v1beta.CreateDeviceRequest.prototype.getDevice = function() {
  return /** @type{?proto.sora.app.v1beta.Device} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Device, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Device|undefined} value
 * @return {!proto.sora.app.v1beta.CreateDeviceRequest} returns this
*/
proto.sora.app.v1beta.CreateDeviceRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateDeviceRequest} returns this
 */
proto.sora.app.v1beta.CreateDeviceRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateDeviceRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.sora.app.v1beta.CreateDeviceRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.CreateDeviceRequest} returns this
 */
proto.sora.app.v1beta.CreateDeviceRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && sora_app_v1beta_types_pb.Device.toObject(includeInstance, f),
    wrappedDeviceAccessToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateDeviceResponse}
 */
proto.sora.app.v1beta.CreateDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateDeviceResponse;
  return proto.sora.app.v1beta.CreateDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateDeviceResponse}
 */
proto.sora.app.v1beta.CreateDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Device;
      reader.readMessage(value,sora_app_v1beta_types_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWrappedDeviceAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getWrappedDeviceAccessToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Device device = 1;
 * @return {?proto.sora.app.v1beta.Device}
 */
proto.sora.app.v1beta.CreateDeviceResponse.prototype.getDevice = function() {
  return /** @type{?proto.sora.app.v1beta.Device} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Device, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Device|undefined} value
 * @return {!proto.sora.app.v1beta.CreateDeviceResponse} returns this
*/
proto.sora.app.v1beta.CreateDeviceResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateDeviceResponse} returns this
 */
proto.sora.app.v1beta.CreateDeviceResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateDeviceResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string wrapped_device_access_token = 2;
 * @return {string}
 */
proto.sora.app.v1beta.CreateDeviceResponse.prototype.getWrappedDeviceAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.CreateDeviceResponse} returns this
 */
proto.sora.app.v1beta.CreateDeviceResponse.prototype.setWrappedDeviceAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && sora_app_v1beta_types_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateDeviceRequest}
 */
proto.sora.app.v1beta.UpdateDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateDeviceRequest;
  return proto.sora.app.v1beta.UpdateDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateDeviceRequest}
 */
proto.sora.app.v1beta.UpdateDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Device;
      reader.readMessage(value,sora_app_v1beta_types_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional Device device = 1;
 * @return {?proto.sora.app.v1beta.Device}
 */
proto.sora.app.v1beta.UpdateDeviceRequest.prototype.getDevice = function() {
  return /** @type{?proto.sora.app.v1beta.Device} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Device, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Device|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateDeviceRequest} returns this
*/
proto.sora.app.v1beta.UpdateDeviceRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateDeviceRequest} returns this
 */
proto.sora.app.v1beta.UpdateDeviceRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateDeviceRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && sora_app_v1beta_types_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateDeviceResponse}
 */
proto.sora.app.v1beta.UpdateDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateDeviceResponse;
  return proto.sora.app.v1beta.UpdateDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateDeviceResponse}
 */
proto.sora.app.v1beta.UpdateDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Device;
      reader.readMessage(value,sora_app_v1beta_types_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional Device device = 1;
 * @return {?proto.sora.app.v1beta.Device}
 */
proto.sora.app.v1beta.UpdateDeviceResponse.prototype.getDevice = function() {
  return /** @type{?proto.sora.app.v1beta.Device} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Device, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Device|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateDeviceResponse} returns this
*/
proto.sora.app.v1beta.UpdateDeviceResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateDeviceResponse} returns this
 */
proto.sora.app.v1beta.UpdateDeviceResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateDeviceResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteDeviceRequest}
 */
proto.sora.app.v1beta.DeleteDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteDeviceRequest;
  return proto.sora.app.v1beta.DeleteDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteDeviceRequest}
 */
proto.sora.app.v1beta.DeleteDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteDeviceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteDeviceRequest} returns this
 */
proto.sora.app.v1beta.DeleteDeviceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteDeviceRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteDeviceRequest} returns this
 */
proto.sora.app.v1beta.DeleteDeviceRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteDeviceResponse}
 */
proto.sora.app.v1beta.DeleteDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteDeviceResponse;
  return proto.sora.app.v1beta.DeleteDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteDeviceResponse}
 */
proto.sora.app.v1beta.DeleteDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadProjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadProjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadProjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadProjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadProjectsRequest}
 */
proto.sora.app.v1beta.ReadProjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadProjectsRequest;
  return proto.sora.app.v1beta.ReadProjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadProjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadProjectsRequest}
 */
proto.sora.app.v1beta.ReadProjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadProjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadProjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadProjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadProjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.ReadProjectsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadProjectsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadProjectsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadProjectsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadProjectsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectsList: jspb.Message.toObjectList(msg.getProjectsList(),
    sora_app_v1beta_types_pb.Project.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadProjectsResponse}
 */
proto.sora.app.v1beta.ReadProjectsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadProjectsResponse;
  return proto.sora.app.v1beta.ReadProjectsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadProjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadProjectsResponse}
 */
proto.sora.app.v1beta.ReadProjectsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Project;
      reader.readMessage(value,sora_app_v1beta_types_pb.Project.deserializeBinaryFromReader);
      msg.addProjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadProjectsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadProjectsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadProjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadProjectsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Project.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Project projects = 1;
 * @return {!Array<!proto.sora.app.v1beta.Project>}
 */
proto.sora.app.v1beta.ReadProjectsResponse.prototype.getProjectsList = function() {
  return /** @type{!Array<!proto.sora.app.v1beta.Project>} */ (
    jspb.Message.getRepeatedWrapperField(this, sora_app_v1beta_types_pb.Project, 1));
};


/**
 * @param {!Array<!proto.sora.app.v1beta.Project>} value
 * @return {!proto.sora.app.v1beta.ReadProjectsResponse} returns this
*/
proto.sora.app.v1beta.ReadProjectsResponse.prototype.setProjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sora.app.v1beta.Project=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sora.app.v1beta.Project}
 */
proto.sora.app.v1beta.ReadProjectsResponse.prototype.addProjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sora.app.v1beta.Project, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.ReadProjectsResponse} returns this
 */
proto.sora.app.v1beta.ReadProjectsResponse.prototype.clearProjectsList = function() {
  return this.setProjectsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && sora_app_v1beta_types_pb.Project.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateProjectRequest}
 */
proto.sora.app.v1beta.CreateProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateProjectRequest;
  return proto.sora.app.v1beta.CreateProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateProjectRequest}
 */
proto.sora.app.v1beta.CreateProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Project;
      reader.readMessage(value,sora_app_v1beta_types_pb.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Project.serializeBinaryToWriter
    );
  }
};


/**
 * optional Project project = 1;
 * @return {?proto.sora.app.v1beta.Project}
 */
proto.sora.app.v1beta.CreateProjectRequest.prototype.getProject = function() {
  return /** @type{?proto.sora.app.v1beta.Project} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Project, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Project|undefined} value
 * @return {!proto.sora.app.v1beta.CreateProjectRequest} returns this
*/
proto.sora.app.v1beta.CreateProjectRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateProjectRequest} returns this
 */
proto.sora.app.v1beta.CreateProjectRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateProjectRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && sora_app_v1beta_types_pb.Project.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateProjectResponse}
 */
proto.sora.app.v1beta.CreateProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateProjectResponse;
  return proto.sora.app.v1beta.CreateProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateProjectResponse}
 */
proto.sora.app.v1beta.CreateProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Project;
      reader.readMessage(value,sora_app_v1beta_types_pb.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Project.serializeBinaryToWriter
    );
  }
};


/**
 * optional Project project = 1;
 * @return {?proto.sora.app.v1beta.Project}
 */
proto.sora.app.v1beta.CreateProjectResponse.prototype.getProject = function() {
  return /** @type{?proto.sora.app.v1beta.Project} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Project, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Project|undefined} value
 * @return {!proto.sora.app.v1beta.CreateProjectResponse} returns this
*/
proto.sora.app.v1beta.CreateProjectResponse.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateProjectResponse} returns this
 */
proto.sora.app.v1beta.CreateProjectResponse.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateProjectResponse.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && sora_app_v1beta_types_pb.Project.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateProjectRequest}
 */
proto.sora.app.v1beta.UpdateProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateProjectRequest;
  return proto.sora.app.v1beta.UpdateProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateProjectRequest}
 */
proto.sora.app.v1beta.UpdateProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Project;
      reader.readMessage(value,sora_app_v1beta_types_pb.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Project.serializeBinaryToWriter
    );
  }
};


/**
 * optional Project project = 1;
 * @return {?proto.sora.app.v1beta.Project}
 */
proto.sora.app.v1beta.UpdateProjectRequest.prototype.getProject = function() {
  return /** @type{?proto.sora.app.v1beta.Project} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Project, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Project|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateProjectRequest} returns this
*/
proto.sora.app.v1beta.UpdateProjectRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateProjectRequest} returns this
 */
proto.sora.app.v1beta.UpdateProjectRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateProjectRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && sora_app_v1beta_types_pb.Project.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateProjectResponse}
 */
proto.sora.app.v1beta.UpdateProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateProjectResponse;
  return proto.sora.app.v1beta.UpdateProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateProjectResponse}
 */
proto.sora.app.v1beta.UpdateProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Project;
      reader.readMessage(value,sora_app_v1beta_types_pb.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Project.serializeBinaryToWriter
    );
  }
};


/**
 * optional Project project = 1;
 * @return {?proto.sora.app.v1beta.Project}
 */
proto.sora.app.v1beta.UpdateProjectResponse.prototype.getProject = function() {
  return /** @type{?proto.sora.app.v1beta.Project} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Project, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Project|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateProjectResponse} returns this
*/
proto.sora.app.v1beta.UpdateProjectResponse.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateProjectResponse} returns this
 */
proto.sora.app.v1beta.UpdateProjectResponse.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateProjectResponse.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteProjectRequest}
 */
proto.sora.app.v1beta.DeleteProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteProjectRequest;
  return proto.sora.app.v1beta.DeleteProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteProjectRequest}
 */
proto.sora.app.v1beta.DeleteProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteProjectRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteProjectRequest} returns this
 */
proto.sora.app.v1beta.DeleteProjectRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteProjectResponse}
 */
proto.sora.app.v1beta.DeleteProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteProjectResponse;
  return proto.sora.app.v1beta.DeleteProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteProjectResponse}
 */
proto.sora.app.v1beta.DeleteProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadAnnotatorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadAnnotatorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadAnnotatorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadAnnotatorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadAnnotatorsRequest}
 */
proto.sora.app.v1beta.ReadAnnotatorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadAnnotatorsRequest;
  return proto.sora.app.v1beta.ReadAnnotatorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadAnnotatorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadAnnotatorsRequest}
 */
proto.sora.app.v1beta.ReadAnnotatorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadAnnotatorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadAnnotatorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadAnnotatorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadAnnotatorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.ReadAnnotatorsRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.ReadAnnotatorsRequest} returns this
 */
proto.sora.app.v1beta.ReadAnnotatorsRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadAnnotatorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadAnnotatorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotatorsList: jspb.Message.toObjectList(msg.getAnnotatorsList(),
    sora_app_v1beta_types_pb.Annotator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadAnnotatorsResponse}
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadAnnotatorsResponse;
  return proto.sora.app.v1beta.ReadAnnotatorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadAnnotatorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadAnnotatorsResponse}
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Annotator;
      reader.readMessage(value,sora_app_v1beta_types_pb.Annotator.deserializeBinaryFromReader);
      msg.addAnnotators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadAnnotatorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadAnnotatorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Annotator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Annotator annotators = 1;
 * @return {!Array<!proto.sora.app.v1beta.Annotator>}
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse.prototype.getAnnotatorsList = function() {
  return /** @type{!Array<!proto.sora.app.v1beta.Annotator>} */ (
    jspb.Message.getRepeatedWrapperField(this, sora_app_v1beta_types_pb.Annotator, 1));
};


/**
 * @param {!Array<!proto.sora.app.v1beta.Annotator>} value
 * @return {!proto.sora.app.v1beta.ReadAnnotatorsResponse} returns this
*/
proto.sora.app.v1beta.ReadAnnotatorsResponse.prototype.setAnnotatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sora.app.v1beta.Annotator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sora.app.v1beta.Annotator}
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse.prototype.addAnnotators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sora.app.v1beta.Annotator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.ReadAnnotatorsResponse} returns this
 */
proto.sora.app.v1beta.ReadAnnotatorsResponse.prototype.clearAnnotatorsList = function() {
  return this.setAnnotatorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateAnnotatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateAnnotatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateAnnotatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateAnnotatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotator: (f = msg.getAnnotator()) && sora_app_v1beta_types_pb.Annotator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateAnnotatorRequest}
 */
proto.sora.app.v1beta.CreateAnnotatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateAnnotatorRequest;
  return proto.sora.app.v1beta.CreateAnnotatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateAnnotatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateAnnotatorRequest}
 */
proto.sora.app.v1beta.CreateAnnotatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Annotator;
      reader.readMessage(value,sora_app_v1beta_types_pb.Annotator.deserializeBinaryFromReader);
      msg.setAnnotator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateAnnotatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateAnnotatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateAnnotatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateAnnotatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Annotator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Annotator annotator = 1;
 * @return {?proto.sora.app.v1beta.Annotator}
 */
proto.sora.app.v1beta.CreateAnnotatorRequest.prototype.getAnnotator = function() {
  return /** @type{?proto.sora.app.v1beta.Annotator} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Annotator, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Annotator|undefined} value
 * @return {!proto.sora.app.v1beta.CreateAnnotatorRequest} returns this
*/
proto.sora.app.v1beta.CreateAnnotatorRequest.prototype.setAnnotator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateAnnotatorRequest} returns this
 */
proto.sora.app.v1beta.CreateAnnotatorRequest.prototype.clearAnnotator = function() {
  return this.setAnnotator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateAnnotatorRequest.prototype.hasAnnotator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateAnnotatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateAnnotatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateAnnotatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateAnnotatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotator: (f = msg.getAnnotator()) && sora_app_v1beta_types_pb.Annotator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateAnnotatorResponse}
 */
proto.sora.app.v1beta.CreateAnnotatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateAnnotatorResponse;
  return proto.sora.app.v1beta.CreateAnnotatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateAnnotatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateAnnotatorResponse}
 */
proto.sora.app.v1beta.CreateAnnotatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Annotator;
      reader.readMessage(value,sora_app_v1beta_types_pb.Annotator.deserializeBinaryFromReader);
      msg.setAnnotator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateAnnotatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateAnnotatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateAnnotatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateAnnotatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Annotator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Annotator annotator = 1;
 * @return {?proto.sora.app.v1beta.Annotator}
 */
proto.sora.app.v1beta.CreateAnnotatorResponse.prototype.getAnnotator = function() {
  return /** @type{?proto.sora.app.v1beta.Annotator} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Annotator, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Annotator|undefined} value
 * @return {!proto.sora.app.v1beta.CreateAnnotatorResponse} returns this
*/
proto.sora.app.v1beta.CreateAnnotatorResponse.prototype.setAnnotator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateAnnotatorResponse} returns this
 */
proto.sora.app.v1beta.CreateAnnotatorResponse.prototype.clearAnnotator = function() {
  return this.setAnnotator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateAnnotatorResponse.prototype.hasAnnotator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateAnnotatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateAnnotatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateAnnotatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateAnnotatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotator: (f = msg.getAnnotator()) && sora_app_v1beta_types_pb.Annotator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateAnnotatorRequest}
 */
proto.sora.app.v1beta.UpdateAnnotatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateAnnotatorRequest;
  return proto.sora.app.v1beta.UpdateAnnotatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateAnnotatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateAnnotatorRequest}
 */
proto.sora.app.v1beta.UpdateAnnotatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Annotator;
      reader.readMessage(value,sora_app_v1beta_types_pb.Annotator.deserializeBinaryFromReader);
      msg.setAnnotator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateAnnotatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateAnnotatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateAnnotatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateAnnotatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Annotator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Annotator annotator = 1;
 * @return {?proto.sora.app.v1beta.Annotator}
 */
proto.sora.app.v1beta.UpdateAnnotatorRequest.prototype.getAnnotator = function() {
  return /** @type{?proto.sora.app.v1beta.Annotator} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Annotator, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Annotator|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateAnnotatorRequest} returns this
*/
proto.sora.app.v1beta.UpdateAnnotatorRequest.prototype.setAnnotator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateAnnotatorRequest} returns this
 */
proto.sora.app.v1beta.UpdateAnnotatorRequest.prototype.clearAnnotator = function() {
  return this.setAnnotator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateAnnotatorRequest.prototype.hasAnnotator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateAnnotatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateAnnotatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateAnnotatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateAnnotatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotator: (f = msg.getAnnotator()) && sora_app_v1beta_types_pb.Annotator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateAnnotatorResponse}
 */
proto.sora.app.v1beta.UpdateAnnotatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateAnnotatorResponse;
  return proto.sora.app.v1beta.UpdateAnnotatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateAnnotatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateAnnotatorResponse}
 */
proto.sora.app.v1beta.UpdateAnnotatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Annotator;
      reader.readMessage(value,sora_app_v1beta_types_pb.Annotator.deserializeBinaryFromReader);
      msg.setAnnotator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateAnnotatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateAnnotatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateAnnotatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateAnnotatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Annotator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Annotator annotator = 1;
 * @return {?proto.sora.app.v1beta.Annotator}
 */
proto.sora.app.v1beta.UpdateAnnotatorResponse.prototype.getAnnotator = function() {
  return /** @type{?proto.sora.app.v1beta.Annotator} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Annotator, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Annotator|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateAnnotatorResponse} returns this
*/
proto.sora.app.v1beta.UpdateAnnotatorResponse.prototype.setAnnotator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateAnnotatorResponse} returns this
 */
proto.sora.app.v1beta.UpdateAnnotatorResponse.prototype.clearAnnotator = function() {
  return this.setAnnotator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateAnnotatorResponse.prototype.hasAnnotator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteAnnotatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteAnnotatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteAnnotatorRequest}
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteAnnotatorRequest;
  return proto.sora.app.v1beta.DeleteAnnotatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteAnnotatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteAnnotatorRequest}
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteAnnotatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteAnnotatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteAnnotatorRequest} returns this
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteAnnotatorRequest} returns this
 */
proto.sora.app.v1beta.DeleteAnnotatorRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteAnnotatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteAnnotatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteAnnotatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteAnnotatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteAnnotatorResponse}
 */
proto.sora.app.v1beta.DeleteAnnotatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteAnnotatorResponse;
  return proto.sora.app.v1beta.DeleteAnnotatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteAnnotatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteAnnotatorResponse}
 */
proto.sora.app.v1beta.DeleteAnnotatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteAnnotatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteAnnotatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteAnnotatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteAnnotatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadAlertorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadAlertorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadAlertorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadAlertorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadAlertorsRequest}
 */
proto.sora.app.v1beta.ReadAlertorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadAlertorsRequest;
  return proto.sora.app.v1beta.ReadAlertorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadAlertorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadAlertorsRequest}
 */
proto.sora.app.v1beta.ReadAlertorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadAlertorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadAlertorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadAlertorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadAlertorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.ReadAlertorsRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.ReadAlertorsRequest} returns this
 */
proto.sora.app.v1beta.ReadAlertorsRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.ReadAlertorsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadAlertorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadAlertorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadAlertorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadAlertorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertorsList: jspb.Message.toObjectList(msg.getAlertorsList(),
    sora_app_v1beta_types_pb.Alertor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadAlertorsResponse}
 */
proto.sora.app.v1beta.ReadAlertorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadAlertorsResponse;
  return proto.sora.app.v1beta.ReadAlertorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadAlertorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadAlertorsResponse}
 */
proto.sora.app.v1beta.ReadAlertorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Alertor;
      reader.readMessage(value,sora_app_v1beta_types_pb.Alertor.deserializeBinaryFromReader);
      msg.addAlertors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadAlertorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadAlertorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadAlertorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadAlertorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Alertor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Alertor alertors = 1;
 * @return {!Array<!proto.sora.app.v1beta.Alertor>}
 */
proto.sora.app.v1beta.ReadAlertorsResponse.prototype.getAlertorsList = function() {
  return /** @type{!Array<!proto.sora.app.v1beta.Alertor>} */ (
    jspb.Message.getRepeatedWrapperField(this, sora_app_v1beta_types_pb.Alertor, 1));
};


/**
 * @param {!Array<!proto.sora.app.v1beta.Alertor>} value
 * @return {!proto.sora.app.v1beta.ReadAlertorsResponse} returns this
*/
proto.sora.app.v1beta.ReadAlertorsResponse.prototype.setAlertorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sora.app.v1beta.Alertor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sora.app.v1beta.Alertor}
 */
proto.sora.app.v1beta.ReadAlertorsResponse.prototype.addAlertors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sora.app.v1beta.Alertor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.ReadAlertorsResponse} returns this
 */
proto.sora.app.v1beta.ReadAlertorsResponse.prototype.clearAlertorsList = function() {
  return this.setAlertorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateAlertorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateAlertorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateAlertorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateAlertorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertor: (f = msg.getAlertor()) && sora_app_v1beta_types_pb.Alertor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateAlertorRequest}
 */
proto.sora.app.v1beta.CreateAlertorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateAlertorRequest;
  return proto.sora.app.v1beta.CreateAlertorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateAlertorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateAlertorRequest}
 */
proto.sora.app.v1beta.CreateAlertorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Alertor;
      reader.readMessage(value,sora_app_v1beta_types_pb.Alertor.deserializeBinaryFromReader);
      msg.setAlertor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateAlertorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateAlertorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateAlertorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateAlertorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Alertor.serializeBinaryToWriter
    );
  }
};


/**
 * optional Alertor alertor = 1;
 * @return {?proto.sora.app.v1beta.Alertor}
 */
proto.sora.app.v1beta.CreateAlertorRequest.prototype.getAlertor = function() {
  return /** @type{?proto.sora.app.v1beta.Alertor} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Alertor, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Alertor|undefined} value
 * @return {!proto.sora.app.v1beta.CreateAlertorRequest} returns this
*/
proto.sora.app.v1beta.CreateAlertorRequest.prototype.setAlertor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateAlertorRequest} returns this
 */
proto.sora.app.v1beta.CreateAlertorRequest.prototype.clearAlertor = function() {
  return this.setAlertor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateAlertorRequest.prototype.hasAlertor = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateAlertorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateAlertorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateAlertorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateAlertorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertor: (f = msg.getAlertor()) && sora_app_v1beta_types_pb.Alertor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateAlertorResponse}
 */
proto.sora.app.v1beta.CreateAlertorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateAlertorResponse;
  return proto.sora.app.v1beta.CreateAlertorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateAlertorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateAlertorResponse}
 */
proto.sora.app.v1beta.CreateAlertorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Alertor;
      reader.readMessage(value,sora_app_v1beta_types_pb.Alertor.deserializeBinaryFromReader);
      msg.setAlertor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateAlertorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateAlertorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateAlertorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateAlertorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Alertor.serializeBinaryToWriter
    );
  }
};


/**
 * optional Alertor alertor = 1;
 * @return {?proto.sora.app.v1beta.Alertor}
 */
proto.sora.app.v1beta.CreateAlertorResponse.prototype.getAlertor = function() {
  return /** @type{?proto.sora.app.v1beta.Alertor} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Alertor, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Alertor|undefined} value
 * @return {!proto.sora.app.v1beta.CreateAlertorResponse} returns this
*/
proto.sora.app.v1beta.CreateAlertorResponse.prototype.setAlertor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateAlertorResponse} returns this
 */
proto.sora.app.v1beta.CreateAlertorResponse.prototype.clearAlertor = function() {
  return this.setAlertor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateAlertorResponse.prototype.hasAlertor = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateAlertorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateAlertorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateAlertorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateAlertorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertor: (f = msg.getAlertor()) && sora_app_v1beta_types_pb.Alertor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateAlertorRequest}
 */
proto.sora.app.v1beta.UpdateAlertorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateAlertorRequest;
  return proto.sora.app.v1beta.UpdateAlertorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateAlertorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateAlertorRequest}
 */
proto.sora.app.v1beta.UpdateAlertorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Alertor;
      reader.readMessage(value,sora_app_v1beta_types_pb.Alertor.deserializeBinaryFromReader);
      msg.setAlertor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateAlertorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateAlertorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateAlertorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateAlertorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Alertor.serializeBinaryToWriter
    );
  }
};


/**
 * optional Alertor alertor = 1;
 * @return {?proto.sora.app.v1beta.Alertor}
 */
proto.sora.app.v1beta.UpdateAlertorRequest.prototype.getAlertor = function() {
  return /** @type{?proto.sora.app.v1beta.Alertor} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Alertor, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Alertor|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateAlertorRequest} returns this
*/
proto.sora.app.v1beta.UpdateAlertorRequest.prototype.setAlertor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateAlertorRequest} returns this
 */
proto.sora.app.v1beta.UpdateAlertorRequest.prototype.clearAlertor = function() {
  return this.setAlertor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateAlertorRequest.prototype.hasAlertor = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateAlertorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateAlertorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateAlertorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateAlertorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertor: (f = msg.getAlertor()) && sora_app_v1beta_types_pb.Alertor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateAlertorResponse}
 */
proto.sora.app.v1beta.UpdateAlertorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateAlertorResponse;
  return proto.sora.app.v1beta.UpdateAlertorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateAlertorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateAlertorResponse}
 */
proto.sora.app.v1beta.UpdateAlertorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Alertor;
      reader.readMessage(value,sora_app_v1beta_types_pb.Alertor.deserializeBinaryFromReader);
      msg.setAlertor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateAlertorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateAlertorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateAlertorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateAlertorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Alertor.serializeBinaryToWriter
    );
  }
};


/**
 * optional Alertor alertor = 1;
 * @return {?proto.sora.app.v1beta.Alertor}
 */
proto.sora.app.v1beta.UpdateAlertorResponse.prototype.getAlertor = function() {
  return /** @type{?proto.sora.app.v1beta.Alertor} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Alertor, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Alertor|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateAlertorResponse} returns this
*/
proto.sora.app.v1beta.UpdateAlertorResponse.prototype.setAlertor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateAlertorResponse} returns this
 */
proto.sora.app.v1beta.UpdateAlertorResponse.prototype.clearAlertor = function() {
  return this.setAlertor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateAlertorResponse.prototype.hasAlertor = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteAlertorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteAlertorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteAlertorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteAlertorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteAlertorRequest}
 */
proto.sora.app.v1beta.DeleteAlertorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteAlertorRequest;
  return proto.sora.app.v1beta.DeleteAlertorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteAlertorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteAlertorRequest}
 */
proto.sora.app.v1beta.DeleteAlertorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteAlertorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteAlertorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteAlertorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteAlertorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteAlertorRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteAlertorRequest} returns this
 */
proto.sora.app.v1beta.DeleteAlertorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteAlertorRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteAlertorRequest} returns this
 */
proto.sora.app.v1beta.DeleteAlertorRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteAlertorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteAlertorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteAlertorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteAlertorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteAlertorResponse}
 */
proto.sora.app.v1beta.DeleteAlertorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteAlertorResponse;
  return proto.sora.app.v1beta.DeleteAlertorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteAlertorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteAlertorResponse}
 */
proto.sora.app.v1beta.DeleteAlertorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteAlertorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteAlertorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteAlertorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteAlertorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadEventGeneratorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadEventGeneratorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadEventGeneratorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadEventGeneratorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadEventGeneratorsRequest}
 */
proto.sora.app.v1beta.ReadEventGeneratorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadEventGeneratorsRequest;
  return proto.sora.app.v1beta.ReadEventGeneratorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadEventGeneratorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadEventGeneratorsRequest}
 */
proto.sora.app.v1beta.ReadEventGeneratorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadEventGeneratorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadEventGeneratorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadEventGeneratorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadEventGeneratorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.ReadEventGeneratorsRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.ReadEventGeneratorsRequest} returns this
 */
proto.sora.app.v1beta.ReadEventGeneratorsRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadEventGeneratorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadEventGeneratorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventGeneratorsList: jspb.Message.toObjectList(msg.getEventGeneratorsList(),
    sora_app_v1beta_types_pb.EventGenerator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadEventGeneratorsResponse}
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadEventGeneratorsResponse;
  return proto.sora.app.v1beta.ReadEventGeneratorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadEventGeneratorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadEventGeneratorsResponse}
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.EventGenerator;
      reader.readMessage(value,sora_app_v1beta_types_pb.EventGenerator.deserializeBinaryFromReader);
      msg.addEventGenerators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadEventGeneratorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadEventGeneratorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventGeneratorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sora_app_v1beta_types_pb.EventGenerator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventGenerator event_generators = 1;
 * @return {!Array<!proto.sora.app.v1beta.EventGenerator>}
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse.prototype.getEventGeneratorsList = function() {
  return /** @type{!Array<!proto.sora.app.v1beta.EventGenerator>} */ (
    jspb.Message.getRepeatedWrapperField(this, sora_app_v1beta_types_pb.EventGenerator, 1));
};


/**
 * @param {!Array<!proto.sora.app.v1beta.EventGenerator>} value
 * @return {!proto.sora.app.v1beta.ReadEventGeneratorsResponse} returns this
*/
proto.sora.app.v1beta.ReadEventGeneratorsResponse.prototype.setEventGeneratorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sora.app.v1beta.EventGenerator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sora.app.v1beta.EventGenerator}
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse.prototype.addEventGenerators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sora.app.v1beta.EventGenerator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.ReadEventGeneratorsResponse} returns this
 */
proto.sora.app.v1beta.ReadEventGeneratorsResponse.prototype.clearEventGeneratorsList = function() {
  return this.setEventGeneratorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateEventGeneratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateEventGeneratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateEventGeneratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateEventGeneratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventGenerator: (f = msg.getEventGenerator()) && sora_app_v1beta_types_pb.EventGenerator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateEventGeneratorRequest}
 */
proto.sora.app.v1beta.CreateEventGeneratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateEventGeneratorRequest;
  return proto.sora.app.v1beta.CreateEventGeneratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateEventGeneratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateEventGeneratorRequest}
 */
proto.sora.app.v1beta.CreateEventGeneratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.EventGenerator;
      reader.readMessage(value,sora_app_v1beta_types_pb.EventGenerator.deserializeBinaryFromReader);
      msg.setEventGenerator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateEventGeneratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateEventGeneratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateEventGeneratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateEventGeneratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventGenerator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.EventGenerator.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventGenerator event_generator = 1;
 * @return {?proto.sora.app.v1beta.EventGenerator}
 */
proto.sora.app.v1beta.CreateEventGeneratorRequest.prototype.getEventGenerator = function() {
  return /** @type{?proto.sora.app.v1beta.EventGenerator} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.EventGenerator, 1));
};


/**
 * @param {?proto.sora.app.v1beta.EventGenerator|undefined} value
 * @return {!proto.sora.app.v1beta.CreateEventGeneratorRequest} returns this
*/
proto.sora.app.v1beta.CreateEventGeneratorRequest.prototype.setEventGenerator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateEventGeneratorRequest} returns this
 */
proto.sora.app.v1beta.CreateEventGeneratorRequest.prototype.clearEventGenerator = function() {
  return this.setEventGenerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateEventGeneratorRequest.prototype.hasEventGenerator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateEventGeneratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateEventGeneratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateEventGeneratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateEventGeneratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventGenerator: (f = msg.getEventGenerator()) && sora_app_v1beta_types_pb.EventGenerator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateEventGeneratorResponse}
 */
proto.sora.app.v1beta.CreateEventGeneratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateEventGeneratorResponse;
  return proto.sora.app.v1beta.CreateEventGeneratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateEventGeneratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateEventGeneratorResponse}
 */
proto.sora.app.v1beta.CreateEventGeneratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.EventGenerator;
      reader.readMessage(value,sora_app_v1beta_types_pb.EventGenerator.deserializeBinaryFromReader);
      msg.setEventGenerator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateEventGeneratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateEventGeneratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateEventGeneratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateEventGeneratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventGenerator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.EventGenerator.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventGenerator event_generator = 1;
 * @return {?proto.sora.app.v1beta.EventGenerator}
 */
proto.sora.app.v1beta.CreateEventGeneratorResponse.prototype.getEventGenerator = function() {
  return /** @type{?proto.sora.app.v1beta.EventGenerator} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.EventGenerator, 1));
};


/**
 * @param {?proto.sora.app.v1beta.EventGenerator|undefined} value
 * @return {!proto.sora.app.v1beta.CreateEventGeneratorResponse} returns this
*/
proto.sora.app.v1beta.CreateEventGeneratorResponse.prototype.setEventGenerator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateEventGeneratorResponse} returns this
 */
proto.sora.app.v1beta.CreateEventGeneratorResponse.prototype.clearEventGenerator = function() {
  return this.setEventGenerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateEventGeneratorResponse.prototype.hasEventGenerator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateEventGeneratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateEventGeneratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateEventGeneratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateEventGeneratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventGenerator: (f = msg.getEventGenerator()) && sora_app_v1beta_types_pb.EventGenerator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateEventGeneratorRequest}
 */
proto.sora.app.v1beta.UpdateEventGeneratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateEventGeneratorRequest;
  return proto.sora.app.v1beta.UpdateEventGeneratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateEventGeneratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateEventGeneratorRequest}
 */
proto.sora.app.v1beta.UpdateEventGeneratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.EventGenerator;
      reader.readMessage(value,sora_app_v1beta_types_pb.EventGenerator.deserializeBinaryFromReader);
      msg.setEventGenerator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateEventGeneratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateEventGeneratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateEventGeneratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateEventGeneratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventGenerator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.EventGenerator.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventGenerator event_generator = 1;
 * @return {?proto.sora.app.v1beta.EventGenerator}
 */
proto.sora.app.v1beta.UpdateEventGeneratorRequest.prototype.getEventGenerator = function() {
  return /** @type{?proto.sora.app.v1beta.EventGenerator} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.EventGenerator, 1));
};


/**
 * @param {?proto.sora.app.v1beta.EventGenerator|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateEventGeneratorRequest} returns this
*/
proto.sora.app.v1beta.UpdateEventGeneratorRequest.prototype.setEventGenerator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateEventGeneratorRequest} returns this
 */
proto.sora.app.v1beta.UpdateEventGeneratorRequest.prototype.clearEventGenerator = function() {
  return this.setEventGenerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateEventGeneratorRequest.prototype.hasEventGenerator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateEventGeneratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateEventGeneratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateEventGeneratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateEventGeneratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventGenerator: (f = msg.getEventGenerator()) && sora_app_v1beta_types_pb.EventGenerator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateEventGeneratorResponse}
 */
proto.sora.app.v1beta.UpdateEventGeneratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateEventGeneratorResponse;
  return proto.sora.app.v1beta.UpdateEventGeneratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateEventGeneratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateEventGeneratorResponse}
 */
proto.sora.app.v1beta.UpdateEventGeneratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.EventGenerator;
      reader.readMessage(value,sora_app_v1beta_types_pb.EventGenerator.deserializeBinaryFromReader);
      msg.setEventGenerator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateEventGeneratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateEventGeneratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateEventGeneratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateEventGeneratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventGenerator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.EventGenerator.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventGenerator event_generator = 1;
 * @return {?proto.sora.app.v1beta.EventGenerator}
 */
proto.sora.app.v1beta.UpdateEventGeneratorResponse.prototype.getEventGenerator = function() {
  return /** @type{?proto.sora.app.v1beta.EventGenerator} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.EventGenerator, 1));
};


/**
 * @param {?proto.sora.app.v1beta.EventGenerator|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateEventGeneratorResponse} returns this
*/
proto.sora.app.v1beta.UpdateEventGeneratorResponse.prototype.setEventGenerator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateEventGeneratorResponse} returns this
 */
proto.sora.app.v1beta.UpdateEventGeneratorResponse.prototype.clearEventGenerator = function() {
  return this.setEventGenerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateEventGeneratorResponse.prototype.hasEventGenerator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteEventGeneratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteEventGeneratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteEventGeneratorRequest}
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteEventGeneratorRequest;
  return proto.sora.app.v1beta.DeleteEventGeneratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteEventGeneratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteEventGeneratorRequest}
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteEventGeneratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteEventGeneratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteEventGeneratorRequest} returns this
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteEventGeneratorRequest} returns this
 */
proto.sora.app.v1beta.DeleteEventGeneratorRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteEventGeneratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteEventGeneratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteEventGeneratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteEventGeneratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteEventGeneratorResponse}
 */
proto.sora.app.v1beta.DeleteEventGeneratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteEventGeneratorResponse;
  return proto.sora.app.v1beta.DeleteEventGeneratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteEventGeneratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteEventGeneratorResponse}
 */
proto.sora.app.v1beta.DeleteEventGeneratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteEventGeneratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteEventGeneratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteEventGeneratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteEventGeneratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadLayersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadLayersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadLayersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadLayersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadLayersRequest}
 */
proto.sora.app.v1beta.ReadLayersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadLayersRequest;
  return proto.sora.app.v1beta.ReadLayersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadLayersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadLayersRequest}
 */
proto.sora.app.v1beta.ReadLayersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadLayersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadLayersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadLayersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadLayersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.ReadLayersRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.ReadLayersRequest} returns this
 */
proto.sora.app.v1beta.ReadLayersRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.ReadLayersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.ReadLayersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.ReadLayersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.ReadLayersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadLayersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    layersList: jspb.Message.toObjectList(msg.getLayersList(),
    sora_app_v1beta_types_pb.Layer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.ReadLayersResponse}
 */
proto.sora.app.v1beta.ReadLayersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.ReadLayersResponse;
  return proto.sora.app.v1beta.ReadLayersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.ReadLayersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.ReadLayersResponse}
 */
proto.sora.app.v1beta.ReadLayersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Layer;
      reader.readMessage(value,sora_app_v1beta_types_pb.Layer.deserializeBinaryFromReader);
      msg.addLayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.ReadLayersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.ReadLayersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.ReadLayersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.ReadLayersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Layer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Layer layers = 1;
 * @return {!Array<!proto.sora.app.v1beta.Layer>}
 */
proto.sora.app.v1beta.ReadLayersResponse.prototype.getLayersList = function() {
  return /** @type{!Array<!proto.sora.app.v1beta.Layer>} */ (
    jspb.Message.getRepeatedWrapperField(this, sora_app_v1beta_types_pb.Layer, 1));
};


/**
 * @param {!Array<!proto.sora.app.v1beta.Layer>} value
 * @return {!proto.sora.app.v1beta.ReadLayersResponse} returns this
*/
proto.sora.app.v1beta.ReadLayersResponse.prototype.setLayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sora.app.v1beta.Layer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sora.app.v1beta.Layer}
 */
proto.sora.app.v1beta.ReadLayersResponse.prototype.addLayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sora.app.v1beta.Layer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.ReadLayersResponse} returns this
 */
proto.sora.app.v1beta.ReadLayersResponse.prototype.clearLayersList = function() {
  return this.setLayersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateLayerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateLayerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateLayerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateLayerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    layer: (f = msg.getLayer()) && sora_app_v1beta_types_pb.Layer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateLayerRequest}
 */
proto.sora.app.v1beta.CreateLayerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateLayerRequest;
  return proto.sora.app.v1beta.CreateLayerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateLayerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateLayerRequest}
 */
proto.sora.app.v1beta.CreateLayerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Layer;
      reader.readMessage(value,sora_app_v1beta_types_pb.Layer.deserializeBinaryFromReader);
      msg.setLayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateLayerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateLayerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateLayerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateLayerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLayer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Layer.serializeBinaryToWriter
    );
  }
};


/**
 * optional Layer layer = 1;
 * @return {?proto.sora.app.v1beta.Layer}
 */
proto.sora.app.v1beta.CreateLayerRequest.prototype.getLayer = function() {
  return /** @type{?proto.sora.app.v1beta.Layer} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Layer, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Layer|undefined} value
 * @return {!proto.sora.app.v1beta.CreateLayerRequest} returns this
*/
proto.sora.app.v1beta.CreateLayerRequest.prototype.setLayer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateLayerRequest} returns this
 */
proto.sora.app.v1beta.CreateLayerRequest.prototype.clearLayer = function() {
  return this.setLayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateLayerRequest.prototype.hasLayer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.CreateLayerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.CreateLayerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.CreateLayerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateLayerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    layer: (f = msg.getLayer()) && sora_app_v1beta_types_pb.Layer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.CreateLayerResponse}
 */
proto.sora.app.v1beta.CreateLayerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.CreateLayerResponse;
  return proto.sora.app.v1beta.CreateLayerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.CreateLayerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.CreateLayerResponse}
 */
proto.sora.app.v1beta.CreateLayerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Layer;
      reader.readMessage(value,sora_app_v1beta_types_pb.Layer.deserializeBinaryFromReader);
      msg.setLayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.CreateLayerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.CreateLayerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.CreateLayerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.CreateLayerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLayer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Layer.serializeBinaryToWriter
    );
  }
};


/**
 * optional Layer layer = 1;
 * @return {?proto.sora.app.v1beta.Layer}
 */
proto.sora.app.v1beta.CreateLayerResponse.prototype.getLayer = function() {
  return /** @type{?proto.sora.app.v1beta.Layer} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Layer, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Layer|undefined} value
 * @return {!proto.sora.app.v1beta.CreateLayerResponse} returns this
*/
proto.sora.app.v1beta.CreateLayerResponse.prototype.setLayer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.CreateLayerResponse} returns this
 */
proto.sora.app.v1beta.CreateLayerResponse.prototype.clearLayer = function() {
  return this.setLayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.CreateLayerResponse.prototype.hasLayer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateLayerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateLayerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateLayerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateLayerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    layer: (f = msg.getLayer()) && sora_app_v1beta_types_pb.Layer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateLayerRequest}
 */
proto.sora.app.v1beta.UpdateLayerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateLayerRequest;
  return proto.sora.app.v1beta.UpdateLayerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateLayerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateLayerRequest}
 */
proto.sora.app.v1beta.UpdateLayerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Layer;
      reader.readMessage(value,sora_app_v1beta_types_pb.Layer.deserializeBinaryFromReader);
      msg.setLayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateLayerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateLayerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateLayerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateLayerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLayer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Layer.serializeBinaryToWriter
    );
  }
};


/**
 * optional Layer layer = 1;
 * @return {?proto.sora.app.v1beta.Layer}
 */
proto.sora.app.v1beta.UpdateLayerRequest.prototype.getLayer = function() {
  return /** @type{?proto.sora.app.v1beta.Layer} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Layer, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Layer|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateLayerRequest} returns this
*/
proto.sora.app.v1beta.UpdateLayerRequest.prototype.setLayer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateLayerRequest} returns this
 */
proto.sora.app.v1beta.UpdateLayerRequest.prototype.clearLayer = function() {
  return this.setLayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateLayerRequest.prototype.hasLayer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.UpdateLayerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.UpdateLayerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.UpdateLayerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateLayerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    layer: (f = msg.getLayer()) && sora_app_v1beta_types_pb.Layer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.UpdateLayerResponse}
 */
proto.sora.app.v1beta.UpdateLayerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.UpdateLayerResponse;
  return proto.sora.app.v1beta.UpdateLayerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.UpdateLayerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.UpdateLayerResponse}
 */
proto.sora.app.v1beta.UpdateLayerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_app_v1beta_types_pb.Layer;
      reader.readMessage(value,sora_app_v1beta_types_pb.Layer.deserializeBinaryFromReader);
      msg.setLayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.UpdateLayerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.UpdateLayerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.UpdateLayerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.UpdateLayerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLayer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sora_app_v1beta_types_pb.Layer.serializeBinaryToWriter
    );
  }
};


/**
 * optional Layer layer = 1;
 * @return {?proto.sora.app.v1beta.Layer}
 */
proto.sora.app.v1beta.UpdateLayerResponse.prototype.getLayer = function() {
  return /** @type{?proto.sora.app.v1beta.Layer} */ (
    jspb.Message.getWrapperField(this, sora_app_v1beta_types_pb.Layer, 1));
};


/**
 * @param {?proto.sora.app.v1beta.Layer|undefined} value
 * @return {!proto.sora.app.v1beta.UpdateLayerResponse} returns this
*/
proto.sora.app.v1beta.UpdateLayerResponse.prototype.setLayer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.UpdateLayerResponse} returns this
 */
proto.sora.app.v1beta.UpdateLayerResponse.prototype.clearLayer = function() {
  return this.setLayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.UpdateLayerResponse.prototype.hasLayer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteLayerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteLayerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteLayerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteLayerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteLayerRequest}
 */
proto.sora.app.v1beta.DeleteLayerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteLayerRequest;
  return proto.sora.app.v1beta.DeleteLayerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteLayerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteLayerRequest}
 */
proto.sora.app.v1beta.DeleteLayerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteLayerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteLayerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteLayerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteLayerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteLayerRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteLayerRequest} returns this
 */
proto.sora.app.v1beta.DeleteLayerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.sora.app.v1beta.DeleteLayerRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.DeleteLayerRequest} returns this
 */
proto.sora.app.v1beta.DeleteLayerRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeleteLayerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeleteLayerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeleteLayerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteLayerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeleteLayerResponse}
 */
proto.sora.app.v1beta.DeleteLayerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeleteLayerResponse;
  return proto.sora.app.v1beta.DeleteLayerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeleteLayerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeleteLayerResponse}
 */
proto.sora.app.v1beta.DeleteLayerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeleteLayerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeleteLayerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeleteLayerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeleteLayerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.DeviceSelection.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.DeviceSelection.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.DeviceSelection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.DeviceSelection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeviceSelection.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.DeviceSelection}
 */
proto.sora.app.v1beta.DeviceSelection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.DeviceSelection;
  return proto.sora.app.v1beta.DeviceSelection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.DeviceSelection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.DeviceSelection}
 */
proto.sora.app.v1beta.DeviceSelection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUuids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.DeviceSelection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.DeviceSelection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.DeviceSelection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.DeviceSelection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string uuids = 1;
 * @return {!Array<string>}
 */
proto.sora.app.v1beta.DeviceSelection.prototype.getUuidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sora.app.v1beta.DeviceSelection} returns this
 */
proto.sora.app.v1beta.DeviceSelection.prototype.setUuidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sora.app.v1beta.DeviceSelection} returns this
 */
proto.sora.app.v1beta.DeviceSelection.prototype.addUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.DeviceSelection} returns this
 */
proto.sora.app.v1beta.DeviceSelection.prototype.clearUuidsList = function() {
  return this.setUuidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.TimeSeriesRangeSelection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.TimeSeriesRangeSelection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startCursor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endCursor: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.TimeSeriesRangeSelection}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.TimeSeriesRangeSelection;
  return proto.sora.app.v1beta.TimeSeriesRangeSelection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.TimeSeriesRangeSelection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.TimeSeriesRangeSelection}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartCursor(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.TimeSeriesRangeSelection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.TimeSeriesRangeSelection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp start_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sora.app.v1beta.TimeSeriesRangeSelection} returns this
*/
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.TimeSeriesRangeSelection} returns this
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 start_cursor = 2;
 * @return {number}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.getStartCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.TimeSeriesRangeSelection} returns this
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.setStartCursor = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sora.app.v1beta.TimeSeriesRangeSelection} returns this
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.clearStartCursor = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.hasStartCursor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sora.app.v1beta.TimeSeriesRangeSelection} returns this
*/
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.TimeSeriesRangeSelection} returns this
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 end_cursor = 4;
 * @return {number}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.getEndCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.TimeSeriesRangeSelection} returns this
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.setEndCursor = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sora.app.v1beta.TimeSeriesRangeSelection} returns this
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.clearEndCursor = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.TimeSeriesRangeSelection.prototype.hasEndCursor = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.StreamStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.StreamStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.StreamStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.StreamStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && proto.sora.app.v1beta.DeviceSelection.toObject(includeInstance, f),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.StreamStateRequest}
 */
proto.sora.app.v1beta.StreamStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.StreamStateRequest;
  return proto.sora.app.v1beta.StreamStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.StreamStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.StreamStateRequest}
 */
proto.sora.app.v1beta.StreamStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sora.app.v1beta.DeviceSelection;
      reader.readMessage(value,proto.sora.app.v1beta.DeviceSelection.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.StreamStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.StreamStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.StreamStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.StreamStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sora.app.v1beta.DeviceSelection.serializeBinaryToWriter
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DeviceSelection device = 1;
 * @return {?proto.sora.app.v1beta.DeviceSelection}
 */
proto.sora.app.v1beta.StreamStateRequest.prototype.getDevice = function() {
  return /** @type{?proto.sora.app.v1beta.DeviceSelection} */ (
    jspb.Message.getWrapperField(this, proto.sora.app.v1beta.DeviceSelection, 1));
};


/**
 * @param {?proto.sora.app.v1beta.DeviceSelection|undefined} value
 * @return {!proto.sora.app.v1beta.StreamStateRequest} returns this
*/
proto.sora.app.v1beta.StreamStateRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.StreamStateRequest} returns this
 */
proto.sora.app.v1beta.StreamStateRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.StreamStateRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.sora.app.v1beta.StreamStateRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.StreamStateRequest} returns this
 */
proto.sora.app.v1beta.StreamStateRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.StreamStateResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.StreamStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.StreamStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.StreamStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statesList: jspb.Message.toObjectList(msg.getStatesList(),
    sora_v1beta_common_pb.AnnotatedDeviceState.toObject, includeInstance),
    fromCursor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startCursor: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endCursor: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.StreamStateResponse}
 */
proto.sora.app.v1beta.StreamStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.StreamStateResponse;
  return proto.sora.app.v1beta.StreamStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.StreamStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.StreamStateResponse}
 */
proto.sora.app.v1beta.StreamStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_v1beta_common_pb.AnnotatedDeviceState;
      reader.readMessage(value,sora_v1beta_common_pb.AnnotatedDeviceState.deserializeBinaryFromReader);
      msg.addStates(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromCursor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartCursor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.StreamStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.StreamStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.StreamStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sora_v1beta_common_pb.AnnotatedDeviceState.serializeBinaryToWriter
    );
  }
  f = message.getFromCursor();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStartCursor();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getEndCursor();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * repeated sora.v1beta.AnnotatedDeviceState states = 1;
 * @return {!Array<!proto.sora.v1beta.AnnotatedDeviceState>}
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.getStatesList = function() {
  return /** @type{!Array<!proto.sora.v1beta.AnnotatedDeviceState>} */ (
    jspb.Message.getRepeatedWrapperField(this, sora_v1beta_common_pb.AnnotatedDeviceState, 1));
};


/**
 * @param {!Array<!proto.sora.v1beta.AnnotatedDeviceState>} value
 * @return {!proto.sora.app.v1beta.StreamStateResponse} returns this
*/
proto.sora.app.v1beta.StreamStateResponse.prototype.setStatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sora.v1beta.AnnotatedDeviceState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sora.v1beta.AnnotatedDeviceState}
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.addStates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sora.v1beta.AnnotatedDeviceState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.StreamStateResponse} returns this
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.clearStatesList = function() {
  return this.setStatesList([]);
};


/**
 * optional uint64 from_cursor = 2;
 * @return {number}
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.getFromCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.StreamStateResponse} returns this
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.setFromCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 start_cursor = 3;
 * @return {number}
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.getStartCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.StreamStateResponse} returns this
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.setStartCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 end_cursor = 4;
 * @return {number}
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.getEndCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.StreamStateResponse} returns this
 */
proto.sora.app.v1beta.StreamStateResponse.prototype.setEndCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.StreamEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.StreamEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.StreamEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.StreamEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && proto.sora.app.v1beta.DeviceSelection.toObject(includeInstance, f),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.StreamEventsRequest}
 */
proto.sora.app.v1beta.StreamEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.StreamEventsRequest;
  return proto.sora.app.v1beta.StreamEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.StreamEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.StreamEventsRequest}
 */
proto.sora.app.v1beta.StreamEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sora.app.v1beta.DeviceSelection;
      reader.readMessage(value,proto.sora.app.v1beta.DeviceSelection.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.StreamEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.StreamEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.StreamEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.StreamEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sora.app.v1beta.DeviceSelection.serializeBinaryToWriter
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DeviceSelection device = 1;
 * @return {?proto.sora.app.v1beta.DeviceSelection}
 */
proto.sora.app.v1beta.StreamEventsRequest.prototype.getDevice = function() {
  return /** @type{?proto.sora.app.v1beta.DeviceSelection} */ (
    jspb.Message.getWrapperField(this, proto.sora.app.v1beta.DeviceSelection, 1));
};


/**
 * @param {?proto.sora.app.v1beta.DeviceSelection|undefined} value
 * @return {!proto.sora.app.v1beta.StreamEventsRequest} returns this
*/
proto.sora.app.v1beta.StreamEventsRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.StreamEventsRequest} returns this
 */
proto.sora.app.v1beta.StreamEventsRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.StreamEventsRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.sora.app.v1beta.StreamEventsRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.StreamEventsRequest} returns this
 */
proto.sora.app.v1beta.StreamEventsRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.StreamEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.StreamEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.StreamEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.StreamEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    sora_v1beta_common_pb.Event.toObject, includeInstance),
    fromCursor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startCursor: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endCursor: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.StreamEventsResponse}
 */
proto.sora.app.v1beta.StreamEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.StreamEventsResponse;
  return proto.sora.app.v1beta.StreamEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.StreamEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.StreamEventsResponse}
 */
proto.sora.app.v1beta.StreamEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_v1beta_common_pb.Event;
      reader.readMessage(value,sora_v1beta_common_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromCursor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartCursor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.StreamEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.StreamEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.StreamEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sora_v1beta_common_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getFromCursor();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStartCursor();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getEndCursor();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * repeated sora.v1beta.Event events = 1;
 * @return {!Array<!proto.sora.v1beta.Event>}
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.sora.v1beta.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, sora_v1beta_common_pb.Event, 1));
};


/**
 * @param {!Array<!proto.sora.v1beta.Event>} value
 * @return {!proto.sora.app.v1beta.StreamEventsResponse} returns this
*/
proto.sora.app.v1beta.StreamEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sora.v1beta.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sora.v1beta.Event}
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sora.v1beta.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.StreamEventsResponse} returns this
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional uint64 from_cursor = 2;
 * @return {number}
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.getFromCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.StreamEventsResponse} returns this
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.setFromCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 start_cursor = 3;
 * @return {number}
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.getStartCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.StreamEventsResponse} returns this
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.setStartCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 end_cursor = 4;
 * @return {number}
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.getEndCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.StreamEventsResponse} returns this
 */
proto.sora.app.v1beta.StreamEventsResponse.prototype.setEndCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.QueryStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.QueryStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    devices: (f = msg.getDevices()) && proto.sora.app.v1beta.DeviceSelection.toObject(includeInstance, f),
    range: (f = msg.getRange()) && proto.sora.app.v1beta.TimeSeriesRangeSelection.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.QueryStateRequest}
 */
proto.sora.app.v1beta.QueryStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.QueryStateRequest;
  return proto.sora.app.v1beta.QueryStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.QueryStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.QueryStateRequest}
 */
proto.sora.app.v1beta.QueryStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sora.app.v1beta.DeviceSelection;
      reader.readMessage(value,proto.sora.app.v1beta.DeviceSelection.deserializeBinaryFromReader);
      msg.setDevices(value);
      break;
    case 2:
      var value = new proto.sora.app.v1beta.TimeSeriesRangeSelection;
      reader.readMessage(value,proto.sora.app.v1beta.TimeSeriesRangeSelection.deserializeBinaryFromReader);
      msg.setRange(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.QueryStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.QueryStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevices();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sora.app.v1beta.DeviceSelection.serializeBinaryToWriter
    );
  }
  f = message.getRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sora.app.v1beta.TimeSeriesRangeSelection.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional DeviceSelection devices = 1;
 * @return {?proto.sora.app.v1beta.DeviceSelection}
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.getDevices = function() {
  return /** @type{?proto.sora.app.v1beta.DeviceSelection} */ (
    jspb.Message.getWrapperField(this, proto.sora.app.v1beta.DeviceSelection, 1));
};


/**
 * @param {?proto.sora.app.v1beta.DeviceSelection|undefined} value
 * @return {!proto.sora.app.v1beta.QueryStateRequest} returns this
*/
proto.sora.app.v1beta.QueryStateRequest.prototype.setDevices = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.QueryStateRequest} returns this
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.clearDevices = function() {
  return this.setDevices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.hasDevices = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimeSeriesRangeSelection range = 2;
 * @return {?proto.sora.app.v1beta.TimeSeriesRangeSelection}
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.getRange = function() {
  return /** @type{?proto.sora.app.v1beta.TimeSeriesRangeSelection} */ (
    jspb.Message.getWrapperField(this, proto.sora.app.v1beta.TimeSeriesRangeSelection, 2));
};


/**
 * @param {?proto.sora.app.v1beta.TimeSeriesRangeSelection|undefined} value
 * @return {!proto.sora.app.v1beta.QueryStateRequest} returns this
*/
proto.sora.app.v1beta.QueryStateRequest.prototype.setRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.QueryStateRequest} returns this
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.clearRange = function() {
  return this.setRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.hasRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 limit = 3;
 * @return {number}
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryStateRequest} returns this
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string project_id = 4;
 * @return {string}
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.QueryStateRequest} returns this
 */
proto.sora.app.v1beta.QueryStateRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sora.app.v1beta.QueryStateResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.sora.app.v1beta.QueryStateResponse.ContinuationCase = {
  CONTINUATION_NOT_SET: 0,
  DONE: 2,
  CONT: 3
};

/**
 * @return {proto.sora.app.v1beta.QueryStateResponse.ContinuationCase}
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.getContinuationCase = function() {
  return /** @type {proto.sora.app.v1beta.QueryStateResponse.ContinuationCase} */(jspb.Message.computeOneofCase(this, proto.sora.app.v1beta.QueryStateResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.QueryStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.QueryStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupedStatesMap: (f = msg.getGroupedStatesMap()) ? f.toObject(includeInstance, proto.sora.v1beta.AnnotatedDeviceStates.toObject) : [],
    done: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cont: (f = msg.getCont()) && proto.sora.app.v1beta.TimeSeriesRangeSelection.toObject(includeInstance, f),
    startCursor: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endCursor: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.QueryStateResponse}
 */
proto.sora.app.v1beta.QueryStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.QueryStateResponse;
  return proto.sora.app.v1beta.QueryStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.QueryStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.QueryStateResponse}
 */
proto.sora.app.v1beta.QueryStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getGroupedStatesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.sora.v1beta.AnnotatedDeviceStates.deserializeBinaryFromReader, "", new proto.sora.v1beta.AnnotatedDeviceStates());
         });
      break;
    case 2:
      var value = /** @type {!proto.google.protobuf.NullValue} */ (reader.readEnum());
      msg.setDone(value);
      break;
    case 3:
      var value = new proto.sora.app.v1beta.TimeSeriesRangeSelection;
      reader.readMessage(value,proto.sora.app.v1beta.TimeSeriesRangeSelection.deserializeBinaryFromReader);
      msg.setCont(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartCursor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.QueryStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.QueryStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupedStatesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.sora.v1beta.AnnotatedDeviceStates.serializeBinaryToWriter);
  }
  f = /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCont();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sora.app.v1beta.TimeSeriesRangeSelection.serializeBinaryToWriter
    );
  }
  f = message.getStartCursor();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getEndCursor();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * map<string, sora.v1beta.AnnotatedDeviceStates> grouped_states = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.sora.v1beta.AnnotatedDeviceStates>}
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.getGroupedStatesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.sora.v1beta.AnnotatedDeviceStates>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.sora.v1beta.AnnotatedDeviceStates));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sora.app.v1beta.QueryStateResponse} returns this
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.clearGroupedStatesMap = function() {
  this.getGroupedStatesMap().clear();
  return this;
};


/**
 * optional google.protobuf.NullValue done = 2;
 * @return {!proto.google.protobuf.NullValue}
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.getDone = function() {
  return /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.protobuf.NullValue} value
 * @return {!proto.sora.app.v1beta.QueryStateResponse} returns this
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.setDone = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.sora.app.v1beta.QueryStateResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sora.app.v1beta.QueryStateResponse} returns this
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.clearDone = function() {
  return jspb.Message.setOneofField(this, 2, proto.sora.app.v1beta.QueryStateResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.hasDone = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeSeriesRangeSelection cont = 3;
 * @return {?proto.sora.app.v1beta.TimeSeriesRangeSelection}
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.getCont = function() {
  return /** @type{?proto.sora.app.v1beta.TimeSeriesRangeSelection} */ (
    jspb.Message.getWrapperField(this, proto.sora.app.v1beta.TimeSeriesRangeSelection, 3));
};


/**
 * @param {?proto.sora.app.v1beta.TimeSeriesRangeSelection|undefined} value
 * @return {!proto.sora.app.v1beta.QueryStateResponse} returns this
*/
proto.sora.app.v1beta.QueryStateResponse.prototype.setCont = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.sora.app.v1beta.QueryStateResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.QueryStateResponse} returns this
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.clearCont = function() {
  return this.setCont(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.hasCont = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 start_cursor = 4;
 * @return {number}
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.getStartCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryStateResponse} returns this
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.setStartCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 end_cursor = 5;
 * @return {number}
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.getEndCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryStateResponse} returns this
 */
proto.sora.app.v1beta.QueryStateResponse.prototype.setEndCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.QueryEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.QueryEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    devices: (f = msg.getDevices()) && proto.sora.app.v1beta.DeviceSelection.toObject(includeInstance, f),
    range: (f = msg.getRange()) && proto.sora.app.v1beta.TimeSeriesRangeSelection.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.QueryEventsRequest}
 */
proto.sora.app.v1beta.QueryEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.QueryEventsRequest;
  return proto.sora.app.v1beta.QueryEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.QueryEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.QueryEventsRequest}
 */
proto.sora.app.v1beta.QueryEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sora.app.v1beta.DeviceSelection;
      reader.readMessage(value,proto.sora.app.v1beta.DeviceSelection.deserializeBinaryFromReader);
      msg.setDevices(value);
      break;
    case 2:
      var value = new proto.sora.app.v1beta.TimeSeriesRangeSelection;
      reader.readMessage(value,proto.sora.app.v1beta.TimeSeriesRangeSelection.deserializeBinaryFromReader);
      msg.setRange(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.QueryEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.QueryEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevices();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sora.app.v1beta.DeviceSelection.serializeBinaryToWriter
    );
  }
  f = message.getRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sora.app.v1beta.TimeSeriesRangeSelection.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional DeviceSelection devices = 1;
 * @return {?proto.sora.app.v1beta.DeviceSelection}
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.getDevices = function() {
  return /** @type{?proto.sora.app.v1beta.DeviceSelection} */ (
    jspb.Message.getWrapperField(this, proto.sora.app.v1beta.DeviceSelection, 1));
};


/**
 * @param {?proto.sora.app.v1beta.DeviceSelection|undefined} value
 * @return {!proto.sora.app.v1beta.QueryEventsRequest} returns this
*/
proto.sora.app.v1beta.QueryEventsRequest.prototype.setDevices = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.QueryEventsRequest} returns this
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.clearDevices = function() {
  return this.setDevices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.hasDevices = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimeSeriesRangeSelection range = 2;
 * @return {?proto.sora.app.v1beta.TimeSeriesRangeSelection}
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.getRange = function() {
  return /** @type{?proto.sora.app.v1beta.TimeSeriesRangeSelection} */ (
    jspb.Message.getWrapperField(this, proto.sora.app.v1beta.TimeSeriesRangeSelection, 2));
};


/**
 * @param {?proto.sora.app.v1beta.TimeSeriesRangeSelection|undefined} value
 * @return {!proto.sora.app.v1beta.QueryEventsRequest} returns this
*/
proto.sora.app.v1beta.QueryEventsRequest.prototype.setRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.QueryEventsRequest} returns this
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.clearRange = function() {
  return this.setRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.hasRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 limit = 3;
 * @return {number}
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryEventsRequest} returns this
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string project_id = 4;
 * @return {string}
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.QueryEventsRequest} returns this
 */
proto.sora.app.v1beta.QueryEventsRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.QueryEventsResponse.repeatedFields_ = [1];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sora.app.v1beta.QueryEventsResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.sora.app.v1beta.QueryEventsResponse.ContinuationCase = {
  CONTINUATION_NOT_SET: 0,
  DONE: 2,
  CONT: 3
};

/**
 * @return {proto.sora.app.v1beta.QueryEventsResponse.ContinuationCase}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.getContinuationCase = function() {
  return /** @type {proto.sora.app.v1beta.QueryEventsResponse.ContinuationCase} */(jspb.Message.computeOneofCase(this, proto.sora.app.v1beta.QueryEventsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.QueryEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.QueryEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    sora_v1beta_common_pb.Event.toObject, includeInstance),
    done: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cont: (f = msg.getCont()) && proto.sora.app.v1beta.TimeSeriesRangeSelection.toObject(includeInstance, f),
    startCursor: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endCursor: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.QueryEventsResponse}
 */
proto.sora.app.v1beta.QueryEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.QueryEventsResponse;
  return proto.sora.app.v1beta.QueryEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.QueryEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.QueryEventsResponse}
 */
proto.sora.app.v1beta.QueryEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sora_v1beta_common_pb.Event;
      reader.readMessage(value,sora_v1beta_common_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = /** @type {!proto.google.protobuf.NullValue} */ (reader.readEnum());
      msg.setDone(value);
      break;
    case 3:
      var value = new proto.sora.app.v1beta.TimeSeriesRangeSelection;
      reader.readMessage(value,proto.sora.app.v1beta.TimeSeriesRangeSelection.deserializeBinaryFromReader);
      msg.setCont(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartCursor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.QueryEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.QueryEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sora_v1beta_common_pb.Event.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCont();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sora.app.v1beta.TimeSeriesRangeSelection.serializeBinaryToWriter
    );
  }
  f = message.getStartCursor();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getEndCursor();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * repeated sora.v1beta.Event events = 1;
 * @return {!Array<!proto.sora.v1beta.Event>}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.sora.v1beta.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, sora_v1beta_common_pb.Event, 1));
};


/**
 * @param {!Array<!proto.sora.v1beta.Event>} value
 * @return {!proto.sora.app.v1beta.QueryEventsResponse} returns this
*/
proto.sora.app.v1beta.QueryEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sora.v1beta.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sora.v1beta.Event}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sora.v1beta.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.QueryEventsResponse} returns this
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional google.protobuf.NullValue done = 2;
 * @return {!proto.google.protobuf.NullValue}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.getDone = function() {
  return /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.protobuf.NullValue} value
 * @return {!proto.sora.app.v1beta.QueryEventsResponse} returns this
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.setDone = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.sora.app.v1beta.QueryEventsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sora.app.v1beta.QueryEventsResponse} returns this
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.clearDone = function() {
  return jspb.Message.setOneofField(this, 2, proto.sora.app.v1beta.QueryEventsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.hasDone = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeSeriesRangeSelection cont = 3;
 * @return {?proto.sora.app.v1beta.TimeSeriesRangeSelection}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.getCont = function() {
  return /** @type{?proto.sora.app.v1beta.TimeSeriesRangeSelection} */ (
    jspb.Message.getWrapperField(this, proto.sora.app.v1beta.TimeSeriesRangeSelection, 3));
};


/**
 * @param {?proto.sora.app.v1beta.TimeSeriesRangeSelection|undefined} value
 * @return {!proto.sora.app.v1beta.QueryEventsResponse} returns this
*/
proto.sora.app.v1beta.QueryEventsResponse.prototype.setCont = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.sora.app.v1beta.QueryEventsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.QueryEventsResponse} returns this
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.clearCont = function() {
  return this.setCont(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.hasCont = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 start_cursor = 4;
 * @return {number}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.getStartCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryEventsResponse} returns this
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.setStartCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 end_cursor = 5;
 * @return {number}
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.getEndCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryEventsResponse} returns this
 */
proto.sora.app.v1beta.QueryEventsResponse.prototype.setEndCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.RangeCase = {
  RANGE_NOT_SET: 0,
  MONTH: 2
};

/**
 * @return {proto.sora.app.v1beta.QueryStateAggregateRequest.RangeCase}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.prototype.getRangeCase = function() {
  return /** @type {proto.sora.app.v1beta.QueryStateAggregateRequest.RangeCase} */(jspb.Message.computeOneofCase(this, proto.sora.app.v1beta.QueryStateAggregateRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.QueryStateAggregateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.QueryStateAggregateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    month: (f = msg.getMonth()) && proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateRequest}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.QueryStateAggregateRequest;
  return proto.sora.app.v1beta.QueryStateAggregateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.QueryStateAggregateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateRequest}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange;
      reader.readMessage(value,proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.deserializeBinaryFromReader);
      msg.setMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.QueryStateAggregateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.QueryStateAggregateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange;
  return proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string timezone = 3;
 * @return {string}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateRequest} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MonthRange month = 2;
 * @return {?proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.prototype.getMonth = function() {
  return /** @type{?proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange} */ (
    jspb.Message.getWrapperField(this, proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange, 2));
};


/**
 * @param {?proto.sora.app.v1beta.QueryStateAggregateRequest.MonthRange|undefined} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateRequest} returns this
*/
proto.sora.app.v1beta.QueryStateAggregateRequest.prototype.setMonth = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.sora.app.v1beta.QueryStateAggregateRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateRequest} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.prototype.clearMonth = function() {
  return this.setMonth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.QueryStateAggregateRequest.prototype.hasMonth = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.QueryStateAggregateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.QueryStateAggregateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    countsList: jspb.Message.toObjectList(msg.getCountsList(),
    proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.QueryStateAggregateResponse;
  return proto.sora.app.v1beta.QueryStateAggregateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.QueryStateAggregateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry;
      reader.readMessage(value,proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.deserializeBinaryFromReader);
      msg.addCounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.QueryStateAggregateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.QueryStateAggregateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.QueryStateAggregateResponse.Date.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.QueryStateAggregateResponse.Date} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.Date}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.QueryStateAggregateResponse.Date;
  return proto.sora.app.v1beta.QueryStateAggregateResponse.Date.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.QueryStateAggregateResponse.Date} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.Date}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.QueryStateAggregateResponse.Date.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.QueryStateAggregateResponse.Date} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.Date} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.Date} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 day = 3;
 * @return {number}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.Date} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.Date.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && proto.sora.app.v1beta.QueryStateAggregateResponse.Date.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry;
  return proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sora.app.v1beta.QueryStateAggregateResponse.Date;
      reader.readMessage(value,proto.sora.app.v1beta.QueryStateAggregateResponse.Date.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sora.app.v1beta.QueryStateAggregateResponse.Date.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Date date = 1;
 * @return {?proto.sora.app.v1beta.QueryStateAggregateResponse.Date}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.prototype.getDate = function() {
  return /** @type{?proto.sora.app.v1beta.QueryStateAggregateResponse.Date} */ (
    jspb.Message.getWrapperField(this, proto.sora.app.v1beta.QueryStateAggregateResponse.Date, 1));
};


/**
 * @param {?proto.sora.app.v1beta.QueryStateAggregateResponse.Date|undefined} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry} returns this
*/
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated CountEntry counts = 1;
 * @return {!Array<!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry>}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.prototype.getCountsList = function() {
  return /** @type{!Array<!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry, 1));
};


/**
 * @param {!Array<!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry>} value
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse} returns this
*/
proto.sora.app.v1beta.QueryStateAggregateResponse.prototype.setCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry}
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.prototype.addCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sora.app.v1beta.QueryStateAggregateResponse.CountEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sora.app.v1beta.QueryStateAggregateResponse} returns this
 */
proto.sora.app.v1beta.QueryStateAggregateResponse.prototype.clearCountsList = function() {
  return this.setCountsList([]);
};


goog.object.extend(exports, proto.sora.app.v1beta);
