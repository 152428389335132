import { RGBAColor } from "@deck.gl/core";
import { RgbColor } from "react-colorful";

export const COLOR_PALETTE: RGBAColor[] = [
  [230, 25, 75],
  [60, 180, 75],
  [255, 225, 25],
  [0, 130, 200],
  [245, 130, 48],
  [145, 30, 180],
  [70, 240, 240],
  [240, 50, 230],
  [210, 245, 60],
  [250, 190, 212],
  [0, 128, 128],
  [220, 190, 255],
  [170, 110, 40],
  [255, 250, 200],
  [128, 0, 0],
  [170, 255, 195],
  [128, 128, 0],
  [255, 215, 180],
  [0, 0, 128],
  [128, 128, 128],
];

const offset = 3;

export const randColor = () =>
  COLOR_PALETTE[Math.floor(Math.random() * COLOR_PALETTE.length)];

export const idxToColor = (idx: number) =>
  COLOR_PALETTE[(idx + offset) % COLOR_PALETTE.length];

export const colorFromUUID = (uuid: string): RGBAColor => {
  const uuidDec = parseInt(uuid.slice(0, 6), 16);
  return COLOR_PALETTE[uuidDec % COLOR_PALETTE.length];
};

export const colorFromCssHex = (hex: string): RGBAColor => {
  if (hex.length != "#RRGGBB".length) {
    throw new Error(`unsupported hex color value ${hex}`);
  }
  const colorInt = parseInt(hex.slice(1, 7) + "FF", 16);
  return stripAlpha(colorArrayFromInt(colorInt));
};

export const colorArray2RgbColor = (ca: RGBAColor): RgbColor => ({
  r: ca[0],
  g: ca[1],
  b: ca[2],
});

export const rgbColor2ColorArray = (rgbC: RgbColor): RGBAColor => [
  rgbC.r,
  rgbC.g,
  rgbC.b,
];

// Converts an array of 3 or 4 integers less than 256 to a integer
// so that the array represents the digits of the integer in base
// 256 with the most significant byte at the start of the array
export const intFromColorArray = (c: RGBAColor): number => {
  let n = 0;
  for (let i = 0, a = 0x1000000; i < c.length; ++i, a >>>= 8) {
    n += (c[i] || 0) * a;
  }
  return n;
};

// Converts an integer to an array of 4 integers less than 256
// so that the array represents the digits of the integer in base
// 256 with the most significant byte at the start of the array
export const colorArrayFromInt = (n: number): RGBAColor => {
  if (n < 0) {
    throw TypeError("Input must be a positive integer");
  }
  const color: RGBAColor = [0, 0, 0, 0];
  for (let i = 0, a = 0xff000000; i < color.length; ++i, a >>>= 8) {
    color[i] = (n & a) >>> (8 * (3 - i));
  }
  return color;
};

export const forceAlpha = (
  c: RGBAColor,
  a: number,
): [number, number, number, number] & RGBAColor => {
  switch (c.length) {
    case 3:
      return [...(c as [number, number, number]), a];
    case 4:
      c[3] = a;
      return c as [number, number, number, number];
    default:
      throw TypeError("Color Array must have 3 or 4 elements");
  }
};

export const stripAlpha = (
  c: RGBAColor,
): [number, number, number] & RGBAColor => {
  switch (c.length) {
    case 3:
    case 4:
      return [c[0], c[1], c[2]];
    default:
      throw TypeError("Color Array must have 3 or 4 elements");
  }
};

export const setOpacity = (color: RGBAColor, opacity: number): RGBAColor => [
  color[0],
  color[1],
  color[2],
  opacity,
];

export function filledTypedArray(color: RGBAColor, length: number): Uint8Array {
  const colorValBuffer = new Uint8Array(4);
  // Get the alpha value of the color, or set it to 255 if not specified
  const alpha = color[3] ?? 255;
  colorValBuffer.set(forceAlpha(color, alpha));
  const colorVal = new Uint32Array(colorValBuffer.buffer)[0];
  // colorVal is color as int with R as left byte and A as right byte **in platform endianness**.

  // now we fill [colorVal, colorVal, colorVal, ...] **in platform endianness**
  const colorBufUI32 = new Uint32Array(length).fill(colorVal);

  // now we reinterpret as [[r,g,b,a], [r,g,b,a], [r,g,b,a]]
  const colorBuf = new Uint8Array(colorBufUI32.buffer);

  return colorBuf;
}
