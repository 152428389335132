import { createAction, createReducer } from "@reduxjs/toolkit";
import { FieldWithType } from "../../../utils/fields";
import { RootState } from "../../store";
import {
  Annotator,
  AnnotatorType,
  GEOFENCE_ANNOTATION_TYPE,
  MAP_MATCH_ANNOTATION_TYPE,
  newAnnotator,
} from "../annotator";
import { Layer } from "../layers";

const initialState = {
  current: newAnnotator(
    undefined,
    GEOFENCE_ANNOTATION_TYPE,
    {},
    "",
  ) as Annotator,
  allowAutomatedUpdates: {
    name: true,
    config: {
      annotationKey: true,
      annotationPrefix: true,
    },
  },
};

export const setLayer = createAction<Layer>("annotatorBuilder/setLayer");
export const setAnnotationName = createAction<string>(
  "annotatorBuilder/setAnnotationName",
);
export const setAnnotationKeyName = createAction<string>(
  "annotatorBuilder/setAnnotationKeyName",
);
export const setAnnotationPrefix = createAction<string>(
  "annotatorBuilder/setAnnotationPrefix",
);
export const setType = createAction<AnnotatorType>("annotatorBuilder/setType");
export const setCurrentAnnotator = createAction<Annotator>(
  "annotatorBuilder/setCurrentAnnotator",
);
export const setCurrentAnnotatorToExisting = createAction<Annotator>(
  "annotatorBuilder/setCurrentAnnotatorToExisting",
);
export const toggleMapMatchAnnotation = createAction<FieldWithType>(
  "annotatorBuilder/toogleMapMatchAnnotator",
);
export const generatedAnnotatorNamesFromLayer = createAction<Layer>(
  "annotatorBuilder/generatedAnnotatorNamesFromLayer",
);

export const annotatorBuilder = createReducer(initialState, (builder) => {
  // Note: this builder is unrelated to the annotatorBuilder. They are
  // completely different. This one is for building the reducer for redux, the
  // annotatorBuilder is for building an annotator object. The reducer happens
  // act on state that contains an annotator object.
  builder
    .addCase(setLayer, (state, action) => {
      state.current.config.layerId = action.payload.id;
    })
    .addCase(generatedAnnotatorNamesFromLayer, (state, action) => {
      switch (state.current.type) {
        case GEOFENCE_ANNOTATION_TYPE:
          if (state.allowAutomatedUpdates.name) {
            state.current.name = `${action.payload.name} Geofencer`;
          }
          if (state.allowAutomatedUpdates.config.annotationKey) {
            state.current.config.annotationKeyName = `in_${action.payload.name}`;
          }
          break;
        case MAP_MATCH_ANNOTATION_TYPE:
          if (state.allowAutomatedUpdates.name) {
            state.current.name = `${action.payload.name} Map Matcher`;
          }
          if (state.allowAutomatedUpdates.config.annotationPrefix) {
            state.current.config.annotationPrefix = `${action.payload.name}_`;
          }
          break;
      }
    })
    .addCase(setAnnotationName, (state, action) => {
      state.allowAutomatedUpdates.name = false;
      state.current.name = action.payload;
    })
    .addCase(setType, (state, action) => {
      if (state.current.type == action.payload) {
        return;
      } else {
        state.current.type = action.payload;
        state.current.config = {};
      }
    })
    .addCase(setCurrentAnnotator, (state, action) => {
      state.current = action.payload;
      state.allowAutomatedUpdates = {
        ...initialState.allowAutomatedUpdates,
        config: { ...initialState.allowAutomatedUpdates.config },
      };
    })
    .addCase(setCurrentAnnotatorToExisting, (state, action) => {
      state.current = action.payload;
      state.allowAutomatedUpdates = {
        name: false,
        config: {
          annotationKey: false,
          annotationPrefix: false,
        },
      };
    })
    .addCase(setAnnotationKeyName, (state, action) => {
      state.allowAutomatedUpdates.config.annotationKey = false;
      if (state.current.type == MAP_MATCH_ANNOTATION_TYPE) {
        return;
      }
      state.current.config.annotationKeyName = action.payload;
    })
    .addCase(setAnnotationPrefix, (state, action) => {
      state.allowAutomatedUpdates.config.annotationPrefix = false;
      if (state.current.type == GEOFENCE_ANNOTATION_TYPE) {
        return;
      }
      state.current.config.annotationPrefix = action.payload;
    })
    .addCase(toggleMapMatchAnnotation, (state, action) => {
      if (state.current.type == GEOFENCE_ANNOTATION_TYPE) {
        return;
      }
      const mapMatchProperties = state.current.config?.mapMatchProperties || {};
      if (mapMatchProperties[action.payload.name]) {
        delete mapMatchProperties[action.payload.name];
      } else {
        mapMatchProperties[action.payload.name] = action.payload.type;
      }
      state.current.config.mapMatchProperties = mapMatchProperties;
    });
});

export const selectCurrentAnnotator = (state: RootState) =>
  state.app.ui.annotatorBuilder.current;
