import { all, put, select, takeEvery } from "redux-saga/effects";
import { Annotator } from "../reducers/annotator";
import { Layer, layerSelectors } from "../reducers/layers";

import {
  generatedAnnotatorNamesFromLayer,
  selectCurrentAnnotator,
  setLayer,
  setType,
} from "../reducers/ui/annotatorBuilder";

function* setLayerSaga(action: ReturnType<typeof setLayer>) {
  yield put(generatedAnnotatorNamesFromLayer(action.payload));
}

// When we change types, if the layerId has been set then we need to populate
// the config for the new type of annotator automatically as if the layer was
// selected for the new type
function* setTypeSaga(_action: ReturnType<typeof setType>) {
  const currentAnnotator: Annotator = yield select(selectCurrentAnnotator);
  const layerId = currentAnnotator.config.layerId;

  if (layerId && typeof layerId == "string") {
    const layer: Layer = yield select((s) =>
      layerSelectors.selectById(s, layerId),
    );
    yield put(generatedAnnotatorNamesFromLayer(layer));
  }
}

export default function* annotatorsSaga() {
  yield all([
    takeEvery(setLayer, setLayerSaga),
    takeEvery(setType, setTypeSaga),
  ]);
}
