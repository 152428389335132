import { PitchYawRoll } from "@deck.gl/core/utils/positions";
import { DeviceState } from "../redux/reducers/device-state";

type Orientation = DeviceState["orientation"];

/*
I'd love to be able to say that this is a hard-one arithmetic optimization that I've painstakingly proven
but really i just couldn't get the matrix version to work.
*/
export function rotateForDeckGL(o: Orientation | undefined): PitchYawRoll {
  //   const gltfFix = new Matrix4().setRowMajor(0,0,1,0, -1,0,0,0, 0,-1,0,0, 0,0,0,1);
  //   let e = new Euler()
  //   if (o) {
  //     e = e.fromRollPitchYaw(radians(o.roll), radians(o.pitch), radians(o.yaw)) // sets ZYX order, i.e. Z/yaw first.
  //   }
  //   const m = e.getRotationMatrix(new Matrix4()) as Matrix4;
  //   const [roll, pitch, yaw] = e.fromRotationMatrix(m.multiplyRight(gltfFix)).toVector3()
  //   return [degrees(pitch), degrees(yaw), degrees(roll)]

  const { roll, pitch, yaw } = o || { roll: 0, pitch: 0, yaw: 0 };
  // not quite correct.. this applies roll before pitch :(
  return [-roll, 180 - yaw, 90 - pitch];
}
