import {
  createAction,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { produce, original, isDraft, Draft } from "immer";

import { AnnotatedDeviceState } from "../../generated/sora/v1beta/common_pb";

import { appendInOrder } from "../../utils/appendInOrder";
import { ProjectId } from "./projects";

import {
  Chunks,
  computeChunkId as _computeChunkId,
  computeChunkBounds as _computeChunkBounds,
  computeChunkIdsInRange as _computeChunkIdsInRange,
  chunkGroupedItems as _chunkGroupedItems,
  objectEntries,
  ChunkId,
  GroupedStuff,
} from "./chunks";

import { RequestState } from "./loading";

export type DeviceState = {
  /** device id, NOT DS id */
  id: string;
  /** unix timestamp in seconds */
  time: number;
  pos: {
    lat: number;
    lon: number;
    alt?: number;
  };
  orientation?: {
    yaw: number;
    pitch: number;
    roll: number;
  };
  userData: Record<string, unknown>;
  annotations: Record<string, unknown>;
};

// If you pull a number out of your butt and choose a power of two, everyone
// will assume you had a deep and meaninful reason to pick that one.
const DEVICE_STATE_CHUNK_LENGTH_SEC = 65536;

export const computeChunkId = _computeChunkId.bind(
  null,
  DEVICE_STATE_CHUNK_LENGTH_SEC,
);
export const computeChunkBounds = _computeChunkBounds.bind(
  null,
  DEVICE_STATE_CHUNK_LENGTH_SEC,
);
export const computeChunksInRange = _computeChunkIdsInRange.bind(
  null,
  DEVICE_STATE_CHUNK_LENGTH_SEC,
);
export const chunkGroupedItems = _chunkGroupedItems.bind(
  undefined,
  DEVICE_STATE_CHUNK_LENGTH_SEC,
) as <T extends { time: number }>(
  groupedItems: GroupedStuff<T>,
) => Chunks<typeof groupedItems>;
/* typescript should be able to infer this through the `bind` but it can't, this should be
removable in a future version of typescript (tested: 4.9)
seems related to https://github.com/microsoft/TypeScript/pull/28920 but not quite the same... */

export type DeviceStatesChunk = {
  states: EntityState<DeviceStates>;
  requestState: RequestState;
};

/** The root of device states stuff in the redux store. Organized as
 * for each chunk (time slice)
 * for each device (entity)
 * time series of devicestates.
 */
export type DeviceStatesRoot = Chunks<DeviceStatesChunk>;

/** A time series of devicestates, for a single device, in a single chunk. */
export type DeviceStates = {
  id: string;
  states: DeviceState[];
};

export type GroupedStates = {
  [id: string]: DeviceState[];
};

export const fromPB = (annotatedState: AnnotatedDeviceState): DeviceState => {
  const s = annotatedState.getState();
  const p = s?.getPos();
  const o = s?.getOrientation();
  const t = s?.getTime();
  const id = s?.getDeviceId();
  return {
    id: id || "",
    time: (t?.getSeconds() || 0) + (t?.getNanos() || 0) * 1e-9,
    pos: {
      lat: p?.getLat() || 0,
      lon: p?.getLon() || 0,
      alt: p?.getAlt(),
    },
    orientation: o
      ? {
          yaw: o.getYaw(),
          pitch: o.getPitch(),
          roll: o.getRoll(),
        }
      : undefined,
    userData: s?.getUserData()?.toJavaScript() || {},
    annotations: annotatedState?.getAnnotations()?.toJavaScript() || {},
  };
};

const appendStatesToEntity = (entity: DeviceStates, states: DeviceState[]) => {
  return produce(entity, (draft) => {
    draft.states = appendInOrder(
      draft.states,
      states,
      (x, y) => x.time - y.time,
    );
  });
};

const initialEntity = (id: string, states: DeviceState[]) => ({
  id,
  states,
});

const deviceStateAdapter = createEntityAdapter<DeviceStates>();

function getInitialState(): DeviceStatesRoot {
  return { chunks: {}, chunkLengthSeconds: DEVICE_STATE_CHUNK_LENGTH_SEC };
}

function getInitialChunkState(requestState: RequestState): DeviceStatesChunk {
  return {
    states: deviceStateAdapter.getInitialState(),
    requestState,
  };
}

import assert from "assert";
function _assertStateOk(state: DeviceStatesRoot) {
  if (isDraft(state)) {
    state = original(state);
  }
  let lastChunkId: number | undefined = undefined;
  for (const [_chunkId, chunk] of objectEntries(state.chunks)) {
    const devices = chunk.states;
    assert(
      devices.ids.length == Object.values(devices.entities).length,
      "entities must be consistent",
    );
    const chunkId = parseInt(_chunkId);
    assert(isFinite(chunkId), `chunkId must be numeric, ${_chunkId} isn't`);
    assert(
      lastChunkId === undefined || lastChunkId < chunkId,
      "chunks must be ordered",
    ); // current representation guarantees this by object iter spec but i might change representation

    for (const deviceId of devices.ids) {
      let lastTime: number | undefined = undefined;
      const dss = devices.entities[deviceId];
      assert(dss);

      assert(
        dss.states.length > 0,
        "every device in a chunk must have at least one device state",
      );

      for (const ds of dss.states) {
        const expectedChunkId = _computeChunkId(
          state.chunkLengthSeconds,
          ds.time,
        );
        assert(
          expectedChunkId == chunkId,
          `device state must be in the right chunk, ${expectedChunkId} != ${chunkId} for ds ${ds.time} dev ${ds.id}`,
        );
        assert(
          lastTime === undefined || lastTime <= ds.time,
          `device times must be ordered`,
        );
        lastTime = ds.time;
      }
    }

    lastChunkId = chunkId;
  }
}

export type UpsertGroupStatesRequest = {
  chunkId: ChunkId;
  states: GroupedStates;
  /**
   * if null, the existing requestState will be preserved or set to Pending.
   * This is explicit because the only time you want the auto behaviour is when streaming
   */
  requestState: RequestState | null;
};

const deviceStateSlice = createSlice({
  name: "deviceState",
  initialState: getInitialState(),
  reducers: {
    upsertGroupedStates: (
      draftState: Draft<DeviceStatesRoot>,
      action: PayloadAction<UpsertGroupStatesRequest>,
    ) => {
      const state: DeviceStatesRoot | undefined = original(draftState);
      if (state === undefined) {
        throw new Error(
          `upsertGroupedStates was given a non-draft ${draftState}`,
        );
      }
      if (ASSERTS) {
        _assertStateOk(state);
      }

      const old = state.chunks;
      const chunkId = action.payload.chunkId;

      const oldChunk =
        old[chunkId] ||
        getInitialChunkState({
          type: "pending",
        });

      let newDevices = oldChunk.states;

      for (const [id, ds] of Object.entries(action.payload.states)) {
        const oldEntity = oldChunk.states.entities[id] || initialEntity(id, []);
        const entity = appendStatesToEntity(oldEntity, ds);
        newDevices = deviceStateAdapter.upsertOne(newDevices, entity);
      }

      const newChunk = {
        states: newDevices,
        requestState:
          action.payload.requestState === null
            ? oldChunk.requestState
            : action.payload.requestState,
      };

      return {
        chunks: { ...old, [chunkId]: newChunk },
        chunkLengthSeconds: state.chunkLengthSeconds,
      };
    },
    clearDeviceStates: (_state) => getInitialState(),
  },
});

export type StartDeviceStateStreamRequest = {
  projectId: ProjectId;
};

export type StopDeviceStateStreamRequest = StartDeviceStateStreamRequest;

export type QueryDeviceStateRequest = {
  range: { start: Date; end: Date };
  projectId: ProjectId;
};
export const queryDeviceStateRequested = createAction<QueryDeviceStateRequest>(
  "deviceState/queryRequested",
);
export const queryDeviceStateSucceeded = createAction<QueryDeviceStateRequest>(
  "deviceState/querySucceeded",
);
export const queryDeviceStateFailed = createAction<Error>(
  "deviceState/queryFailed",
);
export const startDeviceStateStream =
  createAction<StartDeviceStateStreamRequest>("deviceState/startStream");

export const { upsertGroupedStates, clearDeviceStates } =
  deviceStateSlice.actions;
export default deviceStateSlice.reducer;
