import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
  Config,
  ProjectConfigMap,
  Projects,
  removeExpiredProjects,
  setEntityVisibility,
  updateEntityVisibility,
  VisibilitySetRequest,
} from "../reducers/settings";

import { selectProjectIdFromRoute } from "../selectors/router";
import {
  Entity,
  selectAllPreferences,
  selectEntities,
} from "../selectors/settings";

// Saga that handles updating the visibility of an entity
export function* setEntityVisibilitySaga(
  action: ReturnType<typeof updateEntityVisibility>,
): Generator<unknown, void, unknown> {
  // Get the current project ID from the router and get the payload from the action
  const projectId = (yield select(selectProjectIdFromRoute)) as string;
  const { payload } = action;
  const { entityType, entityId, updateAll, value } = payload;

  // Get all entities of the specified type
  const entities = (yield select(selectEntities(entityType))) as Entity[];

  let ids;
  if (updateAll) {
    // If updating all entities, map over all entities and get their IDs
    ids = entities.map((entity) => entity.id);
  } else {
    // If updating a specific entity, add its ID to the list
    ids = entityId ? [entityId] : [];
  }

  // Create a config object with the new visibility value for each ID
  const config: Config = {};
  ids.map((id: string) => (config[id] = value));

  // Create a request object with the project ID, entity type, config, and timestamp
  const lastUpdatedAt: number = Date.now();
  const request: VisibilitySetRequest = {
    projectId,
    entityType,
    config,
    lastUpdatedAt,
  };

  // Dispatch an action to update the visibility of the specified entities
  yield put(setEntityVisibility(request));

  // Call a helper function to remove any stale preferences from local storage
  yield call(expireStalePreferencesSaga);

  return;
}

// Helper function to remove stale preferences from local storage
export function* expireStalePreferencesSaga() {
  // Get all preferences from local storage
  const preferences: ProjectConfigMap = yield select(selectAllPreferences);

  const expiredProjects: Projects = [];
  const ONE_WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000; // 604800000 ms

  // Loop over all preferences and check if they have expired
  for (const [projectId, config] of Object.entries(preferences)) {
    const { lastUpdatedAt } = config;

    // Compare lastUpdatedAt with the current time
    if (Date.now() - lastUpdatedAt > ONE_WEEK_IN_MILLISECONDS) {
      // If the preference has expired, add its project ID to the list
      expiredProjects.push(projectId);
    }
  }

  if (expiredProjects.length > 0) {
    // Dispatch an action to remove expired projects from preferences
    yield put(removeExpiredProjects(expiredProjects));
  }

  return;
}

export default function* localStorageSaga() {
  yield all([takeLatest(updateEntityVisibility, setEntityVisibilitySaga)]);
}
