import { AnyAction, combineReducers, Reducer } from "redux";
import keplerGlReducer, {
  visStateUpdaters,
  uiStateUpdaters,
} from "kepler.gl/reducers";
import { ActionTypes } from "kepler.gl/actions";

import appReducer from "./app";
import {
  fileUploadStarted,
  fileUploadProgress,
  filesUploadSucceeded,
  fileUploadFailed,
  uploadModalClear,
} from "../reducers/layers";

export type KeplerGlState = Partial<{
  uiState: {
    currentModal: string | null;
    activeSidePanel: string | null;
    mapControls: Partial<{
      splitMap: {
        show: boolean;
      };
      mapLegend: {
        show: boolean;
        active: boolean;
      };
    }>;
  };
  visState: {
    interactionConfig: {
      geocoder: {
        enabled: boolean;
      };
    };
    fileLoading: boolean;
    fileLoadingProgress: Partial<{
      [key: string]: {
        percent: number;
        message: string | undefined;
        fileName: string;
        error: unknown | null;
      };
    }>;
  };
}>;

// kepler puts all its stuff under a `map` subkey by some magic
// to do with us providing id="map" to our Kepler instantiation.
export type RootKeplerGlState = {
  map?: KeplerGlState;
};

export const createRootReducer = <S>(routerReducer: Reducer<S>) =>
  combineReducers({
    app: appReducer,
    router: routerReducer,
    keplerGl: <Reducer<RootKeplerGlState, AnyAction>>keplerGlReducer
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      .initialState({
        uiState: {
          currentModal: null,
          activeSidePanel: "devices",
          mapControls: {
            ...uiStateUpdaters.DEFAULT_MAP_CONTROLS,
            splitMap: {
              show: false,
            },
            mapLegend: {
              show: false,
              active: false,
            },
          },
        },
        visState: {
          interactionConfig: {
            // Turn off Geocoder widget by default
            // Other config can go here i.e tooltips/brushes/geolocation etc, so keeping this snippet for future
            ...visStateUpdaters.INITIAL_VIS_STATE.interactionConfig,
            geocoder: {
              ...visStateUpdaters.INITIAL_VIS_STATE.interactionConfig.geocoder,
              enabled: false,
            },
          },
        },
      } as KeplerGlState)
      .plugin({
        [fileUploadStarted.toString()]: (
          state: KeplerGlState,
          action: ReturnType<typeof fileUploadStarted>,
        ): KeplerGlState => {
          const progress = action.payload.reduce((acc, { name: fileName }) => {
            return {
              ...acc,
              [fileName]: {
                percent: 0,
                message: "reading...",
                fileName,
                error: null,
              },
            };
          }, {});

          return {
            ...state,
            visState: {
              ...state.visState!,
              fileLoading: true,
              fileLoadingProgress: progress,
            },
          };
        },
        [fileUploadProgress.toString()]: (
          state: KeplerGlState,
          action: ReturnType<typeof fileUploadProgress>,
        ): KeplerGlState => {
          const { fileName, fraction, message } = action.payload;
          return {
            ...state,
            visState: {
              ...state.visState!,
              fileLoadingProgress: {
                ...state.visState?.fileLoadingProgress,
                [fileName]: {
                  percent: fraction, // despite its name, percent \in [0,1]
                  message,
                  fileName,
                  error: null,
                },
              },
            },
          };
        },
        [filesUploadSucceeded.toString()]: (
          state: KeplerGlState,
        ): KeplerGlState => ({
          ...state,
          visState: {
            ...state.visState!,
            fileLoading: false,
            fileLoadingProgress: {},
          },
        }),
        [fileUploadFailed.toString()]: (
          state: KeplerGlState,
          action: ReturnType<typeof fileUploadFailed>,
        ): KeplerGlState => {
          const { fileName, error } = action.payload;

          return {
            ...state,
            visState: {
              ...state.visState!,
              fileLoadingProgress: {
                ...state.visState!.fileLoadingProgress,
                [fileName]: {
                  percent: 1,
                  message: undefined,
                  fileName,
                  ...state.visState!.fileLoadingProgress[fileName],
                  error: error,
                },
              },
            },
          };
        },
        [uploadModalClear.toString()]: (
          state: KeplerGlState,
        ): KeplerGlState => ({
          ...state,
          visState: {
            ...state.visState!,
            fileLoading: false,
            fileLoadingProgress: {},
          },
        }),
        // Kepler hardcodes opening the panel with id "layer" when you restore a collapsed sidepanel.
        // This crashes kepler for us because we have no such panel, here is a hack to fix such silliness.
        [ActionTypes.TOGGLE_SIDE_PANEL]: (
          state: KeplerGlState,
          { payload: id }: { payload: string },
        ) => {
          if (id === "layer") {
            return {
              ...state,
              uiState: { ...state.uiState, activeSidePanel: "devices" },
            };
          }
          return state;
        },
      }),
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  });
