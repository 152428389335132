import { combineReducers } from "redux";

import { annotatorBuilder } from "./annotatorBuilder";
import { modal } from "./modal";
import confirm from "./confirm";

export const ui = combineReducers({
  annotatorBuilder,
  modal,
  confirm: confirm.reducer,
});
