import { FC, FormEvent } from "react";
import { Icons, InlineInput, PanelHeaderAction } from "kepler.gl/components";

import {
  Layer,
  updateLayer,
  deleteLayerRequested,
} from "../../../redux/reducers/layers";
import { startConfirm } from "../../../redux/reducers/ui/confirm";
import { useAppDispatch } from "../../../redux/hooks";
import {
  HeaderActionSection,
  HeaderLabelSection,
  StyledPanelHeaderWrapper,
} from "../../common";
import {
  EntityType,
  updateEntityVisibility,
} from "../../../redux/reducers/settings";

const GeoLayerNameEditor: FC<GeoLayerPanelHeaderProps> = ({ model }) => {
  const dispatch = useAppDispatch();

  const onEdit = (e: FormEvent<HTMLInputElement>) => {
    dispatch(
      updateLayer({
        id: model.id,
        changes: { name: e.currentTarget.value },
      }),
    );
  };

  return (
    <InlineInput
      type="text"
      value={model.name}
      onClick={(e: FormEvent<HTMLInputElement>) => {
        e.stopPropagation();
      }}
      onChange={onEdit}
      id={`${model.name}:input-geo-layer-label`}
    />
  );
};

type GeoLayerPanelHeaderProps = {
  model: Layer;
  visible?: boolean;
};

export const GeoLayerPanelHeader: FC<GeoLayerPanelHeaderProps> = ({
  model,
  visible,
}) => {
  const dispatch = useAppDispatch();

  const onToggleVisibility = () => {
    dispatch(
      updateEntityVisibility({
        entityType: EntityType.Layer,
        entityId: model.id,
        value: !visible,
      }),
    );
  };

  const onToggleConfig = () => {
    dispatch(
      updateLayer({
        id: model.id,
        changes: { showConfig: !model.showConfig },
      }),
    );
  };

  const onDelete = () => {
    dispatch(
      startConfirm({
        description: { id: "geoLayer.confirmDelete" },
        variant: "delete",
        onAccept: deleteLayerRequested(model),
      }),
    );
  };

  return (
    <StyledPanelHeaderWrapper
      active={model.showConfig}
      labelRCGColorValues={model.color}
    >
      <HeaderLabelSection>
        <Icons.VertDots height="20px" />
        <GeoLayerNameEditor model={model} visible={visible} />
      </HeaderLabelSection>
      <HeaderActionSection>
        <PanelHeaderAction
          id={model.id}
          tooltip="tooltip.deleteGeoLayer"
          onClick={onDelete}
          IconComponent={Icons.Trash}
        />
        <PanelHeaderAction
          id={model.id}
          tooltip={visible ? "tooltip.hideGeoLayer" : "tooltip.showGeoLayer"}
          onClick={onToggleVisibility}
          IconComponent={visible ? Icons.EyeSeen : Icons.EyeUnseen}
        />
        <PanelHeaderAction
          id={model.id}
          onClick={onToggleConfig}
          tooltip={"tooltip.geoLayerSettings"}
          IconComponent={model.showConfig ? Icons.ArrowUp : Icons.ArrowDown}
        />
      </HeaderActionSection>
    </StyledPanelHeaderWrapper>
  );
};
