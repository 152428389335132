import {
  createAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RGBAColor } from "@deck.gl/core";

import { EventGenerator as EventGeneratorPB } from "../../generated/sora/app/v1beta/types_pb";

import {
  colorArrayFromInt,
  intFromColorArray,
  randColor,
  stripAlpha,
} from "../../utils/color";
import { selectEventGeneratorsSlice } from "../selectors/event-generators";
import { ProjectId } from "./projects";

type _EventGenerator = {
  name: string;
  color: RGBAColor;
  version: string;
  expression: string;
  alertorId?: string;
  projectId: ProjectId;
  showConfig: boolean;
};

export const NO_EVENT_GENERATOR = "NO_EVENT_GENERATOR";

export type DummyEventGenerator = _EventGenerator & {
  id: typeof NO_EVENT_GENERATOR;
};
export type EventGenerator = _EventGenerator & { id: string };

export const fromPB = (eg: EventGeneratorPB): EventGenerator => ({
  id: eg.getId(),
  name: eg.getName(),
  color: stripAlpha(colorArrayFromInt(eg.getColor())),
  version: eg.getVersion(),
  expression: eg.getExpression(),
  alertorId: eg.getAlertorId() === "" ? undefined : eg.getAlertorId(),
  projectId: eg.getProjectId(),
  showConfig: false,
});

export const newEventGenerator = (
  name: string,
  projectId: ProjectId,
  jsonLogic?: object,
) => ({
  id: "",
  name,
  projectId,
  color: randColor(),
  version: "jsonLogic-1",
  expression: JSON.stringify(jsonLogic),
  showConfig: false,
});

export const toPB = (eg: EventGenerator) =>
  new EventGeneratorPB()
    .setId(eg.id)
    .setName(eg.name)
    .setColor(intFromColorArray(eg.color))
    .setVersion(eg.version)
    .setExpression(eg.expression)
    .setAlertorId(eg.alertorId || "")
    .setProjectId(eg.projectId);

const eventGeneratorAdapter = createEntityAdapter<
  EventGenerator | DummyEventGenerator
>();

const getInitialState = () => {
  const _init = eventGeneratorAdapter.getInitialState();
  return eventGeneratorAdapter.addOne(_init, {
    id: NO_EVENT_GENERATOR,
    projectId: "",
    name: "[Orphaned Events]",
    color: [200, 80, 20],
    version: "",
    expression: "",
    showConfig: false,
  });
};

const eventGeneratorSlice = createSlice({
  name: "eventGenerator",
  initialState: getInitialState(),
  reducers: {
    addEventGenerators: eventGeneratorAdapter.upsertMany,
    updateEventGenerator: eventGeneratorAdapter.updateOne,
    deleteEventGenerator: eventGeneratorAdapter.removeOne,
    clearEventGenerators: (_state) => {
      return getInitialState();
    },
  },
});

export const readEventGeneratorsRequested = createAction<ProjectId>(
  "eventGenerators/readRequested",
);
export const createEventGeneratorRequested = createAction<EventGenerator>(
  "eventGenerators/createRequested",
);
export const deleteEventGeneratorRequested = createAction<EventGenerator>(
  "eventGenerators/deleteRequested",
);

export const eventGeneratorSelectors = eventGeneratorAdapter.getSelectors(
  selectEventGeneratorsSlice,
);
export const {
  addEventGenerators,
  updateEventGenerator,
  deleteEventGenerator,
  clearEventGenerators,
} = eventGeneratorSlice.actions;
export default eventGeneratorSlice.reducer;
