import * as FullStory from "@fullstory/browser";

import type { User } from "@auth0/auth0-react";

// should be called as early as possible in app initialization
export function init() {
  FullStory.init({
    orgId: "1721BB",
  });
}

// uidForFullStory should be the skylark/swift account id of the user
export function identifyToFullStory(uidForFullStory: string, user: User) {
  FullStory.identify(uidForFullStory, {
    email: user.email,
    displayName: user.preferred_username,
  });
}
