import { FC, FormEvent } from "react";

import { Icons, InlineInput, PanelHeaderAction } from "kepler.gl/components";
import { useAppDispatch } from "../../../redux/hooks";
import {
  Device,
  centerDevice,
  toggleDeviceConfig,
} from "../../../redux/reducers/devices";
import {
  StyledPanelHeaderWrapper,
  HeaderActionSection,
  HeaderLabelSection,
} from "../../common";
import {
  EntityType,
  updateEntityVisibility,
} from "../../../redux/reducers/settings";

export const DeviceNameEditor: FC<DevicePanelHeaderProps> = ({ model }) => (
  <InlineInput
    id={`${model.name}:input-device-label`}
    type="text"
    value={model.name}
    onClick={(e: FormEvent<HTMLInputElement>) => {
      e.stopPropagation();
    }}
    readOnly
  />
);

type DevicePanelHeaderProps = {
  model: Device;
  visible?: boolean;
};

export const DevicePanelHeader: FC<DevicePanelHeaderProps> = ({
  model,
  visible,
}) => {
  const dispatch = useAppDispatch();

  return (
    <StyledPanelHeaderWrapper
      active={model.showConfig}
      labelRCGColorValues={model.color}
    >
      <HeaderLabelSection>
        <Icons.VertDots height="20px" />
        <DeviceNameEditor model={model} visible={visible} />
      </HeaderLabelSection>
      <HeaderActionSection>
        <PanelHeaderAction
          id={model.id}
          tooltip={"tooltip.deviceCenter"}
          onClick={() => dispatch(centerDevice(model))}
          IconComponent={Icons.Crosshairs}
        />
        <PanelHeaderAction
          id={model.id}
          tooltip={visible ? "tooltip.hideDevice" : "tooltip.showDevice"}
          onClick={() =>
            dispatch(
              updateEntityVisibility({
                entityType: EntityType.Device,
                entityId: model.id,
                value: !visible,
              }),
            )
          }
          IconComponent={visible ? Icons.EyeSeen : Icons.EyeUnseen}
        />
        <PanelHeaderAction
          id={model.id}
          tooltip={"tooltip.deviceSettings"}
          onClick={() => dispatch(toggleDeviceConfig(model.id))}
          IconComponent={model.showConfig ? Icons.ArrowUp : Icons.ArrowDown}
        />
      </HeaderActionSection>
    </StyledPanelHeaderWrapper>
  );
};
