import { Link, useParams } from "react-router-dom";
import { Typography } from "@mui/material";

export const ProjectNotFound = () => {
  const params = useParams();
  return (
    <>
      <Typography variant="h1">404</Typography>
      <Typography variant="body1">
        Project {params.projectId} was not found. Click{" "}
        <Link to="/projects">here</Link> to return to the project selection
        page.
      </Typography>
    </>
  );
};
