import { FC } from "react";
import { Icons } from "kepler.gl/components";

import { TooltipBox } from ".";
import {
  DEVICE_EVENT_TYPE,
  Event,
  GENERATED_EVENT_TYPE,
} from "../../redux/reducers/events";
import { useAppSelector } from "../../redux/hooks";
import { selectDateFormatter } from "../../redux/selectors/settings";
import { selectDevicesSlice } from "../../redux/selectors/devices";
import { selectEventGeneratorsSlice } from "../../redux/selectors/event-generators";

const stringify = (v: unknown) => {
  if (typeof v === "object") {
    return JSON.stringify(v);
  } else {
    return String(v);
  }
};

type EventTooltipProps = {
  event: Event;
};

export const EventTooltip: FC<EventTooltipProps> = ({ event }) => {
  const dateFormat = useAppSelector(selectDateFormatter);

  const devices = useAppSelector(selectDevicesSlice);
  const device = devices.entities[event.deviceId];

  const deviceName = device ? device.name : `(device ${event.deviceId})`;

  const eventGenerators = useAppSelector(selectEventGeneratorsSlice);
  let title: string;
  switch (event.type) {
    case GENERATED_EVENT_TYPE: {
      const eventGenerator = eventGenerators.entities[event.eventGeneratorId];
      const egName = eventGenerator
        ? eventGenerator.name
        : `(event generator ${event.eventGeneratorId})`;
      title = egName;
      break;
    }
    case DEVICE_EVENT_TYPE: {
      title = `Event from ${deviceName}`;
      break;
    }
  }

  return (
    <TooltipBox title={title} icon={<Icons.Info />}>
      <table>
        <tbody>
          <tr>
            <th scope="row">Device</th>
            <td>{deviceName}</td>
          </tr>
          <tr>
            <th scope="row">Latitude</th>
            <td>{event.lat}</td>
          </tr>
          <tr>
            <th scope="row">Longitude</th>
            <td>{event.lon}</td>
          </tr>
          <tr>
            <th scope="row">Altitude</th>
            <td>{event.alt}</td>
          </tr>
          <tr>
            <th scope="row">Time</th>
            <td>{dateFormat.format(new Date(event.time * 1_000))}</td>
          </tr>
          <tr>
            <th scope="row">Type</th>
            <td>{event.type}</td>
          </tr>
          {Object.entries(event.payload).map(([k, v]) => (
            <tr key={k + "-payload-row"}>
              <th className="payload" scope="row" key={k + "-payload-key"}>
                {k}
              </th>
              <td key={k + "-payload-val"}>{stringify(v)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </TooltipBox>
  );
};
