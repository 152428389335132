import { FC, ReactNode } from "react";
import { RgbColorPicker, RgbColor } from "react-colorful";
import { RGBAColor } from "@deck.gl/core/utils/color";
import { LayerConfigGroup, SidePanelSection } from "kepler.gl/components";
import debounce from "lodash.debounce";
import styled from "styled-components";

import { colorArray2RgbColor, rgbColor2ColorArray } from "../../utils/color";

export const StyledSidePanelRow = styled.div`
  font-size: 12px;
  border-radius: 1px;
  margin-bottom: 8px;
  z-index: 1000;

  &.dragging {
    cursor: move;
  }
`;

export const ColorPickerWrapper = styled.div`
  .react-colorful {
    height: 100px;
    width: 90%;
  }
  .react-colorful__saturation {
    border-radius: 4px 4px 0 0;
  }
  .react-colorful__hue {
    height: 10px;
    border-radius: 0 0 4px 4px;
  }
  .react-colorful__pointer {
    width: 16px;
    height: 16px;
  }
`;

export const StyledConfigurator = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.layerConfiguratorMargin};
  padding: ${({ theme }) => theme.layerConfiguratorPadding};
  border-left: ${({ theme }) => theme.layerConfiguratorBorder} dashed
    ${({ theme }) => theme.layerConfiguratorBorderColor};
`;

export type ConfigModel = {
  id: string | symbol;
  color: RGBAColor;
  showConfig: boolean;
};

export type ConfigModelProps<T extends ConfigModel> = {
  model: T;
  visible?: boolean;
};

type SidePanelRowProps<T extends ConfigModel> = ConfigModelProps<T> & {
  onSetColor?: (_id: T["id"], _color: RGBAColor) => void;
  Header: FC<ConfigModelProps<T>>;
  children: ReactNode;
};

export const SidePanelRow = <T extends ConfigModel>({
  model,
  visible,
  onSetColor,
  Header,
  children,
}: SidePanelRowProps<T>) => (
  <StyledSidePanelRow>
    <Header model={model} visible={visible} />
    {model.showConfig && (
      <StyledConfigurator>
        <LayerConfigGroup label={"config.details"} collapsible>
          {children}
        </LayerConfigGroup>
        {onSetColor && (
          <LayerConfigGroup label={"config.color"} collapsible>
            <SidePanelSection>
              <ColorPickerWrapper>
                <RgbColorPicker
                  color={colorArray2RgbColor(model.color)}
                  onChange={debounce(
                    (x: RgbColor) =>
                      onSetColor(model.id, rgbColor2ColorArray(x)),
                    200,
                  )}
                />
              </ColorPickerWrapper>
            </SidePanelSection>
          </LayerConfigGroup>
        )}
      </StyledConfigurator>
    )}
  </StyledSidePanelRow>
);
