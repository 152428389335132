import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import AutoSizer from "react-virtualized-auto-sizer";
import styled, { ThemeProvider } from "styled-components";
import { theme as basicTheme } from "kepler.gl/styles";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/system";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { startDeviceStateStream } from "../redux/reducers/device-state";
import { startEventStream } from "../redux/reducers/events";
import { readLayersRequested } from "../redux/reducers/layers";
import { readAnnotatorsRequested } from "../redux/reducers/annotator";
import { readEventGeneratorsRequested } from "../redux/reducers/event-generators";
import { readAlertorsRequested } from "../redux/reducers/alertor";
import {
  projectSelectors,
  selectProjectsReadAllState,
} from "../redux/reducers/projects";
import { BottomPanel } from "../components/bottom-panel/BottomPanel";
import { Map } from "../components/Map";
import { AppServiceContext } from "../api/appService";
import { ProjectNotFound } from "../components/projects/ProjectNotFound";
import { Loading } from "../components/Loading";
import { readDevicesRequested } from "../redux/reducers/devices";

const GlobalStyle = styled.div`
  transition: margin 1s, height 1s;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;

  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeight};
  font-size: ${({ theme }) => theme.fontSize};
  line-height: ${({ theme }) => theme.lineHeight};

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

export const swiftOrange = "#FF8300";

// this is a Styled Components theme
const swiftTheme = {
  ...basicTheme,
  fontFamily: `Roboto, 'Helvetica Neue', Helvetica, sans-serif`,
  btnFontFamily: `Roboto, 'Helvetica Neue', Helvetica, sans-serif`,
  activeColor: swiftOrange,
  logoColor: swiftOrange,
  inputBorderActiveColor: basicTheme.inputColor,
  histogramOpacity: 0.7,
  rangePlotHLarge: 40,
  rangePlotHLargePalm: 40,
  rangePlotContainerHLarge: 56,
  rangePlotContainerHLargePalm: 56,
};

// this is a MUI theme
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiPickerStaticWrapper: {
      styleOverrides: {
        content: {
          backgroundColor: swiftTheme.bottomWidgetBgd,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: swiftTheme.bottomWidgetBgd,
          "&.Mui-selected": {
            backgroundColor: swiftOrange,
          },
          "&:focus.Mui-selected": {
            backgroundColor: swiftOrange,
          },
        },
        today: {
          backgroundColor: "#42a5f5",
        },
      },
    },
  },
});

export const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { hasToken } = useContext(AppServiceContext);
  const params = useParams();
  const projectId = params.projectId;
  const readAllState = useAppSelector(selectProjectsReadAllState);
  const maybeProject = useAppSelector((state) =>
    projectSelectors.selectById(state, projectId || ""),
  );

  useEffect(() => {
    if (hasToken && projectId && maybeProject) {
      dispatch(startDeviceStateStream({ projectId }));
      dispatch(startEventStream({ projectId }));

      dispatch(readDevicesRequested(projectId));
      dispatch(readLayersRequested(projectId));
      dispatch(readAnnotatorsRequested(projectId));
      dispatch(readEventGeneratorsRequested(projectId));
      dispatch(readAlertorsRequested(projectId));
    }
  }, [hasToken, projectId, maybeProject]);

  return readAllState.type === "pending" ? (
    <Loading />
  ) : maybeProject ? (
    <ThemeProvider theme={swiftTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <GlobalStyle>
          <AutoSizer>
            {({ height, width }) => (
              <MuiThemeProvider theme={darkTheme}>
                <Map height={height} width={width} />
                <BottomPanel width={width} />
              </MuiThemeProvider>
            )}
          </AutoSizer>
        </GlobalStyle>
      </LocalizationProvider>
    </ThemeProvider>
  ) : (
    <ProjectNotFound />
  );
};
