export const root = document.getElementById("root");

// assign to empty string in tests
export const APP_URL = root?.getAttribute("data-app-url") || "";
export const APP_VERSION = root?.getAttribute("data-app-version") || "";
export const SERVER_URL = root?.getAttribute("data-server-url") || "";
export const PORTAL_DOMAIN = root?.getAttribute("data-portal-domain") || "";

export const MAPBOX_TOKEN = root?.getAttribute("data-mapbox-token") || "";
export const AUTH0_DOMAIN = root?.getAttribute("data-auth0-domain") || "";
export const AUTH0_CLIENT_ID = root?.getAttribute("data-auth0-client-id") || "";
export const AUTH0_AUDIENCE = root?.getAttribute("data-auth0-audience") || "";
export const AUTH0_ACCOUNT_ID_CLAIM =
  root?.getAttribute("data-auth0-account-id-claim") || "";
export const AUTH0_SUBDOMAIN_CLAIM =
  root?.getAttribute("data-auth0-subdomain-claim") || "";

export const APP_NAME = "SORA";
export const TIME_REF = Date.now() / 1000;
export const UPDATE_DEBOUNCE_MS = 500;

export const SORA_API_URL = "https://buf.build/swift-nav/sora-api";
export const SORA_SUPPORT_URL =
  "https://support.swiftnav.com/support/solutions/folders/44001237795";

// TODO: Hide email from scrapers by only displaying it to logged-in users
export const SORA_FEEDBACK_CHANNEL = "sora-feedback@swift-nav.com";
