import { createAction, createReducer } from "@reduxjs/toolkit";
import { original } from "immer";

import { Event as EventPB } from "../../generated/sora/v1beta/common_pb";
import { appendInOrder } from "../../utils/appendInOrder";
import * as loading from "./loading";
import { ProjectId } from "./projects";
import {
  Chunks,
  computeChunkId as _computeChunkId,
  computeChunkBounds as _computeChunkBounds,
  computeChunkIdsInRange as _computeChunkIdsInRange,
  ChunkId,
} from "./chunks";

type _Event = {
  deviceId: string;
  time: number;
  lat: number;
  lon: number;
  alt: number;
  payload: object;
};

// Possible TODO: put these into Sora API itself as an enum or something,
// they are cross-cutting and affect things from db scheme through to the frontend.
export const GENERATED_EVENT_TYPE = "sora.generated_event.v1";
export const DEVICE_EVENT_TYPE = "sora.device_event.v1";

export type GeneratedEvent = _Event & {
  type: typeof GENERATED_EVENT_TYPE;
  eventGeneratorId: string;
};

export type DeviceEvent = _Event & {
  type: typeof DEVICE_EVENT_TYPE;
};

export type Event = GeneratedEvent | DeviceEvent;

const EVENTS_CHUNK_LENGTH_SEC = 65536;

export const computeChunkId = _computeChunkId.bind(
  null,
  EVENTS_CHUNK_LENGTH_SEC,
);
export const computeChunkBounds = _computeChunkBounds.bind(
  null,
  EVENTS_CHUNK_LENGTH_SEC,
);
export const computeChunksInRange = _computeChunkIdsInRange.bind(
  null,
  EVENTS_CHUNK_LENGTH_SEC,
);

export type EventsChunk = {
  events: Event[];
  requestState: loading.RequestState;
};

export type EventsRoot = Chunks<EventsChunk>;

function getInitialState(): EventsRoot {
  return {
    chunks: {},
    chunkLengthSeconds: EVENTS_CHUNK_LENGTH_SEC,
  };
}

export type SpaceTime = {
  lat: number;
  lon: number;
  alt: number;
  time: number;
};

export type QueryEventsRequest = {
  projectId: ProjectId;
  range: { start: Date; end: Date };
};

export const queryEventsRequested = createAction<QueryEventsRequest>(
  "events/queryRequested",
);
export const queryEventsSucceeded = createAction<QueryEventsRequest>(
  "events/querySucceeded",
);
export const queryEventsFailed = createAction<Error>("events/queryFailed");

export type StartEventStreamRequest = {
  projectId: ProjectId;
};
export const startEventStream =
  createAction<StartEventStreamRequest>("events/startStream");

export const addEvents = createAction<{
  chunkId: ChunkId;
  events: Event[];
  requestState: loading.RequestState | null;
}>("events/add");
export const clearEvents = createAction<undefined>("events/clear");
export const centerSpaceTime = createAction<SpaceTime>(
  "events/centerSpaceTime",
);

export const fromPB = (e: EventPB): Event => {
  const t = e.getTime();

  const _event = {
    deviceId: e.getDeviceId(),
    time: (t?.getSeconds() || 0) + (t?.getNanos() || 0) * 1e-9,
    lat: e.getPos()?.getLat() || 0,
    lon: e.getPos()?.getLon() || 0,
    alt: e.getPos()?.getAlt() || 0,
    payload: e.getPayload()?.toJavaScript() || {},
  };

  const type = e.getType();
  switch (type) {
    case GENERATED_EVENT_TYPE: {
      const egId = _event.payload["eventGeneratorId"];
      if (!egId || typeof egId !== "string") {
        console.dir(e.toObject());
        throw new Error(`event missing event generator id!`);
      }
      return Object.assign(_event, { type, eventGeneratorId: egId });
    }
    case DEVICE_EVENT_TYPE: {
      return Object.assign(_event, { type });
    }
    default:
      console.dir(e.toObject());
      throw new Error(`event had invalid event type ${type}`);
  }
};

export default createReducer<EventsRoot>(getInitialState(), (builder) =>
  builder
    .addCase(addEvents, (draftState, action) => {
      const state = original(draftState);
      if (process.env.NODE_ENV !== "production") {
        //_assertStateOk(state);
      }

      const chunkId = action.payload.chunkId;
      const newEvents = action.payload.events;

      const old = state.chunks;

      const existing = old[chunkId] || {
        events: [],
        requestState: loading.pendingRequestState(),
      };

      const added = appendInOrder(
        existing.events,
        newEvents,
        (x, y) => x.time - y.time,
      );

      const chunk = {
        events: added,
        requestState:
          action.payload.requestState === null
            ? existing.requestState
            : action.payload.requestState,
      };

      return {
        chunks: { ...old, [chunkId]: chunk },
        chunkLengthSeconds: state.chunkLengthSeconds,
      };
    })
    .addCase(clearEvents, () => getInitialState()),
);
