import { createSelector } from "reselect";

import { selectDeviceStatesSlice } from "./devices";
import { extractFieldFromNestedObject, TypeObject } from "../../utils/fields";
import { createChunkSelector } from "./creators";
import { selectAnnotatorsSlice } from "./annotator";
import { annotationKeys } from "../reducers/annotator";

// User data and annotations are considered to be "custom" fields
// This selector will merge them across all current devices.
// If there are no states for a given chunk, null is returned (sorry)
// so that this can be detected in the .all() selector below.
const _selectDeviceStateCustomFields = createChunkSelector(
  [selectDeviceStatesSlice],
  [],
  (_chunkId, c) => {
    if (!c) {
      return null;
    }
    let acc: TypeObject | null = null;
    for (const devId in c.states.entities) {
      const dev = c.states.entities[devId];
      if (!dev || dev.states.length == 0) {
        continue;
      }

      const dss = dev.states;
      const fields = extractFieldFromNestedObject(
        dss,
        (ds) => ds?.userData || {},
        (ds) => ds?.annotations || {},
      );
      acc = Object.assign(acc || {}, fields);
    }
    return acc;
  },
);

export const selectDeviceStateCustomFields = createSelector(
  [_selectDeviceStateCustomFields.all],
  function selectDeviceStateCustomFields(fields): TypeObject {
    const cf = Object.values(fields.chunks);
    const nonNullResults = cf.filter((f) => f !== null);
    if (nonNullResults.length == 0) {
      // special case: if the dashboard has no states, we suggest
      // these two fields. This is to make onboarding and demos nicer, where users'
      // initial experience with Sora means they will have no data in it yet.
      return {
        bearing: "number",
        speed: "number",
      };
    }
    return Object.assign({}, ...nonNullResults);
  },
);

const _selectDeviceStateAnnotations = createChunkSelector(
  [selectDeviceStatesSlice],
  [],
  (_chunkId, c) => {
    if (!c) {
      return {};
    }

    return c.states.ids.reduce((acc: TypeObject, devId) => {
      const dss = c.states.entities[devId]?.states || [];
      const fields = extractFieldFromNestedObject(
        dss,
        (ds) => ds?.annotations || {},
      );
      return Object.assign(acc, fields);
    }, {});
  },
);

export const selectDeviceStateAnnotations = createSelector(
  [_selectDeviceStateAnnotations.all],
  (fields): TypeObject => Object.assign({}, ...Object.values(fields.chunks)),
);

export const selectAnnotatorFields = createSelector(
  [selectAnnotatorsSlice],
  (annotators) => {
    const fields: TypeObject = {};
    for (const id in annotators.entities) {
      const a = annotators.entities[id];
      if (!a) {
        continue;
      }
      Object.assign(fields, annotationKeys(a));
    }
    return fields;
  },
);
