import { MessageDescriptor } from "react-intl";
import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "kepler.gl/actions";
import { PayloadAction } from "@reduxjs/toolkit";

import { fileUploadFailed, uploadModalClear } from "../reducers/layers";
import { messages } from "../../intl/message";

export function* showErrorInUploadModal(
  e: unknown,
  fileName: string,
  localizationId: NonNullable<MessageDescriptor["id"]>,
) {
  console.error(e);
  yield put(
    fileUploadFailed({
      error: messages.en[localizationId] || localizationId,
      fileName: fileName,
    }),
  );
}

function* toggleModalSaga(action: PayloadAction<PayloadAction<string>>) {
  if (action?.payload?.payload === null) {
    yield put(uploadModalClear());
  }
}

export default function* errorsSaga() {
  yield all([takeLatest(ActionTypes.TOGGLE_MODAL, toggleModalSaga)]);
}
