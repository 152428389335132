import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form, Formik, FormikErrors, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";

import { useAppDispatch } from "../../../redux/hooks";
import {
  createProjectRequested,
  initialProject,
  Project,
  ProjectIcon,
  IconURLMapPNG,
  updateProjectRequested,
} from "../../../redux/reducers/projects";
import { BUS_DEMO_TYPE } from "../../../redux/reducers/demos";

type ProjectView = Omit<Project, "demoType"> & { isBusDemo: boolean };

function toModel(v: ProjectView): Project {
  const { isBusDemo, ..._p } = v;
  const p = Object.assign(_p, { demoType: isBusDemo ? BUS_DEMO_TYPE : null });
  return p;
}

function toView(p: Project): ProjectView {
  const { demoType, ..._v } = p;
  const v = Object.assign(_v, { isBusDemo: demoType == BUS_DEMO_TYPE });
  return v;
}

type ProjectFormProps = {
  project?: Project;
  onClose: () => void;
};

export const ProjectForm: FC<ProjectFormProps> = ({ project, onClose }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  return (
    <Formik
      initialValues={toView(initialProject(project))}
      validate={(values) => {
        const errors: FormikErrors<ProjectView> = {};

        if (!values.name) {
          errors.name = intl.formatMessage({ id: "project.modal.required" });
        }

        return errors;
      }}
      onSubmit={(values, helpers) => {
        project
          ? dispatch(
              updateProjectRequested({
                project: toModel(values),
                helpers: helpers as FormikHelpers<unknown>,
                intl,
                onSuccess: onClose,
              }),
            )
          : dispatch(
              createProjectRequested({
                project: toModel(values),
                helpers: helpers as FormikHelpers<unknown>,
                intl,
                onSuccess: onClose,
              }),
            );
      }}
    >
      {({ values, handleChange, submitForm, resetForm, isSubmitting }) => (
        <Form>
          <DialogContent sx={{ width: 500 }}>
            <Box margin={1}>
              <Typography variant="body1">
                <FormattedMessage id="project.modal.message" />
              </Typography>
            </Box>
            <Box margin={1}>
              <Field
                component={TextField}
                name="name"
                type="text"
                label={intl.formatMessage({ id: "project.modal.name" })}
                fullWidth
              />
            </Box>
            <Box margin={1}>
              <Field
                component={TextField}
                name="description"
                type="text"
                label={intl.formatMessage({ id: "project.modal.description" })}
                fullWidth
                multiline
                minRows={3}
                maxRows={10}
              />
            </Box>
            <Box margin={1}>
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                {/* When using FormControl and Select together, both InputLabel and label on Select are needed.
                InputLabel displays label text and styles it, while label provides accessibility info and applies styling when Select is focused or filled. */}
                <InputLabel>
                  {intl.formatMessage({ id: "project.modal.selectIcon" })}
                </InputLabel>
                <Select
                  name="icon"
                  value={values.icon}
                  onChange={handleChange}
                  label={intl.formatMessage({ id: "project.modal.selectIcon" })}
                >
                  {Object.values(ProjectIcon).map((icon) => (
                    <MenuItem key={icon} value={icon}>
                      <Typography
                        noWrap
                        sx={{
                          width: "100%",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {intl.formatMessage({
                            id: `project.modal.icon.${icon}`,
                          })}
                        </span>
                        <img
                          src={IconURLMapPNG[icon]}
                          alt={`${icon} icon`}
                          width="48px"
                          height="36px"
                        />
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <FormControlLabel
              control={
                <Switch
                  name="isBusDemo"
                  checked={values.isBusDemo}
                  onChange={handleChange}
                />
              }
              label={intl.formatMessage({
                id: values.isBusDemo
                  ? "project.modal.isBusDemoOn"
                  : "project.modal.isBusDemoOff",
              })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ margin: 1 }}
              variant="text"
              color="secondary"
              disabled={isSubmitting}
              onClick={() => resetForm()}
            >
              <FormattedMessage id="project.modal.reset" />
            </Button>
            <Button
              sx={{ margin: 1 }}
              variant="text"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              <FormattedMessage
                id={project ? "project.modal.update" : "project.modal.create"}
              />
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};
