import {
  all,
  call,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  ReadEventGeneratorsRequest,
  ReadEventGeneratorsResponse,
  UpdateEventGeneratorRequest,
  DeleteEventGeneratorRequest,
  CreateEventGeneratorRequest,
  CreateEventGeneratorResponse,
} from "../../generated/sora/app/v1beta/service_pb";

import {
  addEventGenerators,
  createEventGeneratorRequested,
  deleteEventGeneratorRequested,
  fromPB,
  readEventGeneratorsRequested,
  toPB,
  updateEventGenerator,
  EventGenerator,
  eventGeneratorSelectors,
  deleteEventGenerator,
} from "../reducers/event-generators";
import { UPDATE_DEBOUNCE_MS } from "../../constants";
import { callConfig } from "../../api/callConfig";

function* readEventGeneratorsSaga(
  action: ReturnType<typeof readEventGeneratorsRequested>,
) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const readEventGeneratorsRequest =
    new ReadEventGeneratorsRequest().setProjectId(action.payload);
  let readEventGeneratorsResponse: ReadEventGeneratorsResponse;
  try {
    readEventGeneratorsResponse = yield call(
      (req) => appService.readEventGenerators(req),
      readEventGeneratorsRequest,
    );
  } catch (e) {
    console.error(e);
    return;
  }

  const eventGenerators = readEventGeneratorsResponse
    .getEventGeneratorsList()
    .map((l) => fromPB(l));

  yield put(addEventGenerators(eventGenerators));
}

function* createEventGeneratorSaga(
  action: ReturnType<typeof createEventGeneratorRequested>,
) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const createEventGeneratorRequest =
    new CreateEventGeneratorRequest().setEventGenerator(toPB(action.payload));
  let createEventGeneratorResponse: CreateEventGeneratorResponse;
  try {
    createEventGeneratorResponse = yield call(
      (req) => appService.createEventGenerator(req),
      createEventGeneratorRequest,
    );
  } catch (e) {
    console.error(e);
    return;
  }

  const maybeEventGenerator = createEventGeneratorResponse.getEventGenerator();
  if (maybeEventGenerator) {
    yield put(addEventGenerators([fromPB(maybeEventGenerator)]));
  }
}

function* updateEventGeneratorSaga(
  action: ReturnType<typeof updateEventGenerator>,
) {
  if (
    action.payload.changes.name ||
    action.payload.changes.color ||
    action.payload.changes.version ||
    action.payload.changes.expression ||
    action.payload.changes.alertorId !== undefined
  ) {
    const appService = callConfig.call.appServiceContext?.appService;
    if (!appService) {
      return;
    }

    const eventGenerator: EventGenerator = yield select(
      eventGeneratorSelectors.selectById,
      action.payload.id.toString(),
    );

    const updateEventGeneratorRequest =
      new UpdateEventGeneratorRequest().setEventGenerator(toPB(eventGenerator));

    try {
      yield call(
        (req) => appService.updateEventGenerator(req),
        updateEventGeneratorRequest,
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }
}

function* deleteEventGeneratorSaga(
  action: ReturnType<typeof deleteEventGeneratorRequested>,
) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const deleteEventGeneratorRequest = new DeleteEventGeneratorRequest()
    .setId(action.payload.id)
    .setProjectId(action.payload.projectId);
  try {
    yield call(
      (req) => appService.deleteEventGenerator(req),
      deleteEventGeneratorRequest,
    );
  } catch (e) {
    console.error(e);
    return;
  }

  yield put(deleteEventGenerator(action.payload.id));
}

export default function* eventGeneratorsSaga() {
  yield all([
    takeEvery(createEventGeneratorRequested, createEventGeneratorSaga),
    debounce(
      UPDATE_DEBOUNCE_MS,
      updateEventGenerator,
      updateEventGeneratorSaga,
    ),
    takeEvery(deleteEventGeneratorRequested, deleteEventGeneratorSaga),
    takeLatest(readEventGeneratorsRequested, readEventGeneratorsSaga),
  ]);
}
