import { createAction, createReducer } from "@reduxjs/toolkit";

import { TimeSeriesRangeSelection as TimeSeriesRangeSelectionPB } from "../../generated/sora/app/v1beta/service_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { RootState } from "../store";

export type TimeSeriesRangeSelection = {
  time: {
    startIncl: Date;
    endIncl: Date;
  };
  cursor: {
    startExcl: number;
    endExcl: number;
  } | null;
};

export type SelectionsRoot = {
  ds: TimeSeriesRangeSelection | null;
  ev: TimeSeriesRangeSelection | null;
};

const initialState: SelectionsRoot = {
  ds: null,
  ev: null,
};

export const toPB = (
  t: TimeSeriesRangeSelection,
): TimeSeriesRangeSelectionPB => {
  const out = new TimeSeriesRangeSelectionPB();

  out.setStartTime(Timestamp.fromDate(t.time.startIncl));
  out.setEndTime(Timestamp.fromDate(t.time.endIncl));

  if (t.cursor !== null) {
    out.setStartCursor(t.cursor.startExcl);
    out.setEndCursor(t.cursor.endExcl);
  }

  return out;
};

export const selectTimeSeriesRangeSelection = (state: RootState) =>
  state.app.timeSeriesRangeSelection;

export const setTimeSeriesRangeSelectionDS =
  createAction<TimeSeriesRangeSelection>("timeSeriesRangeSelection/set/ds");

export const setTimeSeriesRangeSelectionEv =
  createAction<TimeSeriesRangeSelection>("timeSeriesRangeSelection/set/ev");

export default createReducer(initialState, (builder) => {
  builder.addCase(setTimeSeriesRangeSelectionDS, (selections, action) => {
    selections.ds = action.payload;
  });
  builder.addCase(setTimeSeriesRangeSelectionEv, (selections, action) => {
    selections.ev = action.payload;
  });
});
