import { createSelector } from "reselect";
import { Position2D, Position3D } from "@deck.gl/core";
import "core-js/actual/array/find-last";

import { createChunkSelector } from "./creators";
import { createDeepEqualSelector } from "./creators";
import { selectSelectionEnd, selectSliderBounds } from "./time-control";
import { computeChunkBounds, DeviceState } from "../reducers/device-state";
import { TIME_REF } from "../../constants";

import { Device } from "../reducers/devices";
import { RootState } from "../store";
import assert from "assert";
import { objectEntries } from "../reducers/chunks";
import { shallowEqual } from "react-redux";
import { EntityId } from "@reduxjs/toolkit";

const stateToPosition2D = (s: DeviceState): Position2D => [
  s.pos.lon,
  s.pos.lat,
];
const stateToPosition3D = (s: DeviceState): Position3D => [
  s.pos.lon,
  s.pos.lat,
  0,
];

export const selectDevicesSlice = (state: RootState) => state.app.devices;
export const selectDeviceStatesSlice = (state: RootState) =>
  state.app.deviceStates;

// return all deviceIds we know about, whether they have state or not.
export const selectIds = createSelector(selectDevicesSlice, (d) => d.ids, {
  memoizeOptions: { resultEqualityCheck: shallowEqual },
});

// return the indices to deviceIds that have at least some state in the slider
export const selectIxsInSlider = createSelector(
  selectIds,
  selectDeviceStatesSlice,
  selectSliderBounds,
  (ids, cds, slider) => {
    const seenIds = new Set<EntityId>();
    const unseenIds = new Set(ids);
    for (const [chunkId, chunk] of objectEntries(cds.chunks)) {
      // if chunk is outside the slider, skip it
      const bounds = computeChunkBounds(chunkId);
      if (slider.end <= bounds.startIncl || bounds.endExcl < slider.start) {
        continue;
      }
      for (const id of unseenIds) {
        const entity = chunk.states.entities[id];
        if (entity === undefined) {
          continue;
        }
        if (entity.states.length == 0) {
          continue;
        }
        const firstState = entity.states[0];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const lastState = entity.states.at(-1)!;

        // if the states of this device do not overlap the slider, skip
        if (lastState.time <= slider.start || slider.end < firstState.time) {
          continue;
        }
        // else we've found this device has some state in the slider.
        seenIds.add(id);
        unseenIds.delete(id);
      }
    }
    const ixsInSlider = [];
    for (let ix = 0; ix < ids.length; ix++) {
      if (seenIds.has(ids[ix])) {
        ixsInSlider.push(ix);
      }
    }
    return ixsInSlider;
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } },
);

export const selectDeviceStatesChunk = createChunkSelector(
  [selectDeviceStatesSlice],
  [selectIds],
  function selectDeviceStates(chunkId, ds, ids) {
    return ids.map((id) => {
      const states = ds?.states.entities[id]?.states;
      // A hack to avoid the offset-out-of-bounds bug
      return states && states.length > 0
        ? [states[0], states[0], ...states]
        : [];
    });
  },
);

/**
 * Returns a set, keyed by device id, of the "current" device state per-device,
 * which is the most recent state <= the end of the current selection.
 * This is undefined for devices that have no state before the end of the current selection.
 */
export const selectCurrDeviceState = createSelector(
  selectSelectionEnd,
  selectIds,
  selectIxsInSlider,
  selectDeviceStatesSlice,
  (end, ids, ixs, ds): (DeviceState | undefined)[] => {
    const currentStates: (DeviceState | undefined)[] = Array.from(
      ids.map((_) => undefined),
    );

    const unseenIxs = new Set(ixs);

    // iterate over chunks, from future to past
    for (const [chunkId, chunk] of objectEntries(ds.chunks).reverse()) {
      const bounds = computeChunkBounds(chunkId);
      // skip chunk if it starts after the selection end
      if (end < bounds.startIncl) {
        continue;
      }

      for (const ix of unseenIxs) {
        const id = ids[ix];

        // skip device if it's not in the chunk
        const entity = chunk.states.entities[id];
        if (entity === undefined) {
          continue;
        }
        if (entity.states.length == 0) {
          continue;
        }

        const firstState = entity.states[0];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const lastState = entity.states.at(-1)!;

        // if this device's states start after selection end, we can skip
        if (end < firstState.time) {
          continue;
        }
        let currentState: DeviceState | undefined = undefined;
        // if this device's states finish before the selection end, we
        // can pick the last state, because we know we've already processed
        // all future chunks
        if (lastState.time <= end) {
          currentState = lastState;
        }
        // slow path - linear search a sorted array :(
        else {
          currentState = entity.states.findLast(
            (s) => s.time <= end,
          ) as DeviceState;
        }

        // by now, there should always be some state where state.time < selection end
        ASSERTS && assert(currentState !== undefined);

        currentStates[ix] = currentState;
        unseenIxs.delete(ix);
      }
    }
    return currentStates;
  },
);

export const selectPaths = createChunkSelector(
  [selectDeviceStatesChunk],
  [],
  (_chunkId, states) =>
    states?.map((ss) => ss.map((s) => stateToPosition2D(s))),
);

export const selectTimestamps = createChunkSelector(
  [selectDeviceStatesChunk],
  [],
  (_chunkId, states) => states?.map((ss) => ss.map((s) => s.time - TIME_REF)),
);

export const selectPositions = createSelector(selectCurrDeviceState, (states) =>
  states.map((s) => (s !== undefined ? stateToPosition3D(s) : undefined)),
);

export const selectOrientations = createSelector(
  selectCurrDeviceState,
  (states) => states.map((s) => (s !== undefined ? s.orientation : undefined)),
);

export const selectDevices = createSelector(
  selectIds,
  selectDevicesSlice,
  (ids, ds) => ids.map((id) => ds.entities[id] as Device),
);

export const selectColors = createDeepEqualSelector(
  selectIds,
  selectDevicesSlice,
  (ids, ds) => ids.map((id) => (ds.entities[id] as Device).color),
);

export const selectDeviceNames = createDeepEqualSelector(
  selectIds,
  selectDevicesSlice,
  (ids, ds) => ids.map((id) => (ds.entities[id] as Device).name),
);
