import { IntlShape } from "react-intl";
import { FormikHelpers } from "formik";
import {
  createAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RGBAColor } from "@deck.gl/core";

import { Project as ProjectPB } from "../../generated/sora/app/v1beta/types_pb";

import {
  colorArrayFromInt,
  intFromColorArray,
  randColor,
  stripAlpha,
} from "../../utils/color";
import { RootState } from "../store";
import {
  erroredRequestState,
  pendingRequestState,
  succeededRequestState,
} from "./loading";
import { NavigateFunction } from "react-router";

export type ProjectId = string;

export type Project = {
  id: ProjectId;
  name: string;
  description: string;
  color: RGBAColor;
  demoType: string | null;
  icon: ProjectIcon;
};

export type CreateProjectRequest = {
  project: Project;
  helpers: FormikHelpers<unknown>;
  intl: IntlShape;
  onSuccess: () => void;
};

export type UpdateProjectRequest = {
  project: Project;
  helpers: FormikHelpers<unknown>;
  intl: IntlShape;
  onSuccess: () => void;
};

export type ChangeProjectRequest = {
  to: ProjectId;
  from?: ProjectId;
  navigate: NavigateFunction;
};

export const initialProject = (project?: Project): Project => ({
  id: project?.id || "",
  name: project?.name || "",
  description: project?.description || "",
  color: project?.color || randColor(),
  demoType: project ? project.demoType : null,
  icon: project?.icon || ProjectIcon.bus,
});

export enum ProjectIcon {
  train = "train",
  bus = "bus",
  drone = "drone",
  point = "point",
  car = "car",
}

const STATIC_PATH = "/files/static/models";
export const IconURLMapGLB: Record<ProjectIcon, string> = {
  [ProjectIcon.train]: `${STATIC_PATH}/train.glb`,
  [ProjectIcon.bus]: `${STATIC_PATH}/bus.glb`,
  [ProjectIcon.drone]: `${STATIC_PATH}/drone.glb`,
  [ProjectIcon.point]: `${STATIC_PATH}/point.glb`,
  [ProjectIcon.car]: `${STATIC_PATH}/car.glb`,
};

export const IconURLMapPNG: Record<ProjectIcon, string> = {
  [ProjectIcon.train]: `${STATIC_PATH}/train.png`,
  [ProjectIcon.bus]: `${STATIC_PATH}/bus.png`,
  [ProjectIcon.drone]: `${STATIC_PATH}/drone.png`,
  [ProjectIcon.point]: `${STATIC_PATH}/point.png`,
  [ProjectIcon.car]: `${STATIC_PATH}/car.png`,
};

const getIcon = (icon: string): ProjectIcon => {
  if (icon in ProjectIcon) {
    return icon as ProjectIcon;
  }
  return ProjectIcon.bus;
};

export const fromPB = (p: ProjectPB): Project => ({
  id: p.getId(),
  name: p.getName(),
  description: p.getDescription(),
  color: stripAlpha(colorArrayFromInt(p.getDashboard()?.getColor() || 0)),
  demoType: p.getDemoType() || null,
  icon: getIcon(p.getDashboard()?.getIcon() || ""),
});

export const toPB = (p: Project) =>
  new ProjectPB()
    .setId(p.id)
    .setName(p.name)
    .setDescription(p.description)
    .setColor(intFromColorArray(p.color))
    .setDemoType(p.demoType || "")
    .setDashboard(
      new ProjectPB.Dashboard()
        .setColor(intFromColorArray(p.color))
        .setIcon(getIcon(p.icon)),
    );

const projectAdapter = createEntityAdapter<Project>();
const projectSlice = createSlice({
  name: "projects",
  initialState: projectAdapter.getInitialState({
    readAll: pendingRequestState(),
  }),
  reducers: {
    addProjects: projectAdapter.upsertMany,
    updateProject: projectAdapter.updateOne,
    deleteProject: projectAdapter.removeOne,
    setReadProjectsErrored: (state, action) => {
      state.readAll = erroredRequestState(action.payload);
    },
    setReadProjectsPending: (state) => {
      state.readAll = pendingRequestState();
    },
    setReadProjectsSucceeded: (state) => {
      state.readAll = succeededRequestState();
    },
  },
});

export const readProjectsRequested = createAction<undefined>(
  "projects/readRequested",
);
export const createProjectRequested = createAction<CreateProjectRequest>(
  "projects/createRequested",
);
export const updateProjectRequested = createAction<UpdateProjectRequest>(
  "projects/updateRequested",
);
export const changeProjectAndNavigateToDashboard =
  createAction<ChangeProjectRequest>("project/change");

export const projectSelectors = projectAdapter.getSelectors(
  (state: RootState) => state.app.projects,
);
export const selectProjectsReadAllState = (state: RootState) =>
  state.app.projects.readAll;
export const {
  addProjects,
  updateProject,
  deleteProject,
  setReadProjectsErrored,
  setReadProjectsPending,
  setReadProjectsSucceeded,
} = projectSlice.actions;
export default projectSlice.reducer;
