import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
  Annotator,
  createAnnotatorRequested,
  updateAnnotator,
} from "../../../redux/reducers/annotator";
import { GeofenceAnnotatorBuilder } from "./GeofenceAnnotatorBuilder";
import { MapMatchAnnotatorBuilder } from "./MapMatchAnnotatorBuilder";
import { AnnotationPreview } from "./AnnotationPreview";
import { annotatorToTabIndex } from "./annotator";

type AnnotatorBuilderProps = {
  annotator?: Annotator;
  onClose: () => void;
};

export const AnnotatorBuilder: FC<AnnotatorBuilderProps> = ({
  annotator,
  onClose,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [tabIndex, setTabIndex] = useState(annotatorToTabIndex(annotator));
  const builtAnnotator = useAppSelector(
    (state: RootState) => state.app.ui.annotatorBuilder.current,
  );

  const formSubmit = () => {
    if (annotator) {
      dispatch(
        updateAnnotator({
          id: annotator.id,
          changes: {
            name: builtAnnotator.name,
            config: builtAnnotator.config,
          },
        }),
      );
    } else {
      dispatch(createAnnotatorRequested(builtAnnotator));
    }
    onClose();
  };

  return (
    <>
      <DialogContent sx={{ minWidth: 750 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabIndex} onChange={(_, x) => setTabIndex(x)}>
            <Tab
              label={intl.formatMessage({ id: "annotator.modal.geofence" })}
            />
            <Tab
              label={intl.formatMessage({ id: "annotator.modal.mapMatch" })}
            />
          </Tabs>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            {
              [<GeofenceAnnotatorBuilder />, <MapMatchAnnotatorBuilder />][
                tabIndex
              ]
            }
          </Grid>
          <Grid item xs={6}>
            <AnnotationPreview />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="text" onClick={formSubmit}>
          <FormattedMessage
            id={annotator ? "annotator.modal.update" : "annotator.modal.create"}
          />
        </Button>
      </DialogActions>
    </>
  );
};
