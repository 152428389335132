import { Selector, createSelector } from "reselect";

import { RootState } from "../store";

import { selectEventGeneratorsSlice } from "./event-generators";
import {
  computeChunkBounds,
  Event,
  GENERATED_EVENT_TYPE,
} from "../reducers/events";
import {
  EventGenerator,
  NO_EVENT_GENERATOR,
} from "../reducers/event-generators";
import { alertorSelectors } from "../reducers/alertor";
import { createChunkSelector } from "./creators";
import { ChunkId } from "../reducers/chunks";
import { selectSliderBounds } from "./time-control";

import "core-js/actual/array/find-last-index";

export const selectEvents = (state: RootState) => state.app.events;

const _selectEventsInSlider = createChunkSelector(
  [selectEvents],
  [selectSliderBounds],
  (chunkId, c, slider) => {
    const es = c?.events;

    if (es === undefined || es.length == 0) {
      return es;
    }

    // if chunk is outside the slider, skip it
    const bounds = computeChunkBounds(chunkId);
    if (slider.end < bounds.startIncl || bounds.endExcl <= slider.start) {
      return [];
    }
    // if chunk is inside the slider, return the lot
    if (slider.start <= bounds.startIncl && bounds.endExcl <= slider.end) {
      return es;
    }

    const firstEvent = es[0];
    const lastEvent = es.at(-1)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    // if no events are inside the slider, skip it
    if (slider.end < firstEvent.time || lastEvent.time < slider.start) {
      return [];
    }

    // if all events are inside the slider, return the lot
    if (slider.start <= firstEvent.time && lastEvent.time <= slider.end) {
      return es;
    }

    const ixFirstInside = es.findIndex((e) => slider.start <= e.time);
    const ixLastInside = es.findLastIndex((e) => e.time <= slider.end);

    return es.slice(ixFirstInside, ixLastInside + 1);
  },
);

export const selectEventsByEventGeneratorIds = createChunkSelector(
  [_selectEventsInSlider],
  [selectEventGeneratorsSlice],
  (_chunkId: ChunkId, es: Event[] | undefined, egs) => {
    if (es === undefined) {
      return es;
    }
    const grouped = es.group((e) => {
      // EG ID missing
      const egId =
        e.type == GENERATED_EVENT_TYPE
          ? e.eventGeneratorId
          : NO_EVENT_GENERATOR;
      // EG ID found, but didn't match one of ours
      return egId in egs.entities ? egId : NO_EVENT_GENERATOR;
      // you may wish to distinguish between the above two at some point?
    });

    // .group() returns an object with null prototype: this is fine, but Typescript can't
    // represent it, and it can cause subtle breakage, so I'm disallowing it. If typescript
    // could represent it I wouldn't mind.
    return { ...grouped };
  },
);

const _selectEventsCount = createChunkSelector(
  [selectEventsByEventGeneratorIds],
  [],
  (_chunkId: ChunkId, es): Map<string | typeof NO_EVENT_GENERATOR, number> => {
    if (!es) return new Map();
    return new Map(
      Object.entries(es).map(([egId, es]) => [egId, es.length] as const),
    );
  },
);

export const selectEventCounts: Selector<
  RootState,
  Map<string | typeof NO_EVENT_GENERATOR, number>
> = createSelector([_selectEventsCount.all], (chunkCounts) => {
  const agg = new Map<string | typeof NO_EVENT_GENERATOR, number>();
  for (const c of Object.values(chunkCounts.chunks)) {
    if (!c) {
      continue;
    }
    for (const [k, v] of c) {
      const added = (agg.get(k) || 0) + v;
      agg.set(k, added);
    }
  }
  return agg;
});

type EventLogEntry = Event & {
  eventGenerator: EventGenerator;
  eventGeneratorName: string;
  alertName: string;
};

export const selectEventsForLog = createChunkSelector(
  [selectEvents],
  [selectEventGeneratorsSlice, alertorSelectors.selectEntities],
  (_chunkId, c, egs, alertorsById): (Event | EventLogEntry)[] | undefined => {
    const events = c?.events;
    if (events === undefined) {
      return undefined;
    }
    return events.map((e) => {
      const egId =
        e.type == GENERATED_EVENT_TYPE ? e.eventGeneratorId : undefined;
      const eg = egId !== undefined ? egs.entities[egId] : undefined;
      if (!eg) {
        return e;
      }
      return {
        ...e,
        eventGenerator: eg,
        eventGeneratorName: eg.name,
        alertName: eg.alertorId
          ? (alertorsById[eg.alertorId] || { name: "DELETED" }).name
          : "NONE",
      };
    });
  },
);
