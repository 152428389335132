import {
  createSelectorCreator,
  defaultMemoize,
  defaultEqualityCheck,
  createSelector,
  OutputSelectorFields,
} from "reselect";
import isEqual from "lodash.isequal";
import { ChunkId, Chunks, mapChunks } from "../reducers/chunks";

// create a "selector creator" that uses lodash.isequal instead of ===
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, {
  equalityCheck: defaultEqualityCheck,
  resultEqualityCheck: isEqual,
});

const isEqualish = <T>(v1: T, v2: T): boolean => {
  if (v1 === v2) {
    return true;
  }

  if (Array.isArray(v1) && Array.isArray(v2)) {
    if (v1.length !== v2.length) {
      return false;
    }
    return v1.every((v, ix) => v2[ix] === v);
  }
  return false;
};

// create a "selector creator" that when passed an array will shallow compare
// each element and match if all elements are === equal.
export const createEqualishSelector = createSelectorCreator(defaultMemoize, {
  equalityCheck: isEqualish,
  resultEqualityCheck: isEqualish,
});

type CompleteChunkSelector<S, R> =
  | ((state: S) => Chunks<R>)
  | OutputChunkSelector<S, R>;
type ChunkSelector<S, R> =
  | CompleteChunkSelector<S, R>
  | ((state: S, chunkId: ChunkId) => R);
type Sel<S, R> = (state: S) => R;

/* eslint-disable @typescript-eslint/no-explicit-any */

type ChunkType<C> = C extends Chunks<infer R>
  ? R
  : C extends (state: any) => Chunks<infer R2>
  ? R2
  : C extends (state: any, ChunkId: ChunkId) => infer R3
  ? R3
  : C extends OutputChunkSelector<any, infer R4>
  ? R4
  : never;

type SelResultArray<
  CDeps extends ChunkSelector<any, any>[],
  Deps extends Sel<any, any>[],
> = [
  ...{ [I in keyof CDeps]: ChunkType<CDeps[I]> | undefined },
  ...{ [I in keyof Deps]: ReturnType<Deps[I]> },
];

// This is incomplete as it only looks at CDeps, not Deps,
// but it is internal to the implementation here so I'm not too worried.
type SelInput<CDeps extends ChunkSelector<any, any>[]> =
  CDeps extends ChunkSelector<infer S, any>[] ? S : never;

const isChunkSelector = Symbol("isChunkSelector");

type OutputChunkSelector<S, R> = {
  (state: S, chunkId: ChunkId): R;
  all: (state: S) => Chunks<R>;
  some: (state: S, chunkIds: Set<ChunkId>) => Chunks<R>;
  [isChunkSelector]: true;
};

/**
 * create a "selector creator" for chunk selectors that will create an independent
 * defaultMemoized cache per chunk. Usage:
 *
 *     let chunkDep1, chunkDep2: (state: S) => Chunks<??> = ...
 *     let dep1, dep2: (state: S) => ?? = ...
 *
 *     const selector = createChunkSelector(
 *       [chunkDep1, chunkDep2],
 *       [dep1, dep2],
 *       (c1, c2, d1, d2): R => { ... }
 *     )
 *
 * `selector` and `selector.all` are now reselect selectors:
 *
 *     selector: (state: S, chunkId) => R;
 *     selector.all: (state: S) => Chunks<R>;
 *
 *  like normal selectors, chunk selectors can also be used as inputs to new selectors:
 *
 *     const selector2 = createChunkSelector(
 *         [selector],
 *         [],
 *         (r: R): R2 => { ... }
 *     )
 *
 */

export const createChunkSelector = function createChunkSelector<
  CDeps extends [CompleteChunkSelector<any, any>, ...ChunkSelector<any, any>[]],
  Deps extends Sel<any, any>[],
  R,
  S = SelInput<CDeps>,
>(
  chunkDeps: CDeps,
  deps: [...Deps],
  selectorFn: (chunkId: ChunkId, ...args: SelResultArray<CDeps, Deps>) => R,
) {
  /** we call createSelector once per chunkId. This is
   * a single one of them.
   */
  type CSel = ((state: S, chunkId: ChunkId) => R) &
    OutputSelectorFields<(...args: SelResultArray<CDeps, Deps>) => R>;

  const csels = new Map<ChunkId, CSel>();

  // our chunk dependencies can either be of the form
  //     (S, ChunkId) => A (if the user is nesting createChunkSelectors)
  // or
  //     (S) => Chunks<A> .
  // This normalizes both options to look like (S,Cid)=>A.
  const _chunkDeps = chunkDeps.map((c) =>
    isChunkSelector in c
      ? c
      : c.length == 2
      ? c
      : c.length == 1
      ? // typescript should be able to infer the c cast, check again in a few versions (ts 4.9)
        (state: S, chunkId: ChunkId) =>
          (c as (state: S) => Chunks<R>)(state).chunks[chunkId]
      : (null as never),
  );

  // as promised, call createSelector once per chunkId. The
  // reason for this is so each chunk has a separate selector cache.
  function getCSel(chunkId: ChunkId) {
    if (!csels.has(chunkId)) {
      const _selectorFn = selectorFn.bind(null, chunkId) as (
        ...args: any[]
      ) => R;
      const csel = createSelector(_chunkDeps.concat(deps), _selectorFn);
      csels.set(chunkId, csel);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return csels.get(chunkId)!;
  }

  // Result 1: selector that runs on a specific chunk
  const selectOne = function (state: S, chunkId: ChunkId): R {
    return getCSel(chunkId)(state, chunkId);
  };

  // input functions
  type SelAllSelsCDep = (
    state: S,
  ) => Chunks<unknown> | ((c: ChunkId) => unknown);

  // selector args, output of input functions
  type SelAllArgsCDep = Chunks<unknown> | ((c: ChunkId) => unknown);

  // our chunk dependencies can either be of the form
  //     (S, ChunkId) => A (if the user is nesting createChunkSelectors)
  // or
  //     (S) => Chunks<A> .
  // This normalizes both options to look like (S)=>(Chunks<A> | ((ChunkId) => A)).
  // Note that the first argument is prohibited from being a manually passed (s,cid)=>A function.
  const c0 = chunkDeps[0];
  const _chunkDep0 = isChunkSelector in c0 ? c0.all : c0;
  const _chunkDepsAll = chunkDeps
    .slice(1, undefined)
    .map((c) =>
      isChunkSelector in c
        ? c.all
        : c.length == 2
        ? (s) => (cid: ChunkId) => c(s, cid)
        : c.length == 1
        ? c
        : (null as never),
    ) as SelAllSelsCDep[];

  // Result 2: selector that runs on all chunks and returns a result
  // of Chunks<R>.
  const selectAll = createSelector(
    [_chunkDep0, ..._chunkDepsAll, ...deps],
    function (completeChunks, ...args): Chunks<R> {
      const l = chunkDeps.length - 1;
      const chunksArgs = [completeChunks as SelAllArgsCDep].concat(
        args.slice(0, l),
      );
      const depArgs = args.slice(l, undefined) as unknown[];

      return mapChunks(completeChunks, (_c, cid): R => {
        const chunkArgs = chunksArgs.map((a) =>
          "chunks" in a ? a.chunks[cid] : a(cid),
        );
        const csel = getCSel(cid);
        const _memoizedResultFunc = csel.memoizedResultFunc as (
          ...args: any[]
        ) => R;
        return _memoizedResultFunc(...chunkArgs, ...depArgs);
      });
    },
  );

  // Result 3: selector that runs on a set of specific chunks
  const selectSome = createSelector(
    [
      (_state: S, chunkIds: Set<ChunkId>) => chunkIds,
      _chunkDep0,
      ..._chunkDepsAll,
      ...deps,
    ],
    function (chunkIds, completeChunks, ...args): Chunks<R> {
      const l = chunkDeps.length - 1;
      const chunksArgs = [completeChunks as SelAllArgsCDep].concat(
        args.slice(0, l),
      );
      const depArgs = args.slice(l, undefined) as unknown[];

      const chunks: Chunks<R>["chunks"] = {};

      for (const cid of chunkIds) {
        const chunkArgs = chunksArgs.map((a) =>
          "chunks" in a ? a.chunks[cid] : a(cid),
        );
        const csel = getCSel(cid);
        const _memoizedResultFunc = csel.memoizedResultFunc as (
          ...args: any[]
        ) => R;
        chunks[cid] = _memoizedResultFunc(...chunkArgs, ...depArgs);
      }

      return {
        chunks,
        chunkLengthSeconds: completeChunks.chunkLengthSeconds,
      };
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (a instanceof Set && b instanceof Set) {
            if (a.size != b.size) {
              return false;
            }
            const c = new Set(a);
            for (const x of b) {
              c.add(x);
            }
            return a.size == c.size;
          } else {
            return a === b;
          }
        },
      },
    },
  );

  return Object.assign(selectOne, {
    all: selectAll,
    some: selectSome,
    [isChunkSelector]: true,
  }) as OutputChunkSelector<S, R>;
};

/* eslint-enable @typescript-eslint/no-explicit-any */
