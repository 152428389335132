import {
  ComponentProps,
  createContext,
  FC,
  useCallback,
  useEffect,
  useReducer,
  useState,
  useContext,
} from "react";
import * as dateFns from "date-fns";

import { useAppSelector } from "../hooks";
import { selectProjectIdFromRoute } from "../selectors/router";
import { gimmeTheReturn } from "../../utils/itertools";

import { AppServiceContext } from "../../api/appService";

import rpc, {
  QueryStateAggregateResponse,
} from "../../generated/sora/app/v1beta/service_pb";
import { AppServicePromiseClient } from "../../generated/sora/app/v1beta/service_grpc_web_pb";
import { selectTimezone } from "../selectors/settings";
import { Timezone } from "../../constants/Timezones";

// newtypes, following https://stackoverflow.com/a/68022235/5264127
type DateAsStr = string & { readonly DateAsStr: unique symbol };
function DateAsStr(d: Date) {
  return dateFns.formatISO(d, { representation: "date" }) as DateAsStr;
}

type MonthAsStr = string & { readonly MonthAsStr: unique symbol };
function MonthAsStr(d: Date) {
  return DateAsStr(dateFns.startOfMonth(d)) as string as MonthAsStr;
}

// this is the type of our context
// type+namespace is a pattern I like the look of at first glance, but i don't think it's idiomatic.
// I am tempted to go an set it as a real prototype but then if we ever hire a real frontend guy
// they will slap me.
// releated, I spent eight hours writing Rust last night.
/* also related, */ /* eslint-disable @typescript-eslint/no-namespace */
type DayCountsData = {
  counts: Map<DateAsStr, number>;
  monthlyMaxes: Map<MonthAsStr, number>;
};
namespace DayCountsData {
  type This = DayCountsData;
  export function New(): This {
    return {
      counts: new Map(),
      monthlyMaxes: new Map(),
    };
  }

  type Action = "RESET" | { monthStr: MonthAsStr; pullResult: CountsPullData };

  export function reducer(state: This, action: Action): This {
    if (action === "RESET") {
      return New();
    }
    const { monthStr, pullResult } = action;
    return {
      counts: new Map([...state.counts, ...pullResult.counts]),
      monthlyMaxes: new Map(state.monthlyMaxes).set(monthStr, pullResult.max),
    };
  }
}

// actually i lied, **this** is the type of our context
type DayCounts = {
  request: (d: Date) => void;
  data: DayCountsData;
};

// the pull from server stuff you probably opened this file
// to poke at
async function* pullDeviceStateTotals(
  appService: AppServicePromiseClient | undefined,
  month: Date,
  projectId: string,
  timezone: Timezone,
): AsyncGenerator<CountsPullState, CountsPullState, unknown> {
  if (!appService) {
    return { state: "ERROR", message: "appService not yet defined" };
  }

  const tz = timezone.ianaId;

  // prepare request
  const reqBody = new rpc.QueryStateAggregateRequest()
    .setMonth(
      new rpc.QueryStateAggregateRequest.MonthRange()
        .setTimezone(tz)
        .setYear(month.getFullYear())
        .setMonth(month.getMonth() + 1),
    )
    .setProjectId(projectId);

  // send request
  const req = appService.queryStateAggregate(reqBody);

  // send an update
  yield { state: "IN_PROGRESS" };

  // wait for the response
  let resp: QueryStateAggregateResponse;
  try {
    resp = await req;
  } catch (e) {
    console.error(e);
    return { state: "ERROR", message: (e as object)?.toString() };
  }

  // map protobuf response to something sane, damn that js codegen sucks.
  const results = new Map<DateAsStr, number>();
  let maxCount = 0;
  for (const r of resp.getCountsList()) {
    const _d = r.getDate();
    if (!_d) {
      const e = new Error("DeviceStateCounts send with a null date!");
      console.error(e, resp.getCountsList());
      return { state: "ERROR", message: e.toString() };
    }
    const c = r.getCount();
    const d = new Date(_d.getYear(), _d.getMonth() - 1, _d.getDay());
    results.set(DateAsStr(d), r.getCount());
    if (c > maxCount) {
      maxCount = c;
    }
  }

  // done!
  return { state: "COMPLETE", value: { counts: results, max: maxCount } };
}

type CountsPullData = { counts: Map<DateAsStr, number>; max: number };
type CountsPullState =
  | { state: "REQUESTED"; date: Date }
  | { state: "INITIAL" }
  | { state: "IN_PROGRESS" }
  | { state: "COMPLETE"; value: CountsPullData }
  | { state: "ERROR"; message?: string };

type MonthlyCountsPullStates = {
  s: Map<MonthAsStr, CountsPullState>;
  tz: string;
};
namespace MonthlyCountsPullStates {
  type This = MonthlyCountsPullStates;

  export function New({ tz }: { tz: string }): This {
    return {
      s: new Map(),
      tz: tz,
    };
  }
  export type Action =
    | {
        type: "PULLSTATE";
        monthStr: MonthAsStr;
        monthState: CountsPullState;
        tz: string;
      }
    | { type: "TZ"; tz: string };

  export function reducer(state: This, action: Action): This {
    if (action.type == "TZ") {
      if (action.tz != state.tz) {
        return New({ tz: action.tz });
      } else {
        return state;
      }
    }

    const { monthStr, monthState, tz } = action;
    // timezone has been changed since request dispatched, drop it
    if (tz != state.tz) {
      return state;
    }

    if (monthState.state == "REQUESTED") {
      const existing = state.s.get(monthStr);
      if (existing) {
        // bail
        return state;
      }
    }

    const newState = new Map(state.s);
    newState.set(monthStr, monthState);
    return { ...state, s: newState };
  }
}

type _ProviderProps = {
  projectId: string;
} & Omit<ComponentProps<typeof DayCountsContext.Provider>, "value">;

const _Provider: FC<_ProviderProps> = function _DayCountsProvider({
  projectId,
  children,
}) {
  // It's very important that this get recreated if projectId ever changed, as its state will be invalid,
  // doing that with a key= is easier than handling it ourselves, see https://stackoverflow.com/a/72914696/5264127.

  // grab the client
  const { appService } = useContext(AppServiceContext);

  const timezone = useAppSelector(selectTimezone);

  // track state of our requests to the server
  const [pullStates, dispatchPullStates] = useReducer(
    MonthlyCountsPullStates.reducer,
    { tz: timezone.ianaId },
    MonthlyCountsPullStates.New,
  );

  const processRequest = useCallback(
    async function processRequest(monthDate: Date) {
      const monthStr = MonthAsStr(monthDate);

      // dispatch request
      const puller = gimmeTheReturn(
        pullDeviceStateTotals(appService, monthDate, projectId, timezone),
      );

      // track updates
      for await (const s of puller) {
        dispatchPullStates({
          type: "PULLSTATE",
          monthStr,
          monthState: s,
          tz: timezone.ianaId,
        });
      }

      // set final result
      dispatchPullStates({
        type: "PULLSTATE",
        monthStr,
        monthState: puller.result,
        tz: timezone.ianaId,
      });
      if (puller.result.state === "COMPLETE") {
        countsDispatch({ monthStr, pullResult: puller.result.value });
      }
    },
    [timezone.ianaId],
  );

  const request = useCallback(
    async function request(d: Date) {
      const monthDate = dateFns.startOfMonth(d);
      const monthStr = MonthAsStr(d);

      // if we execute the below dispatch synchronously, then we hit
      // https://reactjs.org/link/setstate-in-render - react whinges because we
      // supposedly setState of this parent (the context) during the render of a child
      // (the day picker day). I would have thought using `dispatch` here instead
      // of `setState` should be allowed. This might be a react bug / behaviour that is
      // fixed in the future - this comment applies to React 17.0.2 .
      await new Promise((res, _rej) => window.setTimeout(res, 0));

      dispatchPullStates({
        type: "PULLSTATE",
        monthStr,
        monthState: { state: "REQUESTED", date: monthDate },
        tz: timezone.ianaId,
      });
    },
    [timezone.ianaId],
  );

  // send a request for the month containing a given date.
  // this is passed to consumers of this guy so they can us for data for a given date.
  useEffect(
    function processRequests() {
      for (const [monthStr, monthState] of pullStates.s) {
        if (monthState.state == "REQUESTED") {
          dispatchPullStates({
            type: "PULLSTATE",
            monthStr,
            monthState: { state: "INITIAL" },
            tz: pullStates.tz,
          });
          processRequest(monthState.date);
        }
      }
    },
    [pullStates], // requesting only depends on what have pulled / are pulling
  );

  // track all of the counts we have received
  const [counts, countsDispatch] = useReducer(
    DayCountsData.reducer,
    undefined,
    DayCountsData.New,
  );

  // this is our context value, we track it as a state so we only change it when deps change.
  const [contextValue, setContextValue] = useState<DayCounts>(() => ({
    request,
    data: counts,
  }));

  // we update it inside useEffect to avoid render loops...
  useEffect(
    function updateContextValue() {
      setContextValue({
        request: request,
        data: counts,
      }); // ... compute it from request,count ...
    },
    [request, counts], // ... and it gets a new identity only when they change.
  );

  // if the timezone changes, we need to reset our counts and pull states
  useEffect(() => {
    if (timezone.ianaId == pullStates.tz) {
      return;
    }
    dispatchPullStates({ type: "TZ", tz: timezone.ianaId });
    countsDispatch("RESET");
  }, [timezone.ianaId]);

  // finally, return a wrapped Provider so this can be used as a provider itself.
  return (
    <DayCountsContext.Provider value={contextValue}>
      {children}
    </DayCountsContext.Provider>
  );
};

// The actual provider we export is a wrapped version of the above, to handle
// resetting properly if projectId changes.
type ProviderProps = Omit<ComponentProps<typeof _Provider>, "projectId">;

const DayCountsProvider: FC<ProviderProps> = function DayCountsProvider(props) {
  const projectId = useAppSelector(selectProjectIdFromRoute);

  if (projectId == undefined) {
    // this is maybe problematic if you ever want to enable using sora
    // without a current project, good luck..
    return <></>;
  }

  return (
    <_Provider projectId={projectId} key={projectId} {...props}></_Provider>
  );
};

// the actual context
const DayCountsContext = createContext<DayCounts>(
  /* f off eslint */ // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!, // this is fine, provided "value" is always set in the Provider, see https://reactjs.org/docs/context.html#reactcreatecontext
);

export {
  DayCountsProvider as Provider,
  DayCountsContext as Context,
  DayCountsContext as default,
  DayCounts,
  DateAsStr,
  MonthAsStr,
};
