import { FC } from "react";
import { Icons } from "kepler.gl/components";

import { TooltipBox } from ".";
import { useAppSelector } from "../../redux/hooks";
import { DeviceStateData } from "../../layers/devices-layer";
import { selectDateFormatter } from "../../redux/selectors/settings";

type DeviceTooltipProps = {
  device: DeviceStateData;
};

export const DeviceTooltip: FC<DeviceTooltipProps> = ({
  device: { name, state },
}) => {
  const dateFormat = useAppSelector(selectDateFormatter);

  return (
    <TooltipBox title={name} icon={<Icons.Rocket />}>
      <table className="device-tooltip">
        <tbody>
          <tr>
            <td>State</td>
          </tr>
          <tr>
            <th scope="row">Latitude</th>
            <td>{state.pos.lat}</td>
          </tr>
          <tr>
            <th scope="row">Longitude</th>
            <td>{state.pos.lon}</td>
          </tr>
          <tr>
            <th scope="row">Altitude</th>
            <td>{state.pos.alt}</td>
          </tr>
          <tr>
            <th scope="row">Pitch</th>
            <td>{state.orientation?.pitch}</td>
          </tr>
          <tr>
            <th scope="row">Yaw</th>
            <td>{state.orientation?.yaw}</td>
          </tr>
          <tr>
            <th scope="row">Roll</th>
            <td>{state.orientation?.roll}</td>
          </tr>
          <tr>
            <th scope="row">Time</th>
            <td>{dateFormat.format(new Date(state.time * 1_000))}</td>
          </tr>
          {Object.entries(state.userData).map(([k, v]) => (
            <tr key={k + "-state-row"}>
              <th className="userdata" scope="row" key={k + "-state-key"}>
                {k}
              </th>
              <td key={k + "-state-val"}>{String(v)}</td>
            </tr>
          ))}
          <tr>
            <td>Annotations</td>
          </tr>
          {Object.entries(state.annotations).map(([k, v]) => (
            <tr key={k + "-annotations-row"}>
              <th className="data" scope="row" key={k + "-annotations-key"}>
                {k}
              </th>
              <td key={k + "-annotations-val"}>{String(v)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </TooltipBox>
  );
};
