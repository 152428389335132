import { UnaryOperator } from "react-awesome-query-builder";

export const hasChanged: UnaryOperator = {
  label: "has changed",
  formatOp: (field: string) => `hasChanged(${field})`,
  cardinality: 0,
  jsonLogic: (field) => ({
    // we need both the field name and the field value when
    // evaluating the hasChanged operator on the backend
    hasChanged: [field.var, field],
  }),
};

// Use this when parsing jsonLogic that is the output of the below rectifyHasChanged function
export const hasChangedForParsing: UnaryOperator = {
  label: "has changed",
  formatOp: (field: string) => `hasChanged(${field})`,
  cardinality: 0,
  jsonLogic: "hasChanged",
};

// json must have been parsed from valid JSON. Otherwise this function may have
// unexpected results
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rectifyHasChanged = (json: any): any => {
  if (json == null) {
    return null;
  }

  if (Array.isArray(json)) {
    return json.map((x) => rectifyHasChanged(x));
  }

  if (typeof json === "object") {
    return Object.fromEntries(
      Object.entries(json).map(([k, v]) => {
        if (k === "hasChanged" && Array.isArray(v) && v.length == 2) {
          return ["hasChanged", v[1]];
        }
        return [k, rectifyHasChanged(v)];
      }),
    );
  }

  return json;
};
