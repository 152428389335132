import { FC } from "react";
import { createChunkSelector } from "../../../redux/selectors/creators";
import { selectDeviceStatesSlice } from "../../../redux/selectors/devices";
import { selectEvents } from "../../../redux/selectors/events";
import { createSelector } from "reselect";
import { useAppSelector } from "../../../redux/hooks";
import * as loading from "../../../redux/reducers/loading";

// currently unused, but I want to eventually put a little spinner next to the counts.
// in an ordered enum for use with reduce(math.max).
enum LoadStates {
  LOADED = 0,
  PENDING = 1,
  ERRORED = 2,
}

// almost, but not quite, the same logic as in LoadingIndicator.. :(
function _chunkRequestState<C extends { requestState: loading.RequestState }>(
  chunk: C | undefined,
) {
  // No chunk? the whole thing is unloaded
  if (!chunk) {
    return LoadStates.PENDING;
  }
  switch (chunk.requestState.type) {
    case "succeeded":
      return LoadStates.LOADED;
    case "pending":
      return LoadStates.PENDING;
    case "errored":
      return LoadStates.ERRORED;
    default:
      return null as never;
  }
}

const dsChunkCounts = createChunkSelector(
  [selectDeviceStatesSlice],
  [],
  (chunkId, chunk) => {
    const devices = Object.values(chunk?.states.entities || {});
    return devices
      .map((dss) => dss?.states.length || 0)
      .reduce((total, count) => total + count, 0);
  },
);
const evChunkCounts = createChunkSelector(
  [selectEvents],
  [],
  (chunkId, chunk) => {
    const events = chunk?.events || [];
    return events.length;
  },
);

const dsCount = createSelector([dsChunkCounts.all], (allCounts) => {
  const counts = Object.values(allCounts.chunks);
  return counts.reduce((total = 0, count = 0) => total + count, 0);
});

const evCount = createSelector([evChunkCounts.all], (allCounts) => {
  const counts = Object.values(allCounts.chunks);
  return counts.reduce((total = 0, count = 0) => total + count, 0);
});

const nf = new Intl.NumberFormat();

export const DataCounts: FC = function DataCounts() {
  const nds = useAppSelector(dsCount) || 0;
  const nev = useAppSelector(evCount) || 0;

  return (
    <>
      <span>device states: {nf.format(nds)}</span>{" "}
      <span>events: {nf.format(nev)}</span>
    </>
  );
};
