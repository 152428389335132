import {
  createAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { Feature } from "geojson";
import { toggleModal, wrapTo } from "kepler.gl/actions";
import { ADD_DATA_ID } from "kepler.gl/constants";
import { RGBAColor } from "@deck.gl/core";

import { Layer as LayerPB } from "../../generated/sora/app/v1beta/types_pb";

import {
  colorArrayFromInt,
  colorFromUUID,
  intFromColorArray,
  stripAlpha,
} from "../../utils/color";
import { fromString as featureFromString } from "./features";
import { selectLayersSlice } from "../selectors/layers";
import { randHex } from "../../utils/rand";
import { ProjectId } from "./projects";

export type Layer = {
  id: string;
  name: string;
  color: RGBAColor;
  projectId: ProjectId;
  showConfig: boolean;
  features: Feature[];
};

export const newLayer = (
  name: string,
  features: Feature[],
  projectId: ProjectId,
): Layer => ({
  id: "",
  name,
  color: colorFromUUID(randHex()),
  projectId,
  showConfig: false,
  features,
});

export const fromPB = (l: LayerPB): Layer => ({
  id: l.getId(),
  name: l.getName(),
  color: stripAlpha(colorArrayFromInt(l.getColor())),
  projectId: l.getProjectId(),
  showConfig: false,
  features: featureFromString(l.getFeaturesJson()),
});

export const toPB = (l: Layer) =>
  new LayerPB()
    .setId(l.id)
    .setName(l.name)
    .setColor(intFromColorArray(l.color))
    .setFeaturesJson(JSON.stringify(l.features))
    .setProjectId(l.projectId);

const layerAdapter = createEntityAdapter<Layer>();
const layerSlice = createSlice({
  name: "geoLayer",
  initialState: layerAdapter.getInitialState(),
  reducers: {
    addLayers: layerAdapter.upsertMany,
    updateLayer: layerAdapter.updateOne,
    deleteLayer: layerAdapter.removeOne,
    clearLayers: layerAdapter.removeAll,
  },
});

export type UploadProgress = {
  fraction: number;
  message: string;
  fileName: string;
};

export type UploadSuccess = {
  layers: Layer[];
  fileName: string;
};

export type UploadFailed = {
  error: string;
  fileName: string;
};

export const readLayersRequested = createAction<ProjectId>(
  "layer/readRequested",
);
export const deleteLayerRequested = createAction<Layer>(
  "layer/deleteRequested",
);
export const fileUploadStarted = createAction<File[]>(
  "layer/fileUploadStarted",
);
export const fileUploadProgress = createAction<UploadProgress>(
  "layer/fileUploadProgress",
);
export const fileUploadFailed = createAction<UploadFailed>(
  "layer/fileUploadFailed",
);
export const filesUploadSucceeded = createAction("layer/filesUploadSucceeded");
export const fileUploadSucceeded = createAction<UploadSuccess>(
  "layer/fileUploadSucceeded",
);

export const uploadModalShow = wrapTo("map")(toggleModal(ADD_DATA_ID));
export const uploadModalClear = createAction<undefined>(
  "layer/uploadModalClear",
);

export const layerSelectors = layerAdapter.getSelectors(selectLayersSlice);
export const { addLayers, updateLayer, deleteLayer, clearLayers } =
  layerSlice.actions;
export default layerSlice.reducer;
