import {
  EntityId,
  PayloadAction,
  createAction,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { IntlShape } from "react-intl";
import { FormikHelpers } from "formik";
import { RGBAColor } from "@deck.gl/core";

import { Device as DevicePB } from "../../generated/sora/app/v1beta/types_pb";

import { upsertGroupedStates } from "./device-state";
import {
  colorArrayFromInt,
  colorFromUUID,
  intFromColorArray,
  randColor,
  stripAlpha,
} from "../../utils/color";
import { selectDevicesSlice } from "../selectors/devices";
import { ProjectId } from "./projects";
import {
  erroredRequestState,
  pendingRequestState,
  RequestState,
  succeededRequestState,
} from "./loading";
import { CreateDeviceFormValues } from "../../components/devices/CreateDevice";

export type Device = {
  id: string;
  name: string;
  color: RGBAColor;
  projectIds: string[];
  showConfig: boolean;
};

export type CreateDeviceRequest = {
  createDeviceFormValues: CreateDeviceFormValues;
  auth0State: string;
  helpers: FormikHelpers<CreateDeviceFormValues>;
  intl: IntlShape;
  onSuccessNavigateTo: (_location: URL) => void;
};

export const initialDevice = () => ({
  id: "",
  name: "",
  color: randColor(),
  projectId: "",
  showConfig: false,
});

const dummyDeviceFromState = (id: string): Device => ({
  id,
  name: `Device ${id.slice(0, 6)}`,
  color: colorFromUUID(id),
  projectIds: [],
  showConfig: false,
});

export const toPB = (d: Device): DevicePB =>
  new DevicePB()
    .setId(d.id)
    .setName(d.name)
    .setColor(intFromColorArray(d.color))
    .setProjectIdList(d.projectIds);

export const fromPB = (d: DevicePB): Device => ({
  id: d.getId(),
  name: d.getName(),
  color: stripAlpha(colorArrayFromInt(d.getColor())),
  projectIds: d.getProjectIdList(),
  showConfig: false,
});

export type DeviceVisibility = {
  id: EntityId;
  visible: boolean;
};

export type DeviceColor = {
  id: EntityId;
  color: RGBAColor;
};

const devicesAdapter = createEntityAdapter<Device>();

type DevicesState = EntityState<Device> & { readAll: RequestState };

const devicesSlice = createSlice({
  name: "devices",
  initialState: devicesAdapter.getInitialState({
    readAll: pendingRequestState(),
  }) as DevicesState,
  reducers: {
    setDeviceColor: (state: DevicesState, action: PayloadAction<DeviceColor>) =>
      devicesAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          color: action.payload.color,
        },
      }),

    toggleDeviceConfig: (
      state: DevicesState,
      action: PayloadAction<EntityId>,
    ) =>
      devicesAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          showConfig: !(state.entities[action.payload] as Device).showConfig,
        },
      }),
    addDevices: devicesAdapter.upsertMany,
    clearDevices: devicesAdapter.removeAll,
    setReadDevicesErrored: (state, action) => {
      state.readAll = erroredRequestState(action.payload);
    },
    setReadDevicesPending: (state) => {
      state.readAll = pendingRequestState();
    },
    setReadDevicesSucceeded: (state) => {
      state.readAll = succeededRequestState();
    },
  },
  extraReducers: {
    [upsertGroupedStates.toString()]: (
      state,
      action: ReturnType<typeof upsertGroupedStates>,
    ) =>
      devicesAdapter.upsertMany(
        state,
        Object.keys(action.payload.states).flatMap((id) =>
          state.entities[id] ? [] : [dummyDeviceFromState(id)],
        ),
      ),
  },
});

export const centerDevice = createAction<Device>("devices/centerDevice");
export const createDeviceRequested = createAction<CreateDeviceRequest>(
  "devices/createRequested",
);
export const readDevicesRequested = createAction<ProjectId>(
  "devices/readRequested",
);

export const devicesSelector = devicesAdapter.getSelectors(selectDevicesSlice);
export const {
  addDevices,
  clearDevices,
  setDeviceColor,
  toggleDeviceConfig,
  setReadDevicesErrored,
  setReadDevicesPending,
  setReadDevicesSucceeded,
} = devicesSlice.actions;
export default devicesSlice.reducer;
