import {
  Action,
  createAction,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { MessageDescriptor } from "react-intl";

export type ConfirmVariant = "delete";

export type ConfirmRoot =
  | {
      visible: false;
    }
  | {
      visible: true;
      variant: ConfirmVariant;
      description: MessageDescriptor;
    };

const initialState: ConfirmRoot = Object.freeze({
  visible: false,
});

/**
 * Confirm modal system.
 *
 * The logic for this flow is in the `confirm` saga. The `startConfirm`
 * action is the only "public" action you should be dispatching. All others
 * are considered internal details between the saga and the store.
 * @module reducers/ui/confirm
 */

const confirmSlice = createSlice({
  name: "ui/confirm",
  initialState: initialState as ConfirmRoot,
  reducers: {
    _showDialog: (_state, { payload: p }: PayloadAction<ShowDialog>) => {
      return {
        visible: true,
        ...p,
      };
    },
    _hideDialog: (_state, _action: PayloadAction<void>) => {
      return initialState;
    },
  },
});

export type StartConfirm<A = unknown> = {
  onAccept: Action<A>;
  variant: ConfirmVariant;
  description: MessageDescriptor;
};

/**
 * This allows you to pop up a confirm modal dialog.
 * To pop up the dialog, dispatch
 *
 *     startConfirm({
 *      description: 'Are you sure you want to foo dangerously?
 *      onAccept: dangerousFooAction({id: myFoo})
 *     })
 *
 * from your code. This will pop up an OK/Cancel box with the message.
 * If the user clicks 'OK', `dangerousFooAction` will be
 * `dispatch`ed. If the user clicks 'Cancel', nothing will happen.
 *
 * Props is passed through so you can give a warning color on the OK button.
 * You can probably abuse it to do much more than this, please don't.
 */
export const startConfirm =
  createAction<StartConfirm<unknown>>("confirm/start");

export type ShowDialog = {
  variant: ConfirmVariant;
  description: MessageDescriptor;
};

export type DialogDone = {
  result: "OK" | "cancelled";
};
export const dialogDone = createAction<DialogDone>("confirm/_done");

export const { _showDialog, _hideDialog } = confirmSlice.actions;

export default confirmSlice;
