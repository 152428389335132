import { useEffectOnce } from "usehooks-ts";
import { Link } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";

import {
  APP_NAME,
  SORA_API_URL,
  SORA_FEEDBACK_CHANNEL,
  SORA_SUPPORT_URL,
  PORTAL_DOMAIN,
} from "../constants";

import Logout from "../components/Logout";
import { Modals } from "../components/modals";
import { Confirm } from "../components/modals/confirm";
import styled from "styled-components";

import {
  Code,
  ManageAccounts,
  MenuBook,
  RateReview,
} from "@mui/icons-material";
import { useContext } from "react";
import { AppServiceContext } from "../api/appService";

const drawerWidth = 240;

const BottomAligned = styled.div`
  margin-top: auto;
`;

const StyledLink = styled.div`
  width: 100%;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const App = () => {
  const location = useLocation();
  const { subdomain } = useContext(AppServiceContext);
  const portalUrl = subdomain
    ? `https://${subdomain}.${PORTAL_DOMAIN}/settings`
    : undefined;

  useEffectOnce(() => {
    document.title = APP_NAME;
  });

  return (
    <>
      <Modals />
      <Confirm />
      {location.pathname.includes("dashboard") ? (
        <Outlet />
      ) : (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Toolbar />
            <Divider />
            <List>
              <ListItem disablePadding>
                <StyledLink>
                  <Link href="/projects">
                    <ListItemButton>
                      <ListItemIcon>
                        <CollectionsBookmarkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Projects" />
                    </ListItemButton>
                  </Link>
                </StyledLink>
              </ListItem>
              <ListItem disablePadding>
                <StyledLink>
                  <Link
                    {...(portalUrl && { href: portalUrl })}
                    target="_blank"
                    title="Account Settings"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <ManageAccounts></ManageAccounts>
                      </ListItemIcon>
                      <ListItemText primary="Account" />
                    </ListItemButton>
                  </Link>
                </StyledLink>
              </ListItem>
              <ListItem disablePadding>
                <StyledLink>
                  <Link
                    href={`mailto:${SORA_FEEDBACK_CHANNEL}`}
                    target="_blank"
                    title="Give us your feedback and help us improve!"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <RateReview></RateReview>
                      </ListItemIcon>
                      <ListItemText primary="Feedback" />
                    </ListItemButton>
                  </Link>
                </StyledLink>
              </ListItem>
              <ListItem disablePadding>
                <StyledLink>
                  <Link href={SORA_API_URL} target="_blank">
                    <ListItemButton>
                      <ListItemIcon>
                        <Code></Code>
                      </ListItemIcon>
                      <ListItemText primary="API Reference" />
                    </ListItemButton>
                  </Link>
                </StyledLink>
              </ListItem>
              <ListItem disablePadding>
                <StyledLink>
                  <Link href={SORA_SUPPORT_URL} target="_blank">
                    <ListItemButton>
                      <ListItemIcon>
                        <MenuBook></MenuBook>
                      </ListItemIcon>
                      <ListItemText primary="Documentation" />
                    </ListItemButton>
                  </Link>
                </StyledLink>
              </ListItem>
            </List>
            <Divider />
            <BottomAligned>
              <Logout />
            </BottomAligned>
          </Drawer>
          <Outlet />
        </Box>
      )}
    </>
  );
};
