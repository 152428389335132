import {
  all,
  call,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  ReadAnnotatorsRequest,
  ReadAnnotatorsResponse,
  UpdateAnnotatorRequest,
  DeleteAnnotatorRequest,
  CreateAnnotatorRequest,
  CreateAnnotatorResponse,
} from "../../generated/sora/app/v1beta/service_pb";

import {
  Annotator,
  addAnnotators,
  annotatorSelectors,
  createAnnotatorRequested,
  deleteAnnotatorRequested,
  fromPB,
  readAnnotatorsRequested,
  toPB,
  updateAnnotator,
  deleteAnnotator,
} from "../reducers/annotator";
import { UPDATE_DEBOUNCE_MS } from "../../constants";
import { callConfig } from "../../api/callConfig";

function* readAnnotatorsSaga(
  action: ReturnType<typeof readAnnotatorsRequested>,
) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const readAnnotatorsRequest = new ReadAnnotatorsRequest().setProjectId(
    action.payload,
  );
  let readAnnotatorsResponse: ReadAnnotatorsResponse;
  try {
    readAnnotatorsResponse = yield call(
      (req) => appService.readAnnotators(req),
      readAnnotatorsRequest,
    );
  } catch (e) {
    console.error(e);
    return;
  }

  const annotators = readAnnotatorsResponse
    .getAnnotatorsList()
    .map((l) => fromPB(l));

  yield put(addAnnotators(annotators));
}

function* createAnnotatorSaga(
  action: ReturnType<typeof createAnnotatorRequested>,
) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const createAnnotatorRequest = new CreateAnnotatorRequest().setAnnotator(
    toPB(action.payload),
  );
  let createAnnotatorResponse: CreateAnnotatorResponse;
  try {
    createAnnotatorResponse = yield call(
      (req) => appService.createAnnotator(req),
      createAnnotatorRequest,
    );
  } catch (e) {
    console.error(e);
    return;
  }

  const maybeAnnotator = createAnnotatorResponse.getAnnotator();
  if (maybeAnnotator) {
    yield put(addAnnotators([fromPB(maybeAnnotator)]));
  }
}

function* updateAnnotatorSaga(action: ReturnType<typeof updateAnnotator>) {
  if (
    action.payload.changes.name ||
    action.payload.changes.type ||
    action.payload.changes.color ||
    action.payload.changes.enabled ||
    action.payload.changes.config
  ) {
    const appService = callConfig.call.appServiceContext?.appService;
    if (!appService) {
      return;
    }

    const annotator: Annotator = yield select(
      annotatorSelectors.selectById,
      action.payload.id.toString(),
    );

    const updateAnnotatorRequest = new UpdateAnnotatorRequest().setAnnotator(
      toPB(annotator),
    );

    try {
      yield call(
        (req) => appService.updateAnnotator(req),
        updateAnnotatorRequest,
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }
}

function* deleteAnnotatorSaga(
  action: ReturnType<typeof deleteAnnotatorRequested>,
) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const deleteAnnotatorRequest = new DeleteAnnotatorRequest()
    .setId(action.payload.id)
    .setProjectId(action.payload.projectId);
  try {
    yield call(
      (req) => appService.deleteAnnotator(req),
      deleteAnnotatorRequest,
    );
  } catch (e) {
    console.error(e);
    return;
  }

  yield put(deleteAnnotator(action.payload.id));
}

export default function* annotatorsSaga() {
  yield all([
    takeEvery(createAnnotatorRequested, createAnnotatorSaga),
    debounce(UPDATE_DEBOUNCE_MS, updateAnnotator, updateAnnotatorSaga),
    takeEvery(deleteAnnotatorRequested, deleteAnnotatorSaga),
    takeLatest(readAnnotatorsRequested, readAnnotatorsSaga),
  ]);
}
