import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Icons, SidePanelSection, Tooltip } from "kepler.gl/components";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { SidePanelRow } from "../SidePanelRow";
import { SidePanelIcons, IconButton, EvenlySpaced } from "../../common";
import {
  EVENT_LOG_MODAL,
  newEventGeneratorModal,
  showModal,
} from "../../../redux/reducers/ui/modal";
import {
  EventGenerator,
  eventGeneratorSelectors,
  NO_EVENT_GENERATOR,
  updateEventGenerator,
} from "../../../redux/reducers/event-generators";
import * as eventSelectors from "../../../redux/selectors/events";
import { EventGeneratorPanelHeader } from "./EventGeneratorPanelHeader";
import { EventGeneratorConfigurator } from "./EventGeneratorConfigurator";
import {
  EntityType,
  updateEntityVisibility,
} from "../../../redux/reducers/settings";
import { selectVisible } from "../../../redux/selectors/settings";

export default () => {
  const dispatch = useAppDispatch();
  const eventGenerators = useAppSelector(eventGeneratorSelectors.selectAll);
  const visibilities: { [key: string]: boolean } = useAppSelector(
    selectVisible(EntityType.EventGenerator),
  );
  const eventCounts = useAppSelector(eventSelectors.selectEventCounts);

  const onSetColor = useCallback(
    (id, color) => dispatch(updateEventGenerator({ id, changes: { color } })),
    [dispatch],
  );

  return (
    <>
      <SidePanelSection>
        <SidePanelIcons>
          <IconButton>
            <Icons.EyeUnseen
              data-tip
              data-for="eventGenerator.hideAll"
              onClick={() =>
                dispatch(
                  updateEntityVisibility({
                    entityType: EntityType.EventGenerator,
                    updateAll: true,
                    value: false,
                  }),
                )
              }
              height="26"
            />
            <Tooltip id="eventGenerator.hideAll" effect="solid" delayShow={500}>
              <FormattedMessage id="eventGenerator.hideAll" />
            </Tooltip>
          </IconButton>
          <IconButton>
            <Icons.EyeSeen
              data-tip
              data-for="eventGenerator.showAll"
              onClick={() =>
                dispatch(
                  updateEntityVisibility({
                    entityType: EntityType.EventGenerator,
                    updateAll: true,
                    value: true,
                  }),
                )
              }
              height="26"
            />
            <Tooltip id="eventGenerator.showAll" effect="solid" delayShow={500}>
              <FormattedMessage id="eventGenerator.showAll" />
            </Tooltip>
          </IconButton>
        </SidePanelIcons>
      </SidePanelSection>
      <SidePanelSection>
        <EvenlySpaced>
          <Button
            tabIndex={-1}
            width="150px"
            onClick={() => dispatch(showModal(newEventGeneratorModal()))}
          >
            <Icons.Add height="12px" />
            <FormattedMessage id={"eventGenerator.manager.addEventGenerator"} />
          </Button>
          <Button
            secondary
            tabIndex={-1}
            width="80px"
            onClick={() => dispatch(showModal(EVENT_LOG_MODAL))}
          >
            <FormattedMessage id={"eventGenerator.manager.viewLog"} />
          </Button>
        </EvenlySpaced>
      </SidePanelSection>
      <SidePanelSection>
        {eventGenerators.map((eventGenerator) => {
          let maybeConfigurator: JSX.Element | null;
          if (eventGenerator.id === NO_EVENT_GENERATOR) {
            if ((eventCounts.get(NO_EVENT_GENERATOR) || 0) == 0) {
              return null;
            }
            maybeConfigurator = null;
          } else {
            maybeConfigurator = (
              <EventGeneratorConfigurator
                model={eventGenerator as EventGenerator}
              />
            );
          }

          return (
            <SidePanelRow
              key={eventGenerator.id.toString()}
              model={eventGenerator}
              visible={visibilities[eventGenerator.id] ?? true}
              onSetColor={onSetColor}
              Header={EventGeneratorPanelHeader}
            >
              {maybeConfigurator}
            </SidePanelRow>
          );
        })}
      </SidePanelSection>
    </>
  );
};
