// in a route such as /projects/:projectId/dashboard, returns :projectId
export const findIdInRoute = (
  entityPluralName: string,
  pathname?: string,
): string | undefined => {
  if (!pathname) {
    return undefined;
  }
  const pathComponents = pathname.split("/");
  const idIdx = pathComponents.findIndex((s) => s === entityPluralName);
  if (idIdx < 0) {
    return undefined;
  }
  const maybeId = pathComponents[idIdx + 1];
  if (!maybeId) {
    return undefined;
  }
  if (maybeId.length == 0) {
    return undefined;
  }
  return maybeId;
};
