import { ChangeEvent, FC, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IconButton, FormControl, MenuItem, TextField } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  EventGenerator,
  updateEventGenerator,
} from "../../../redux/reducers/event-generators";
import { EvenlySpaced, FieldName, FieldValue } from "../../common";
import {
  selectDeviceStateCustomFields,
  selectAnnotatorFields,
} from "../../../redux/selectors/fields";
import {
  buildConfig,
  eventGeneratorToTree,
  humanReadableRule,
} from "../../modals/event-generator-builder/expression";
import {
  newEventGeneratorModal,
  showModal,
} from "../../../redux/reducers/ui/modal";
import { alertorSelectors } from "../../../redux/reducers/alertor";

type EventGeneratorConfiguratorProps = {
  model: EventGenerator;
};

export const EventGeneratorConfigurator: FC<
  EventGeneratorConfiguratorProps
> = ({ model }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const aFields = useAppSelector(selectAnnotatorFields);
  const dsFields = useAppSelector(selectDeviceStateCustomFields);
  const config = useMemo(
    () => buildConfig({ ...aFields, ...dsFields }),
    [aFields, dsFields],
  );
  const tree = useMemo(
    () => eventGeneratorToTree(model.expression, config),
    [model?.expression, config],
  );

  const alertors = useAppSelector(alertorSelectors.selectAll);

  const onSelectAlertorID = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch(
      updateEventGenerator({
        id: model.id,
        changes: { alertorId: e.target.value },
      }),
    );

  return (
    <FormControl sx={{ ml: 0, mt: 2, minWidth: 120 }} size="small">
      <FieldName>
        <FormattedMessage id="eventGenerator.config.id" />
      </FieldName>
      <FieldValue>{model.id}</FieldValue>
      <EvenlySpaced>
        <FieldName>
          <FormattedMessage id="eventGenerator.config.rule" />
        </FieldName>
        <IconButton
          onClick={() => dispatch(showModal(newEventGeneratorModal(model)))}
          disableRipple
          disableFocusRipple
          sx={{
            padding: "0px",
            color: "#6a7485",
            "&:hover": {
              color: "white",
            },
          }}
        >
          <ModeEditIcon sx={{ fontSize: "18px" }} />
        </IconButton>
      </EvenlySpaced>
      <FieldValue>{humanReadableRule(tree, config)}</FieldValue>
      <TextField
        id="alert-select"
        select
        value={model.alertorId || ""}
        label={intl.formatMessage({ id: "eventGenerator.config.alert" })}
        onChange={onSelectAlertorID}
        sx={{ marginTop: "1em" }}
      >
        <MenuItem value="">
          <FormattedMessage id="eventGenerator.config.noAlertorId" />
        </MenuItem>
        {alertors.map((alertor) => (
          <MenuItem key={alertor.id} value={alertor.id}>
            {alertor.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
