import { all, put, select, takeLatest } from "redux-saga/effects";
import {
  SelectionsRoot,
  selectTimeSeriesRangeSelection,
  setTimeSeriesRangeSelectionDS,
  setTimeSeriesRangeSelectionEv,
} from "../reducers/time-series-range-selection";
import {
  queryDeviceStateRequested,
  queryDeviceStateSucceeded,
} from "../reducers/device-state";
import {
  setSlider,
  setSliderAndSelectionDate,
  TimeControlState,
  zoomSlider,
} from "../reducers/time-control";
import { selectProjectIdFromRoute } from "../selectors/router";
import { selectTimeControl } from "../selectors/time-control";
import { fromUnixTime } from "date-fns";
import { queryEventsRequested, queryEventsSucceeded } from "../reducers/events";

function* setSliderSaga() {
  const projectId: string | undefined = yield select(selectProjectIdFromRoute);
  if (!projectId) {
    return;
  }

  const currentRangeSelection = (yield select(
    selectTimeSeriesRangeSelection,
  )) as SelectionsRoot;

  // assumption: the action that triggered this has already updated the state, so
  // we can read from the state, not the action's payload
  const tc: TimeControlState = yield select(selectTimeControl);

  const start = currentRangeSelection.ds?.time.startIncl;
  const end = currentRangeSelection.ds?.time.endIncl;

  const sliderStart = fromUnixTime(tc.slider.end - tc.slider.length);
  const sliderEnd = fromUnixTime(tc.slider.end);

  if (
    start === undefined ||
    sliderStart < start ||
    end === undefined ||
    end < sliderEnd
  ) {
    const newRangeSelection = {
      start: fromUnixTime(tc.slider.end - tc.slider.length),
      end: fromUnixTime(tc.slider.end),
    };
    yield put(
      queryDeviceStateRequested({
        projectId,
        range: newRangeSelection,
      }),
    );
    yield put(
      queryEventsRequested({
        projectId,
        range: newRangeSelection,
      }),
    );
  }
}

function* updateSelectionsDS(
  action: ReturnType<typeof queryDeviceStateSucceeded>,
) {
  const { start, end } = action.payload.range;
  yield put(
    setTimeSeriesRangeSelectionDS({
      time: { startIncl: start, endIncl: end },
      cursor: null,
    }),
  );
}

function* updateSelectionsEv(action: ReturnType<typeof queryEventsSucceeded>) {
  const { start, end } = action.payload.range;
  yield put(
    setTimeSeriesRangeSelectionEv({
      time: { startIncl: start, endIncl: end },
      cursor: null,
    }),
  );
}

export default function* () {
  yield all([
    // currently, this needs to piggy back off every action that modifies the slider range. That's not great...
    takeLatest(
      [setSlider, setSliderAndSelectionDate, zoomSlider],
      setSliderSaga,
    ),
    takeLatest(queryDeviceStateSucceeded, updateSelectionsDS),
    takeLatest(queryEventsSucceeded, updateSelectionsEv),
  ]);
}
