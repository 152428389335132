import { FC } from "react";
import styled from "styled-components";
import { media } from "kepler.gl/styles";
import { DIMENSIONS } from "kepler.gl/constants";

import TimeControl from "./time-control/TimeControl";
import TimeControlPopup from "./time-control/TimeControlPopup";
import { useAppSelector } from "../../redux/hooks";
import { DataCounts } from "./time-control/DataCounts";

type BottomPanelOuterProps = {
  absolute: boolean;
};

export const BottomPanelOuter = styled.div<BottomPanelOuterProps>`
  ${({ absolute }) =>
    absolute ? "position: absolute; bottom: 0; right: 0;" : ""}

  /* this is the histogram hover in/out - it's here because this is the only hover
     target big enough and i will go run naked on hoddle st before implementing hover
     transitions in js */
  & .kg-range-slider__plot {
    opacity: 0;
    transition: opacity 0.2s;
  }
  &:hover .kg-range-slider__plot,
  & .kg-range-slider__plot:hover {
    opacity: ${({ theme }) => theme.histogramOpacity};
  }
`;

type BottomPanelContainerProps = {
  hasPadding: boolean;
  sidePanelOpen: boolean;
  panelWidth: number;
};

const BottomPanelContainer = styled.div<BottomPanelContainerProps>`
  padding-top: ${({ theme, hasPadding }) =>
    hasPadding ? theme.bottomWidgetPaddingTop : 0}px;
  padding-right: ${({ theme, hasPadding }) =>
    hasPadding ? theme.bottomWidgetPaddingRight : 0}px;
  padding-bottom: ${({ theme, hasPadding }) =>
    hasPadding ? theme.bottomWidgetPaddingBottom : 0}px;
  padding-left: ${({ theme, hasPadding }) =>
    hasPadding ? theme.bottomWidgetPaddingLeft : 0}px;
  width: ${({ theme, panelWidth, sidePanelOpen }) =>
    panelWidth - (sidePanelOpen ? theme.sidePanel.margin.left : 0)}px;
  z-index: 1;
  ${media.portable`padding: 0;`}
`;

export const BottomPanelInner = styled.div`
  background-color: ${({ theme }) => theme.bottomWidgetBgd};
  padding: ${({ theme }) =>
    `${theme.bottomInnerPdVert}px ${theme.bottomInnerPdVert}px`};
  position: relative;
  color: ${({ theme }) => theme.labelColor};
  box-shadow: ${({ theme }) => theme.boxShadow};
  pointer-events: all;

  svg {
    vertical-align: middle;
  }
`;

export const BottomPanelPopup = styled.div`
  padding: ${({ theme }) =>
    `${theme.bottomInnerPdVert}px ${theme.bottomInnerPdVert}px`};
  position: relative;
  margin-top: ${({ theme }) => theme.bottomPanelGap}px;
  color: ${({ theme }) => theme.labelColor};
  box-shadow: ${({ theme }) => theme.boxShadow};
  pointer-events: none !important; /* prevent padding from blocking input */
  & > * {
    /* all children should allow input */
    pointer-events: all;
  }

  svg {
    vertical-align: middle;
  }
`;

export const BottomPanelPopunder = styled.div`
  position: absolute;
  height: 14px;
  margin-top: calc((${({ theme }) => theme.bottomPanelGap}px - 14px) / 2);
  background-color: ${({ theme }) => theme.bottomWidgetBgd};
  font-size: 9px;
  color: ${({ theme }) => theme.labelColor};
`;

type BottomPanelProps = {
  width: number;
};

export const BottomPanel: FC<BottomPanelProps> = ({ width }) => {
  const sidePanelOpen = useAppSelector(
    (state) => !!state.keplerGl?.map?.uiState?.activeSidePanel,
  );

  return (
    <BottomPanelOuter absolute>
      <BottomPanelContainer
        panelWidth={width - (sidePanelOpen ? DIMENSIONS.sidePanel.width : 0)}
        sidePanelOpen={sidePanelOpen}
        hasPadding
      >
        <BottomPanelPopup>
          <TimeControlPopup />
        </BottomPanelPopup>
        <BottomPanelInner>
          <TimeControl />
        </BottomPanelInner>
        <BottomPanelPopunder>
          <DataCounts />
        </BottomPanelPopunder>
      </BottomPanelContainer>
    </BottomPanelOuter>
  );
};
