import {
  createAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RGBAColor } from "@deck.gl/core";
import {
  JavaScriptValue,
  Struct,
} from "google-protobuf/google/protobuf/struct_pb";

import { Annotator as AnnotatorPB } from "../../generated/sora/app/v1beta/types_pb";

import {
  colorArrayFromInt,
  intFromColorArray,
  randColor,
  stripAlpha,
} from "../../utils/color";
import { selectAnnotatorsSlice } from "../selectors/annotator";
import { ProjectId } from "./projects";
import { JSONType, TypeObject } from "../../utils/fields";

export const GEOFENCE_ANNOTATION_TYPE = "sora.geofence.v1";
export const MAP_MATCH_ANNOTATION_TYPE = "sora.mapMatch.v1";

export type AnnotatorType = keyof _AnnotatorTypes;
export type UnknownAnnotatorType = Omit<string, AnnotatorType>;

type _Annotator<AT extends AnnotatorType | UnknownAnnotatorType> = {
  id: string;
  name: string;
  type: AT;
  enabled: boolean;
  color: RGBAColor;
  projectId: ProjectId;
  config: AT extends AnnotatorType
    ? AnnotatorConfig<AT>
    : Record<string, JavaScriptValue>;
  showConfig: boolean;
};

type MapMatchConfig = {
  annotationPrefix?: string;
  mapMatchProperties?: Record<string | number | symbol, JSONType>;
  layerId?: string;
};
type GeoFenceConfig = {
  annotationKeyName?: string;
  layerId?: string;
};

type _AnnotatorTypes = {
  [GEOFENCE_ANNOTATION_TYPE]: {
    config: GeoFenceConfig;
  };
  [MAP_MATCH_ANNOTATION_TYPE]: {
    config: MapMatchConfig;
  };
};
type AnnotatorConfig<AT extends AnnotatorType> = _AnnotatorTypes[AT]["config"];

export type MapMatchAnnotator = _Annotator<typeof MAP_MATCH_ANNOTATION_TYPE>;
export type GeoFenceAnnotator = _Annotator<typeof GEOFENCE_ANNOTATION_TYPE>;
export type UnknownAnnotator = _Annotator<UnknownAnnotatorType>;

export type Annotator = MapMatchAnnotator | GeoFenceAnnotator;

export const fromPB = (a: AnnotatorPB): Annotator => ({
  id: a.getId(),
  name: a.getName(),
  // TODO: handle invalid data
  type: a.getType() as AnnotatorType,
  color: stripAlpha(colorArrayFromInt(a.getColor())),
  projectId: a.getProjectId(),
  enabled: a.getEnabled(),
  config: a.getConfig()?.toJavaScript() || {},
  showConfig: false,
});

export const newAnnotator = <AT extends AnnotatorType>(
  name = "",
  type: AT,
  config: AnnotatorConfig<AT>,
  projectId: ProjectId,
): _Annotator<AT> =>
  ({
    id: "",
    name,
    type,
    color: randColor(),
    projectId,
    enabled: true,
    config,
    showConfig: false,
  } as _Annotator<AT>);

export const toPB = (a: Annotator) =>
  new AnnotatorPB()
    .setId(a.id)
    .setName(a.name)
    .setType(a.type as string)
    .setColor(intFromColorArray(a.color))
    .setEnabled(a.enabled)
    .setConfig(Struct.fromJavaScript(a.config))
    .setProjectId(a.projectId);

const annotatorAdapter = createEntityAdapter<Annotator>();
const annotatorSlice = createSlice({
  name: "annotators",
  initialState: annotatorAdapter.getInitialState(),
  reducers: {
    addAnnotators: annotatorAdapter.upsertMany,
    updateAnnotator: annotatorAdapter.updateOne,
    deleteAnnotator: annotatorAdapter.removeOne,
    clearAnnotators: annotatorAdapter.removeAll,
  },
});

export const annotationKeys = (annotator: Annotator): TypeObject => {
  switch (annotator.type) {
    case GEOFENCE_ANNOTATION_TYPE: {
      const annotationKey = annotator.config?.annotationKeyName;
      if (annotationKey) {
        return { [annotationKey]: "boolean" };
      }
      break;
    }
    case MAP_MATCH_ANNOTATION_TYPE: {
      const prefix = annotator.config?.annotationPrefix;
      const properties = annotator.config?.mapMatchProperties;
      if (!properties || !prefix) {
        return {};
      }

      const fields: TypeObject = {};
      for (const k in properties) {
        const v = properties[k];
        fields[`${prefix}${k}`] = v;
      }
      return fields;
    }
  }
  return {};
};

export const readAnnotatorsRequested = createAction<ProjectId>(
  "annotators/readRequested",
);
export const createAnnotatorRequested = createAction<Annotator>(
  "annotators/createRequested",
);
export const deleteAnnotatorRequested = createAction<Annotator>(
  "annotators/deleteRequest",
);

export const annotatorSelectors = annotatorAdapter.getSelectors(
  selectAnnotatorsSlice,
);
export const {
  addAnnotators,
  updateAnnotator,
  deleteAnnotator,
  clearAnnotators,
} = annotatorSlice.actions;
export default annotatorSlice.reducer;
