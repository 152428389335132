import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultTimezone, Timezone } from "../../constants/Timezones";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

export enum EntityType {
  Layer = "Layer",
  Device = "Device",
  EventGenerator = "EventGenerator",
}

export type ProjectConfig = {
  lastUpdatedAt: number; // Unix timestamp in seconds
  [EntityType.Device]: {
    [key: string]: boolean;
  };
  [EntityType.Layer]: {
    [key: string]: boolean;
  };
  [EntityType.EventGenerator]: {
    [key: string]: boolean;
  };
};

export type ProjectConfigMap = {
  [projectId: string]: ProjectConfig;
};

export type UserPreferences = {
  timezone: Timezone;
  projects: ProjectConfigMap;
};

export type VisbilityUpdateRequest = {
  entityType: EntityType;
  entityId?: string;
  updateAll?: boolean;
  value: boolean;
};

export type Config = {
  [key: string]: boolean;
};

export type VisibilitySetRequest = {
  projectId: string;
  entityType: EntityType;
  config: Config;
  lastUpdatedAt: number;
};

const initialState: UserPreferences = {
  timezone: defaultTimezone,
  projects: {},
};

export type Projects = string[];

const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setTimezone: (state, action: PayloadAction<Timezone>) => {
      state.timezone = action.payload;
    },
    setEntityVisibility: (
      state: UserPreferences,
      action: PayloadAction<VisibilitySetRequest>,
    ) => {
      const { projectId, config, entityType, lastUpdatedAt } = action.payload;
      const { projects } = state;

      const projectConfig = projects[projectId];
      const entityConfig = projectConfig ? projectConfig[entityType] : {};

      return {
        ...state,
        projects: {
          ...projects,
          [projectId]: {
            ...projects[projectId],
            lastUpdatedAt,
            [entityType]: {
              ...entityConfig,
              ...config,
            },
          },
        },
      };
    },
    removeExpiredProjects: (
      state: UserPreferences,
      action: PayloadAction<Projects>,
    ) => {
      const expired = action.payload;
      const projects = { ...state.projects };
      for (const projectId of expired) {
        delete projects[projectId];
      }
      return {
        ...state,
        projects,
      };
    },
  },
});

export const updateEntityVisibility = createAction<VisbilityUpdateRequest>(
  "updateEntityVisibility",
);

export const { setTimezone, setEntityVisibility, removeExpiredProjects } =
  settings.actions;

const persistedReducer = persistReducer(persistConfig, settings.reducer);
export default persistedReducer;
