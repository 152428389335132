import { FC, ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PickInfo } from "@deck.gl/core/lib/deck";
import { Feature } from "geojson";

import { DeviceStateData } from "../../layers/devices-layer";
import { Event } from "../../redux/reducers/events";
import { LayerTooltip } from "./LayerTooltip";
import { DeviceTooltip } from "./DeviceTooltip";
import { EventTooltip } from "./EventTooltip";

export const TooltipHeader = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.6em;
`;

type TooltipBoxProps = {
  title: string;
  icon: ReactNode;
  children?: ReactNode;
};

export const TooltipBox: FC<TooltipBoxProps> = ({ title, icon, children }) => (
  <>
    <TooltipHeader>
      {icon} {title}
    </TooltipHeader>
    {children}
  </>
);

type TooltipBoxStyleProps = {
  x: number;
  y: number;
};

const TooltipBoxStyle = styled.div<TooltipBoxStyleProps>`
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  left: ${({ x }) => `${x}px`};
  top: ${({ y }) => `${y}px`}};

  background-color: ${({ theme }) => theme.panelContentBackground};
  color: ${({ theme }) => theme.textColor};
  border-radius: 5px;
  padding: 0 ${({ theme }) => theme.bottomInnerPdVert}px;
  margin-left: -${({ theme }) => theme.bottomInnerPdVert}px;
  font-size: 0.8em;
  box-shadow: ${({ theme }) => theme.boxShadow};

  animation: fadeIn 0.2s ease;
  animation-fill-mode: both;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 90%;
    }
  }

  &.disappearing {
    animation: fadeOut 0.5s ease;
    animation-delay: 0.5s;
    animation-fill-mode: both;

    @keyframes fadeOut {
      0% {
        opacity: 90%;
      }
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  th {
    text-align: right;
    font-weight: normal;
    padding-right: 1em;
  }

  .device-tooltip {
    white-space: nowrap;
  }

  .userdata {
    font-family: monospace;
    color: orange; /* TODO: Move to theme */
  }

  .data {
    font-family: monospace;
    color: orange; /* TODO: Move to theme */
  }

  .payload {
    font-family: monospace;
    color: orange; /* TODO: Move to theme */
  }
`;

export type InfoType = DeviceStateData | Event | Feature;

type DeviceStateTooltipProps = {
  tooltipType: "device";
  hoverInfo: PickInfo<DeviceStateData>;
};

type EventTooltipProps = {
  tooltipType: "event";
  hoverInfo: PickInfo<Event>;
};

type FeatureTooltipProps = {
  tooltipType: "feature";
  hoverInfo: PickInfo<Feature>;
};

export type TooltipProps = (
  | DeviceStateTooltipProps
  | EventTooltipProps
  | FeatureTooltipProps
) & { offset?: number };

const _Tooltip: FC<TooltipProps> = ({ tooltipType, hoverInfo }) => {
  if (!hoverInfo || !hoverInfo.object) {
    return null;
  }

  switch (tooltipType) {
    case "device":
      return <DeviceTooltip device={hoverInfo.object} />;
    case "feature":
      return (
        <LayerTooltip
          geoLayerId={hoverInfo.layer.id.slice("geo-layer-".length)}
          featureProps={hoverInfo.object.properties}
        />
      );
    case "event":
      return <EventTooltip event={hoverInfo.object} />;
  }
};

export const Tooltip: FC<TooltipProps> = (props) => {
  const { hoverInfo, offset = 10 } = props;
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = element;
    if (!current) {
      return;
    }

    const rect = current.getBoundingClientRect();
    const { width, height } = rect;
    setDimensions({ width, height });
  }, [props.hoverInfo]);

  const calculateDynamicPosition = () => {
    const { width, height } = dimensions;
    let { x, y } = hoverInfo;

    x += offset;
    // If the hover position x + width are greater than window width, pull it to the left
    if (x + width > window.innerWidth) {
      x = x - width - offset;
    }

    y += offset;
    // If the hover position y + height are greater than window height, pull it to the top
    if (y + height > window.innerHeight) {
      y = y - height - offset;
    }

    return { x, y };
  };

  const { x, y } = calculateDynamicPosition();

  return (
    <TooltipBoxStyle x={x} y={y}>
      <div ref={element}>{_Tooltip(props)}</div>
    </TooltipBoxStyle>
  );
};
