const notDone = Symbol("not done");
type NotDone = typeof notDone;

/**
 * looping over a generator with
 *
 *     for (const v of generator) { ... }
 *
 * is great to get the generator's yields, but gives
 * no easy way to get at the generator's return.
 *
 * gimmeTheReturn addresses this. Use it like:
 *
 *     const gen = gimmeTheReturn(generator);
 *     for (const v of gen) {
 *         ...
 *     }
 *     // now gen.result will have the return value
 *     // from the generator
 *     do_something_with(gen.result)
 *
 * Asking for .result before the generator has finished
 * will throw an Error.
 */
export function gimmeTheReturn<T, TReturn, TNext>(
  gen: AsyncGenerator<T, TReturn, TNext>,
): AsyncIterableIterator<T> & { get result(): TReturn } {
  let result: TReturn | NotDone = notDone;
  async function next() {
    const val = await gen.next();
    if (val.done) {
      result = val.value;
    }
    return val;
  }
  return {
    [Symbol.asyncIterator]: function () {
      return this;
    },
    next,
    get result(): TReturn {
      if (result === notDone) {
        throw Error("iterable not finished!");
      }
      return result;
    },
  };
}
