import {
  eventChannel,
  END,
  EventChannel,
  Buffer,
  FlushableChannel,
  TakeableChannel,
} from "redux-saga";
import { ClientReadableStream } from "grpc-web";
import { delay, flush, take } from "redux-saga/effects";

// needs to match defn in @types/redux-saga which doesn't seem to be exported.
// eslint-disable-next-line @typescript-eslint/ban-types
type NotUndefined = {} | null;

export const connectStream = <RESP extends NotUndefined>(
  stream: ClientReadableStream<RESP>,
  buffer?: Buffer<RESP>,
): EventChannel<RESP> =>
  eventChannel((emitter) => {
    stream.on("data", emitter);
    stream.on("error", (e: Error) => {
      console.error(e?.message || e);
      return emitter(END);
    });
    return () => stream.cancel();
  }, buffer);

/**
 * Simple debounce: wait for `delaySec` seconds, then
 * return anything that's turned up on `chan` in that time.
 */
export function* takeDebounced<RESP extends NotUndefined>(
  delaySec: number,
  chan: FlushableChannel<RESP> & TakeableChannel<RESP>,
) {
  // wait delaySec sec, ...
  yield delay(delaySec * 1000);

  // ... then wait for something to appear on the stream, ...
  const resp: RESP = yield take(chan);

  // .. and grab anything else that has arrived in that time as well.
  const resps: RESP[] = yield flush(chan);
  return [resp].concat(resps);
}
