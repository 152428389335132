import {
  PickersDay,
  StaticDatePicker,
  PickersDayProps,
} from "@mui/x-date-pickers";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FC, useContext, useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { selectTimeControl } from "../../../redux/selectors/time-control";
import DeviceStateCountsContext, {
  DateAsStr,
  MonthAsStr,
  Provider as DeviceStateCountsProvider,
} from "../../../redux/contexts/DayCounts";
import { colorFromCssHex, stripAlpha } from "../../../utils/color";
import colorConvert from "color-convert";
import { ThemeContext } from "styled-components";

type DatePickerProps = {
  onChange: (newDate: Date | null) => void;
  date: Date;
};

const renderInput = (props: TextFieldProps) => <TextField {...props} />;

const DatePicker: FC<DatePickerProps> = function DatePicker({
  onChange: onChangeFromParent,
  date: dateFromParent,
}: DatePickerProps) {
  const timeControl = useAppSelector(selectTimeControl);

  return (
    <DeviceStateCountsProvider>
      <StaticDatePicker
        disabled={timeControl.live}
        displayStaticWrapperAs="desktop"
        value={dateFromParent}
        renderInput={renderInput}
        renderDay={renderDatePickerDay}
        onChange={onChangeFromParent}
        disableFuture={true}
      />
    </DeviceStateCountsProvider>
  );
};

function renderDatePickerDay(
  date: Date,
  selectedDates: (Date | null)[],
  pickerProps: PickersDayProps<Date>,
): JSX.Element {
  return <CountedPickerDay date={date} {...pickerProps} />;
}

const CountedPickerDay = function ({
  date,
  ...pickerProps
}: { date: Date } & PickersDayProps<Date>) {
  const theme = useContext(ThemeContext);

  const counts = useContext(DeviceStateCountsContext);

  // request the current date, to ensure it loads (eventually)
  counts.request(date);

  // if the current date hasn't loaded yet, myCount will be undefined
  const myCount = counts.data.counts.get(DateAsStr(date));
  const maxCount = counts.data.monthlyMaxes.get(MonthAsStr(date)) || 1;

  // normalize counts against biggest count for the current month
  // f off typescript, undefined/number = NaN: number
  /* f off eslint */ // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const weight = myCount! / maxCount; // [0, 1]

  // don't override background of .selected or .today, they are important
  const isHighlightedDay = pickerProps.selected || pickerProps.today;

  const bgColor = useMemo(
    function getBgColor() {
      if (!isFinite(weight)) {
        // this is normal when the counts haven't loaded yet
        return undefined;
      } else if (isHighlightedDay) {
        return undefined;
      }

      const baseBgColor = stripAlpha(colorFromCssHex(theme.bottomWidgetBgd));
      const baseBgColorHsl = colorConvert.rgb.hsl(baseBgColor);
      const satFactor = weight * 0.2 + 1; // [1, 1.2]
      const brightnessFactor = weight + 1; // [1, 2]
      baseBgColorHsl[1] *= satFactor;
      baseBgColorHsl[2] *= brightnessFactor;

      const c = baseBgColorHsl;
      return `hsl(${c[0]}, ${c[1]}%, ${c[2]}%)`;
    },
    [isHighlightedDay, weight, theme],
  );

  return <PickersDay sx={{ backgroundColor: bgColor }} {...pickerProps} />;
};

export default DatePicker;
