import { ComponentType, FC } from "react";
import {
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from "@auth0/auth0-react";

import { Loading } from "./Loading";

type ProtectedProps = {
  component: ComponentType<object>;
  options?: WithAuthenticationRequiredOptions;
};

export const Protected: FC<ProtectedProps> = ({ component, options }) => {
  const Component = withAuthenticationRequired(component, {
    ...options,
    onRedirecting: () => <Loading />,
  });
  return <Component />;
};
