import { Action } from "redux";
import { Task } from "redux-saga";
import {
  all,
  call,
  cancel,
  cancelled,
  debounce,
  fork,
  put,
  PutEffect,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { IntlShape } from "react-intl";
import { FormikErrors } from "formik";

import {
  ReadProjectsRequest,
  ReadProjectsResponse,
  UpdateProjectRequest,
  DeleteProjectRequest,
  CreateProjectRequest,
  CreateProjectResponse,
} from "../../generated/sora/app/v1beta/service_pb";

import { UPDATE_DEBOUNCE_MS } from "../../constants";
import {
  addProjects,
  changeProjectAndNavigateToDashboard,
  createProjectRequested,
  deleteProject,
  fromPB,
  readProjectsRequested,
  setReadProjectsPending,
  toPB,
  updateProject,
  setReadProjectsSucceeded,
  setReadProjectsErrored,
  updateProjectRequested,
  Project,
} from "../reducers/projects";
import { callConfig } from "../../api/callConfig";
import { clearAlertors } from "../reducers/alertor";
import { clearLayers } from "../reducers/layers";
import { clearAnnotators } from "../reducers/annotator";
import { clearEventGenerators } from "../reducers/event-generators";
import { clearDeviceStates } from "../reducers/device-state";
import { clearDevices } from "../reducers/devices";
import { clearEvents } from "../reducers/events";
import deviceStateSaga from "./device-state";
import eventsSaga from "./events";

const projectErrorToMessage = (
  e: Error,
  intl: IntlShape,
): FormikErrors<Project> => {
  if (e.message.includes("(SQLSTATE 23505)")) {
    return {
      name: intl.formatMessage({ id: "error.project.uniqueName" }),
    };
  }
  return {};
};

function* readProjectsSaga() {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  yield put(setReadProjectsPending());

  const readProjectsRequest = new ReadProjectsRequest();
  let readProjectsResponse: ReadProjectsResponse;

  try {
    readProjectsResponse = yield call(
      (req) => appService.readProjects(req),
      readProjectsRequest,
    );
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      put(setReadProjectsErrored(e.message));
    }
    return;
  }

  const projects = readProjectsResponse.getProjectsList().map((l) => fromPB(l));
  yield all([put(addProjects(projects)), put(setReadProjectsSucceeded())]);
}

function* createProjectSaga(action: ReturnType<typeof createProjectRequested>) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const createProjectRequest = new CreateProjectRequest().setProject(
    toPB(action.payload.project),
  );
  let createProjectResponse: CreateProjectResponse;
  try {
    createProjectResponse = yield call(
      (req) => appService.createProject(req),
      createProjectRequest,
    );
  } catch (e) {
    if (e instanceof Error) {
      action.payload.helpers.setErrors(
        projectErrorToMessage(e, action.payload.intl),
      );
      action.payload.helpers.setSubmitting(false);
    } else {
      console.error(e);
    }
    return;
  }

  const maybeProject = createProjectResponse.getProject();
  if (maybeProject) {
    yield put(addProjects([fromPB(maybeProject)]));
  }

  action.payload.helpers.setSubmitting(false);
  action.payload.onSuccess();
}

function* updateProjectSaga(action: ReturnType<typeof updateProjectRequested>) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const updateProjectRequest = new UpdateProjectRequest().setProject(
    toPB(action.payload.project),
  );

  try {
    yield call((req) => appService.updateProject(req), updateProjectRequest);
  } catch (e) {
    if (e instanceof Error) {
      action.payload.helpers.setErrors(
        projectErrorToMessage(e, action.payload.intl),
      );
      action.payload.helpers.setSubmitting(false);
    } else {
      console.error(e);
    }
    return;
  }

  yield put(
    updateProject({
      id: action.payload.project.id,
      changes: action.payload.project,
    }),
  );
  action.payload.helpers.setSubmitting(false);
  action.payload.onSuccess();
}

function* deleteProjectSaga(action: ReturnType<typeof deleteProject>) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const deleteProjectRequest = new DeleteProjectRequest().setId(
    action.payload as string,
  );
  try {
    yield call((req) => appService.deleteProject(req), deleteProjectRequest);
  } catch (e) {
    console.error(e);
    return;
  }
}

function* changeProjectSaga() {
  let projectSagas: Task<unknown> = yield fork(perProjectSagas);

  while (true) {
    const action: ReturnType<typeof changeProjectAndNavigateToDashboard> =
      yield take(changeProjectAndNavigateToDashboard);

    const clearActions: PutEffect<Action>[] = [
      // Device data
      put(clearDeviceStates()),
      put(clearDevices()),
      put(clearEvents()),

      // App data
      put(clearAlertors()),
      put(clearAnnotators()),
      put(clearEventGenerators()),
      put(clearLayers()),
    ];

    yield all(clearActions);

    yield cancel(projectSagas);

    projectSagas = yield fork(perProjectSagas);

    const { to: projectId, navigate } = action.payload;

    navigate(`/projects/${projectId}/dashboard`);
  }
}

// any sagas in here will be restarted whenever the project changes.
// this allows them to have easy per-project initialization
// and cleanup code. Eventually I think most sagas should be inside here.
function* perProjectSagas() {
  // the try/finally is actually currently a no-op, I expect something will end up there. If it never does
  // then safe to remove.
  try {
    yield all([fork(deviceStateSaga), fork(eventsSaga)]);
  } finally {
    const c: boolean = yield cancelled();
    if (c) {
      console.debug("stopping per project sagas..");
    }
  }
}

export default function* projectsSaga() {
  yield all([
    takeEvery(createProjectRequested, createProjectSaga),
    debounce(UPDATE_DEBOUNCE_MS, updateProjectRequested, updateProjectSaga),
    takeEvery(deleteProject, deleteProjectSaga),
    takeLatest(readProjectsRequested, readProjectsSaga),
    call(changeProjectSaga),
  ]);
}
