import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form, Formik, FormikErrors } from "formik";
import { Switch, TextField } from "formik-mui";
import {
  MenuItem,
  Button,
  Grid,
  Typography,
  Skeleton,
  FormControlLabel,
} from "@mui/material";

import { DeviceSelection } from "./DeviceSelection";
import { Project } from "../../redux/reducers/projects";
import { useAppDispatch } from "../../redux/hooks";
import { createDeviceRequested } from "../../redux/reducers/devices";
import { newProjectModal, showModal } from "../../redux/reducers/ui/modal";

export type CreateDeviceFormLayout = "loading" | "no-projects" | "add-device";

type CreateDevicesProps = {
  auth0State: string;
  layout: CreateDeviceFormLayout;
  projects: Project[];
};

export type CreateDeviceFormValues = {
  projectId: string;
  existingDevice: boolean;
  deviceId?: string;
  name?: string;
};

const initialFormValues = {
  projectId: "",
  existingDevice: false,
};

export const CreateDevice: FC<CreateDevicesProps> = ({
  auth0State,
  layout,
  projects,
}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  switch (layout) {
    case "add-device":
      return (
        <Formik
          initialValues={initialFormValues as CreateDeviceFormValues}
          validate={(values) => {
            const errors: FormikErrors<CreateDeviceFormValues> = {};

            if (!values.projectId) {
              errors.projectId = intl.formatMessage({
                id: "devices.create.required",
              });
            }

            if (!values.existingDevice && !values.name) {
              errors.name = intl.formatMessage({
                id: "devices.create.required",
              });
            }

            if (values.existingDevice && !values.deviceId) {
              errors.deviceId = intl.formatMessage({
                id: "devices.create.required",
              });
            }

            return errors;
          }}
          onSubmit={(values, helpers) => {
            dispatch(
              createDeviceRequested({
                createDeviceFormValues: values,
                auth0State,
                helpers,
                intl,
                onSuccessNavigateTo: (location: URL) =>
                  window.location.replace(location),
              }),
            );
          }}
        >
          {({ submitForm, resetForm, isSubmitting, values }) => (
            <Form>
              <Grid container spacing={2} m={1}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="projectId"
                    select
                    label={intl.formatMessage({
                      id: "devices.create.projectId.label",
                    })}
                    fullWidth
                    children={projects.map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        <Typography noWrap>{p.name}</Typography>
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Field
                        component={Switch}
                        name="existingDevice"
                        disabled={!values.projectId}
                        checked={values.existingDevice}
                      />
                    }
                    label={intl.formatMessage({
                      id: "devices.create.newDevice",
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  {values.existingDevice ? (
                    <DeviceSelection
                      projectId={values.projectId}
                      values={values}
                    />
                  ) : (
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      label={intl.formatMessage({
                        id: "devices.create.name.label",
                      })}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => resetForm()}
                  >
                    <FormattedMessage id="devices.create.button.reset" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    <FormattedMessage id="devices.create.button.register" />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      );
    case "no-projects":
      return (
        <>
          <Grid item xs={10}>
            <Typography noWrap>
              <FormattedMessage id="devices.create.noProjects" />
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Button
              variant="contained"
              onClick={() => dispatch(showModal(newProjectModal()))}
            >
              <FormattedMessage id="project.create" />
            </Button>
          </Grid>
        </>
      );
    case "loading":
      return (
        <>
          <Grid item xs={10}>
            <Typography noWrap>
              <Skeleton animation="wave" sx={{ width: 548 }} />
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography noWrap>
              <Skeleton animation="wave" sx={{ width: 548 }} />
            </Typography>
          </Grid>
        </>
      );
  }
};
