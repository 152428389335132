import styled from "styled-components";
import { Button } from "kepler.gl/components";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setSelection,
  toggleRangePopup,
} from "../../../redux/reducers/time-control";
import { selectTimeControl } from "../../../redux/selectors/time-control";

const TimeControlPopupDiv = styled.div`
  display: flex;
  justify-content: end;
  height: 27px;
  pointer-events: none;
`;

const RangeControls = styled.div`
  z-index: 11; /* just needs to be above the histogram */
  div {
    margin-left: ${({ theme }) => theme.bottomInnerPdVert}px;
  }
`;

export default function TimeControlPopup() {
  const dispatch = useAppDispatch();
  const { selection, rangePopupVisible } = useAppSelector(selectTimeControl);

  const onClickRange = (range: number) => () => {
    dispatch(
      setSelection({
        end: selection.end,
        length: range,
      }),
    );
    dispatch(toggleRangePopup());
  };

  return (
    <TimeControlPopupDiv>
      {rangePopupVisible && (
        <RangeControls>
          <Button onClick={onClickRange(3 * 24 * 3600)} secondary small>
            3 d
          </Button>
          <Button onClick={onClickRange(1 * 24 * 3600)} secondary small>
            1 d
          </Button>
          <Button onClick={onClickRange(12 * 3600)} secondary small>
            12 h
          </Button>
          <Button onClick={onClickRange(3 * 3600)} secondary small>
            3 h
          </Button>
          <Button onClick={onClickRange(1 * 3600)} secondary small>
            1 h
          </Button>
          <Button onClick={onClickRange(30 * 60)} secondary small>
            30 m
          </Button>
          <Button onClick={onClickRange(5 * 60)} secondary small>
            5 m
          </Button>
        </RangeControls>
      )}
    </TimeControlPopupDiv>
  );
}
