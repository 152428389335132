import {
  all,
  call,
  debounce,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { FeatureCollection, GeometryCollection } from "geojson";
import { isFeatureCollection, isGeometryCollection } from "geojson-validation";
import { toggleModal, wrapTo } from "kepler.gl/actions";

import {
  ReadLayersRequest,
  ReadLayersResponse,
  CreateLayerRequest,
  CreateLayerResponse,
  UpdateLayerRequest,
  DeleteLayerRequest,
} from "../../generated/sora/app/v1beta/service_pb";
import { Layer as LayerPB } from "../../generated/sora/app/v1beta/types_pb";

import {
  Layer,
  addLayers,
  deleteLayer,
  deleteLayerRequested,
  fileUploadProgress,
  fileUploadStarted,
  fileUploadSucceeded,
  filesUploadSucceeded,
  fromPB,
  layerSelectors,
  newLayer,
  readLayersRequested,
  toPB,
  updateLayer,
} from "../reducers/layers";
import { intFromColorArray } from "../../utils/color";
import { showErrorInUploadModal } from "./errors";
import { UPDATE_DEBOUNCE_MS } from "../../constants";
import { callConfig } from "../../api/callConfig";
import { selectProjectIdFromRoute } from "../selectors/router";
import { AppServicePromiseClient } from "../../generated/sora/app/v1beta/service_grpc_web_pb";

function* readLayersSaga(action: ReturnType<typeof readLayersRequested>) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const readLayersRequest = new ReadLayersRequest().setProjectId(
    action.payload,
  );
  let readLayersResponse: ReadLayersResponse;
  try {
    readLayersResponse = yield call(
      (req) => appService.readLayers(req),
      readLayersRequest,
    );
  } catch (e) {
    console.error(e);
    return;
  }

  const layers = readLayersResponse
    .getLayersList()
    .map((l) => fromPB(l))
    .filter((x): x is Layer => !!x);

  yield put(addLayers(layers));
}

export function* uploadLayersSaga(
  action: ReturnType<typeof fileUploadStarted>,
) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const files = action.payload;
  const uploads = files.map((file: File) =>
    call(validateUploadLayerSaga, file, appService),
  );

  const layers: LayerUploadResult[] = yield all(uploads);

  if (layers.some((layer) => layer === undefined)) {
    // layer errored, leave the dialog open
    return;
  }

  yield put(filesUploadSucceeded());
  yield put(wrapTo("map")(toggleModal(null)));
}

type LayerUploadResult = Layer[] | undefined;

/**
 * returns Layer[] if ok, undefined, if something went wrong
 */
function* validateUploadLayerSaga(
  file: File,
  appService: AppServicePromiseClient,
) {
  yield put(
    fileUploadProgress({
      fraction: 0.125,
      message: "selected files...",
      fileName: file.name,
    }),
  );

  let fileText: string;
  try {
    fileText = yield file.text();
  } catch (e) {
    yield showErrorInUploadModal(e, file.name, "error.upload.readingFiles");
    return;
  }

  yield put(
    fileUploadProgress({
      fraction: 0.25,
      message: "parsing...",
      fileName: file.name,
    }),
  );

  let fileJSON: object;
  try {
    fileJSON = JSON.parse(fileText);
  } catch (e) {
    yield showErrorInUploadModal(e, file.name, "error.upload.invalidJSON");
    return;
  }

  // It's more convient to use the a list type from here
  const fcs: FeatureCollection[] = [fileJSON].filter(isFeatureCollection);
  const gcs: GeometryCollection[] = [fileJSON].filter(isGeometryCollection);

  const fcsFromGcs: FeatureCollection[] = gcs.map((gc) => ({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: gc,
      },
    ],
  }));

  if (fcs.length + fcsFromGcs.length === 0) {
    const e = new Error("No feature collections");
    yield showErrorInUploadModal(
      e,
      file.name,
      "error.upload.noFeatureCollections",
    );
    return;
  }

  yield put(
    fileUploadProgress({
      fraction: 0.5,
      message: "preparing upload...",
      fileName: file.name,
    }),
  );

  const projectId: string | undefined = yield select(selectProjectIdFromRoute);
  if (!projectId) {
    const e = new Error("No projectId in route");
    yield showErrorInUploadModal(e, file.name, "error.upload.noProjectId");
    return;
  }

  let layerPBs: LayerPB[];
  try {
    layerPBs = [...fcs, ...fcsFromGcs].map((fc) =>
      toPB(newLayer(file.name.replace(/\.[^.]*$/, ""), fc.features, projectId)),
    );
  } catch (e) {
    yield showErrorInUploadModal(e, file.name, "error.upload.prepareError");
    return;
  }

  const rpcs = layerPBs.map((l) =>
    call(
      (req) => appService.createLayer(req),
      new CreateLayerRequest().setLayer(l),
    ),
  );

  yield put(
    fileUploadProgress({
      fraction: 0.75,
      message: "uploading...",
      fileName: file.name,
    }),
  );

  let resps: CreateLayerResponse[];
  try {
    resps = yield all(rpcs);
  } catch (e) {
    yield showErrorInUploadModal(e, file.name, "error.upload.serverError");
    return;
  }

  let layers: Layer[];
  try {
    layers = resps
      .map((resp) => resp.getLayer())
      .filter((l): l is LayerPB => !!l)
      .map((l) => fromPB(l));
  } catch (e) {
    yield showErrorInUploadModal(e, file.name, "error.upload.responseError");
    return;
  }

  yield put(
    fileUploadSucceeded({
      layers: layers,
      fileName: file.name,
    }),
  );

  return layers;
}

function* fileUploadSucceededSaga(
  action: ReturnType<typeof fileUploadSucceeded>,
) {
  const { fileName, layers } = action.payload;
  const successData = {
    fraction: 1.0,
    message: "done.",
    fileName,
  };

  yield all([put(fileUploadProgress(successData)), put(addLayers(layers))]);
}

function* updateLayerSaga(action: ReturnType<typeof updateLayer>) {
  // only the name or color can be updated for now
  if (action.payload.changes.name || action.payload.changes.color) {
    const appService = callConfig.call.appServiceContext?.appService;
    if (!appService) {
      return;
    }

    const layer: Layer = yield select(
      layerSelectors.selectById,
      action.payload.id.toString(),
    );

    const layerPB = new LayerPB()
      .setId(action.payload.id.toString())
      .setProjectId(layer.projectId)
      .setName(layer.name)
      .setColor(intFromColorArray(layer.color));

    const updateLayerRequest = new UpdateLayerRequest().setLayer(layerPB);

    try {
      yield call((req) => appService.updateLayer(req), updateLayerRequest);
    } catch (e) {
      console.error(e);
      return;
    }
  }
}

function* deleteLayerSaga(action: ReturnType<typeof deleteLayerRequested>) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const deleteLayerRequest = new DeleteLayerRequest()
    .setId(action.payload.id)
    .setProjectId(action.payload.projectId);

  try {
    yield call((req) => appService.deleteLayer(req), deleteLayerRequest);
  } catch (e) {
    console.error(e);
  }

  yield put(deleteLayer(action.payload.id));
}

export default function* layersSaga() {
  yield all([
    debounce(UPDATE_DEBOUNCE_MS, updateLayer, updateLayerSaga),
    takeLatest(deleteLayerRequested, deleteLayerSaga),
    takeLatest(readLayersRequested, readLayersSaga),
    takeLatest(fileUploadStarted, uploadLayersSaga),
    takeLatest(fileUploadSucceeded, fileUploadSucceededSaga),
  ]);
}
