import { FC, ReactNode } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { Dialog, DialogProps, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export type ModalTitleProps = {
  id: string;
  onClose?: DialogProps["onClose"];
  children?: ReactNode;
};

const ModalTitle: FC<ModalTitleProps> = ({ children, onClose, ...other }) => (
  <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose ? (
      <IconButton
        onClick={() => onClose({}, "escapeKeyDown")}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </DialogTitle>
);

interface ModalProps extends DialogProps {
  title: NonNullable<MessageDescriptor["id"]>;
  children?: ReactNode;
}

export const Modal: FC<ModalProps> = ({ title, children, ...props }) => {
  return (
    <Dialog {...props}>
      <ModalTitle id="modal-title" onClose={props.onClose}>
        <FormattedMessage id={title} />
      </ModalTitle>
      {children}
    </Dialog>
  );
};
