import { useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { MAP_MATCH_ANNOTATION_TYPE } from "../../../redux/reducers/annotator";
import { layerSelectors } from "../../../redux/reducers/layers";
import { maybeById } from "../../../utils/selectors";
import {
  selectCurrentAnnotator,
  setLayer,
  setAnnotationName,
  setAnnotationPrefix,
  setType,
  toggleMapMatchAnnotation,
} from "../../../redux/reducers/ui/annotatorBuilder";
import {
  extractFieldFromNestedObject,
  FieldWithType,
} from "../../../utils/fields";
import { Feature } from "geojson";

export const MapMatchAnnotatorBuilder = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const maybeLayersById = maybeById(
    useAppSelector(layerSelectors.selectEntities),
  );
  const layers = useAppSelector(layerSelectors.selectEntities);
  const currentAnnotator = useAppSelector(selectCurrentAnnotator);

  useEffect(() => {
    dispatch(setType(MAP_MATCH_ANNOTATION_TYPE));
  }, []);

  const onToggleCheckbox = useCallback((f: FieldWithType) => {
    dispatch(toggleMapMatchAnnotation(f));
  }, []);

  if (currentAnnotator.type != MAP_MATCH_ANNOTATION_TYPE) {
    return null;
  }

  const layer = maybeLayersById(
    (currentAnnotator.config?.layerId as string) || undefined,
  );

  const featureProperties = extractFieldFromNestedObject(
    layer?.features || ([] as Feature[]),
    (f) => f.properties || {},
  );

  return (
    <>
      <FormControl sx={{ m: 3 }}>
        <InputLabel id="annotator-select">
          <FormattedMessage id="annotator.modal.layer" />
        </InputLabel>
        <Select
          labelId="annotator-select"
          id="annotator-select"
          value={currentAnnotator?.config?.layerId || ""}
          label={intl.formatMessage({ id: "annotator.modal.layer" })}
          onChange={(e) => {
            const layer = layers[e.target.value as string];
            if (layer) {
              dispatch(setLayer(layer));
            }
          }}
        >
          {Object.values(layers).flatMap((layer) =>
            layer
              ? [
                  <MenuItem key={layer.id} value={layer.id}>
                    {layer.name}
                  </MenuItem>,
                ]
              : [],
          )}
        </Select>
        <TextField
          margin="dense"
          id="name"
          label={intl.formatMessage({
            id: "annotator.modal.annotatorName",
          })}
          type="text"
          fullWidth
          variant="standard"
          value={currentAnnotator.name}
          size="small"
          onChange={(e) => dispatch(setAnnotationName(e.target.value))}
        />
        <TextField
          margin="dense"
          id="name"
          label={intl.formatMessage({
            id: "annotator.modal.annotationPrefix",
          })}
          type="text"
          fullWidth
          variant="standard"
          value={currentAnnotator?.config?.annotationPrefix || ""}
          size="small"
          onChange={(e) => dispatch(setAnnotationPrefix(e.target.value))}
        />
        <FormHelperText>
          <FormattedMessage id="annotator.modal.annotationPrefixHelper" />
        </FormHelperText>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" />
                <TableCell component="th">
                  <FormattedMessage id="annotator.modal.featureTable.propertyName" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(featureProperties).map(([name, type]) => (
                <TableRow
                  key={name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="td" scope="row">
                    <Checkbox
                      checked={
                        !!(
                          (currentAnnotator.config?.mapMatchProperties ||
                            {}) as {
                            [key: string]: string;
                          }
                        )[name]
                      }
                      onChange={() => onToggleCheckbox({ name, type })}
                    />
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>
    </>
  );
};
