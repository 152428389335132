import {
  Annotator,
  GEOFENCE_ANNOTATION_TYPE,
  MAP_MATCH_ANNOTATION_TYPE,
} from "../../../redux/reducers/annotator";

export const annotatorToTabIndex = (a?: Annotator) => {
  if (!a) {
    return 0;
  }

  switch (a.type) {
    case MAP_MATCH_ANNOTATION_TYPE:
      return 1;
    case GEOFENCE_ANNOTATION_TYPE:
    default:
      return 0;
  }
};
