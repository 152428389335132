import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import { Button, Icons, SidePanelSection } from "kepler.gl/components";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { SidePanelRow } from "../SidePanelRow";
import {
  annotatorSelectors,
  GEOFENCE_ANNOTATION_TYPE,
  newAnnotator,
} from "../../../redux/reducers/annotator";
import { SidePanelIcons } from "../../common";
import { AnnotatorPanelHeader } from "./AnnotatorPanelHeader";
import { AnnotatorConfigurator } from "./AnnotatorConfigurator";
import { newAnnotatorModal, showModal } from "../../../redux/reducers/ui/modal";
import { setCurrentAnnotator } from "../../../redux/reducers/ui/annotatorBuilder";

export default () => {
  const dispatch = useAppDispatch();
  const annotators = useAppSelector(annotatorSelectors.selectAll);
  const params = useParams();

  const projectId = params.projectId;

  return (
    <>
      <SidePanelSection>
        <SidePanelIcons />
      </SidePanelSection>
      <SidePanelSection>
        <Button
          tabIndex={-1}
          width="150px"
          onClick={() => {
            if (projectId) {
              dispatch(
                setCurrentAnnotator(
                  newAnnotator(
                    undefined,
                    GEOFENCE_ANNOTATION_TYPE,
                    {},
                    projectId,
                  ),
                ),
              );
              dispatch(showModal(newAnnotatorModal()));
            }
          }}
        >
          <Icons.Add height="12px" />
          <FormattedMessage id={"annotator.manager.addAnnotation"} />
        </Button>
      </SidePanelSection>
      <SidePanelSection>
        {annotators.map((annotator) => (
          <SidePanelRow
            key={annotator.id}
            model={annotator}
            Header={AnnotatorPanelHeader}
          >
            <AnnotatorConfigurator model={annotator} />
          </SidePanelRow>
        ))}
      </SidePanelSection>
    </>
  );
};
