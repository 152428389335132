// Add english messages here, other languages will use these
// if translations not available for a message
// prettier-ignore
const en = {
  "alertor.config.color": "Color",
  "alertor.config.details": "Alert Details",
  "alertor.config.id": "ID",
  "alertor.config.url": "Slack Webhook URL",
  "alertor.manager.addAlert": "Add Alertor",
  "alertor.manager.confirmDelete": "Are you sure you want to permanently delete this Alertor?",
  "annotator.config.color": "Color",
  "annotator.config.details": "Annotation Details",
  "annotator.config.id": "ID",
  "annotator.manager.addAnnotation": "Add Annotator",
  "annotator.manager.confirmDelete": "Are you sure you want to permanently delete this Annotator?",
  "annotator.modal.annotationKey": "Annotation Key",
  "annotator.modal.annotationKeyHelper": "This a key used to store the value of this annotation. Changing it will affect subsequent annotations and event generators that may depend on it.",
  "annotator.modal.annotationPrefix": "Annotation Key Prefix",
  "annotator.modal.annotationPrefixHelper": "The keys in this annotation will be prefixed with thie value. Changing it will affect subsequent annotations and event generators that depend on it.",
  "annotator.modal.annotatorName": "Annotator Name",
  "annotator.modal.create": "Create",
  "annotator.modal.featureTable.annotationKeyName": "Annotation Key Name",
  "annotator.modal.featureTable.propertyName": "Feature Property Keys",
  "annotator.modal.geofence": "Geofence",
  "annotator.modal.layer": "Layer",
  "annotator.modal.mapMatch": "Map Match",
  "annotator.modal.title": "Annotator Builder",
  "annotator.modal.update": "Update",
  "config.color": "Color",
  "config.details": "Details",
  "confirm.delete": "Delete",
  "device.color": "Device Color",
  "device.details": "Device Details",
  "device.details.id": "ID",
  "device.add": "Add Device",
  "device.hideAll": "Hide All",
  "device.showAll": "Show All",
  "devices.create.button.register": "Register",
  "devices.create.button.reset": "Reset",
  "devices.create.deviceId.label": "Existing Device",
  "devices.create.name.label": "New Device Name",
  "devices.create.newDevice": "Use existing device from project",
  "devices.create.noAuth0State": "Critical Error: Please run sora login again. If this persists, contact support@swiftnav.com",
  "devices.create.noProjects": "Please create a project before you log in a device.",
  "devices.create.projectId.label": "Project",
  "devices.create.required": "Required",
  "devices.create.title": "Register Device",
  "error.project.uniqueName": "There is already a project with that name. Please choose another.",
  "error.upload.invalidJSON": "A file does not contain valid JSON",
  "error.upload.noFeatureCollections": "Could not find GeoJSON FeatureCollections in the uploaded files.",
  "error.upload.noProjectId": "Could not obtain project id.",
  "error.upload.oneFile": "You should only have been able to select one file to upload",
  "error.upload.prepareError": "Error preparing files for upload.",
  "error.upload.readingFiles": "Error reading files",
  "error.upload.responseError": "Error in response messages from server",
  "error.upload.serverError": "Error encountered while uploading files.",
  "eventGenerator.builder.create": "Create Event Generator",
  "eventGenerator.builder.nameHelperText": "Enter a name for the Event Generator. The events it generates will be identified by this name.",
  "eventGenerator.builder.nameLabel": "Event Generator Name",
  "eventGenerator.builder.queryString": "Resulting Rule: ",
  "eventGenerator.builder.title": "Event Generator Builder",
  "eventGenerator.builder.update": "Update Event Generator",
  "eventGenerator.config.alert": "Alert",
  "eventGenerator.config.color": "Color",
  "eventGenerator.config.details": "Event Generator Details",
  "eventGenerator.config.id": "ID",
  "eventGenerator.config.noAlertorId": "No Alert",
  "eventGenerator.config.rule": "Rule",
  "eventGenerator.hideAll": "Hide All",
  "eventGenerator.manager.addEventGenerator": "Add Event Generator",
  "eventGenerator.manager.confirmDelete": "Are you sure you want to permanently delete this event generator?",
  "eventGenerator.manager.viewLog": "View Log",
  "eventGenerator.showAll": "Show All",
  "events.log.alertName": "Alert",
  "events.log.alt": "Altitude",
  "events.log.deviceId": "Device ID",
  "events.log.eventGeneratorName": "Event Generator",
  "events.log.lat": "Latitude",
  "events.log.lon": "Longitude",
  "events.log.payload": "Payload Data",
  "events.log.time": "Time",
  "events.log.title": "Event Log",
  "geoLayer.add": "Add Layer",
  "geoLayer.color": "Layer Color",
  "geoLayer.details": "Layer Details",
  "geoLayer.details.id": "Layer ID",
  "geoLayer.hideAll": "Hide All",
  "geoLayer.showAll": "Show All",
  "geoLayer.confirmDelete": "Are you sure you want to delete this layer?",
  "settings.timezone": "Timezone",
  "project.card.action.delete": "Delete",
  "project.card.action.confirmDelete": "Are you sure you want to delete this project? This will remove all of its devices, stored data, annotators and event generators, etc!",
  "project.card.action.edit": "Edit",
  "project.create": "New Project",
  "project.modal.create": "Create",
  "project.modal.description": "Description (Optional)",
  "project.modal.isBusDemoOn": "Include demo buses",
  "project.modal.isBusDemoOff": "Don't include any demo buses",
  "project.modal.message": "A project will allow you to distinguish between different types of devices. You must give it a name and you have the option to give a longer form description.",
  "project.modal.name": "Name",
  "project.modal.required": "Required",
  "project.modal.reset": "Reset",
  "project.modal.title": "New Project",
  "project.modal.update": "Update",
  "project.modal.selectIcon": "Select Icon",
  "project.modal.icon.train": "Train",
  "project.modal.icon.bus": "Bus",
  "project.modal.icon.drone": "Drone",
  "project.modal.icon.point": "Point",
  "project.modal.icon.car": "Car",
  "project.title": "Projects",
  "sidebar.panels.settings": "Display Settings",
  "sidebar.panels.alertors": "Alerts",
  "sidebar.panels.annotators": "Annotations",
  "sidebar.panels.devices": "Devices",
  "sidebar.panels.event-generators": "Events",
  "sidebar.panels.geo-layers": "Layers",
  "timeControl.live": "LIVE",
  "tooltip.annotatorEdit": "Edit Annotator",
  "tooltip.deleteAnnotator": "Delete Annotator",
  "tooltip.deleteEventGenerator": "Delete Event Generator",
  "tooltip.deleteGeoLayer": "Delete layer",
  "tooltip.deviceCenter": "Center device",
  "tooltip.deviceSettings": "Device settings",
  "tooltip.editAnnotator": "Edit Annotator",
  "tooltip.eventGeneratorSettings": "Event Generator Settings",
  "tooltip.geoLayerSettings": "Layer settings",
  "tooltip.hideDevice": "Hide device",
  "tooltip.hideEventGenerator": "Hide Events",
  "tooltip.hideGeoLayer": "Hide layer",
  "tooltip.logout": "Logout",
  "tooltip.feedback": "Give us your feedback and help us improve!",
  "tooltip.showDevice": "Show device",
  "tooltip.showEventGenerator": "Show Events",
  "tooltip.showGeoLayer": "Show layer",
};

export const messages = {
  en,
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace FormatjsIntl {
    interface Message {
      ids: keyof typeof messages.en;
    }

    interface IntlConfig {
      locale: "en";
    }
  }
}
