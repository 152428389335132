import { HTMLAttributes, useCallback } from "react";
import { useIntl } from "react-intl";
import { SidePanelSection } from "kepler.gl/components";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectSettings } from "../../../redux/selectors/settings";
import { setTimezone } from "../../../redux/reducers/settings";
import {
  FormControl,
  TextField,
  Autocomplete,
  MenuItem,
  ListItemText,
  createFilterOptions,
  AutocompleteRenderOptionState,
} from "@mui/material";
import { styled } from "@mui/system";
import timezones, { Timezone } from "../../../constants/Timezones";

const renderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: Timezone,
  state: AutocompleteRenderOptionState,
) => (
  <MenuItemTZ selected={state.selected} {...props} key={option.ianaId}>
    <ListItemText>{option.displayName}</ListItemText>
    <small>{option.ianaId}</small>
  </MenuItemTZ>
);

const filterOptions = createFilterOptions({
  stringify: (option: Timezone) =>
    `${option.ianaId.replaceAll("_", " ")} ${option.displayName}`,
});

const getGroupBy = (option: Timezone) => option.group;

const getOptionLabel = (option: Timezone) => option.ianaId;

export default () => {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectSettings);
  const { timezone } = settings;
  const intl = useIntl();

  const onChangeTimezone = useCallback(
    (_event, value: Timezone, _reason) => dispatch(setTimezone(value)),
    [dispatch],
  );

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        label={intl.formatMessage({ id: "settings.timezone" })}
      />
    ),
    [intl],
  );

  return (
    <>
      <SidePanelSection>
        <FormControl fullWidth>
          <Autocomplete
            id="settings-timezone"
            options={timezones}
            value={timezone}
            disableClearable
            onChange={onChangeTimezone}
            groupBy={getGroupBy}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            filterOptions={filterOptions}
            renderInput={renderInput}
            sx={{
              width: "100%",
            }}
          />
        </FormControl>
      </SidePanelSection>
    </>
  );
};

const MenuItemTZ = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  "& .MuiTypography-root": {
    fontSize: "14px",
  },
  "&.MuiAutocomplete-option": {
    alignItems: "start",
  },
  alignItems: "start",
  "& .MuiListItemText": {
    display: "block",
  },
  "& > small": {
    display: "block",
    fontSize: "12px",
  },
});
