import { JsonLogicValue } from "react-awesome-query-builder";

const t = (x: JsonLogicValue) => typeof x;

export type JSONType = ReturnType<typeof t>;

export type TypeObject = Record<string, JSONType>;

export type FieldWithType = {
  name: string;
  type: JSONType;
};

export const toTypeObject = (x: object) => {
  const t: TypeObject = {};
  for (const k in x) {
    t[k] = typeof x[k as keyof typeof x];
  }
  return t;
};

export const extractFieldFromNestedObject = <T>(
  xs: T[],
  ...selectObjects: ((_: T) => Record<string, unknown>)[]
): TypeObject =>
  xs.reduce((acc, x) => {
    return selectObjects.reduce(
      (acc2: TypeObject, fn) => Object.assign(acc2, toTypeObject(fn(x))),
      acc,
    );
  }, {} as TypeObject);
