import { createSelector } from "reselect";

import { findIdInRoute } from "../../utils/findIdInRoute";
import { RootState } from "../store";

export const selectRouterState = (state: RootState) => state.router;
export const selectLocation = createSelector(
  selectRouterState,
  (r) => r?.location,
);
export const selectProjectIdFromRoute = createSelector(selectLocation, (l) =>
  findIdInRoute("projects", l?.pathname),
);
