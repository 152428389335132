import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { IntlShape } from "react-intl";
import { FormikErrors } from "formik";

import {
  CreateDeviceRequest,
  CreateDeviceResponse,
  ReadDevicesRequest,
  ReadDevicesResponse,
} from "../../generated/sora/app/v1beta/service_pb";

import { callConfig } from "../../api/callConfig";
import {
  Device,
  addDevices,
  clearDevices,
  createDeviceRequested,
  devicesSelector,
  fromPB,
  initialDevice,
  readDevicesRequested,
  setReadDevicesErrored,
  setReadDevicesSucceeded,
  toPB,
} from "../reducers/devices";
import { AUTH0_DOMAIN } from "../../constants";
import { EntityId } from "@reduxjs/toolkit";

const deviceErrorToMessage = (
  e: Error,
  intl: IntlShape,
): FormikErrors<Device> => {
  if (e.message.includes("(SQLSTATE 23505)")) {
    return {
      name: intl.formatMessage({ id: "error.project.uniqueName" }),
    };
  }
  return {};
};

function* createDeviceSaga(action: ReturnType<typeof createDeviceRequested>) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const formValues = action.payload.createDeviceFormValues;

  let device: Device;
  if (formValues.existingDevice) {
    device = yield select(
      devicesSelector.selectById,
      formValues.deviceId as EntityId,
    );
  } else {
    device = {
      ...initialDevice(),
      name: action.payload.createDeviceFormValues.name || "",
      projectIds: [action.payload.createDeviceFormValues.projectId],
    };
  }

  const createDeviceRequest = new CreateDeviceRequest()
    .setDevice(toPB(device))
    .setState(action.payload.auth0State);

  let createDeviceResponse: CreateDeviceResponse;
  try {
    createDeviceResponse = yield call(
      (x) => appService.createDevice(x),
      createDeviceRequest,
    );
  } catch (e) {
    if (e instanceof Error) {
      action.payload.helpers.setErrors(
        deviceErrorToMessage(e, action.payload.intl),
      );
      action.payload.helpers.setSubmitting(false);
    } else {
      console.error(e);
    }
    return;
  }

  action.payload.helpers.setSubmitting(false);

  const maybeWrappedToken = createDeviceResponse.getWrappedDeviceAccessToken();
  if (maybeWrappedToken) {
    const params = new URLSearchParams({
      wrapped_token: maybeWrappedToken,
      state: action.payload.auth0State,
    });

    action.payload.onSuccessNavigateTo(
      new URL(`https://${AUTH0_DOMAIN}/continue?${params}`),
    );
  }
}

function* readDevicesSaga(action: ReturnType<typeof readDevicesRequested>) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  yield put(clearDevices());

  const readDevicesRequest = new ReadDevicesRequest().setProjectId(
    action.payload,
  );

  let readDevicesResponse: ReadDevicesResponse;
  try {
    readDevicesResponse = yield call(
      (x) => appService.readDevices(x),
      readDevicesRequest,
    );
  } catch (e) {
    yield put(setReadDevicesErrored(e));
    return;
  }

  const devices = readDevicesResponse.getDevicesList().map((d) => fromPB(d));
  yield all([put(addDevices(devices)), put(setReadDevicesSucceeded())]);
}

export default function* devicesSaga() {
  yield all([
    takeEvery(createDeviceRequested, createDeviceSaga),
    takeEvery(readDevicesRequested, readDevicesSaga),
  ]);
}
