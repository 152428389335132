import { FC, useMemo, useState, useCallback } from "react";
import { useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import {
  Builder,
  BuilderProps,
  Config,
  ImmutableTree,
  Query,
  Utils as QbUtils,
} from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectAnnotatorFields,
  selectDeviceStateCustomFields,
} from "../../../redux/selectors/fields";
import {
  EventGenerator,
  createEventGeneratorRequested,
  newEventGenerator,
  updateEventGenerator,
} from "../../../redux/reducers/event-generators";
import {
  buildConfig,
  eventGeneratorToTree,
  humanReadableRule,
} from "./expression";
import { randHex } from "../../../utils/rand";

const StyledBuilderContainer = styled.div`
  padding: 10px;
`;

type EventGeneratorBuilderProps = {
  eventGenerator?: EventGenerator;
  onClose: () => void;
};

export const EventGeneratorBuilder: FC<EventGeneratorBuilderProps> = ({
  eventGenerator,
  onClose,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const dsFields = useAppSelector(selectDeviceStateCustomFields);
  const aFields = useAppSelector(selectAnnotatorFields);
  const config = useMemo(
    () => buildConfig({ ...aFields, ...dsFields }),
    [aFields, dsFields],
  );
  const params = useParams();

  const projectId = params.projectId;

  const [name, setName] = useState(
    eventGenerator?.name || `Event Generator ${randHex()}`,
  );
  const [treeConfig, setTreeConfig] = useState({
    tree: eventGeneratorToTree(eventGenerator?.expression, config),
    config: config,
  });

  const onChange = useCallback(
    (immutableTree: ImmutableTree, config: Config) => {
      setTreeConfig((prevTreeConfig) => ({
        ...prevTreeConfig,
        tree: immutableTree,
        config: config,
      }));
    },
    [],
  );

  const jsonLogic = useMemo(
    () => QbUtils.jsonLogicFormat(treeConfig.tree, treeConfig.config).logic,
    [treeConfig],
  );

  const onCreate = () => {
    if (projectId) {
      dispatch(
        createEventGeneratorRequested(
          newEventGenerator(name, projectId, jsonLogic),
        ),
      );
    }
    onClose();
  };

  const onUpdate = () => {
    dispatch(
      updateEventGenerator({
        id: (eventGenerator as EventGenerator).id,
        changes: {
          name,
          expression: JSON.stringify(jsonLogic),
        },
      }),
    );
    onClose();
  };

  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <StyledBuilderContainer>
        <Builder {...props} />
      </StyledBuilderContainer>
    ),
    [],
  );

  return (
    <>
      <DialogContent>
        <FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={intl.formatMessage({
              id: "eventGenerator.builder.nameLabel",
            })}
            type="text"
            fullWidth
            variant="standard"
            defaultValue={name}
            size="small"
            onBlur={(e) => setName(e.target.value)}
          />
          <FormHelperText>
            <FormattedMessage id="eventGenerator.builder.nameHelperText" />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <Query
            {...config}
            value={treeConfig.tree}
            onChange={onChange}
            renderBuilder={renderBuilder}
          />
        </FormControl>
        <Typography variant="body1">
          <FormattedMessage id="eventGenerator.builder.queryString" />
        </Typography>
        <pre>{humanReadableRule(treeConfig.tree, treeConfig.config)}</pre>
      </DialogContent>
      <DialogActions>
        {eventGenerator ? (
          <Button variant="text" onClick={onUpdate}>
            <FormattedMessage id="eventGenerator.builder.update" />
          </Button>
        ) : (
          <Button variant="text" onClick={onCreate}>
            <FormattedMessage id="eventGenerator.builder.create" />
          </Button>
        )}
      </DialogActions>
    </>
  );
};
