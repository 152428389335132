import { FC, FormEvent } from "react";
import { Icons, InlineInput, PanelHeaderAction } from "kepler.gl/components";

import {
  EventGenerator,
  updateEventGenerator,
  deleteEventGeneratorRequested,
  NO_EVENT_GENERATOR,
  DummyEventGenerator,
} from "../../../redux/reducers/event-generators";
import {
  HeaderActionSection,
  HeaderLabelSection,
  StyledPanelHeaderWrapper,
} from "../../common";
import { useAppDispatch } from "../../../redux/hooks";
import assert from "assert";
import { startConfirm } from "../../../redux/reducers/ui/confirm";
import {
  EntityType,
  updateEntityVisibility,
} from "../../../redux/reducers/settings";

const EventGeneratorNameEditor: FC<EventGeneratorPanelHeaderProps> = ({
  model,
}) => {
  const dispatch = useAppDispatch();

  const onEdit = (e: FormEvent<HTMLInputElement>) => {
    ASSERTS && assert(model.id !== NO_EVENT_GENERATOR);
    dispatch(
      updateEventGenerator({
        id: model.id,
        changes: { name: e.currentTarget.value },
      }),
    );
  };

  return (
    <InlineInput
      type="text"
      value={model.name}
      onClick={(e: FormEvent<HTMLInputElement>) => {
        e.stopPropagation();
      }}
      onChange={onEdit}
      disabled={model.id === NO_EVENT_GENERATOR}
      id={`${model.name}:input-event-generator-label`}
    />
  );
};

type EventGeneratorPanelHeaderProps = {
  model: EventGenerator | DummyEventGenerator;
  visible?: boolean;
};

export const EventGeneratorPanelHeader: FC<EventGeneratorPanelHeaderProps> = ({
  model,
  visible,
}) => {
  const dispatch = useAppDispatch();

  const onToggleVisibility = () => {
    dispatch(
      updateEntityVisibility({
        entityType: EntityType.EventGenerator,
        entityId: model.id,
        value: !visible,
      }),
    );
  };

  const onToggleConfig = () => {
    dispatch(
      updateEventGenerator({
        id: model.id as string, // reduxtoolkit will still accept the symbol in this case
        changes: { showConfig: !model.showConfig },
      }),
    );
  };

  const onDelete = () => {
    ASSERTS && assert(model.id !== NO_EVENT_GENERATOR);
    dispatch(
      startConfirm({
        description: { id: "eventGenerator.manager.confirmDelete" },
        onAccept: deleteEventGeneratorRequested(model),
        variant: "delete",
      }),
    );
  };

  return (
    <StyledPanelHeaderWrapper
      active={model.showConfig}
      labelRCGColorValues={model.color}
    >
      <HeaderLabelSection>
        <Icons.VertDots height="20px" />
        <EventGeneratorNameEditor model={model} visible={visible} />
      </HeaderLabelSection>
      <HeaderActionSection>
        <PanelHeaderAction
          id={model.id}
          tooltip="tooltip.deleteEventGenerator"
          onClick={onDelete}
          disabled={model.id === NO_EVENT_GENERATOR}
          IconComponent={Icons.Trash}
        />
        <PanelHeaderAction
          id={model.id}
          tooltip={
            visible
              ? "tooltip.hideEventGenerator"
              : "tooltip.showEventGenerator"
          }
          onClick={onToggleVisibility}
          IconComponent={visible ? Icons.EyeSeen : Icons.EyeUnseen}
        />
        <PanelHeaderAction
          onClick={onToggleConfig}
          id={model.id}
          tooltip={"tooltip.eventGeneratorSettings"}
          IconComponent={model.showConfig ? Icons.ArrowUp : Icons.ArrowDown}
        />
      </HeaderActionSection>
    </StyledPanelHeaderWrapper>
  );
};
