import { useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { AppServiceContext } from "../api/appService";
import {
  projectSelectors,
  readProjectsRequested,
  selectProjectsReadAllState,
} from "../redux/reducers/projects";

import {
  CreateDevice,
  CreateDeviceFormLayout,
} from "../components/devices/CreateDevice";

export const DevicesCreate = () => {
  const dispatch = useAppDispatch();
  const [searchParams, _] = useSearchParams();
  // this needs to be set in the redirect to auth0
  const auth0State = searchParams.get("state");

  const { hasToken } = useContext(AppServiceContext);

  const projectsReadState = useAppSelector(selectProjectsReadAllState);
  useEffect(() => {
    if (hasToken && projectsReadState.type === "pending") {
      dispatch(readProjectsRequested());
    }
  }, [hasToken]);

  const projects = useAppSelector(projectSelectors.selectAll);

  const layout: CreateDeviceFormLayout =
    projectsReadState.type === "succeeded" && projects.length > 0
      ? "add-device"
      : projectsReadState.type === "succeeded"
      ? "no-projects"
      : "loading";

  return auth0State ? (
    <Grid container spacing={2} m={1}>
      <Grid item xs={12}>
        <Typography variant="h2">
          <FormattedMessage id="devices.create.title" />
        </Typography>
      </Grid>
      <CreateDevice
        auth0State={auth0State}
        layout={layout}
        projects={projects}
      />
    </Grid>
  ) : (
    <Typography variant="h3">
      <FormattedMessage id="devices.create.noAuth0State" />
    </Typography>
  );
};
