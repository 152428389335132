import { FC, FormEvent } from "react";
import { Icons, InlineInput, PanelHeaderAction } from "kepler.gl/components";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import {
  Annotator,
  updateAnnotator,
  deleteAnnotatorRequested,
} from "../../../redux/reducers/annotator";
import {
  HeaderActionSection,
  HeaderLabelSection,
  StyledPanelHeaderWrapper,
} from "../../common";
import { useAppDispatch } from "../../../redux/hooks";
import { newAnnotatorModal, showModal } from "../../../redux/reducers/ui/modal";
import { setCurrentAnnotatorToExisting } from "../../../redux/reducers/ui/annotatorBuilder";
import { startConfirm } from "../../../redux/reducers/ui/confirm";

const AnnotatorNameEditor: FC<AnnotatorPanelHeaderProps> = ({ model }) => {
  const dispatch = useAppDispatch();

  const onEdit = (e: FormEvent<HTMLInputElement>) => {
    dispatch(
      updateAnnotator({
        id: model.id,
        changes: { name: e.currentTarget.value },
      }),
    );
  };

  return (
    <InlineInput
      type="text"
      value={model.name}
      onClick={(e: FormEvent<HTMLInputElement>) => {
        e.stopPropagation();
      }}
      onChange={onEdit}
      id={`${model.name}:input-annotation-config-label`}
    />
  );
};

type AnnotatorPanelHeaderProps = {
  model: Annotator;
};

const EditIcon = (props: object) => (
  <ModeEditIcon {...props} sx={{ fontSize: "18px" }} />
);

export const AnnotatorPanelHeader: FC<AnnotatorPanelHeaderProps> = ({
  model,
}) => {
  const dispatch = useAppDispatch();

  const onToggleConfig = () => {
    dispatch(
      updateAnnotator({
        id: model.id,
        changes: { showConfig: !model.showConfig },
      }),
    );
  };

  const onDelete = () => {
    dispatch(
      startConfirm({
        description: { id: "annotator.manager.confirmDelete" },
        variant: "delete",
        onAccept: deleteAnnotatorRequested(model),
      }),
    );
  };

  return (
    <StyledPanelHeaderWrapper
      active={model.showConfig}
      labelRCGColorValues={[255, 255, 255]}
    >
      <HeaderLabelSection>
        <Icons.VertDots height="20px" />
        <AnnotatorNameEditor model={model} />
      </HeaderLabelSection>
      <HeaderActionSection>
        <PanelHeaderAction
          id={model.id}
          tooltip="tooltip.editAnnotator"
          onClick={() => {
            dispatch(setCurrentAnnotatorToExisting(model));
            dispatch(showModal(newAnnotatorModal(model)));
          }}
          IconComponent={EditIcon}
        />
        <PanelHeaderAction
          id={model.id}
          tooltip="tooltip.deleteAnnotator"
          onClick={onDelete}
          IconComponent={Icons.Trash}
        />
        <PanelHeaderAction
          onClick={onToggleConfig}
          id={model.id}
          tooltip={"tooltip.annotatorSettings"}
          IconComponent={model.showConfig ? Icons.ArrowUp : Icons.ArrowDown}
        />
      </HeaderActionSection>
    </StyledPanelHeaderWrapper>
  );
};
