import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

import { ConfirmVariant, dialogDone } from "../../redux/reducers/ui/confirm";
import { FC, useCallback } from "react";
import { MessageDescriptor, useIntl } from "react-intl";

const selectState = (state: RootState) => state.app.ui.confirm;

const KEY = "dialog-singleton";

type ConfirmInternalProps = {
  ok: MessageDescriptor;
  props?: {
    okButton?: ButtonProps;
    cancelButton?: ButtonProps;
  };
};

const ConfirmVariants: { [k in ConfirmVariant]: ConfirmInternalProps } = {
  delete: {
    ok: { id: "confirm.delete" },
    props: {
      okButton: { color: "error" },
    },
  },
};

type NoProps = Record<string, never>;

const Confirm: FC<NoProps> = function Confirm() {
  const confirmSlice = useAppSelector(selectState);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const visible = confirmSlice.visible;

  const handleCancel = useCallback(
    () => dispatch(dialogDone({ result: "cancelled" })),
    [],
  );

  const handleOk = useCallback(
    () => dispatch(dialogDone({ result: "OK" })),
    [],
  );

  if (!visible) {
    return <Dialog key={KEY} open={false} />;
  }

  const vProps = ConfirmVariants[confirmSlice.variant];
  const description = intl.formatMessage(confirmSlice.description);
  const okText = intl.formatMessage(vProps.ok);

  return (
    <Dialog key={KEY} open={true}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          {...vProps.props?.cancelButton}
          autoFocus
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button {...vProps.props?.okButton} onClick={handleOk}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { Confirm };
