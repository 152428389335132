import { createSelector } from "reselect";
import { MAX_SLIDER, MIN_SLIDER } from "../reducers/time-control";

import { RootState } from "../store";

export const selectTimeControl = (state: RootState) => state.app.timeControl;

export const selectLive = createSelector(selectTimeControl, (t) => t.live);
export const selectSelectionEnd = createSelector(
  selectTimeControl,
  (t) => t.selection.end,
);

export const selectSliderBounds = createSelector(
  selectTimeControl,
  (tc) => ({ start: tc.slider.end - tc.slider.length, end: tc.slider.end }),
  {
    memoizeOptions: {
      resultEqualityCheck: (s1, s2) =>
        s1.start === s2.start && s1.end === s2.end,
    },
  },
);

export const sliderZoomedInFully = createSelector(
  selectTimeControl,
  (tc) => MIN_SLIDER >= tc.slider.length,
);

export const sliderZoomedOutFully = createSelector(
  selectTimeControl,
  (tc) => tc.slider.length >= MAX_SLIDER,
);
