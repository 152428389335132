import { IconLayer } from "@deck.gl/layers";
import { DataFilterExtension } from "@deck.gl/extensions";

import { TIME_REF } from "../constants";
import { RootState } from "../redux/store";
import { selectTimeControl } from "../redux/selectors/time-control";
import { eventGeneratorSelectors } from "../redux/reducers/event-generators";
import * as E from "../redux/reducers/events";
import { selectEventsByEventGeneratorIds } from "../redux/selectors/events";
import { filledTypedArray } from "../utils/color";
import { objectEntries } from "../redux/reducers/chunks";
import { createChunkSelector } from "../redux/selectors/creators";
import * as arrow from "apache-arrow";
import { flattenFixedSizeList, makeVectorFixedSizeList } from "../utils/arrow";
import { toArrayBufferView } from "apache-arrow/util/buffer";
import { TimeControlState } from "../redux/reducers/time-control";
import { TooltipProps } from "../components/tooltips";
import { selectVisible } from "../redux/selectors/settings";
import { EntityType } from "../redux/reducers/settings";

type Event = E.Event;

const ICON_MAPPING = {
  0: { x: 0, y: 0, width: 128, height: 128, anchorY: 128, mask: true },
};

const selectTable = createChunkSelector(
  [selectEventsByEventGeneratorIds],
  [eventGeneratorSelectors.selectAll, selectVisible(EntityType.EventGenerator)],
  (chunkId, eges, egs, visibilities) => {
    return egs.map((eg) => {
      if (!eg) {
        return null;
      }
      if (!eges) {
        return null;
      }
      const es = eges[eg.id];
      if (!es) {
        return null;
      }
      const length = es.length;
      const positions = arrow.vectorFromArray(
        es.map((e) => [e.lon, e.lat]),
        new arrow.FixedSizeList(2, new arrow.Field("_", new arrow.Float64())),
      );
      const icons = arrow.makeVector(new Uint8Array(length).fill(0));
      const colorsBuf = filledTypedArray(eg.color, length);
      const colors = makeVectorFixedSizeList(
        colorsBuf,
        new arrow.FixedSizeList(4, new arrow.Field("_", new arrow.Uint8())),
      );
      const filterValue = arrow.makeVector(
        Float32Array.from(es.map((e) => e.time - TIME_REF)),
      );
      const size = arrow.makeVector(new Float32Array(length).fill(3));
      const rawEvents = es;

      return {
        table: new arrow.Table({
          positions,
          icons,
          colors,
          filterValue,
          size,
        }),
        eg: { id: eg.id, visible: visibilities[eg.id] ?? true },
        es: rawEvents,
      };
    });
  },
);

const selectLayerData = createChunkSelector(
  [selectTable],
  [],
  (chunkId, tablesAndEgs) => {
    if (!tablesAndEgs) {
      return undefined;
    }
    const layers = tablesAndEgs.map((tableAndEg) => {
      if (!tableAndEg) {
        return null;
      }
      const { table, eg, es } = tableAndEg;

      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const positions = flattenFixedSizeList(
        table.getChild("positions")!,
        true,
      );
      const icons = table.getChild("icons")!.toArray();
      const filterValue = table.getChild("filterValue")!.toArray();
      const size = table.getChild("size")!.toArray();
      const colors = flattenFixedSizeList(table.getChild("colors")!, true);
      /* eslint-enable @typescript-eslint/no-non-null-assertion */

      const data = {
        length: table.numRows,
        attributes: {
          getPosition: { value: positions, size: 2 },
          getIcon: { value: icons, size: 1 },
          getFilterValue: { value: filterValue, size: 1 },
          getSize: { value: size, size: 1 },
          getColor: {
            value: toArrayBufferView(Uint8ClampedArray, colors),
            size: 4,
          },
        },
      };
      return {
        egId: eg.id,
        id: `events-layer-${eg.id.toString()}-${chunkId}`,
        data,
        visible: eg.visible,
        es,
      };
    });
    const res = new Map();
    for (const l of layers) {
      if (!l) {
        continue;
      }
      res.set(l.egId, l);
    }
    return res;
  },
);

type LayerData = {
  id: string;
  data: unknown;
  visible: boolean;
  /** these are sent through for the sole purpose of the tooltip. */
  es: Event[];
};

function renderLayer(
  timeControl: TimeControlState,
  setTooltip: (_: TooltipProps) => void,
  { id, data, visible, es }: LayerData,
) {
  const rangeFromHard =
    timeControl.selection.end - timeControl.selection.length * 0.9 - TIME_REF;
  const rangeFromSoft =
    timeControl.selection.end - timeControl.selection.length - TIME_REF;
  const rangeTo = timeControl.selection.end - TIME_REF;

  return new IconLayer<Event>({
    id,
    data: data as any, // eslint-disable-line @typescript-eslint/no-explicit-any
    visible,
    iconAtlas:
      "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png",
    iconMapping: ICON_MAPPING,
    pickable: true,
    autoHighlight: true,
    sizeScale: 10,
    parameters: {
      depthTest: false,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onHover: (info) => {
      setTooltip({
        tooltipType: "event",
        hoverInfo: { ...info, object: es[info.index] },
      });
      return true;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filterRange: [rangeFromSoft, rangeTo],
    filterSoftRange: [rangeFromHard, rangeTo],
    filterTransformSize: false,
    extensions: [new DataFilterExtension({ filterSize: 1 })],
  });
}

export default function (
  state: RootState,
  setTooltip: (_: TooltipProps) => void,
) {
  const timeControl = selectTimeControl(state);
  const eventGenerators = eventGeneratorSelectors.selectAll(state);

  const renderEnd = timeControl.selection.end;
  const renderStart = renderEnd - timeControl.selection.length;

  const chunkIds = new Set(E.computeChunksInRange(renderStart, renderEnd));

  const layers = selectLayerData.some(state, chunkIds);

  return objectEntries(layers.chunks).flatMap(([_cid, chunkLayers]) =>
    eventGenerators.map((eg) => {
      const layer = chunkLayers && chunkLayers.get(eg.id);
      return layer && renderLayer(timeControl, setTooltip, layer);
    }),
  );
}
