import { put, takeLatest, delay, all } from "redux-saga/effects";

import { setLive, tickLive } from "../reducers/time-control";

function* setLiveSaga(action: ReturnType<typeof setLive>) {
  const live = action.payload;
  if (!live) {
    return;
  }

  do {
    yield put(tickLive(new Date()));
    yield delay(1000);
  } while (true);
}

export default function* () {
  yield all([
    // trigger this on start, but make sure it goes through the takeLatest mechanism.
    put(setLive(true)),
    takeLatest(setLive, setLiveSaga),
  ]);
}
