import { ReactJason } from "react-jason";

import { RootState } from "../../../redux/store";
import { useAppSelector } from "../../../redux/hooks";
import { selectDeviceStateAnnotations } from "../../../redux/selectors/fields";
import { annotationKeys } from "../../../redux/reducers/annotator";

export const selectBuiltAnnotator = (state: RootState) =>
  state.app.ui.annotatorBuilder.current;

export const AnnotationPreview = () => {
  const existingAnnotations = useAppSelector(selectDeviceStateAnnotations);
  const builtAnnotator = useAppSelector(selectBuiltAnnotator);

  const annotationsWithTypes = {
    ...existingAnnotations,
    ...annotationKeys(builtAnnotator),
  };

  return <ReactJason value={annotationsWithTypes} />;
};
