import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { RGBAColor } from "deck.gl";
import { Button, Icons, SidePanelSection, Tooltip } from "kepler.gl/components";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  FieldName,
  FieldValue,
  IconButton,
  SidePanelIcons,
} from "../../common";
import { setDeviceColor } from "../../../redux/reducers/devices";
import { selectDevices } from "../../../redux/selectors/devices";
import { SidePanelRow } from "../SidePanelRow";
import { DevicePanelHeader } from "./DevicePanelHeader";
import {
  EntityType,
  updateEntityVisibility,
} from "../../../redux/reducers/settings";
import { selectVisible } from "../../../redux/selectors/settings";

export default () => {
  const dispatch = useAppDispatch();
  const devices = useAppSelector(selectDevices);
  const visibilities: { [key: string]: boolean } = useAppSelector(
    selectVisible(EntityType.Device),
  );

  const onSetColor = useMemo(
    () => (id: string, color: RGBAColor) => {
      dispatch(setDeviceColor({ id, color }));
    },
    [dispatch],
  );

  return (
    <>
      <SidePanelSection>
        <SidePanelIcons>
          <IconButton>
            <Icons.EyeUnseen
              data-tip
              data-for="device.hideAll"
              onClick={() =>
                dispatch(
                  updateEntityVisibility({
                    entityType: EntityType.Device,
                    updateAll: true,
                    value: false,
                  }),
                )
              }
              height="26"
            />
            <Tooltip id="device.hideAll" effect="solid" delayShow={500}>
              <FormattedMessage id="device.hideAll" />
            </Tooltip>
          </IconButton>
          <IconButton>
            <Icons.EyeSeen
              data-tip
              data-for="device.showAll"
              onClick={() =>
                dispatch(
                  updateEntityVisibility({
                    entityType: EntityType.Device,
                    updateAll: true,
                    value: true,
                  }),
                )
              }
              height="26"
            />
            <Tooltip id="device.showAll" effect="solid" delayShow={500}>
              <FormattedMessage id="device.showAll" />
            </Tooltip>
          </IconButton>
        </SidePanelIcons>
      </SidePanelSection>
      <SidePanelSection>
        <a
          href="https://github.com/swift-nav/sora-device-client"
          target="_blank"
        >
          <Button tabIndex={-1} width="105px">
            <Icons.Add height="12px" />
            <FormattedMessage id="device.add" />
          </Button>
        </a>
      </SidePanelSection>
      <SidePanelSection>
        {devices.map((device) => (
          <SidePanelRow
            key={device.id}
            model={device}
            visible={visibilities[device.id] ?? true}
            onSetColor={onSetColor}
            Header={DevicePanelHeader}
          >
            <FieldName>
              <FormattedMessage id={"device.details.id"} />
            </FieldName>
            <FieldValue>{device.id}</FieldValue>
          </SidePanelRow>
        ))}
      </SidePanelSection>
    </>
  );
};
