import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Annotator } from "../../../redux/reducers/annotator";
import { FieldName, FieldValue } from "../../common";

type AnnotatorConfiguratorProps = {
  model: Annotator;
};

export const AnnotatorConfigurator: FC<AnnotatorConfiguratorProps> = ({
  model,
}) => {
  return (
    <>
      <FieldName>
        <FormattedMessage id="annotator.config.id" />
      </FieldName>
      <FieldValue>{model.id}</FieldValue>
    </>
  );
};
