import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { DialogContent } from "@mui/material";
import { DataGrid, GridColDef, GridSortItem } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import ExploreIcon from "@mui/icons-material/Explore";

import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { createChunkSelector } from "../../../redux/selectors/creators";
import {
  selectEvents,
  selectEventsForLog,
} from "../../../redux/selectors/events";
import { centerSpaceTime, SpaceTime } from "../../../redux/reducers/events";
import { createSelector } from "reselect";
import { setLive } from "../../../redux/reducers/time-control";

type CenterEventProps = {
  spaceTime: SpaceTime;
  onClose: () => void;
};

const CenterEvent: FC<CenterEventProps> = ({ spaceTime, onClose }) => {
  const dispatch = useAppDispatch();
  const onCenter = useCallback(
    (x) => () => {
      dispatch(setLive(false));
      dispatch(centerSpaceTime(x));
      onClose();
    },
    [],
  );

  return (
    <IconButton onClick={onCenter(spaceTime)}>
      <ExploreIcon />
    </IconButton>
  );
};

type EventLogProps = {
  onClose: () => void;
};

// TODO: just keep it sorted in the first place
const _selectSortedAugmentedEvents = createChunkSelector(
  [selectEventsForLog],
  [],
  (_chunkId, events) => {
    if (!events) {
      return events;
    }
    return [...events]
      .sort((a, b) => b.time - a.time)
      .map((e, i) => ({
        ...e,
        id: i,
      }));
  },
);

const selectSortedAugmentedEvents = createSelector(
  [_selectSortedAugmentedEvents.all],
  (sorted) => {
    const es = Object.values(sorted.chunks)
      .filter(((es) => es !== undefined) as <T>(t: T | undefined) => t is T)
      .flat(1);
    return es;
  },
);

const selectEventsLoading = createSelector(selectEvents, function (events) {
  const somePending = Object.values(events.chunks).find(
    (c) => c?.requestState.type === "pending",
  );
  if (somePending !== undefined) {
    return "pending";
  } else {
    return "successful";
  }
});

export const EventLog: FC<EventLogProps> = ({ onClose }) => {
  const intl = useIntl();
  const sortedAugmentedEvents = useAppSelector(selectSortedAugmentedEvents);
  const eventsLoading = useAppSelector(selectEventsLoading);

  const columns: GridColDef<typeof sortedAugmentedEvents[number]>[] = useMemo(
    () => [
      {
        field: "center",
        headerName: "",
        width: 40,
        disableExport: true,
        align: "center",
        renderCell: ({ row: { lat, lon, alt, time } }) => (
          <CenterEvent spaceTime={{ lat, lon, alt, time }} onClose={onClose} />
        ),
      },
      {
        field: "time",
        headerName: intl.formatMessage({ id: "events.log.time" }),
        valueFormatter: ({ value }) => new Date(value * 1000).toLocaleString(),
        minWidth: 190,
      },
      {
        field: "deviceId",
        headerName: intl.formatMessage({ id: "events.log.deviceId" }),
        minWidth: 300,
      },
      {
        field: "lat",
        headerName: intl.formatMessage({ id: "events.log.lat" }),
        minWidth: 100,
      },
      {
        field: "lon",
        headerName: intl.formatMessage({ id: "events.log.lon" }),
        minWidth: 100,
      },
      {
        field: "alt",
        headerName: intl.formatMessage({ id: "events.log.alt" }),
        minWidth: 100,
      },
      {
        field: "eventGeneratorName",
        headerName: intl.formatMessage({ id: "events.log.eventGeneratorName" }),
        minWidth: 200,
      },
      {
        field: "alertName",
        headerName: intl.formatMessage({ id: "events.log.alertName" }),
        minWidth: 200,
      },
      {
        field: "payload",
        headerName: intl.formatMessage({ id: "events.log.payload" }),
        flex: 800,
        minWidth: 400,
        valueGetter: ({ row: payload }) => JSON.stringify(payload),
      },
    ],
    [],
  );

  const sortModel = useMemo(
    () => [{ field: "time", sort: "desc" } as GridSortItem],
    [],
  );
  return (
    <DialogContent>
      <DataGrid
        rows={sortedAugmentedEvents}
        columns={columns}
        density="compact"
        autoPageSize
        disableColumnFilter
        disableColumnMenu
        loading={eventsLoading === "pending"}
        sortModel={sortModel}
      />
    </DialogContent>
  );
};
