import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { MenuItem, Typography } from "@mui/material";

import {
  devicesSelector,
  readDevicesRequested,
} from "../../redux/reducers/devices";
import { useAppDispatch } from "../../redux/hooks";
import { CreateDeviceFormValues } from "./CreateDevice";

type DeviceSelectionProps = {
  projectId: string;
  values: CreateDeviceFormValues;
};

export const DeviceSelection: FC<DeviceSelectionProps> = ({
  projectId,
  values,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const devices = useSelector(devicesSelector.selectAll);

  useEffect(() => {
    dispatch(readDevicesRequested(projectId));
  }, [projectId]);

  return (
    <Field
      component={TextField}
      name="deviceId"
      select
      label={intl.formatMessage({
        id: "devices.create.deviceId.label",
      })}
      value={values.deviceId || ""}
      fullWidth
    >
      {devices.map((p) => (
        <MenuItem key={p.id} value={p.id}>
          <Typography noWrap>{p.name}</Typography>
        </MenuItem>
      ))}
    </Field>
  );
};
