import { GeoJsonLayer } from "@deck.gl/layers";
import { Feature } from "geojson";

import { RootState } from "../redux/store";
import { setOpacity } from "../utils/color";
import { TooltipProps } from "../components/tooltips";
import { useAppSelector } from "../redux/hooks";
import { EntityType } from "../redux/reducers/settings";
import { selectVisible } from "../redux/selectors/settings";

function getLineWidth(f: Feature): number {
  switch (f.geometry.type) {
    case "LineString":
    case "MultiLineString":
      return 2;
    default:
      return 1;
  }
}

const geoLayers = (state: RootState, setTooltip: (_: TooltipProps) => void) => {
  const visibilities: { [key: string]: boolean } = useAppSelector(
    selectVisible(EntityType.Layer),
  );
  return Object.values(state.app.geoLayers.entities).flatMap((l) =>
    l
      ? [
          new GeoJsonLayer<Feature>({
            id: "geo-layer-" + l.id,
            data: l.features,
            pickable: true,
            autoHighlight: true,
            getFillColor: (_) => setOpacity(l.color, 127),
            highlightColor: setOpacity(l.color, 30),
            getLineColor: (_) => setOpacity(l.color, 190),
            getLineWidth: getLineWidth,
            lineWidthUnits: "pixels",
            pointRadiusMinPixels: 5,
            visible: visibilities[l.id] ?? true,
            updateTriggers: {
              getFillColor: l.color,
              getLineColor: l.color,
            },
            onHover: (info) =>
              setTooltip({ tooltipType: "feature", hoverInfo: info }),
          }),
        ]
      : [],
  );
};

export default geoLayers;
