import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  changeProjectAndNavigateToDashboard,
  projectSelectors,
} from "../../redux/reducers/projects";

export const ProjectSelection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [projectName, setProjectName] = useState(params.projectId);
  const projects = useAppSelector(projectSelectors.selectAll);

  const handleChange = useMemo(
    () => (e: SelectChangeEvent) => {
      setProjectName(e.target.value);
      dispatch(
        changeProjectAndNavigateToDashboard({
          to: e.target.value,
          from: params.projectId,
          navigate,
        }),
      );
    },
    [setProjectName, navigate],
  );

  return (
    <FormControl size="small">
      <Select
        variant="outlined"
        value={projectName}
        onChange={handleChange}
        sx={{ width: 100 }}
      >
        {projects.map((p) => (
          <MenuItem key={p.id} value={p.id}>
            <Typography noWrap>{p.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
