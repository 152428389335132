import { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Button, Grid, Typography } from "@mui/material";

import { ProjectCard } from "../components/projects/ProjectCard";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  projectSelectors,
  readProjectsRequested,
  selectProjectsReadAllState,
} from "../redux/reducers/projects";
import { newProjectModal, showModal } from "../redux/reducers/ui/modal";
import { AppServiceContext } from "../api/appService";

export const Projects = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { hasToken } = useContext(AppServiceContext);

  useEffect(() => {
    if (hasToken) {
      dispatch(readProjectsRequested());
    }
  }, [hasToken]);

  const projects = useAppSelector(projectSelectors.selectAll);
  const readAllState = useAppSelector(selectProjectsReadAllState);

  return location.pathname.includes("dashboard") ? (
    <Outlet />
  ) : (
    <>
      <Grid container spacing={2} m={1}>
        <Grid item xs={12}>
          <Typography variant="h2">
            <FormattedMessage id="project.title" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => dispatch(showModal(newProjectModal()))}
          >
            <FormattedMessage id="project.create" />
          </Button>
        </Grid>
        {readAllState.type === "succeeded" ? (
          projects.map((p) => (
            <Grid key={p.id} item>
              <ProjectCard key={p.id} project={p}></ProjectCard>
            </Grid>
          ))
        ) : (
          <>
            <Grid item>
              <ProjectCard />
            </Grid>
          </>
        )}
      </Grid>
      <Outlet />
    </>
  );
};
