import { createSelector } from "reselect";
import { Device, devicesSelector } from "../reducers/devices";
import {
  EventGenerator,
  eventGeneratorSelectors,
} from "../reducers/event-generators";
import { Layer, layerSelectors } from "../reducers/layers";
import { EntityType } from "../reducers/settings";
import { RootState } from "../store";
import { selectProjectIdFromRoute } from "./router";

export const selectSettings = (state: RootState) => state.app.settings;

export const selectTimezone = createSelector(selectSettings, (s) => s.timezone);

export const selectDateFormatter = createSelector(
  selectSettings,
  (s) =>
    new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZoneName: "short",
      timeZone: s.timezone.ianaId,
    }),
);

// Get all user preferences
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectAllPreferences = createSelector(
  selectSettings,
  (s) => s?.projects,
);

// Get user preferences for the selected project
export const selectPreferences = createSelector(
  selectProjectIdFromRoute,
  selectAllPreferences,
  (projectId, preferences) => {
    if (!projectId) {
      return null;
    }
    return preferences[projectId];
  },
);

// Get the visibility settings for a particular entity type
export const selectVisible = (entityType: EntityType) => {
  return createSelector(selectPreferences, (preferences) => {
    if (!preferences || !preferences[entityType]) {
      return {};
    }
    return preferences[entityType];
  });
};

// Get all entities of a particular type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectEntities: any = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.EventGenerator:
      return eventGeneratorSelectors.selectAll;
    case EntityType.Layer:
      return layerSelectors.selectAll;
    case EntityType.Device:
      return devicesSelector.selectAll;
    default:
      throw new Error(`Invalid entity type: ${entityType}`);
  }
};

export type Entity = Layer | Device | EventGenerator;
