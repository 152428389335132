import { isFeature } from "geojson-validation";
import { Feature } from "geojson";

export const fromString = (s: string): Feature[] => {
  const maybeFeatures = JSON.parse(s);
  if (
    Array.isArray(maybeFeatures) &&
    maybeFeatures.every((f) => isFeature(f))
  ) {
    return maybeFeatures as unknown as Feature[];
  }
  return [];
};
