import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import LogoSvgAsset from "../../assets/logo.svg";
import { ProjectSelection } from "./ProjectSelection";

const LogoTitle = styled.div`
  display: inline-block;
  margin-left: 6px;
`;

const LogoName = styled.div`
  a {
    color: ${({ theme }) => theme.logoColor};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.17px;
  }
`;

const LogoVersion = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.subtextColor};
  letter-spacing: 0.83px;
  line-height: 14px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 2px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid ${({ theme }) => theme.logoColor}; ;
`;

const LogoSvg = () => <img src={LogoSvgAsset} alt="Logo" />;

type LogoProps = {
  appName: string;
  version: string;
};

const Logo: FC<LogoProps> = ({ appName, version }) => (
  <LogoWrapper>
    <Link to="/">
      <LogoSvg />
    </Link>
    <LogoTitle>
      <LogoName>
        <Link to="/">{appName}</Link>
      </LogoName>
      <LogoVersion>{version}</LogoVersion>
    </LogoTitle>
    <Triangle />
    <ProjectSelection />
  </LogoWrapper>
);

export default Logo;
