import { useCallback } from "react";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import { Button, Icons, SidePanelSection } from "kepler.gl/components";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { SidePanelRow } from "../SidePanelRow";
import {
  alertorSelectors,
  createAlertorRequested,
  newAlertor,
  updateAlertor,
} from "../../../redux/reducers/alertor";
import { SidePanelIcons } from "../../common";
import { AlertorPanelHeader } from "./AlertorPanelHeader";
import { AlertConfigurator } from "./AlertorConfigurator";

export default () => {
  const dispatch = useAppDispatch();
  const alertors = useAppSelector(alertorSelectors.selectAll);
  const params = useParams();

  const projectId = params.projectId;

  const onSetColor = useCallback(
    (id, color) => dispatch(updateAlertor({ id, changes: { color } })),
    [dispatch],
  );

  return (
    <>
      <SidePanelSection>
        <SidePanelIcons />
      </SidePanelSection>
      <SidePanelSection>
        <Button
          tabIndex={-1}
          width="105px"
          onClick={() =>
            projectId && dispatch(createAlertorRequested(newAlertor(projectId)))
          }
        >
          <Icons.Add height="12px" />
          <FormattedMessage id={"alertor.manager.addAlert"} />
        </Button>
      </SidePanelSection>
      <SidePanelSection>
        {alertors.map((alertor) => (
          <SidePanelRow
            key={alertor.id}
            model={alertor}
            onSetColor={onSetColor}
            Header={AlertorPanelHeader}
          >
            <AlertConfigurator model={alertor} />
          </SidePanelRow>
        ))}
      </SidePanelSection>
    </>
  );
};
