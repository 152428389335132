import { FC, useCallback, useState } from "react";
import { injectComponents } from "kepler.gl/components";
import throttle from "lodash.throttle";

import { APP_NAME, APP_URL, APP_VERSION, MAPBOX_TOKEN } from "../constants";
import { replaceLoadDataModal } from "../factories/load-data-modal";
import { replaceMapControl } from "../factories/map-control";
import { replacePanelHeader } from "../factories/panel-header";
import { injectCustomPanels } from "../factories/custom-panels";
import devicesLayer from "../layers/devices-layer";
import deviceHistoryLayers from "../layers/device-history-layer";
import geoLayers from "../layers/geo-layers";
import eventLayers from "../layers/events-layers";
import { messages } from "../intl/message";
import { Tooltip, TooltipProps } from "../components/tooltips";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";

// Custom styling for Kepler
import "../styles/kepler.css";

// NOTE: injectCustomPanels() injected first otherwise SidePanelFactory will override some injections
const KeplerGl = injectComponents([
  ...injectCustomPanels(),
  replaceLoadDataModal(),
  replaceMapControl(),
  replacePanelHeader(),
]);

type MapProps = {
  height: number;
  width: number;
};

export const Map: FC<MapProps> = ({ height, width }) => {
  const state = useAppSelector((state) => state);

  const [tooltip, setTooltipRaw] = useState<TooltipProps>();
  const setTooltip = useCallback(
    throttle(setTooltipRaw, 20, {
      trailing: true,
      leading: true,
    }),
    [setTooltipRaw],
  );

  const layers = [
    ...geoLayers(state, setTooltip),
    ...deviceHistoryLayers(state),
    ...devicesLayer(state, setTooltip),
    ...eventLayers(state, setTooltip),
  ];

  return (
    <>
      <KeplerGl
        appName={APP_NAME}
        appWebsite={APP_URL}
        version={APP_VERSION}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        id="map"
        getState={(state: RootState) => state.keplerGl}
        deckGlProps={{ layers }}
        width={width}
        height={height}
        localeMessages={messages}
      />
      {tooltip && <Tooltip {...tooltip} />}
    </>
  );
};
