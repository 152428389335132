import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { layerSelectors } from "../../../redux/reducers/layers";
import {
  selectCurrentAnnotator,
  setLayer,
  setAnnotationKeyName,
  setAnnotationName,
  setType,
} from "../../../redux/reducers/ui/annotatorBuilder";
import { GEOFENCE_ANNOTATION_TYPE } from "../../../redux/reducers/annotator";

export const GeofenceAnnotatorBuilder = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const layers = useAppSelector(layerSelectors.selectEntities);
  const currentAnnotator = useAppSelector(selectCurrentAnnotator);

  useEffect(() => {
    dispatch(setType(GEOFENCE_ANNOTATION_TYPE));
  }, []);

  if (currentAnnotator.type != GEOFENCE_ANNOTATION_TYPE) {
    return null;
  }

  return (
    <>
      <FormControl sx={{ m: 3 }}>
        <InputLabel id="annotator-select">
          <FormattedMessage id="annotator.modal.layer" />
        </InputLabel>
        <Select
          labelId="annotator-select"
          id="annotator-select"
          value={currentAnnotator?.config?.layerId || ""}
          label={intl.formatMessage({ id: "annotator.modal.layer" })}
          onChange={(e) => {
            const layer = layers[e.target.value as string];
            if (layer) {
              dispatch(setLayer(layer));
            }
          }}
        >
          {Object.values(layers).flatMap((layer) =>
            layer
              ? [
                  <MenuItem key={layer.id} value={layer.id}>
                    {layer.name}
                  </MenuItem>,
                ]
              : [],
          )}
        </Select>
        <TextField
          margin="dense"
          id="name"
          label={intl.formatMessage({
            id: "annotator.modal.annotatorName",
          })}
          type="text"
          fullWidth
          variant="standard"
          value={currentAnnotator.name}
          size="small"
          onChange={(e) => dispatch(setAnnotationName(e.target.value))}
        />
        <TextField
          margin="dense"
          id="name"
          label={intl.formatMessage({
            id: "annotator.modal.annotationKey",
          })}
          type="text"
          fullWidth
          variant="standard"
          value={currentAnnotator?.config?.annotationKeyName || ""}
          size="small"
          onChange={(e) => dispatch(setAnnotationKeyName(e.target.value))}
        />
        <FormHelperText>
          <FormattedMessage id="annotator.modal.annotationKeyHelper" />
        </FormHelperText>
      </FormControl>
    </>
  );
};
