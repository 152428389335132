import { TripsLayer } from "@deck.gl/geo-layers";
import * as arrow from "apache-arrow";
import { toArrayBufferView } from "apache-arrow/util/buffer";
import assert from "assert";

import { TIME_REF } from "../constants";
import { RootState } from "../redux/store";
import {
  selectColors,
  selectIds,
  selectIxsInSlider,
} from "../redux/selectors/devices";
import { selectTimeControl } from "../redux/selectors/time-control";
import { ChunkId } from "../redux/reducers/chunks";
import * as DS from "../redux/reducers/device-state";
import { TimeControlState } from "../redux/reducers/time-control";

import { setOpacity, filledTypedArray } from "../utils/color";
import {
  flattenBatches,
  flattenFixedSizeList,
  makeVectorFixedSizeList,
} from "../utils/arrow";

import { createChunkSelector } from "../redux/selectors/creators";
import { selectPaths, selectTimestamps } from "../redux/selectors/devices";
import { Position2D } from "@deck.gl/core";
import { selectVisible } from "../redux/selectors/settings";
import { EntityType } from "../redux/reducers/settings";

type Trip = {
  paths: Position2D[];
  timestamps: number[];
};

type TripTable2D = arrow.Table<{
  positions: arrow.FixedSizeList<arrow.Float64>;
  timestamps: arrow.Float32;
}>;

type TripTable3D = arrow.Table<{
  positions: arrow.FixedSizeList<arrow.Float64>;
  timestamps: arrow.Float32;
  colors: arrow.FixedSizeList<arrow.Uint8>;
}>;

// Define a constant time threshold for defining trips
// Setting this too low i.e <30 seconds will lead to very slow performance
const TRIP_THRESHOLD_SECONDS = 900;

/** For example, timestamps for a given device are
 *
 * [1677470100, 1677470105, 1677470170, 1677470175],
 *
 * this will split the paths into two or more trips if the gap
 * between two adjacent timestamps is greater than TRIP_THRESHOLD_SECONDS
 *
 * The result will be [1677470100, 1677470105], [1677470170, 1677470175].
 *
 * The purpose of this is to get rid of the long confusing device trails
 * generated when a device goes offline for various reasons
 *
 *  */
export const selectDeviceTrips = createChunkSelector(
  [selectPaths, selectTimestamps],
  [],
  function (_chunkId, devicesPaths, devicesTimestamps) {
    if (!devicesPaths || !devicesTimestamps) {
      return;
    }

    ASSERTS && assert(devicesPaths.length == devicesTimestamps.length);

    const deviceTrips: Trip[][] = new Array(devicesPaths.length);

    for (let deviceIx = 0; deviceIx < devicesPaths.length; deviceIx++) {
      const path: Position2D[] = devicesPaths[deviceIx];
      const timestamps: number[] = devicesTimestamps[deviceIx];

      const trips: Trip[] = [];

      // If there are no paths, move on
      if (timestamps.length == 0) {
        deviceTrips[deviceIx] = [];
        continue;
      }

      let start = 0;
      for (let j = 1; j < timestamps.length; j++) {
        // When threshold crosses, make it into a new trip
        if (timestamps[j] - timestamps[j - 1] > TRIP_THRESHOLD_SECONDS) {
          trips.push({
            paths: path.slice(start, j),
            timestamps: timestamps.slice(start, j),
          });
          start = j;
        }
      }

      // Push last trip
      trips.push({
        paths: path.slice(start),
        timestamps: timestamps.slice(start),
      });

      deviceTrips[deviceIx] = trips;
    }

    return deviceTrips;
  },
);

const selectDeviceTripTables = createChunkSelector(
  [selectDeviceTrips],
  [],
  function (_chunkId, deviceTrips) {
    if (deviceTrips === undefined) {
      return undefined;
    }

    /** number of devices */
    const n = deviceTrips.length;

    // overallocate, we will shrink them later. items only get set in here if there are >0 paths for that device.
    const deviceIndices: number[] = new Array(n);
    const deviceTripTables: TripTable2D[][] = new Array(n);
    const startIndices: number[] = new Array(n);

    // iterate, but ...
    let offset = 0;
    let i = -1;
    let k = -1;
    for (let deviceIx = 0; deviceIx < n; deviceIx++) {
      const trips = deviceTrips[deviceIx];

      // exclude devices with no trips i.e no paths.
      if (trips.length == 0) {
        continue;
      }

      i++;

      const tripTables: TripTable2D[] = [];
      for (let t = 0; t < trips.length; t++) {
        const { paths, timestamps } = trips[t];

        const positionsVec = arrow.vectorFromArray(
          paths,
          new arrow.FixedSizeList(2, new arrow.Field("_", new arrow.Float64())),
        );
        const timestampsVec = arrow.vectorFromArray(
          timestamps,
          new arrow.Float32(),
        );

        ASSERTS && assert(positionsVec.length === timestampsVec.length);

        const trip = new arrow.Table({
          positions: positionsVec,
          timestamps: timestampsVec,
        });

        tripTables.push(trip);

        k++;
        startIndices[k] = offset;
        offset += trip.numRows;
      }

      deviceIndices[i] = deviceIx;
      deviceTripTables[i] = tripTables;
    }

    // shrink length
    deviceIndices.length = deviceTripTables.length = i + 1;
    startIndices.length = k + 1;

    return {
      tables: deviceTripTables,
      startIndices,
      deviceIndices,
    };
  },
);

const selectDeviceTriplayers = createChunkSelector(
  [selectDeviceTripTables],
  [
    selectIxsInSlider,
    selectIds,
    selectColors,
    selectVisible(EntityType.Device),
  ],
  function (_chunkid, paths, ixs, ids, colors, visible) {
    if (paths === undefined) {
      return undefined;
    }

    const { deviceIndices, tables } = paths;
    const deviceTripTables: TripTable3D[][] = [];

    for (let i = 0; i < deviceIndices.length; i++) {
      const deviceIx = deviceIndices[i];
      const trips = tables[i];

      const deviceTrips: TripTable3D[] = [];
      for (let t = 0; t < trips.length; t++) {
        const table = trips[t];
        const color = setOpacity(
          colors[deviceIx],
          visible[ids[deviceIx]] ?? true ? 255 : 0,
        );
        const colorBuf = filledTypedArray(color, table.numRows);

        ASSERTS && assert(colorBuf.length === table.numRows * 4);

        const colorsVec = makeVectorFixedSizeList(
          colorBuf,
          new arrow.FixedSizeList(4, new arrow.Field("_", new arrow.Uint8())),
        );

        const trip: TripTable3D = table.assign(
          new arrow.Table({ colors: colorsVec }),
        );
        deviceTrips.push(trip);
      }

      deviceTripTables[i] = deviceTrips;
    }

    return {
      tables: deviceTripTables,
      deviceIndices: paths.deviceIndices,
      startIndices: paths.startIndices,
    };
  },
);

/** Given chunks for device X are
 *
 * `[1,2,3], [4,5,6], [7,8,9]` ,
 *
 * this will generate some extra layers to patch the bounds between adjacent
 * chunks, e.g.
 *
 * `[3,4], [6,7]` .
 *
 * The purpose of this is because deck.gl draws a path using
 * the array entries as points, and the paths for adjacent chunks
 * need to be visually joined.
 *
 *  */
export const selectDeviceJoiningTriplayer = createChunkSelector(
  [
    selectDeviceTriplayers,
    (state: RootState, chunkId: ChunkId) => {
      const prevChunkId = parseInt(chunkId) - 1;
      return selectDeviceTriplayers(state, `${prevChunkId}`);
    },
  ],
  [],
  function (chunkId, devices, prevDevices) {
    if (devices === undefined || prevDevices === undefined) {
      return undefined;
    }

    const { deviceIndices, tables } = prevDevices;
    const prevTripsMap: { [deviceIx: number]: TripTable3D[] } = {};

    for (let i = 0; i < deviceIndices.length; i++) {
      const deviceIx = deviceIndices[i];
      prevTripsMap[deviceIx] = tables[i];
    }

    const joiningTables: TripTable3D[] = [];
    const startIndices: number[] = [];

    let j = -1;
    let offset = 0;
    for (let i = 0; i < devices.deviceIndices.length; i++) {
      // typescript shouldn't need this annotation, check again in a few versions (4.9)
      const deviceIx: number = devices.deviceIndices[i];

      // device wasn't in the previous chunk, nothing to join
      if (!(deviceIx in prevTripsMap)) {
        continue;
      }

      // Get first trip in the current chunk
      const trip: TripTable3D = devices.tables[i][0];

      // Get the last trip in the old chunk
      const prevTrips = prevTripsMap[deviceIx];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const prevTrip: TripTable3D = prevTrips.at(-1)!;

      // First timestmap in the trip and the last timestamp of the last trip
      const startOfThis = trip.get(0);
      const endOfLast = prevTrip.get(prevTrip.numRows - 1);

      if (!startOfThis || !endOfLast) {
        throw new Error("missing joining rows");
      }

      const startTimestamp = startOfThis.timestamps;
      const endTimestamp = endOfLast.timestamps;

      if (!startTimestamp || !endTimestamp) {
        throw new Error("missing timesstamps");
      }

      // If the gap between chunks is more than the trip threshold, don't create a joining layer
      if (startTimestamp - endTimestamp > TRIP_THRESHOLD_SECONDS) {
        continue;
      }

      j++;

      const table: TripTable3D = new arrow.Table({
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        positions: arrow.vectorFromArray(
          [endOfLast.positions.toArray(), startOfThis.positions.toArray()],
          trip.getChild("positions")!.type,
        ),
        timestamps: arrow.vectorFromArray(
          [endOfLast.timestamps, startOfThis.timestamps],
          trip.getChild("timestamps")!.type,
        ),
        colors: arrow.vectorFromArray(
          [endOfLast.colors.toArray(), startOfThis.colors.toArray()],
          trip.getChild("colors")!.type,
        ),
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      });

      startIndices[j] = offset;
      joiningTables[j] = table;
      offset += table.numRows; // always 2...
    }

    startIndices.length = joiningTables.length = j + 1;
    return { tables: joiningTables, startIndices: startIndices };
  },
);

const selectTriplayerTable = createChunkSelector(
  [selectDeviceTriplayers, selectDeviceJoiningTriplayer],
  [],
  function (chunkId, devices, joiningTables) {
    if (devices === undefined) {
      return undefined;
    }

    // at this point, allTrips is a big table with one row for each path vertex. However not it's
    // still probably split internally into a recordbatch per device, because that's how concat()
    // works fast.
    const tripTables = devices.tables.flat(1);
    let allTrips: TripTable3D | undefined;
    let joiningLayer: TripTable3D | undefined;

    if (tripTables.length > 0) {
      allTrips = tripTables[0].concat(...tripTables.slice(1));

      // we join it now, because the intention is that all this happens in a webworker to keep things fast.
      allTrips = flattenBatches(allTrips);
      ASSERTS && assert(allTrips.batches.length == 1);

      if (joiningTables && joiningTables.tables.length > 0) {
        joiningLayer = joiningTables.tables[0].concat(
          ...joiningTables.tables.slice(1),
        );
        joiningLayer = flattenBatches(joiningLayer);
      }
    }

    return {
      tripLayer: allTrips,
      startIndices: devices.startIndices,
      joiningLayer: joiningLayer,
      joiningStartIndices: joiningTables?.startIndices,
    };
  },
);

const selectTriplayers = createChunkSelector(
  [selectTriplayerTable],
  [],
  function (chunkId, trips) {
    if (!trips) {
      return undefined;
    }
    return (
      [
        [
          `device-history-layer-${chunkId}`,
          trips.tripLayer,
          trips.startIndices,
        ],
        [
          `device-history-layer-${chunkId}-joining`,
          trips.joiningLayer,
          trips.joiningStartIndices,
        ],
      ] as const
    )
      .map(([layerId, layer, indices]) => {
        if (!layer || !indices) {
          return undefined;
        }

        // These should all be zero-copy operations
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const _pos = flattenFixedSizeList(layer.getChild("positions")!, true);
        const _color = flattenFixedSizeList(layer.getChild("colors")!, true);
        const _timestamps = layer.getChild("timestamps")!.toArray();
        /* eslint-enable @typescript-eslint/no-non-null-assertion */

        return {
          id: layerId,
          data: {
            length: indices.length,
            startIndices: indices,
            attributes: {
              getPath: {
                value: _pos,
                size: 2,
              },
              getColor: {
                value: toArrayBufferView(Uint8ClampedArray, _color),
                size: 4,
              },
              getTimestamps: {
                value: _timestamps,
                size: 1,
              },
            },
          },
        };
      })
      .filter(<T>(result: T | undefined): result is T => result !== undefined);
  },
);

function SoraTripsLayer(
  id: string,
  data: unknown,
  timeControl: TimeControlState,
) {
  return new TripsLayer({
    id: id,
    data: data,
    positionFormat: "XY",
    opacity: 0.8,
    widthUnits: "pixels",
    getWidth: 3,
    parameters: {
      depthTest: false,
    },
    trailLength: timeControl.selection.length,
    currentTime: timeControl.selection.end - TIME_REF,
    _pathType: "open",
  });
}

export default function (state: RootState) {
  const timeControl = selectTimeControl(state);

  const renderEnd = timeControl.selection.end;
  const renderStart = renderEnd - timeControl.selection.length;

  const chunkIds = new Set(DS.computeChunksInRange(renderStart, renderEnd));

  const tripsChunksInRange = selectTriplayers.some(state, chunkIds);

  const layers = Object.values(tripsChunksInRange.chunks).flatMap(
    (tripLayerData) => {
      if (!tripLayerData) {
        return [];
      }

      return tripLayerData.map((layer) =>
        SoraTripsLayer(layer.id, layer.data, timeControl),
      );
    },
  );

  return layers;
}
