import { Draft, original } from "immer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as dateFns from "date-fns";
import { startOfDayInTz } from "../../utils/date";

import { centerSpaceTime } from "./events";
import assert from "assert";
import { defaultTimezone, Timezone } from "../../constants/Timezones";

export type TimeRange = {
  length: number;
  end: number;
};

export type TimeControlState = {
  live: boolean;
  now: number;
  selection: TimeRange;
  slider: TimeRange;
  rangePopupVisible: boolean;
};

const getInitialState = (t: number): TimeControlState => {
  const state = {
    live: true,
    now: t,
    selection: {
      length: 30 * 60,
      end: t,
    },
    slider: {
      length: 1 * 3600,
      end: t,
    },
    rangePopupVisible: false,
    timePopupVisible: true,
  };
  _setSliderAndSelectionDate(state, dateFns.fromUnixTime(t), defaultTimezone);
  return state;
};

export const MAX_SLIDER = dateFns.milliseconds({ days: 3 }) / 1000;
export const MIN_SLIDER = dateFns.milliseconds({ minutes: 1 }) / 1000;

function clampRangeToConstraints(range: TimeRange): TimeRange {
  if (MIN_SLIDER > range.length) {
    return {
      end: range.end,
      length: MIN_SLIDER,
    };
  } else if (range.length > MAX_SLIDER) {
    return {
      end: range.end,
      length: MAX_SLIDER,
    };
  } else {
    return range;
  }
}

/**
 * Given a factor n, and a (slider containing a [selection]  ),
 * returns a new slider, with length *= n, with the proportions
 * of space around the selection preserved.
 */
function zoomSliderIn(
  factor: number,
  selection: TimeRange,
  slider: TimeRange,
): TimeRange {
  ASSERTS && assert(factor <= 1);

  const sliderStart = slider.end - slider.length;
  const selectionStart = selection.end - selection.length;

  // if zooming in, we want to keep the proportions of
  // (space before selection)[=====](space after selection).
  // If the slider becomes smaller than the selection, then
  // if we want to keep the selection end fixed and crop
  // the start.

  // currently:

  // before + sel + after == slider
  // before + after = slider - sel := remainder (1)
  // before / after := prop (2)
  // => after = remainder/(1+p)

  const oldBefore = selectionStart - sliderStart;
  const oldAfter = slider.end - selection.end;

  // force 0/0 to 1, other n/0 gets Infinity which is ok.
  const proportion = oldAfter == 0 && oldBefore == 0 ? 1 : oldBefore / oldAfter;

  // so with new length:
  let newLength = slider.length * factor;
  newLength = Math.max(newLength, MIN_SLIDER);

  const remainder = newLength - selection.length;

  const newAfter = remainder / (1 + proportion);

  // if we go smaller than the selection, newAfter < 0, so Math.ax
  // locks the slider end in this case.
  const sliderEnd = selection.end + Math.max(newAfter, 0);

  ASSERTS && assert(isFinite(sliderEnd) && isFinite(newLength));

  return { end: sliderEnd, length: newLength };
}

function zoomSliderOut(
  factor: number,
  selection: TimeRange,
  slider: TimeRange,
  live: boolean,
  now: number,
) {
  ASSERTS && assert(factor >= 1);

  // if zooming out, we want the proportions before:after to approach K:1.
  const K = 4;

  // so, we distribute any new space to before:after by ratio K:1.
  // const oldBefore = selectionStart - sliderStart; // true but unneeded
  const oldAfter = slider.end - selection.end;

  let newLength = slider.length * factor;
  newLength = Math.min(newLength, MAX_SLIDER);

  const extraSpace = newLength - slider.length;

  //const newBefore = oldBefore + (K / (K + 1)) * extraSpace; // true but unneeded
  let newAfter: number;
  if (live) {
    // if live, lock slider end to selection end, and assume selection end is now.
    newAfter = 0;
  } else {
    newAfter = oldAfter + (1 / (K + 1)) * extraSpace;
    // if not live, also lock slider end to now if necessary.
    // this and the above could be simplified to remove branching on (live),
    // but I don't want to.. I believe we shouldn't be locking to `now` if not live,
    // I just have yet to convince Sachin...
    const timeToNow = now - selection.end;
    newAfter = Math.min(newAfter, timeToNow);
  }

  const sliderEnd = selection.end + newAfter;
  return { end: sliderEnd, length: newLength };
}

export function _zoomSlider(
  factor: number,
  selection: TimeRange,
  slider: TimeRange,
  live: boolean,
  now: number,
) {
  if (factor < 1) {
    return zoomSliderIn(factor, selection, slider);
  } else {
    return zoomSliderOut(factor, selection, slider, live, now);
  }
}

// Expand the slider range if necessary to fit whole selection.
// Note: this is *NOT* a pure function
const expandSliderToSelection = (state: Draft<TimeControlState>) => {
  if (state.selection.end > state.slider.end) {
    state.slider.end = state.selection.end;
  }
  if (
    state.selection.end - state.selection.length <
    state.slider.end - state.slider.length
  ) {
    state.slider.length =
      state.selection.length + state.slider.end - state.selection.end;
  }
};

//
function cropSelectionToSlider(
  selection: TimeRange,
  slider: TimeRange,
): TimeRange {
  const selectionStart = selection.end - selection.length;
  const sliderStart = slider.end - slider.length;

  const end = Math.max(
    Math.min(selection.end, slider.end), // crop the right
    sliderStart + 0.05 * slider.length, // but make sure it's at least 1/20 from the left
  );
  const start = Math.min(
    Math.max(selectionStart, sliderStart), // crop the left
    slider.end - 0.05 * slider.length, // but make sure it's at least 1/20 from the right
  );

  const length = end - start;

  if (selection.end == end && selection.length == length) {
    return selection; // no change, so don't make redux think there is one
  } else {
    return { end, length };
  }
}

/**
 * sets the slider to the full range of `date`.
 * `date` MUST be a date representing the start of a day.
 * a date of 2000/01/01 14:00 is valid here: it represents the
 * start of the day for people whose timezone is +1000.
 */
function setSliderToDate(date: Date, now: number): TimeRange {
  const endDate = dateFns.addDays(date, 1);
  const startDate = date;
  const end = Math.min(dateFns.getUnixTime(endDate), now);
  const start = dateFns.getUnixTime(startDate);
  ASSERTS && assert(start < end);
  const length = end - start;
  return { end, length };
}

/**
 * given a date d, finds the full day containing d,
 * sets the slider to cover that full day,
 * and sets the selection to the time of the current selection, on that full day.
 */
const _setSliderAndSelectionDate = (
  state: TimeControlState,
  d: Date,
  tz: Timezone,
): void => {
  const selEnd = dateFns.fromUnixTime(state.selection.end);
  const selEndDay = startOfDayInTz(selEnd, tz.ianaId);
  const selEndTime = dateFns.intervalToDuration({
    start: selEndDay,
    end: selEnd,
  });

  const today = startOfDayInTz(dateFns.fromUnixTime(state.now), tz.ianaId);
  const newDay = startOfDayInTz(d, tz.ianaId);

  // prevent selecting days in the future
  ASSERTS && assert(newDay <= today);

  const newSelEnd = dateFns.add(newDay, selEndTime);

  const newSlider: TimeRange = setSliderToDate(newDay, state.now);

  // select a similar-ish time on the clicked day as on the current day
  let newSelection = {
    end: dateFns.getUnixTime(newSelEnd),
    length: state.selection.length,
  };
  newSelection = cropSelectionToSlider(newSelection, newSlider);

  state.selection = newSelection;
  state.slider = newSlider;
};

function _tickLive(state: TimeControlState, _now: Date) {
  const now = dateFns.getUnixTime(_now);
  const today = dateFns.getUnixTime(dateFns.startOfDay(now));

  // if in the past, snap to today
  const sliderStart = Math.max(state.slider.end - state.slider.length, today);
  const slider: TimeRange = {
    end: now,
    length: now - sliderStart,
  };
  const selection: TimeRange = {
    end: now,
    length: state.selection.length,
  };
  return { now, slider, selection };
}

const initialState = getInitialState(dateFns.getUnixTime(new Date()));

export const timeControlSlice = createSlice({
  name: "timeControl",
  initialState,
  reducers: {
    setLive: (state, action: PayloadAction<boolean>) => {
      state.live = action.payload;
    },
    tickLive: (state, action: PayloadAction<Date>) => {
      ({
        now: state.now,
        selection: state.selection,
        slider: state.slider,
      } = _tickLive(original(state), action.payload));
    },
    setSelection: (state, action: PayloadAction<TimeRange>) => {
      state.selection = clampRangeToConstraints(action.payload);
      expandSliderToSelection(state);
    },
    setSlider: (state, action: PayloadAction<TimeRange>) => {
      state.slider = clampRangeToConstraints(action.payload);
      state.selection = cropSelectionToSlider(state.selection, state.slider);
    },
    zoomSlider: (state, action: PayloadAction<number>) => {
      const factor = action.payload;
      const { slider, selection, live, now } = original(state);

      state.slider = _zoomSlider(factor, selection, slider, live, now);
      state.selection = cropSelectionToSlider(state.selection, state.slider);
    },
    toggleRangePopup: (state) => {
      state.rangePopupVisible = !state.rangePopupVisible;
    },
    setSliderAndSelectionDate: (
      state,
      action: PayloadAction<{ date: Date; tz: Timezone }>,
    ) => {
      _setSliderAndSelectionDate(state, action.payload.date, action.payload.tz);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      centerSpaceTime,
      (state, action: ReturnType<typeof centerSpaceTime>) => {
        ASSERTS && assert(!state.live);
        state.selection.end = action.payload.time;
        expandSliderToSelection(state);
      },
    );
  },
});

export const {
  setLive,
  setSelection,
  setSlider,
  toggleRangePopup,
  setSliderAndSelectionDate,
  tickLive,
  zoomSlider,
} = timeControlSlice.actions;

export default timeControlSlice.reducer;
