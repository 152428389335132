import { combineReducers } from "redux";

import alertors from "./alertor";
import annotators from "./annotator";
import deviceStates from "./device-state";
import devices from "./devices";
import eventGenerators from "./event-generators";
import events from "./events";
import geoLayers from "./layers";
import projects from "./projects";
import timeControl from "./time-control";
import timeSeriesRangeSelection from "./time-series-range-selection";
import settings from "./settings";
import { ui } from "./ui";

const appReducer = combineReducers({
  alertors,
  annotators,
  deviceStates,
  devices,
  eventGenerators,
  events,
  geoLayers,
  projects,
  timeControl,
  timeSeriesRangeSelection,
  ui,
  settings,
});

export default appReducer;
