import {
  Config,
  Fields,
  ImmutableTree,
  JsonGroup,
  Utils as QbUtils,
} from "react-awesome-query-builder";
import muiConfig from "react-awesome-query-builder/lib/config/mui";

import { JSONType, TypeObject } from "../../../utils/fields";
import {
  hasChanged,
  hasChangedForParsing,
  rectifyHasChanged,
} from "./has-changed";

const toQbType = (type: JSONType): keyof typeof muiConfig.types | undefined => {
  switch (type) {
    case "string":
      return "text";
    case "number":
      return "number";
    case "boolean":
      return "boolean";
    default:
      return undefined;
  }
};

export const isDisabled = (label: string) => {
  if (!label || typeof label !== "string") {
    return true;
  }

  return label.includes(".");
};

export const buildConfig = (t: TypeObject): Config => {
  const { proximity: _proximity, ...operators } = muiConfig.operators; // Proximity search is an not an applicable search operator
  return {
    ...muiConfig,
    fields: Object.entries(t).reduce((acc, [name, type]) => {
      const qbType = toQbType(type);
      if (qbType) {
        const orignalType = muiConfig.types[qbType];
        const orignalWidget = orignalType.widgets[qbType];
        const orignalOperators = orignalWidget.operators || [];

        return {
          ...acc,
          [name]: {
            label: name,
            type: qbType,
            operators: [...orignalOperators, "hasChanged"],
            fieldSettings: {},
            valueSources: ["value"],
            preferWidgets: [qbType],
            disabled: isDisabled(name),
          },
        };
      }
      return acc;
    }, {} as Fields),
    operators: {
      ...operators,
      hasChanged,
    },
    settings: {
      ...muiConfig.settings,
      removeEmptyGroupsOnLoad: false,
      removeIncompleteRulesOnLoad: false,
      removeInvalidMultiSelectValuesOnLoad: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fieldSeparator: null as any, // this disables interpreting nested keys like "nested.object".
    },
  };
};

export const buildParsingConfig = (config: Config) => ({
  ...config,
  operators: {
    ...config.operators,
    hasChanged: hasChangedForParsing,
  },
});

/**
 * Construct a ReactAwesomeQueryBuilder Tree from jsonlogic.
 * @param expression is a string of serialized jsonlogic, as stored in the `expression`
 *                   property of EventGenerators.
 */
export const eventGeneratorToTree = (
  expression: string | undefined,
  config: Config,
) => {
  if (expression) {
    const jsonLogic = rectifyHasChanged(JSON.parse(expression));
    if (jsonLogic) {
      const tree = QbUtils.loadFromJsonLogic(
        jsonLogic,
        buildParsingConfig(config),
      );
      if (tree) {
        return tree;
      }
    }
  }
  const queryValue: JsonGroup = { id: QbUtils.uuid(), type: "group" };
  return QbUtils.checkTree(QbUtils.loadTree(queryValue), config);
};

export const humanReadableRule = (tree: ImmutableTree, config: Config) =>
  QbUtils.queryString(tree, config);
