import { all } from "redux-saga/effects";

import alertorsSaga from "./alertor";
import annotatorBuilderSaga from "./annotator-builder";
import annotatorsSaga from "./annotator";
import confirmSaga from "./confirm";
import devicesSaga from "./devices";
import errorsSaga from "./errors";
import eventGeneratorsSaga from "./event-generators";
import layersSaga from "./layers";
import projectsSaga from "./projects";
import timeControlSaga from "./time-control";
import timeSeriesRangeSelectionSaga from "./time-series-range-selection";
import updateMapSaga from "./update-map";
import localStorageSaga from "./local-storage";

export default function* rootSaga() {
  yield all([
    alertorsSaga(),
    annotatorBuilderSaga(),
    annotatorsSaga(),
    confirmSaga(),
    devicesSaga(),
    errorsSaga(),
    eventGeneratorsSaga(),
    layersSaga(),
    projectsSaga(),
    timeControlSaga(),
    timeSeriesRangeSelectionSaga(),
    updateMapSaga(),
    localStorageSaga(),
  ]);
}
