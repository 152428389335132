import {
  all,
  call,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  ReadAlertorsRequest,
  ReadAlertorsResponse,
  UpdateAlertorRequest,
  DeleteAlertorRequest,
  CreateAlertorRequest,
  CreateAlertorResponse,
} from "../../generated/sora/app/v1beta/service_pb";

import {
  addAlertors,
  updateAlertor,
  deleteAlertor,
  createAlertorRequested,
  readAlertorsRequested,
  fromPB,
  toPB,
  Alertor,
  alertorSelectors,
  deleteAlertorRequested,
} from "../reducers/alertor";
import { UPDATE_DEBOUNCE_MS } from "../../constants";
import { callConfig } from "../../api/callConfig";

function* readAlertorsSaga(action: ReturnType<typeof readAlertorsRequested>) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const readAlertorsRequest = new ReadAlertorsRequest().setProjectId(
    action.payload,
  );
  let readAlertorsResponse: ReadAlertorsResponse;
  try {
    readAlertorsResponse = yield call(
      (req) => appService.readAlertors(req),
      readAlertorsRequest,
    );
  } catch (e) {
    console.error(e);
    return;
  }

  const alertors = readAlertorsResponse.getAlertorsList().map((l) => fromPB(l));
  yield put(addAlertors(alertors));
}

function* createAlertorSaga(action: ReturnType<typeof createAlertorRequested>) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const createAlertorRequest = new CreateAlertorRequest().setAlertor(
    toPB(action.payload),
  );
  let createAlertorResponse: CreateAlertorResponse;
  try {
    createAlertorResponse = yield call(
      (req) => appService.createAlertor(req),
      createAlertorRequest,
    );
  } catch (e) {
    console.error(e);
    return;
  }

  const maybeAlertor = createAlertorResponse.getAlertor();
  if (maybeAlertor) {
    yield put(addAlertors([fromPB(maybeAlertor)]));
  }
}

function* updateAlertorSaga(action: ReturnType<typeof updateAlertor>) {
  if (
    action.payload.changes.name ||
    action.payload.changes.color ||
    action.payload.changes.enabled ||
    action.payload.changes.config
  ) {
    const appService = callConfig.call.appServiceContext?.appService;
    if (!appService) {
      return;
    }

    const alertor: Alertor = yield select(
      alertorSelectors.selectById,
      action.payload.id.toString(),
    );

    const updateAlertorRequest = new UpdateAlertorRequest().setAlertor(
      toPB(alertor),
    );

    try {
      yield call((req) => appService.updateAlertor(req), updateAlertorRequest);
    } catch (e) {
      console.error(e);
      return;
    }
  }
}

function* deleteAlertorSaga(action: ReturnType<typeof deleteAlertorRequested>) {
  const appService = callConfig.call.appServiceContext?.appService;
  if (!appService) {
    return;
  }

  const deleteAlertorRequest = new DeleteAlertorRequest()
    .setId(action.payload.id)
    .setProjectId(action.payload.projectId);

  try {
    yield call((req) => appService.deleteAlertor(req), deleteAlertorRequest);
  } catch (e) {
    console.error(e);
    return;
  }

  yield put(deleteAlertor(action.payload.id));
}

export default function* alertorsSaga() {
  yield all([
    takeEvery(createAlertorRequested, createAlertorSaga),
    debounce(UPDATE_DEBOUNCE_MS, updateAlertor, updateAlertorSaga),
    takeEvery(deleteAlertorRequested, deleteAlertorSaga),
    takeLatest(readAlertorsRequested, readAlertorsSaga),
  ]);
}
