import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Icons, SidePanelSection, Tooltip } from "kepler.gl/components";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  layerSelectors,
  updateLayer,
  uploadModalShow,
} from "../../../redux/reducers/layers";
import { SidePanelRow } from "../SidePanelRow";
import {
  FieldName,
  FieldValue,
  IconButton,
  SidePanelIcons,
} from "../../common";
import { GeoLayerPanelHeader } from "./GeoLayerPanelHeader";
import {
  EntityType,
  updateEntityVisibility,
} from "../../../redux/reducers/settings";
import { selectVisible } from "../../../redux/selectors/settings";

export default () => {
  const dispatch = useAppDispatch();
  const layers = useAppSelector(layerSelectors.selectAll);
  const visibilities: { [key: string]: boolean } = useAppSelector(
    selectVisible(EntityType.Layer),
  );

  const onSetColor = useCallback(
    (id, color) => dispatch(updateLayer({ id, changes: { color } })),
    [dispatch],
  );

  return (
    <>
      <SidePanelSection>
        <SidePanelIcons>
          <IconButton>
            <Icons.EyeUnseen
              data-tip
              data-for="geoLayer.hideAll"
              onClick={() =>
                dispatch(
                  updateEntityVisibility({
                    entityType: EntityType.Layer,
                    updateAll: true,
                    value: false,
                  }),
                )
              }
              height="26"
            />
            <Tooltip id="geoLayer.hideAll" effect="solid" delayShow={500}>
              <FormattedMessage id="geoLayer.hideAll" />
            </Tooltip>
          </IconButton>
          <IconButton>
            <Icons.EyeSeen
              data-tip
              data-for="geoLayer.showAll"
              onClick={() =>
                dispatch(
                  updateEntityVisibility({
                    entityType: EntityType.Layer,
                    updateAll: true,
                    value: true,
                  }),
                )
              }
              height="26"
            />
            <Tooltip id="geoLayer.showAll" effect="solid" delayShow={500}>
              <FormattedMessage id="geoLayer.showAll" />
            </Tooltip>
          </IconButton>
        </SidePanelIcons>
      </SidePanelSection>
      <SidePanelSection>
        <Button
          tabIndex={-1}
          width="105px"
          onClick={() => dispatch(uploadModalShow)}
        >
          <Icons.Add height="12px" />
          <FormattedMessage id={"geoLayer.add"} />
        </Button>
      </SidePanelSection>
      <SidePanelSection>
        {layers.map((geoLayer) => (
          <SidePanelRow
            key={geoLayer.id}
            model={geoLayer}
            visible={visibilities[geoLayer.id] ?? true}
            Header={GeoLayerPanelHeader}
            onSetColor={onSetColor}
          >
            <FieldName>
              <FormattedMessage id={"geoLayer.details.id"} />
            </FieldName>
            <FieldValue>{geoLayer.id}</FieldValue>
          </SidePanelRow>
        ))}
      </SidePanelSection>
    </>
  );
};
