import { PayloadAction } from "@reduxjs/toolkit";
import { takeLeading, put, take, all } from "redux-saga/effects";
import {
  DialogDone,
  dialogDone,
  _hideDialog,
  _showDialog,
  startConfirm,
  StartConfirm,
} from "../reducers/ui/confirm";

function* startConfirmSaga({ payload: p }: PayloadAction<StartConfirm>) {
  const { onAccept, ...dialogProps } = p;

  yield put(_showDialog(dialogProps));
  const doneAction: PayloadAction<DialogDone> = yield take(dialogDone);
  yield put(_hideDialog());

  const done = doneAction.payload;
  switch (done.result) {
    case "OK":
      yield put(onAccept);
      break;
    case "cancelled":
      break;
    default: {
      const _t: never = done.result;
      throw new Error("unreachable");
    }
  }
}

export default function* confirmSaga() {
  yield all([takeLeading(startConfirm, startConfirmSaga)]);
}
