import { FC, HTMLAttributes, useMemo } from "react";
import styled from "styled-components";

import { useAppSelector } from "../../../redux/hooks";

import { selectDeviceStatesSlice } from "../../../redux/selectors/devices";
import { selectEvents } from "../../../redux/selectors/events";

import * as DS from "../../../redux/reducers/device-state";
import * as E from "../../../redux/reducers/events";
import * as loading from "../../../redux/reducers/loading";

import { DataMode } from "./TimeControl";
import { createChunkSelector } from "../../../redux/selectors/creators";
import { objectEntries } from "../../../redux/reducers/chunks";
import { RootState } from "../../../redux/store";

const Div_withLoadingIndicator = styled.div`
  position: relative;
`;

const Svg_overlay = styled.svg`
  position: absolute;
  width: 100%;
  height: 10px;

  pointer-events: none;

  z-index: 10; /* relies on definitions in kepler: above slider+selection, below handles */

  & .overlay {
    fill: url(#loadingStripes);
  }

  & #loadingStripe {
    fill: ${({ theme }) => theme.bottomWidgetBgd};
  }
`;

export type Props = {
  rangeStart: number;
  rangeEnd: number;
  dataMode: DataMode;
} & HTMLAttributes<HTMLDivElement>;

function chunkIsPending<C extends { requestState: loading.RequestState }>(
  chunkBounds: { startIncl: number; endExcl: number },
  chunk: C | undefined,
) {
  const wholeChunk = {
    start: chunkBounds.startIncl,
    end: chunkBounds.endExcl,
  };
  // No chunk? the whole thing is unloaded
  if (!chunk) {
    return { pending: false, unloaded: wholeChunk } as const;
  }
  if (chunk.requestState.type == "errored") {
    return { pending: false, unloaded: wholeChunk } as const;
  }
  // Whole thing loaded?
  if (chunk.requestState.type == "succeeded") {
    return { pending: false, unloaded: null } as const;
  }
  // return chunk as well so TS can verify it's never undefined
  return { pending: true, chunk } as const;
}

/**
 * Return the time span of a chunk that has **NOT** yet been
 * loaded
 */
const selectUnloadedDSBounds = createChunkSelector(
  [selectDeviceStatesSlice],
  [],
  function (chunkId, chunk) {
    const chunkBounds = DS.computeChunkBounds(chunkId);
    const p = chunkIsPending(chunkBounds, chunk);
    if (!p.pending) {
      return p.unloaded;
    } else {
      chunk = p.chunk;
    }

    let minTime = chunkBounds.endExcl;
    for (const entity of Object.values(chunk.states.entities)) {
      if (!entity || entity.states.length == 0) {
        continue;
      }
      const firstState = entity.states[0];
      if (firstState.time < minTime) {
        minTime = firstState.time;
      }
    }
    return { start: chunkBounds.startIncl, end: minTime };
  },
);
/**
 * Return the time span of a chunk that has **NOT** yet been
 * loaded
 */
const selectUnloadedEBounds = createChunkSelector(
  [selectEvents],
  [],
  function (chunkId, chunk) {
    const chunkBounds = E.computeChunkBounds(chunkId);
    const p = chunkIsPending(chunkBounds, chunk);
    if (!p.pending) {
      return p.unloaded;
    } else {
      chunk = p.chunk;
    }

    const minTime =
      chunk.events.length == 0 ? chunkBounds.endExcl : chunk.events[0].time;
    return { start: chunkBounds.startIncl, end: minTime };
  },
);

export const WithLoadingIndicator: FC<Props> = function WithLoadingIndicator({
  children,
  rangeStart,
  rangeEnd,
  dataMode,
  ...divProps
}) {
  const W = rangeEnd - rangeStart;

  const selectBounds = useMemo(() => {
    const dsChunkIds = DS.computeChunksInRange(rangeStart, rangeEnd);
    const evChunkIds = E.computeChunksInRange(rangeStart, rangeEnd);

    return {
      DEVICE_STATES: (state: RootState) =>
        selectUnloadedDSBounds.some(state, new Set(dsChunkIds)),
      EVENTS: (state: RootState) =>
        selectUnloadedEBounds.some(state, new Set(evChunkIds)),
    };
  }, [rangeStart, rangeEnd]);

  const unloadedBounds = useAppSelector(selectBounds[dataMode]);

  const chunkLoadState = useMemo(() => {
    return objectEntries(unloadedBounds.chunks)
      .map(([chunkId, c]) => {
        if (c === null) {
          return null;
        }

        const start = (c.start - rangeStart) / W;
        const end = (c.end - rangeStart) / W;
        const width = end - start;
        return { chunkId, x: start, width };
      })
      .filter(<T,>(r: T | null | undefined): r is T => !!r);
  }, [unloadedBounds]);

  return (
    <Div_withLoadingIndicator {...divProps}>
      <Svg_overlay xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="loadingStripes"
            patternUnits="userSpaceOnUse"
            width="2"
            height="1"
            patternTransform="scale(5,5) rotate(30)"
          >
            <rect id="loadingStripe" x="0" y="-0.5" width="1" height="2" />
          </pattern>
        </defs>
        {chunkLoadState.map((r) => (
          <rect
            className="overlay"
            key={r.chunkId}
            x={`${r.x * 100}%`}
            width={`${r.width * 100}%`}
            height="100%"
          />
        ))}
      </Svg_overlay>
      {children}
    </Div_withLoadingIndicator>
  );
};
