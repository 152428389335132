export type Order<T> = (_x: T, _y: T) => number;

// Append two presorted arrays from disjoint ranges in their relative order.
// The order must be specified by the `lessThan` function
// For example:
//  appendInOrder([20, 41, 64], [1, 5, 9], (x, y) => x < y) === [1, 5, 9, 20, 41, 64]
export const appendInOrder = <T>(xs: T[], ys: T[], lessThan: Order<T>): T[] => {
  if (xs.length === 0) return ys;
  if (ys.length === 0) return xs;

  const x0 = xs[0];
  const xEnd = xs.at(-1)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const y0 = ys[0];
  const yEnd = ys.at(-1)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  if (lessThan(x0, y0) <= 0 && lessThan(xEnd, y0) <= 0) {
    return [...xs, ...ys];
  } else if (lessThan(y0, x0) <= 0 && lessThan(yEnd, x0) <= 0) {
    return [...ys, ...xs];
  } else {
    /* this should never happen, but does in at least two situations I know of:
       - when streaming, the first few device states can sometimes be before the
         end of the first fetched block of history
       - event states can sometimes get stored in the database out of order, which
         breaks some assumptions needed for paging (SORA-454)
       both of these should be fixed (or we should decouple request paging from key
       monotonicity-with-time...).
       For now, we drop data if we hit these situations because it makes the frontend
       very slow.
       */

    ASSERTS &&
      console.warn(
        `appendInOrder would need to sort, dropping ${ys.length} values`,
      );
    return xs;
  }
};
