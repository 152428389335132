import "./fixups";
import "./globals";

import { init as initFullStory } from "./integrations/fullStory";
initFullStory();

import { render } from "react-dom";
import { Provider } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { HistoryRouter } from "redux-first-history/rr6";
import OutsideCallConsumer from "react-outside-call";
import { IntlProvider } from "react-intl";

import { history, store } from "./redux/store";
import {
  root,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
} from "./constants";
import { messages } from "./intl/message";
import { AppServiceProvider } from "./api/appService";
import { Protected } from "./components/Protected";
import { App } from "./routes/App";
import { Projects } from "./routes/Projects";
import { Project } from "./routes/Project";
import { Dashboard } from "./routes/Dashboard";
import { Devices } from "./routes/Devices";
import { DevicesCreate } from "./routes/DevicesCreate";
import { callConfig } from "./api/callConfig";

const Root = () => (
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    audience={AUTH0_AUDIENCE}
    redirectUri={window.location.origin}
    onRedirectCallback={(appState) =>
      history.push(appState?.returnTo ?? window.location.pathname)
    }
  >
    <AppServiceProvider>
      <Provider store={store}>
        <HistoryRouter history={history}>
          <IntlProvider locale="en" messages={messages.en}>
            <OutsideCallConsumer config={callConfig}>
              <Routes>
                <Route path="/" element={<Protected component={App} />}>
                  <Route index element={<Navigate to="projects" replace />} />
                  <Route path="projects" element={<Projects />}>
                    <Route path=":projectId" element={<Project />}>
                      <Route index element={<Dashboard />} />
                      <Route path="dashboard" element={<Dashboard />} />
                    </Route>
                  </Route>
                  <Route path="devices" element={<Devices />}>
                    <Route index element={<DevicesCreate />} />
                    <Route path="create" element={<DevicesCreate />} />
                  </Route>
                </Route>
              </Routes>
            </OutsideCallConsumer>
          </IntlProvider>
        </HistoryRouter>
      </Provider>
    </AppServiceProvider>
  </Auth0Provider>
);

render(<Root />, root);
