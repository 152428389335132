import assert from "assert";
import range from "lodash.range";
import "core-js/actual/array/group";

// WTF https://stackoverflow.com/a/72945874
export type ChunkId = `${number}`;

// just a useful type override
export const objectEntries = Object.entries as <T>(
  o: Chunks<T>["chunks"],
) => [ChunkId, T][];

// chunk stuff {
export type Chunks<T> = {
  chunks: Partial<Record<ChunkId, T>>;
  chunkLengthSeconds: number;
};

export function mapChunks<T, R>(
  chunked: Chunks<T>,
  fn: (t: T, chunkId: ChunkId) => R,
) {
  const result: Chunks<R> = {
    chunks: {},
    chunkLengthSeconds: chunked.chunkLengthSeconds,
  };
  const input = chunked.chunks;
  const output = result.chunks;
  let chunkId: ChunkId;
  for (chunkId in input) {
    const chunk = input[chunkId]!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    output[chunkId] = fn(chunk, chunkId);
  }
  return result;
}

// 10 out of 10 nutritionists recommend googling "how do i make a newtype in typescript"
// after looking up the source of this function
export function computeChunkId(
  chunkLengthSeconds: number,
  timestampSec: number,
) {
  return Math.floor(timestampSec / chunkLengthSeconds);
}

/**
 * Given a chunk id, compute its half-open timespan of [startIncl, endExcl), given in seconds.
 */
export function computeChunkBounds(
  chunkLengthSeconds: number,
  chunkId: ChunkId | number,
) {
  const chunkNum = typeof chunkId === "number" ? chunkId : parseInt(chunkId);
  const startIncl = chunkNum * chunkLengthSeconds;
  ASSERTS && assert(!isNaN(startIncl));
  const endExcl = startIncl + chunkLengthSeconds;
  return { startIncl, endExcl };
}

export function computeChunkIdsInRange(
  chunkLengthSeconds: number,
  startIncl: number,
  endIncl: number,
): ChunkId[] {
  const first = computeChunkId(chunkLengthSeconds, startIncl);
  const final = computeChunkId(chunkLengthSeconds, endIncl);

  return range(first, final + 1).map((n) => n.toString() as ChunkId);
}

export type GroupedStuff<T extends { time: number }> = {
  [entityId: string]: T[];
};

/**
 * Given an input like
 *
 *     {
 *       somethingId: [{time: 0}, {time: 10}, {time: 100}, {time: 10000}]
 *       someOtherId: [{time: 20}, {time: 55555}]
 *     }
 *
 * This will chunk everything, and move the entity keys to inside the chunks, returning something like
 *
 *     {
 *        chunks: {
 *          "0": {
 *             somethingId: [{time: 0}, {time: 10}, {time: 100}],
 *             someOtherId: [{time: 20}]
 *           },
 *           "1": {
 *             somethingId: [{time: 10000}],
 *             someOtherId: [{time: 55555}]
 *           }
 *        }
 *     }
 */
export function chunkGroupedItems<T extends { time: number }>(
  chunkLengthSeconds: number,
  groupedStates: GroupedStuff<T>,
): Chunks<typeof groupedStates> {
  const result: Chunks<typeof groupedStates> = {
    chunks: {},
    chunkLengthSeconds: chunkLengthSeconds,
  };
  for (const [entityId, allStates] of Object.entries(groupedStates)) {
    const chunkedStates = allStates.group((s) =>
      computeChunkId(chunkLengthSeconds, s.time),
    );
    for (const [_chunkId, states] of Object.entries(chunkedStates)) {
      const chunkId = _chunkId as ChunkId;
      if (!(chunkId in result.chunks)) {
        result.chunks[chunkId] = {};
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Object.assign(result.chunks[chunkId]!, { [entityId]: states });
    }
  }
  return result;
}
