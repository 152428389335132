import { FC, FormEvent } from "react";
import { FormattedMessage } from "react-intl";
import { InlineInput } from "kepler.gl/components";

import { useAppDispatch } from "../../../redux/hooks";
import { Alertor, updateAlertor } from "../../../redux/reducers/alertor";
import { FieldName, FieldValue } from "../../common";

type AlertConfiguratorProps = {
  model: Alertor;
};

export const AlertConfigurator: FC<AlertConfiguratorProps> = ({ model }) => {
  const dispatch = useAppDispatch();

  const onEdit = (e: FormEvent<HTMLInputElement>) =>
    dispatch(
      updateAlertor({
        id: model.id,
        changes: { config: { url: e.currentTarget.value } },
      }),
    );

  return (
    <>
      <FieldName>
        <FormattedMessage id="alertor.config.id" />
      </FieldName>
      <FieldValue>{model.id}</FieldValue>
      <FieldName>
        <FormattedMessage id="alertor.config.url" />
      </FieldName>
      <InlineInput
        type="text"
        value={model.config.url || ""}
        onClick={(e: FormEvent<HTMLInputElement>) => {
          e.stopPropagation();
        }}
        onChange={onEdit}
      />
    </>
  );
};
